import React, { Fragment, useContext, useState, useEffect } from "react";
import { Media } from "react-breakpoints";
import { Card, Col, Row } from "reactstrap";
import _ from "lodash";
import { api, UserContext, constants } from "../utils";
import {
  FilterableScreen,
  StatCard,
  ShipmentCard,
  SHBSpinner,
} from "../components";
import { DesktopContainer } from "../layouts";
import Alert from "react-s-alert";

export default function DashboardCompany(props) {
  const userCtx = useContext(UserContext);
  const orgId =
    userCtx && userCtx.currentUser && userCtx.currentUser.currentOrgId
      ? userCtx.currentUser.currentOrgId
      : userCtx.currentUser && userCtx.currentUser.orgId;
  const isOrgAdmin = userCtx.currentUser.isCompanyAdminUser || userCtx.currentUser.isHaulerAdminUser;

  const [organization, setOrganization] = useState(null);
  // const [activeJobs, setActiveJobs] = useState([]);
  const [openJobCount, setOpenJobCount] = useState([]);
  const [completedJobCount, setCompletedJobCount] = useState([]);
  const [reservedJobCount, setReservedJobCount] = useState([]);
  const [scheduledJobCount, setScheduledJobCount] = useState([]);
  const [inProgressJobCount, setInProgressJobCount] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [privateJobCount, setPrivateJobCount] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [activeJobStates, setActiveJobStates] = useState([]);
  // const [selectedState, setSelectedState] = useState(null);
  // const [filteredActiveJobs, setFilteredActiveJobs] = useState([]);
  // const [haulerDriverLists, setHaulerDriverLists] = useState([]);
  const showError = props.showError;

  useEffect(() => {
    setLoading(true);
    api
      .post(`general/GetCompanyDashboardData/${orgId}`)
      .then((response) => {
        if (response && response.data && response.data.success == false) {
          Alert.error("There was an error retrieving company dashboard data");
        }
        if (response && response.data) {
          setOrganization(response.data.organization);
          // setActiveJobs(response.data.activeJobs);
          setOpenJobCount(response.data.openJobCount);
          setCompletedJobCount(response.data.completedJobCount);
          setReservedJobCount(response.data.reservedJobCount);
          setScheduledJobCount(response.data.scheduledJobCount);
          setInProgressJobCount(response.data.inProgressJobCount);
          setActiveUsers(response.data.userList);
          setPrivateJobCount(response.data.privateJobCount);
          // setActiveJobStates(
          //   response.data.activeJobs && response.data.activeJobs.length > 0
          //     ? _.sortBy(
          //         _.uniq(response.data.activeJobs.map((x) => x.toStateName))
          //       )
          //     : []
          // );
          // setHaulerDriverLists(response.data.haulerDriverLists);
        }
      })
      .catch((error) => {
        if (showError) {
          Alert.error("There was an error retrieving company dashboard data");
        } else {
          console.error(error);
        }
      })
      .finally(() => setLoading(false));
  }, [userCtx.currentUser, showError, orgId]);

  // const filterBySeletedState = (state) => {
  //   setSelectedState(state);
  //   setFilteredActiveJobs(_.filter(activeJobs, (x) => x.toStateName === state));
  // };

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer
                  // screenName={`${organization && organization.name} Dashboard`}
                  screenName={organization ? `${organization.name} Dashboard` : null}
                >
                  <FilterableScreen
                    filters={<Fragment></Fragment>}
                    pager={null}
                  >
                    <Row>
                      <Col xs="12" md="9" className="mt-4">
                        <Row className="p-0 m-0 mb-2">
                          {userCtx.currentUser.isHaulerUser
                            ? <StatCard
                                cardName={"Search Open Jobs"}
                                link={`/jobs/company/${constants.JOB_STATUS_IDS.OPEN}`}
                                xs="12"
                              />
                            : <StatCard
                                cardName={"Open Jobs"}
                                count={openJobCount}
                                link={`/jobs/company/${constants.JOB_STATUS_IDS.OPEN}`}
                                xs="12"
                              />
                          }
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Reserved Jobs"}
                            count={reservedJobCount}
                            link={`/jobs/company/${constants.JOB_STATUS_IDS.RESERVED}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Scheduled Jobs"}
                            count={scheduledJobCount}
                            link={`/jobs/company/${constants.JOB_STATUS_IDS.SCHEDULED}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"In Progress Jobs"}
                            count={inProgressJobCount}
                            link={`/jobs/company/${constants.JOB_STATUS_IDS.IN_PROGRESS}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Completed Jobs"}
                            count={completedJobCount}
                            link={`/jobs/company/${constants.JOB_STATUS_IDS.COMPLETE}`}
                            xs="12"
                          />
                        </Row>
                        {isOrgAdmin && activeUsers && activeUsers.length ? (
                          <Row className="p-0 m-0 mb-2">
                            <StatCard
                              cardName={"Users"}
                              count={activeUsers.length}
                              link={`/users/company`}
                              xs="12"
                            />
                          </Row>
                        ) : null}
                        {userCtx.currentUser.isHaulerUser
                          ? <StatCard
                              cardName={"Private Jobs"}
                              count={privateJobCount}
                              link={`/privatejobs/`}
                              xs="12"
                            />
                          : null
                        }
                        {/* {haulerDriverLists && haulerDriverLists.length > 0 && (
                          <Row className="p-0 m-0 mb-2">
                            <Col xs="12" className="px-1">
                              <Card className="statCard p-2">
                                <Row>
                                  <Col className="card-title">
                                    Active Haulers and Drivers
                                  </Col>
                                </Row>
                                {haulerDriverLists.map((x, index) => (
                                  <Fragment key={`hauler-firms-${index}`}>
                                    <Row>
                                      <Col>{x.haulerFirm.name}</Col>
                                    </Row>
                                    <Row key={`haulers-${index}`}>
                                      <Col className="ml-4">
                                        {x.haulerDrivers
                                          .map((d) => d.fullName)
                                          .join(", ")}
                                      </Col>
                                    </Row>
                                  </Fragment>
                                ))}
                              </Card>
                            </Col>
                          </Row>
                        )} */}
                      </Col>
                      {/* <Col>
                        <h5 className="primaryDarkText">
                          Upcoming Deliveries (Choose State)
                        </h5>
                        {activeJobStates && activeJobStates.length > 0 && (
                          <select
                            className="form-control"
                            value={selectedState || ""}
                            onChange={(e) =>
                              filterBySeletedState(e.target.value)
                            }
                          >
                            <option value=""></option>
                            {activeJobStates.map((x, index) => (
                              <option
                                key={`activejobstates-${index}`}
                                value={x}
                              >
                                {x}
                              </option>
                            ))}
                          </select>
                        )}
                        {filteredActiveJobs && filteredActiveJobs.length > 0
                          ? _.map(filteredActiveJobs, (j, index) => {
                              return (
                                <Row
                                  key={`shipment-${index}`}
                                  className="p-0 m-0 mb-2"
                                >
                                  <ShipmentCard
                                    cardName={""}
                                    shipment={j}
                                    link={`/job/${j.id}`}
                                    xs="12"
                                  />
                                </Row>
                              );
                            })
                          : null}
                      </Col> */}
                    </Row>
                  </FilterableScreen>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
