import React, { useState, useContext, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Media } from "react-breakpoints";
import { Card, CardBody, Col, Button, Row, Alert } from "reactstrap";
import DebounceInput from "react-debounce-input";
import Select from "react-select";
import _ from "lodash";
import { api, helpers, UserContext, constants } from "../utils";
import {
  CollapsibleCardSection,
  DocumentUpload,
  ProfileImageRender,
} from "../components";
import { DesktopContainer } from "../layouts";
import Switch from "react-switch";

export default function Profile(props) {
  const userCtx = useContext(UserContext);
  const inviteToken = props?.computedMatch?.params?.inviteToken
    ? props.computedMatch.params.inviteToken
    : null;
  const [user, setUser] = useState(userCtx.currentUser);
  const [timezoneList, setTimezoneList] = useState([]);
  const [redirectPath, setRedirectPath] = useState(null);
  const [message, setMessage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    api.getTimezones().then(({ timezones }) => setTimezoneList(timezones));
    if (!inviteToken) {
      setUser(userCtx.currentUser);
    }
  }, [userCtx.currentUser, inviteToken]);

  useEffect(refresh, [user]);

  function refresh() {
    if (!user) return;
    api.post(`general/GetUserDocuments/${user.id}`).then((response) => {
      if (response?.data) {
        setDocumentList(response.data);
        setProfileImage(
          _.find(
            response.data,
            (p) =>
              p.documentTypeId ===
              constants.DOCUMENT_TYPE_IDS.USER_PROFILE_IMAGE
          )
        );
      }
    });
  }

  function onChange(fieldName, fieldValue) {
    let newUser = Object.assign({}, user);
    newUser[fieldName] = fieldValue;
    setUser(newUser);
  }

  function onSubmit() {
    if (inviteToken) {
      setMessage({
        text: "Your user profile has been successfully created. Redirecting you to login now.",
        flavor: "alert-success",
      });
      setRedirectPath("/");
      return;
    }
    let warnings = [];
    if (!user.firstName || _.trim(user.firstName).length < 2) {
      warnings.push("Please provide a valid first name.");
    }
    if (!user.lastName || _.trim(user.lastName).length < 2) {
      warnings.push("Please provide a valid last name.");
    }
    if (!user.username || _.trim(user.username).length < 2) {
      warnings.push("Please provide a username.");
    }
    if (!user.email || !helpers.emailIsValid(user.email)) {
      warnings.push("Please provide a valid email.");
    }
    if (!user.timezone) {
      warnings.push("Please provide a timezone.");
    }
    if (
      _.some(user.roles, (r) => constants.ROLE_IDS.DRIVER === r.typeOfUserRole)
    ) {
      if (!user.cdlNumber || _.trim(user.cdlNumber).length < 2) {
        warnings.push("Please provide a CDL number.");
      }
      if (!user.cdlState) {
        warnings.push("Please provide a CDL state.");
      }
    }
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    api
      .post("Public/SaveProfile", user)
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Your profile has been updated successfully!",
            flavor: "alert-success",
          });
          setTimeout(() => userCtx.signIn(user), 3000);
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onUpdatePassword() {
    let warnings = [];
    if (!user.newPassword || _.trim(user.newPassword).length < 8) {
      warnings.push(
        "Please provide a valid, new password of at least 8 characters."
      );
    }
    if (!user.oldPassword || _.trim(user.oldPassword).length < 8) {
      warnings.push(
        "Please provide a valid, existing password of at least 8 characters."
      );
    }
    if (!user.newPasswordAgain || _.trim(user.newPasswordAgain).length < 8) {
      warnings.push("Please provide your valid, new password a second time.");
    }
    if (user.newPassword !== user.newPasswordAgain) {
      warnings.push(
        "Your new password and password confirmation do not match."
      );
    }
    if (user.newPassword === user.oldPassword) {
      warnings.push("Your new password and old password must be different.");
    }
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    api
      .post("Public/UpdatePassword", {
        password: user.newPassword,
        oldPassword: user.oldPassword,
      })
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Your password has been updated successfully!",
            flavor: "alert-success",
          });
          setUser({
            ...user,
            oldPassword: "",
            newPassword: "",
            newPasswordAgain: "",
          });
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  const isImpersonating = localStorage.getItem("adminUser") ? true : false;
  if (!user) return null;
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={`Profile`}>
                {message ? (
                  <Alert className={message.flavor}>{message.text}</Alert>
                ) : null}
                <Row className={inviteToken ? "publicPagePadRight" : ""}>
                  <Col xs="12" className="mx-0 px-0">
                    <CollapsibleCardSection
                      cardName={"My User Profile"}
                      startOpen={true}
                      cardBody={
                        <Fragment>
                          <Row>
                            {
                              <ProfileImageRender
                                profileImage={profileImage}
                                name={`${user.first_name} ${user.last_name}`}
                              />
                            }
                            <Col>
                              <Row className="mb-2 align-items-end">
                                <Col xs="12">
                                  <Row className="mb-1">
                                    <Col xs="3">
                                      First Name {helpers.requiredStar()}
                                      <DebounceInput
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        onChange={(event) =>
                                          onChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        value={user.firstName || ""}
                                        className="form-control"
                                        debounceTimeout={300}
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Last Name {helpers.requiredStar()}
                                      <DebounceInput
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        onChange={(event) =>
                                          onChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        value={user.lastName || ""}
                                        className="form-control"
                                        debounceTimeout={300}
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Username {helpers.requiredStar()}
                                      <DebounceInput
                                        type="text"
                                        name="username"
                                        id="username"
                                        onChange={(event) =>
                                          onChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        value={user.username || ""}
                                        className="form-control"
                                        debounceTimeout={300}
                                      />
                                    </Col>
                                    <Col xs="3">
                                      Timezone {helpers.requiredStar()}
                                      <Select
                                        options={timezoneList}
                                        value={_.find(
                                          timezoneList,
                                          (x) => x.value === user.timezone
                                        )}
                                        onChange={(option) =>
                                          onChange("timezone", option.value)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-1">
                                    <Col xs="3">
                                      Email {helpers.requiredStar()}
                                      <DebounceInput
                                        type="text"
                                        name="email"
                                        id="email"
                                        onChange={(event) =>
                                          onChange(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        value={user.email || ""}
                                        className="form-control"
                                        debounceTimeout={300}
                                      />
                                    </Col>
                                    <Col xs="3">
                                      <div>Proximity Alerts</div>
                                      <Switch
                                        name="proximityAlertsOn"
                                        onChange={(event) =>
                                          onChange(
                                            "proximityAlertsOn",
                                            !user.proximityAlertsOn
                                          )
                                        }
                                        checked={user.proximityAlertsOn}
                                      />
                                    </Col>
                                    {user.proximityAlertsOn && (
                                      <Col xs="3">
                                        Alert Radius In Miles{" "}
                                        {helpers.requiredStar()}
                                        <DebounceInput
                                          type="number"
                                          name="proximityAlertsRadiusInMiles"
                                          id="proximityAlertsRadiusInMiles"
                                          onChange={(event) =>
                                            onChange(
                                              event.target.name,
                                              event.target.value
                                            )
                                          }
                                          value={
                                            user.proximityAlertsRadiusInMiles ||
                                            ""
                                          }
                                          className="form-control"
                                          style={{ maxWidth: 90 }}
                                          debounceTimeout={300}
                                        />
                                      </Col>
                                    )}
                                    <Col xs="2">
                                      <Col xs="12" className="text-right">
                                        <Button
                                          className="projectPrimary mt-3"
                                          onClick={() => onSubmit()}
                                        >
                                          Save
                                        </Button>
                                      </Col>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mb-4">
                                <Col xs="4">
                                  <Card>
                                    <CardBody>
                                      <Row className="mb-1">
                                        {inviteToken ? null : (
                                          <Col xs="12">
                                            <div className="my-1">
                                              Old Password{" "}
                                              {helpers.requiredStar()}
                                            </div>
                                            <DebounceInput
                                              type="password"
                                              name="oldPassword"
                                              id="oldPassword"
                                              onChange={(event) =>
                                                onChange(
                                                  event.target.name,
                                                  event.target.value
                                                )
                                              }
                                              value={user.oldPassword || ""}
                                              className="form-control"
                                              debounceTimeout={300}
                                            />
                                          </Col>
                                        )}
                                      </Row>
                                      <Row className="mb-1">
                                        <Col xs="12">
                                          <div className="my-1">
                                            {inviteToken ? null : `New `}
                                            Password {helpers.requiredStar()}
                                          </div>
                                          <DebounceInput
                                            type="password"
                                            name="newPassword"
                                            id="newPassword"
                                            onChange={(event) =>
                                              onChange(
                                                event.target.name,
                                                event.target.value
                                              )
                                            }
                                            value={user.newPassword || ""}
                                            className="form-control"
                                            debounceTimeout={300}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="mb-1">
                                        <Col xs="12">
                                          <div className="my-1">
                                            Retype Password{" "}
                                            {helpers.requiredStar()}
                                          </div>
                                          <DebounceInput
                                            type="password"
                                            name="newPasswordAgain"
                                            id="newPasswordAgain"
                                            onChange={(event) =>
                                              onChange(
                                                event.target.name,
                                                event.target.value
                                              )
                                            }
                                            value={user.newPasswordAgain || ""}
                                            className="form-control"
                                            debounceTimeout={300}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs="12" className="text-right">
                                          <Button
                                            className="projectPrimary"
                                            onClick={() => onUpdatePassword()}
                                            size="sm"
                                          >
                                            Update Password
                                          </Button>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                {isImpersonating ? (
                                  <Col xs="4">
                                    <Button
                                      color="dark"
                                      onClick={() =>
                                        userCtx.clearImpersonation()
                                      }
                                    >
                                      Stop Impersonating
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        </Fragment>
                      }
                    />
                    <Row className="mt-2">
                      {user?.includeHaulerModule && (
                        <Col xs="6">
                          <CollapsibleCardSection
                            cardName={"Driver Information"}
                            startOpen={true}
                            cardBody={
                              <Row>
                                <Col>
                                  CDL Number {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="cdlNumber"
                                    id="cdlNumber"
                                    onChange={(event) =>
                                      onChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                    value={user.cdlNumber || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                                <Col>
                                  CDL State {helpers.requiredStar()}
                                  <Select
                                    name="cdlState"
                                    id="cdlState"
                                    closeMenuOnSelect={true}
                                    value={_.find(
                                      constants.STATES,
                                      (u) => u.value === user.cdlState
                                    )}
                                    options={constants.STATES}
                                    onChange={(event) =>
                                      onChange("cdlState", event.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            }
                          />
                        </Col>
                      )}

                      <Col xs="6">
                        <DocumentUpload
                          cardName={helpers.cardHeaderName(
                            "Documents",
                            "upload"
                          )}
                          documentList={documentList}
                          uploadUrl={`general/UploadDocument`}
                          deleteUrl={`general/DeleteDocument`}
                          refresh={refresh}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
