import React, { useState, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import DebounceInput from "react-debounce-input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Alert from "react-s-alert";
import { api, helpers, constants } from "../utils";
import { CollapsibleCardSection, EditUnitType } from "../components";
import { DesktopContainer } from "../layouts";

const HaulerFirm = "Hauler Firm";

export default function CompanyRegistration(props) {
  const [org, setOrganization] = useState(
    Object.assign({}, constants.EMPTY_ORGANIZATION)
  );
  const [stateList, setStateList] = useState([]);
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [redirectToHome, setRedirectToHome] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    api
      .fetch("public/StateList")
      .then((response) => {
        if (response.data) {
          setStateList(
            _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });

    api
      .fetch("public/OrgTypeList")
      .then((response) => {
        if (response.data && response.data.length) {
          setCompanyTypeList(
            _.chain(response.data)
              .reject((d) => {
                return d.name === HaulerFirm;
              })
              .map((d) => {
                d.label = d.name;
                d.value = d.id;
                return d;
              })
              .value()
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function formatLocations() {
    let list = [];
    let primaryLocation = {
      StreetAddress1: org.addressLine1,
      StreetAddress2: org.addressLine2,
      City: org.city,
      StateId: org.state ? org.state.value : null,
      ZipCode: org.zip,
      LocationTypeId: constants.LOCATION_TYPES.Primary,
    };

    list.push(primaryLocation);

    if (org.sameAccountingAddress) {
      let payToLocation = Object.assign({}, primaryLocation);
      payToLocation.LocationTypeId = constants.LOCATION_TYPES.PayTo;
      list.push(payToLocation);
    } else {
      list.push({
        StreetAddress1: org.accountingAddressLine1,
        StreetAddress2: org.accountingAddressLine2,
        City: org.accountingCity,
        StateId: org.accountingState ? org.accountingState.value : null,
        ZipCode: org.accountingZip,
        LocationTypeId: constants.LOCATION_TYPES.PayTo,
      });
    }
    return list;
  }

  function submitRegistration() {
    setSaving(true);
    let warnings = [];
    if (!org.name || _.trim(org.name).length < 2) {
      warnings.push("Please provide a company name");
    }
    if (!org.email || !helpers.emailIsValid(org.email)) {
      warnings.push("Please provide a valid company email.");
    }
    if (!org.phoneNumber) {
      warnings.push("Please provide a company phone number.");
    }
    if (!org.addressLine1) {
      warnings.push("Please provide a company address line 1.");
    }
    if (!org.city) {
      warnings.push("Please provide a City.");
    }
    if (!org.state) {
      warnings.push("Please provide a State.");
    }
    if (!org.zip) {
      warnings.push("Please provide a Zip.");
    }
    if (!org.einNumber || org.einNumber.length < 9) {
      warnings.push("Please provide a valid EIN number.");
    }
    if (
      !org.primaryContactFirstName ||
      _.trim(org.primaryContactFirstName).length < 2
    ) {
      warnings.push("Please provide a valid first name for primary contact.");
    }
    if (
      !org.primaryContactLastName ||
      _.trim(org.primaryContactLastName).length < 2
    ) {
      warnings.push("Please provide a valid last name for primary contact.");
    }
    if (
      !org.primaryContactPhoneNumber ||
      _.trim(org.primaryContactPhoneNumber).length < 10
    ) {
      warnings.push("Please provide a valid phone number for primary contact.");
    }
    if (
      !org.primaryContactEmail ||
      !helpers.emailIsValid(org.primaryContactEmail)
    ) {
      warnings.push("Please provide a valid email for primary contact.");
    }
    if (!org.password || _.trim(org.password).length < 8) {
      warnings.push(
        "Please provide a valid password of at least 8 characters."
      );
    }
    if (!org.passwordAgain || _.trim(org.passwordAgain).length < 8) {
      warnings.push(
        "Please provide your valid confirmation password of at least 8 characters."
      );
    }
    if (org.password !== org.passwordAgain) {
      warnings.push("Your password and confirmation password do not match.");
    }

    if (warnings.length) {
      Alert.error(warnings.join(" "));
      setSaving(false);
      return;
    }

    let user = {
      FirstName: org.primaryContactFirstName,
      MiddleName: org.primaryContactMiddleName,
      LastName: org.primaryContactLastName,
      Email: org.primaryContactEmail,
      Username:
        org.primaryContactUsername !== ""
          ? org.primaryContactUsername
          : org.primaryContactEmail,
      PhoneNumber: org.primaryContactPhoneNumber,
      Password: org.password,
      IsPrimaryContact: org.isPrimaryContact,
    };

    let company = {
      Id: org.id,
      Name: org.name,
      Email: org.email,
      PhoneNumber: org.phoneNumber,
      AboutUs: org.aboutUs,
      EIN: org.einNumber,
      AlternateEIN: org.alternateEIN,
      OrgTypeId: org.companyType ? org.companyType.value : null,
    };

    let payload = {
      User: user,
      Company: company,
      Locations: formatLocations(),
      UnitTypes:
        org.unitTypes && org.unitTypes.length
          ? _.map(org.unitTypes, (t) => {
              return { Id: t.value, Name: t.label };
            })
          : [],
    };

    api
      .post(`Public/RegisterOrg`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success(
            "Your registration is now complete! You will now be redirected to the logon screen."
          );
          return setTimeout(() => setRedirectToHome("/"), 3000);
        } else {
          Alert.error(response.data.message || "An error occurred.");
          setSaving(false);
          return;
        }
      })
      .catch(helpers.catchHandler);
  }

  function registerButton(className) {
    return (
      <Row className="mt-2">
        <Col>
          <ButtonGroup className={className}>
            <Link to="/">
              <Button size="sm" color="link">
                {"Cancel"}
              </Button>
            </Link>
            <Button
              size="sm"
              color="primary"
              onClick={() => submitRegistration()}
              disabled={saving}
            >
              {saving && <FontAwesomeIcon icon="spinner" spin />}

              {"Register"}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  }

  if (redirectToHome !== null) {
    return <Redirect to={redirectToHome} />;
  }

  const screenName = (
    <span className="float-left">
      <FontAwesomeIcon icon="building" /> Company Registration{" "}
    </span>
  );
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={screenName}>
                <Fragment>
                  {registerButton("float-right mb-2")}
                  {/* COMPANY DETAILS */}
                  <Row className="mb-3">
                    <Col>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h5>Company Profile</h5>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row className="mb-1">
                            <Col>
                              Company Name {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="name"
                                id="name"
                                maxLength={50}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    name: e.target.value,
                                  })
                                }
                                value={org.name || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              Email {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="email"
                                id="email"
                                maxLength={320}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    email: e.target.value,
                                  })
                                }
                                value={org.email || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              Address Line 1 {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="addressLine1"
                                id="addressLine1"
                                maxLength={150}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    addressLine1: e.target.value,
                                  })
                                }
                                value={org.addressLine1 || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col>
                              Address Line 2
                              <DebounceInput
                                type="text"
                                name="addressLine2"
                                id="addressLine2"
                                maxLength={150}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    addressLine2: e.target.value,
                                  })
                                }
                                value={org.addressLine2 || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xs="5">
                              City {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="city"
                                id="city"
                                maxLength={150}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    city: e.target.value,
                                  })
                                }
                                value={org.city || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                            <Col xs="3">
                              State {helpers.requiredStar()}
                              <Select
                                closeMenuOnSelect={true}
                                isMulti={false}
                                components={makeAnimated()}
                                options={stateList}
                                onChange={(e) =>
                                  setOrganization({ ...org, state: e })
                                }
                                value={org.state || null}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                onCloseResetsInput={false}
                                classNamePrefix="react-select"
                              />
                            </Col>
                            <Col xs="4">
                              Zip {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="zip"
                                id="zip"
                                maxLength={15}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    zip: e.target.value,
                                  })
                                }
                                value={org.zip || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col xs="12">
                              Phone Number {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                maxLength={20}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    phoneNumber: e.target.value,
                                  })
                                }
                                value={org.phoneNumber || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col xs="12">
                              Company Type {helpers.requiredStar()}
                              <Select
                                closeMenuOnSelect={true}
                                isMulti={false}
                                components={makeAnimated()}
                                options={companyTypeList}
                                onChange={(e) =>
                                  setOrganization({ ...org, companyType: e })
                                }
                                value={org.companyType || null}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                onCloseResetsInput={false}
                                classNamePrefix="react-select"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col xs="12">
                              EIN Number {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="einNumber"
                                id="einNumber"
                                maxLength={64}
                                onChange={(e) =>
                                  setOrganization({
                                    ...org,
                                    einNumber: e.target.value,
                                  })
                                }
                                value={org.einNumber || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {/* BILL TO ADDRESS */}
                  <Row className="mb-3">
                    <Col>
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h5>Pay To Address</h5>
                            </Col>
                            <Col>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={org.sameAccountingAddress}
                                    onChange={() =>
                                      setOrganization({
                                        ...org,
                                        sameAccountingAddress:
                                          !org.sameAccountingAddress,
                                      })
                                    }
                                  />{" "}
                                  Same as company address
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardHeader>
                        {!org.sameAccountingAddress && (
                          <CardBody>
                            <Row className="mb-1">
                              <Col>
                                Address Line 1 {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="accountingAddressLine1"
                                  id="accountingAddressLine1"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      accountingAddressLine1: e.target.value,
                                    })
                                  }
                                  value={org.accountingAddressLine1 || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-1">
                              <Col>
                                Address Line 2
                                <DebounceInput
                                  type="text"
                                  name="accountingAddressLine2"
                                  id="accountingAddressLine2"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      accountingAddressLine2: e.target.value,
                                    })
                                  }
                                  value={org.accountingAddressLine2 || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs="5">
                                City {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="accountingCity"
                                  id="accountingCity"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      accountingCity: e.target.value,
                                    })
                                  }
                                  value={org.accountingCity || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                  // placeholder="Tupelo"
                                />
                              </Col>
                              <Col xs="3">
                                State {helpers.requiredStar()}
                                <Select
                                  closeMenuOnSelect={true}
                                  isMulti={false}
                                  components={makeAnimated()}
                                  options={stateList}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      accountingState: e,
                                    })
                                  }
                                  value={org.accountingState || null}
                                  onBlurResetsInput={false}
                                  onSelectResetsInput={false}
                                  onCloseResetsInput={false}
                                  classNamePrefix="react-select"
                                />
                              </Col>
                              <Col xs="4">
                                Zip {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="accountingZip"
                                  id="accountingZip"
                                  maxLength={15}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      accountingZip: e.target.value,
                                    })
                                  }
                                  value={org.accountingZip || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        )}
                      </Card>
                    </Col>
                  </Row>

                  {/* PRIMARY USER */}
                  <Row className={"mb-2"}>
                    <Col>
                      <CollapsibleCardSection
                        cardName={"Primary Contact Information"}
                        cardBody={
                          <Fragment>
                            <div className="mb-2 pl-2 pr-2">
                              <i>
                                This will be your first user for your
                                organization
                              </i>
                            </div>
                            <div className="p-2">
                              <Row className="mb-1">
                                <Col xs="4">
                                  First Name {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactFirstName"
                                    id="primaryContactFirstName"
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        primaryContactFirstName: e.target.value,
                                      })
                                    }
                                    maxLength={50}
                                    value={org.primaryContactFirstName || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                                <Col xs="4">
                                  Middle Name
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactMiddleName"
                                    id="primaryContactMiddleName"
                                    maxLength={50}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        primaryContactMiddleName:
                                          e.target.value,
                                      })
                                    }
                                    value={org.primaryContactMiddleName || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>

                                <Col xs="4">
                                  Last Name {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactLastName"
                                    id="primaryContactLastName"
                                    maxLength={50}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        primaryContactLastName: e.target.value,
                                      })
                                    }
                                    value={org.primaryContactLastName || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col xs="12">
                                  Phone Number {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactPhoneNumber"
                                    id="primaryContactPhoneNumber"
                                    maxLength={20}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        primaryContactPhoneNumber:
                                          e.target.value,
                                      })
                                    }
                                    value={org.primaryContactPhoneNumber || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col xs="12">
                                  Username {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactUsername"
                                    id="primaryContactUsername"
                                    maxLength={255}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        primaryContactUsername: e.target.value,
                                      })
                                    }
                                    value={org.primaryContactUsername || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col xs="12">
                                  Email {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="primaryContactEmail"
                                    id="primaryContactEmail"
                                    maxLength={320}
                                    onChange={(e) => {
                                      const fieldValue = e.target.value;
                                      let newOrg = {
                                        ...org,
                                        primaryContactEmail: fieldValue,
                                      };
                                      if (fieldValue) {
                                        const minLen = _.min([
                                          fieldValue.length,
                                          org.primaryContactUsername
                                            ? org.primaryContactUsername.length
                                            : 0,
                                        ]);
                                        if (
                                          minLen === 0 ||
                                          _.startsWith(
                                            org.primaryContactUsername,
                                            fieldValue.substring(0, minLen)
                                          )
                                        ) {
                                          newOrg.primaryContactUsername =
                                            fieldValue;
                                        }
                                      }
                                      setOrganization(newOrg);
                                    }}
                                    value={org.primaryContactEmail || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col xs="12">
                                  Password {helpers.requiredStar()}
                                  <DebounceInput
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        password: e.target.value,
                                      })
                                    }
                                    value={org.password || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col xs="12">
                                  Retype Password {helpers.requiredStar()}
                                  <DebounceInput
                                    type="password"
                                    name="passwordAgain"
                                    id="passwordAgain"
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        passwordAgain: e.target.value,
                                      })
                                    }
                                    value={org.passwordAgain || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Fragment>
                        }
                      />
                    </Col>
                  </Row>
                  <EditUnitType
                    registrationMode={true}
                    setOrganization={(e) =>
                      setOrganization({ ...org, unitTypes: e })
                    }
                  />
                  {registerButton("float-right mt-2")}
                </Fragment>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
