import React, { useState, useContext, useEffect, Fragment } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import _ from "lodash";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { api, constants, storage, helpers, UserContext } from "./utils";
import { TermsAndConditions } from "./components";
import { Footer, SidebarMenu } from "./layouts";
import {
  Admin,
  Home,
  Job,
  Jobs,
  Organizations,
  PrivateJob,
  PrivateJobs,
  Profile,
  PublicProfile,
  Register,
  Shed,
  Sheds,
  SignIn,
  User,
  Users,
  ReferenceDataList,
  Reports,
  ResetPassword,
  ForgotPassword,
  UpdatePassword,
  CompanyProfile,
  HaulerFirmProfile,
  PublicProfileCompany,
  Subscriptions,
  CompanyRatings,
  HaulerRatings,
  RatingsContainer,
  Chats,
  Invoices,
  UnInvoicedLineItems,
  Privacy,
  ScheduleViewer,
} from "./pages";

const nowTime = () => new Date().getTime();
window.lastActivityCheckedAt = nowTime();
const { THEME } = constants;

export default function App() {
  const userCtx = useContext(UserContext);
  let history = useHistory();
  const jsonUser = storage.getItem("currentUser");
  const parsedUser = jsonUser
    ? JSON.parse(storage.getItem("currentUser"))
    : null;
  const [currentUser, setCurrentUser] = useState(parsedUser);
  const [theme, setTheme] = useState(THEME.lightTheme);
  const [impersonating, setImpersonation] = useState(
    storage.getItem("adminUser") ? true : false
  );
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const setLastActivity = () => {
      if (!currentUser) {
        // not logged in, clean up and do nothing
        if (storage.getItem("lastActivity")) {
          storage.removeItem("lastActivity");
        }
        return;
      }
      storage.setItem("lastActivity", nowTime());
      if (alertMessage) {
        setAlertMessage(null);
      }
    };

    const startTimer = () => {
      clearIntervalIfExists();
      window.lastActivityIntervalId = window.setInterval(
        compareTimerToLastActivity,
        constants.FIVE_MINUTES_MS
      );
    };

    if (currentUser) {
      window.addEventListener("click", setLastActivity);
      setLastActivity();
      startTimer();
      return () => {
        window.removeEventListener("click", setLastActivity);
        clearIntervalIfExists();
      };
    } else {
      clearIntervalIfExists();
    }
  }, [currentUser, alertMessage]);

  function clearIntervalIfExists() {
    window.clearInterval(window.lastActivityIntervalId);
  }

  function compareTimerToLastActivity() {
    window.lastActivityCheckedAt = nowTime();
    const lastActivity = storage.getItem("lastActivity");
    if (!lastActivity) return;
    if (nowTime() - parseInt(lastActivity) > constants.TWENTY_MINUTES_MS) {
      signOut(
        "You have been logged out due to inactivity. Please log back in to continue."
      );
      return;
    }
    verifyAuthentication();
  }

  function verifyAuthentication() {
    const sessionStartedAt = storage.getItem("sessionStartedAt");

    if (
      !currentUser ||
      !currentUser.token ||
      !sessionStartedAt ||
      nowTime() - sessionStartedAt < constants.TWENTY_MINUTES_MS
    ) {
      return; // not logged in or < 20 min since logon, no need to verify anything
    }

    if (nowTime() - sessionStartedAt > constants.TWENTY_THREE_HOURS_MS) {
      signOut("Your session has timed out.  Please log in.");
    } else {
      api
        .fetch("Public/AuthCheck?nocache=" + nowTime())
        .then((r) => {
          if (!r.data.success) {
            signOut("Your session has timed out. Please log in.");
          }
        })
        .catch((e) => {
          signOut("Your session has timed out. Please log in.");
        });
    }
  }

  function isAuthenticated() {
    return currentUser && currentUser.token ? true : false;
  }

  function resolveInitialUserContext(u) {
    if (!u) return;
    if (u.isSysAdmin) {
      return {
        label: constants.ROLE_NAMES.SYSADMIN,
        value: constants.ROLE_IDS.SYSADMIN,
      };
    }
    if (u.isAdminUser) {
      return _.find(
        u.roles,
        (r) => r.typeOfUserRole === constants.ROLE_IDS.COMPANY_ADMIN
      );
    }
    const dispatcherRole = _.find(
      u.roles,
      (r) => r.typeOfUserRole === constants.ROLE_IDS.DISPATCHER
    );
    if (dispatcherRole) return dispatcherRole;
    return u.roles[0];
  }

  function enhanceUser(u) {
    if (!u) return u;
    u.includeAdminModule = u.isSysAdmin;
    u.includeOrgAdminModule = u.isAdminUser;
    u.includeCompanyModule = u.isCompanyUser;
    u.includeCompanyAdminModule = u.isCompanyAdminUser;
    u.includeHaulerModule = u.isHaulerUser;
    u.includeHaulerFirmModule = u.isHaulerAdminUser;
    // if (u.token) {
    //   delete u.token;
    // }
    return u;
  }

  function impersonate(u, _impersonateToken) {
    if (impersonating) {
      return; // do not allow impersonation a 2nd time if we're already in that mode
    }
    // remember the admin user's details
    const adminUser = JSON.parse(storage.getItem("currentUser"));
    // const adminUserToken = storage.getItem("token");
    // clear storage
    // storage.removeItem("token");
    storage.removeItem("currentUser");
    // set the impersonation details
    u = enhanceUser(u);
    const ur = resolveInitialUserContext(u);
    u.currentRoleId = ur.roleId;
    u.currentOrgId = ur.orgId;
    // storage.setItem("token", impersonateToken);
    storage.setItem("currentUser", JSON.stringify(u));
    // remember the admin details for later
    // storage.setItem("adminToken", adminUserToken);
    storage.setItem("adminUser", JSON.stringify(adminUser));
    setCurrentUser(u);
    // setAuthToken(impersonateToken);
    setImpersonation(true);
    // setCurrentContext(resolveInitialUserContext(u));
    // window.location.pathname = '/home';
    history.push("/home");
  }

  function clearImpersonation() {
    if (!impersonating) {
      return; // do not allow clear of the primary user if we're not impersonating
    }
    // get the original admin user
    const adminUser = JSON.parse(storage.getItem("adminUser"));
    // const adminUserToken = storage.getItem("adminToken");
    // clear localstorage
    // storage.removeItem("adminToken");
    storage.removeItem("adminUser");
    // set the admin user back as primary
    // storage.setItem("token", adminUserToken);
    storage.setItem("currentUser", JSON.stringify(adminUser));
    storage.setItem("untethering", true);
    setCurrentUser(adminUser);
    // setAuthToken(adminUserToken);
    setImpersonation(false);
  }

  function signOut(message) {
    // storage.removeItem("token");
    storage.removeItem("currentUser");
    storage.removeItem("lastActivity");
    storage.removeItem("sessionStartedAt");
    setCurrentUser(null);
    // setAuthToken(null);
    if (message) {
      Alert.warning(message);
      setAlertMessage(message);
      // setSignInMessageVisible(true);
    }
    if (impersonating) {
      clearImpersonation();
    }
  }

  const AuthRoute = ({ component: Component, ...extraProps }) => {
    return (
      <Route
        {...extraProps}
        render={(props) => {
          const combinedProps = Object.assign(props, extraProps);
          if (helpers.mustChangePassword(currentUser)) {
            return <UpdatePassword {...extraProps} currentUser={currentUser} />;
          }
          if (!isAuthenticated()) {
            return <Redirect to="/" />;
          }
          // if (!currentUser?.accepted_tnc) {
          //   return (<TermsAndConditions {...combinedProps} />);
          // }
          return storage.getItem("untethering") ? (
            unSetTetheringAndRedirect(extraProps.location.pathname)
          ) : (
            <Component {...combinedProps} />
          );
        }}
      />
    );
  };

  const LoginRoute = ({ component: Component, ...extraProps }) => {
    if (isAuthenticated()) return <Redirect to="/home" />;
    return (
      <Route
        {...extraProps}
        render={(props) => {
          const combinedProps = Object.assign(props, extraProps);
          if (
            (_.startsWith(combinedProps.path, "/invite") ||
              _.startsWith(combinedProps.path, "/register") ||
              _.startsWith(combinedProps.path, "/reset_password") ||
              _.startsWith(combinedProps.path, "/forgot_password")) &&
            Component
          ) {
            return <Component {...combinedProps} />;
          }
          return <SignIn {...combinedProps} />;
        }}
      />
    );
  };

  function unSetTetheringAndRedirect(path) {
    storage.removeItem("untethering");
    return <Redirect to="/" />;
  }
  const referencePathList = _.map(
    constants.REFERENCE_DATA_URL_LIST,
    (x) => x.reactPath
  );
  // const report_list = _.map(constants.REPORT_URL_LIST, x => x.url);
  const showSidebar = userCtx && currentUser && currentUser.id;

  const user = {
    currentUser: currentUser,
    //currentUserContext: currentContext,
    setCurrentUserContext: (userRole) => {
      let u = Object.assign({}, currentUser);
      u.currentRoleId = userRole.roleId;
      u.currentOrgId = userRole.orgId;
      // setCurrentContext(resolveInitialUserContext(u));
      storage.setItem("currentUser", JSON.stringify(u));
      setCurrentUser(u);
      history.push("/home"); //return <Redirect push to={`/home`} />;
    },
    theme: theme,
    setTheme: setTheme,
    impersonate: impersonate,
    clearImpersonation: clearImpersonation,
    signIn: (newUser, token) => {
      newUser = enhanceUser(newUser);
      const ur = resolveInitialUserContext(newUser);
      newUser.currentRoleId = ur.roleId;
      newUser.currentOrgId = ur.orgId;
      storage.setItem("lastUsername", newUser.username);
      storage.setItem("currentUser", JSON.stringify(newUser));
      storage.setItem("lastActivity", nowTime());
      storage.setItem("sessionStartedAt", nowTime());
      setAlertMessage(null);
      setCurrentUser(newUser);
      // setCurrentContext(resolveInitialUserContext(newUser));
      // if (token) {
      //   storage.setItem("token", token);
      //   setAuthToken(token);
      // }
    },
    signOut: signOut,
  };
  if(user){
    console.log("user -> ", user);
  }
  if(user && user.currentUser && user.currentUser.orgId){
    helpers.ensureMembershipLevelSize(user.currentUser.orgId);
  }

  return (
    <div className={`${theme} siteContainer fullHeight`}>
      <Alert
        effect="slide"
        position="top-right"
        stack={{ limit: 1 }}
        timeout={4000}
        html={true}
        offset={1}
        z-index={4000}
        preserveContext
      />
      <UserContext.Provider value={user}>
        {showSidebar && (
          <Fragment>
            <SidebarMenu />
          </Fragment>
        )}
        <div className="contentWithHeader">
          <Switch>
            {/* PUBLIC ROUTES */}
            <LoginRoute path="/forgot_password" component={ForgotPassword} />
            <LoginRoute
              path="/reset_password/:resetToken"
              component={ResetPassword}
            />
            <LoginRoute path="/register/:type" component={Register} />
            <LoginRoute exact path="/register" component={Register} />
            <LoginRoute path="/invite/:inviteToken" component={Profile} />
            <LoginRoute path="/terms" component={TermsAndConditions} />
            <AuthRoute exact path="/home" component={Home} />
            <AuthRoute exact path="/admin/:tabName" component={Admin} />
            <AuthRoute exact path="/admin" component={Admin} />
            <AuthRoute path="/profile/:profileType" component={Profile} />
            <AuthRoute exact path="/profile" component={Profile} />
            <AuthRoute exact path="/haulerratings/admin" component={HaulerRatings} />
            <AuthRoute exact path="/companyratings/admin" component={CompanyRatings} />
            <AuthRoute exact path="/ratingscontainer/admin" component={RatingsContainer} />
            <AuthRoute path="/publicProfile/:id" component={PublicProfile} />
            <AuthRoute path="/dashboard/:module" component={Home} />
            <AuthRoute exact path="/dashboard" component={Home} />
            <AuthRoute
              path="/org/companies/admin/:orgTypeId"
              component={Organizations}
            />
            <AuthRoute path="/org/:orgType/:module" component={Organizations} />
            <AuthRoute
              path="/admin/profile/company"
              component={CompanyProfile}
            />
            <AuthRoute
              path="/companyProfile/:companyId"
              component={PublicProfileCompany}
            />
            <AuthRoute
              exact
              path="/haulerFirmProfile/:haulerFirmId"
              component={PublicProfileCompany}
            />
            <AuthRoute
              exact
              path="/admin/profile/haulerFirm"
              component={HaulerFirmProfile}
            />
            <AuthRoute path="/user/:id" component={User} />
            <AuthRoute path="/users/:module" component={Users} />
            <AuthRoute path="/sheds/:module" component={Sheds} />
            <AuthRoute path="/shed/:id" component={Shed} />
            <AuthRoute path="/jobs/admin/:jobStatusId" component={Jobs} />
            <AuthRoute path="/jobs/company/:jobStatusId" component={Jobs} />
            <AuthRoute path="/jobs/haulerFirm/:jobStatusId" component={Jobs} />
            <AuthRoute path="/jobs/:module" component={Jobs} />
            <AuthRoute exact path="/jobs" component={Jobs} />
            <AuthRoute path="/job/:id" component={Job} />
            <AuthRoute path="/privatejob/:id" component={PrivateJob} />
            <AuthRoute exact path="/privatejobs" component={PrivateJobs} />
            <AuthRoute
              exact
              path={referencePathList}
              component={ReferenceDataList}
            />
            <AuthRoute
              exact
              path={"/subscriptions"}
              component={Subscriptions}
            />
            <AuthRoute exact path="/reports" component={Reports} />
            <AuthRoute exact path="/chats" component={Chats} />
            <AuthRoute exact path="/invoices" component={Invoices} />
            <AuthRoute exact path="/schedule" component={ScheduleViewer} />
            <AuthRoute
              exact
              path="/uninvoicedlineitems"
              component={UnInvoicedLineItems}
            />
            <LoginRoute exact path="/" />
            <Route exact path="/privacy" component={Privacy} />
            <Route path="/haulerRegistration"
              component={() => {
                window.location.replace('https://shbrotherhood.org/memberships/');
                return null;
              }}
            />
          </Switch>
        </div>
        <Footer className="footerDesktop" />
      </UserContext.Provider>
    </div>
  );
}
