import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import _ from "lodash";

export default function TextNotificationModal(props) {
  const data = props.data;
  const openValue = data && data.length > 0 ? true : false;
  const [open, setOpen] = useState(openValue || false);
  const [message, setMessage] = useState(null);

  // this will be taken out when C# api setup
  useEffect(() => {}, []);

  function closeModal() {
    setOpen(false);
    props.onClose && props.onClose();
  }

  return (
    <Modal isOpen={open} className="minWidth750 lightTheme">
      <ModalHeader>Send Text</ModalHeader>
      <ModalBody>
        <Row className="mb-2">
          <Col xs="12">
            <h5>Text Recipients</h5>
            {_.map(props.data, (u) => {
              return (
                <Row key={`userToText${u.value}`}>
                  <Col xs="8">{u.label}</Col>
                  <Col xs="4">{u.phoneNumber}</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="9">
            Text Message
            <DebounceInput
              className="form-control"
              element="textarea"
              minLength={2}
              value={message}
              debounceTimeout={500}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Col>
          <Col xs="3" className="text-center">
            Character Count
            <br />
            <h5>
              <strong>{message && message.length}</strong>
            </h5>
            <br />
            <br />
            # of Text Messages
            <br />
            <h5>
              <strong>
                {message && message.length > 0
                  ? _.ceil(message.length / 144)
                  : 0}
              </strong>
            </h5>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="float-right">
          <Button onClick={() => closeModal()} size="sm">
            Cancel
          </Button>
          <Button
            className="projectPrimary"
            onClick={() => closeModal()}
            // disabled={!quoteMode}
          >
            Send Text
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}
