import React, { useState } from "react";
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { DesktopContainer } from "../layouts";
import { Media } from "react-breakpoints";
import {
  CompanyRatings,
  HaulerRatings
} from "./";  

export default function RatingsContainer(props) {
  const [activeTab, setActiveTab] = useState('hauler_ratings');

  function tabToggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={"Ratings"}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "hauler_ratings"
                      }, "pointer")}
                      onClick={() => {
                        tabToggle("hauler_ratings");
                      }}
                    >
                      Hauler Ratings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "company_ratings"
                      }, "pointer")}
                      onClick={() => {
                        tabToggle("company_ratings");
                      }}
                    >
                      Company Ratings
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="hauler_ratings">
                    {activeTab === "hauler_ratings" && 
                      <Row className="mt-2 mb-2">
                        <Col>
                          <HaulerRatings />
                        </Col>
                      </Row>
                    }
                  </TabPane>
                  <TabPane tabId="company_ratings">
                    {activeTab === "company_ratings" && 
                      <Row className="mt-2 mb-2">
                        <Col>
                          <CompanyRatings />
                        </Col>
                      </Row>
                    }
                  </TabPane>
                </TabContent>
              </DesktopContainer>
            );
          }
        }}
    </Media>
  );
}