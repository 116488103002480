import axios from "axios";
import Alert from "react-s-alert";
import _ from "lodash";
import pdf1 from "../components/images/fakeInvoice.pdf";
import { helpers } from "./";

const API = "/api";

function userToken() {
  const user = localStorage.getItem("currentUser");
  if (user && user !== "null") {
    return JSON.parse(user).token;
  }
}

function commonHeaders() {
  const token = userToken();
  return {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
}

const DIRECTIONS_KEY =
  "5b3ce3597851110001cf62484d50e88fad024ac893c2a850e9454361";

const expectXlsxHeaders = () =>
  Object.assign(commonHeaders(), {
    "Content-Type": "application/json",
    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

const expectPdfHeaders = () =>
  Object.assign(commonHeaders(), {
    "Content-Type": "application/json",
    Accept: "application/pdf",
  });

const headers = () =>
  Object.assign(commonHeaders(), { "Content-Type": "application/json" });
const multipart_headers = () =>
  Object.assign(commonHeaders(), { "Content-Type": "multipart/form-data" });
const directions_headers = () => {
  return {
    "Content-Type": "application/json; charset=utf-8",
    Accept:
      "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8",
    Authorization: DIRECTIONS_KEY,
  };
};
Object.assign(commonHeaders(), { "Content-Type": "multipart/form-data" });

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
}

const post = (url, data) => {
  return axios({
    method: "post",
    url: `${API}/${url}`,
    data: data,
    timeout: 600000,
    headers: headers(),
  });
};

const postWithExcelReturn = (url, data) => {
  return axios({
    method: "post",
    url: `${API}/${url}`,
    data: data,
    timeout: 600000,
    headers: expectXlsxHeaders(),
    responseType: "arraybuffer",
  });
};

const fetchWithPdfReturn = (url) => {
  return axios({
    method: "get",
    url: `${API}/${url}`,
    timeout: 600000,
    headers: expectPdfHeaders(),
    responseType: "arraybuffer",
  });
};

const apiFetch = (url, params = {}) => {
  return axios.get(`${API}/${url}${queryString(params)}`, {
    headers: headers(),
  });
};

const getOrgDrivers = (orgId) => api
.post(`Org/GetOrgDrivers/${orgId}`)
.then((response) => {
  if (response && response.data) {
    return {drivers:
      _.map(response.data, (d) => {
        d.label = d.name;
        d.value = d.id;
        return d;
      })
    }
  }
})
.catch(helpers.catchHandler);

const getTimezones = () =>
  apiFetch("Reference/TimezoneList").then((r) => ({
    timezones: _.map(r.data, (x) => ({ value: x, label: x })),
  }));

const getCompanies = () =>
  apiFetch("Reference/CompanyList").then((r) => ({
    companies: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getHaulerFirms = () =>
  apiFetch("Reference/HaulingFirmList").then((r) => ({
    haulerFirms: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getJobTypes = () =>
  apiFetch("Public/JobTypeList").then((r) => ({
    jobTypes: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getStates = () =>
  apiFetch("Public/StateList").then((r) => ({
    states: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getUnitTypes = () =>
  apiFetch("Public/UnitTypeList").then((r) => ({
    unitTypes: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getUnitStyles = () =>
  apiFetch("Public/UnitStyleList").then((r) => ({
    unitStyles: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getUnitMaterials = () =>
  apiFetch("Public/UnitMaterialList").then((r) => ({
    unitMaterials: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getUnitColors = () =>
  apiFetch("Public/UnitColorList").then((r) => ({
    unitColors: _.map(r.data, (x) => ({ ...x, value: x.id, label: x.name })),
  }));

const getAvailableRoles = (orgId) =>
    apiFetch(`Org/GetAvailableRoles/${orgId}`)
    .then((r) => {
      let roleObject = {};
      if(r.data && r.data.success) {
        if(r.data.availableRoles) {
          roleObject = {
            availableHaulerRoles: {...r.data.availableRoles}
          };
        }
      }
      return roleObject;
    });

const api = {
  downloadPDF(url) {
    try {
      var link = document.createElement("a");
      link.setAttribute("href", pdf1);
      link.setAttribute("download", pdf1);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      Alert.error("There was an error downloading the document");
    }
  },
  fetch_raw(url, params = {}) {
    return axios.get(`${url}${queryString(params)}`, { headers: headers() });
  },

  fetch: apiFetch,

  upload(verb, url, data) {
    switch (verb.toLowerCase()) {
      case "post":
        return axios.post(`${API}/${url}`, data, { headers: headers() });
      case "put":
        return axios.put(`${API}/${url}`, data, { headers: headers() });
      case "patch":
        return axios.patch(`${API}/${url}`, data, { headers: headers() });
      default:
    }
  },

  post: post,
  postWithExcelReturn: postWithExcelReturn,
  fetchWithPdfReturn: fetchWithPdfReturn,

  get_directions(data) {
    return axios({
      method: "post",
      url: `https://api.openrouteservice.org/v2/directions/driving-car/json`,
      data: data,
      timeout: 600000,
      headers: directions_headers(),
    });
  },

  put(url, data) {
    return axios.put(`${API}/${url}`, data, { headers: headers() });
  },

  patch(url, data) {
    return axios.patch(`${API}/${url}`, data, { headers: headers() });
  },

  delete(url) {
    return axios.delete(`${API}/${url}`, { headers: headers() });
  },

  post_form_data(url, formData) {
    return axios.post(`${API}/${url}`, formData, {
      headers: multipart_headers(),
    });
  },

  put_form_data(url, formData) {
    return axios.put(`${API}/${url}`, formData, {
      headers: multipart_headers(),
    });
  },

  post_form_data_get_blob(url, formData) {
    return axios.post(`${API}/${url}`, formData, {
      headers: multipart_headers(),
      responseType: "blob",
    });
  },

  getOrgDrivers: getOrgDrivers,

  getCompanies: getCompanies,

  getTimezones: getTimezones,

  getHaulerFirms: getHaulerFirms,

  getJobTypes: getJobTypes,

  getStates: getStates,

  getUnitTypes: getUnitTypes,

  getUnitStyles: getUnitStyles,

  getUnitMaterials: getUnitMaterials,

  getUnitColors: getUnitColors,

  getAvailableRoles: getAvailableRoles,

  userToken() {
    return userToken();
  },
  catchHandler: (e) => {
    console.error(e);
  },
  DIRECTIONS_KEY: DIRECTIONS_KEY,
};

export default api;
