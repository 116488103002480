import React from "react";
import Loader from "react-loader-spinner";
import _ from "lodash";


export default function SHBSpinner({on, children, style = {}, className = "", phrases = []}) {
    if(on) {
        return (
            <div style={style} className={className}>
            <Loader
            type="Circles"
            color="#00BFFF"
            height="10%"
            width="10%"
            // timeout={3000}
          />
          <p style={{marginTop: 15, fontStyle: "italic", fontWeight: "550"}}>
          {phrases.length > 0 ? _.sample(phrases) : ""}
          </p>
          </div>
        )
    }
    return children
}