import React from "react";
import { Media } from "react-breakpoints";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { constants } from "../utils";
import { LinkPanel } from "../components";
import { DesktopContainer } from "../layouts";

export default function Admin(props) {
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={"Administration"}>
                <Row className="mt-2 mb-2">
                  <Col>
                    {_.map(constants.REFERENCE_DATA_URL_LIST, (dt) => (
                      <LinkPanel
                        key={`link-${dt.pageTitle}`}
                        colSize="4"
                        iconKey={dt.iconKey}
                        title={dt.pageTitle}
                        routePath={dt.reactPath}
                        data={dt}
                      />
                    ))}
                  </Col>
                </Row>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
