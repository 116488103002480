import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateHelpers } from "../utils";

export default function DateFilter(props) {
  function onChange(value) {
    const dateDisplay = value
      ? `${props.displayName}: ${dateHelpers.formatDateToShortDate(value)}`
      : null;

    props.onChangeCallback({
      value: value,
      filterName: props.filterName,
      filteredDisplay: dateDisplay,
    });
  }

  return (
    <Fragment>
      <div
        className={
          props.customFilterClassName
            ? props.customFilterClassName
            : "filter-definition-caption"
        }
      >
        {props.displayName}
      </div>
      <div className="customDatePickerWidth">
        <DatePicker
          selected={props.value}
          onChange={(e) => onChange(e)}
          className="form-control"
        />
      </div>
    </Fragment>
  );
}
