import React from "react";

export default function EstimatedTimeOfDay(props) {
  return (
    <select
      className="form-control"
      disabled={props.disabled === true ? true : false}
      value={props.value}
      onChange={props.onChange}
    >
      <option value=""></option>
      <option value="AM">AM</option>
      <option value="PM">PM</option>
      <option value="N/A">N/A</option>
    </select>
  );
}
