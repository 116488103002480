import React, { useState, Fragment } from "react";
import { Button, Row, Col } from "reactstrap";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// need to change stripe key and get API key from C# application
// MOST everything on this screen is placeholder with basic Stripe UI example
const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
//const [stripeToken, setStripeToken] = useState(stripePromise);

//need to decide if this should be done in registration or in profile
//useEffect(() => {
//  api
//    .fetch("public/GetToken")
//    .then(response => {
//      if (response.data) {
//        setStripeToken(response.data);
//      }
//    })
//    .catch(error => {
//      if (props.showError) {
//        Alert("There was an error loading stripe token");
//      } else {
//        console.error(error);
//      }
//    });
//}, []);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
      setError(error.message);
    } else {
      setError(null);
      setMessage("Card saved");
      props.onSuccess(paymentMethod.id)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className={props.isAdmin ? "mb-2" : "mb-4"}>
        <Col>
          <h5>{props.subscriptionCost}</h5>
        </Col>
      </Row>
      {props.isAdmin ? null : (
        <Fragment>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {error && <div className="text-danger mt-3 mb-3">{error}</div>}
          {message && <div className="text-success mt-3 mb-3">{message}</div>}
          <Button
            type="submit"
            disabled={!stripe}
            className="float-right projectPrimary mt-3"
            size="sm"
          >
            Add Card
          </Button>
        </Fragment>
      )}
    </form>
  );
};

export default function StripeCheckout(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}
