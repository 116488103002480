import React from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export default function JobSummaryModal(props) {
  function closeModal() {
    props.onClose && props.onClose();
  }

  return (
    <Modal isOpen={props.open} className="minWidth750 lightTheme">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <Row className="mb-2">
          <Col xs="12">
            {props.data.map((u) => (
              <Row key={u.id}>
                <Col xs="8">{u.display}</Col>
                <Col xs="4">{u.date}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="float-right">
          <Button onClick={() => closeModal()} size="sm">
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}
