import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, constants } from "../utils";
import {
  DashboardAdmin,
  DashboardCompany,
  DashboardHaulerFirm
} from './'
import _ from "lodash";

export default function Home(props) {
  const pathParamsModule =
    props
    && props.computedMatch
    && props.computedMatch.params
    && props.computedMatch.params.module;
  const userCtx = useContext(UserContext);
  const history = useHistory();
  
  const currentUser = userCtx.currentUser;

  if (!userCtx || !currentUser) {
    history.push("/");
    return null;
   }
  
  // if (_.includes(constants.COMPANY_ROLES.map(r => r.value), currentUser.currentRoleId)) {
  //   return <DashboardCompany {...props} />;
  // }
  if (currentUser.includeAdminModule || pathParamsModule === 'admin') {
    return <DashboardAdmin {...props} />;
  } else {
    return <DashboardCompany {...props} />;
  }

  // if (_.includes(constants.HAULER_ROLES.map(r => r.value), currentUser.currentRoleId)) {
  //   return <DashboardHaulerFirm {...props} />;
  // }
  // return null;
}
