import _ from "lodash";

const cubicFootInInches = 1728;

function calcCubicForProducts(products) {
  if (products && products.length > 0) {
    const total = _.reduce(
      products,
      function (sum, n) {
        return sum + calcCubic(n.length, n.width, n.height);
      },
      0
    );
    return _.round(total, 2);
  }
  return 0;
}

function calcCubic(length, width, height) {
  return (length * width * height) / cubicFootInInches;
}

function calcPCF(weight, length, width, height) {
  if (weight > 0 && length > 0 && width > 0 && height > 0) {
    const cubic = calcCubic(length, width, height);
    return _.round(weight / cubic, 2);
  }
  return 0;
}

const calc = {
  calcPCF: calcPCF,
  calcCubic: calcCubic,
  calcCubicForProducts: calcCubicForProducts,
};

export default calc;
