import React, { useState, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Col, CardImg, Row } from "reactstrap";
import { api, helpers, constants } from "../utils";
import { CollapsibleCardSection } from "../components";
import { DesktopContainer } from "../layouts";

export default function PublicProfileCompany(props) {
  const orgId =
    props &&
    props.computedMatch &&
    props.computedMatch.params &&
    props.computedMatch.params.id
      ? parseInt(props.computedMatch.params.id, 10)
      : props?.computedMatch?.params?.companyId
      ? parseInt(props.computedMatch.params.companyId, 10)
      : props?.computedMatch?.params?.haulerFirmId
      ? parseInt(props.computedMatch.params.haulerFirmId, 10)
      : props?.orgId
      ? props.orgId
      : 0;

  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    api.fetch(`Org/GetOrgPublicProfileDetails/${orgId}`).then((response) => {
      if (response.data) {
        let organization = response.data;
        organization.orgType = {
          label: organization.orgTypeName,
          value: organization.orgTypeId,
        };
        setOrganization(organization);
      }
    });
  }, [orgId]);

  const profileName =
    organization !== null ? `${organization.companyName}` : "";

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={profileName}>
                {organization && (
                  <Fragment>
                    <Row>
                      <Col xs="6" className="mx-0 px-0">
                        <Row className="mb-2">
                          <Col>
                            <CollapsibleCardSection
                              cardName={"Company Information"}
                              alwaysOpen={true}
                              cardBody={
                                <Fragment>
                                  <Row className="mb-2 align-items-end">
                                    <Col xs="12">
                                      <Row>
                                        <Col xs="6">
                                          <Row className="mb-2">
                                            <Col>
                                              <strong>
                                                <span className="mr-2">
                                                  {organization.companyName}
                                                </span>
                                                {helpers.showStarRating(
                                                  organization.orgTypeId === constants.ORG_TYPE_IDS.HAULER_FIRM
                                                    ? organization.haulerRatingScore
                                                    : organization.companyRatingScore
                                                )}
                                              </strong>
                                            </Col>
                                          </Row>
                                          {organization.primaryLocationId !==
                                          null ? (
                                            <Fragment>
                                              <Row className="mb-1">
                                                <Col>
                                                  <strong>Location:</strong>
                                                </Col>
                                              </Row>
                                              <Row className="mb-1">
                                                <Col>
                                                  {organization.streetAddress1}
                                                </Col>
                                              </Row>
                                              {organization.streetAddress2 && (
                                                <Row className="mb-1">
                                                  <Col>
                                                    {
                                                      organization.streetAddress2
                                                    }
                                                  </Col>
                                                </Row>
                                              )}
                                              <Row className="mb-1">
                                                <Col>
                                                  {organization.city},{" "}
                                                  {organization.stateName}{" "}
                                                  {organization.zipCode}
                                                </Col>
                                              </Row>
                                            </Fragment>
                                          ) : (
                                            <div>
                                              No location set for this company.
                                            </div>
                                          )}

                                          <Row className="mb-2">
                                            <Col xs="12">
                                              <strong>Email: </strong>
                                              <a
                                                href={`mailto:${organization.companyEmail}`}
                                              >
                                                {organization.companyEmail}
                                              </a>
                                            </Col>
                                          </Row>
                                          <Row className="mb-2">
                                            <Col xs="12">
                                              <strong>PH:</strong>{" "}
                                              <a
                                                href={`tel:${organization.companyPhoneNumber}`}
                                              >
                                                {
                                                  organization.companyPhoneNumber
                                                }
                                              </a>
                                            </Col>
                                          </Row>
                                        </Col>
                                        {organization.signedUrl && (
                                          <Col xs="6">
                                            {/*<Card>*/}
                                            <CardImg
                                              top
                                              width="100%"
                                              style={{ maxHeight: `400px` }}
                                              src={organization.signedUrl}
                                              alt={`${organization.companyName} ProfileImage`}
                                            />
                                            {/*  </Card>*/}
                                          </Col>
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </Fragment>
                              }
                              //cardFooter={
                              //  <Row className="mb-1 tagline">
                              //    <Col xs="12">
                              //      <strong>"{organization.companyTagline}"</strong>
                              //    </Col>
                              //  </Row>
                              // }
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        {organization.aboutUs !== "" && (
                          <Row className="mb-2">
                            <Col>
                              <CollapsibleCardSection
                                cardName={"About Us"}
                                alwaysOpen={true}
                                cardBody={
                                  <Fragment>
                                    <Row className="mb-2 align-items-end">
                                      <Col xs="12">
                                        <Row className="mb-1 px-2">
                                          <Col>
                                            <div>
                                              {organization.aboutUs || ""}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Fragment>
                                }
                              />
                            </Col>
                          </Row>
                        )}
                        {organization.primaryUserId !== null && (
                          <Row className="mb-2">
                            <Col>
                              <CollapsibleCardSection
                                cardName={"Primary Contact Information"}
                                alwaysOpen={true}
                                cardBody={
                                  <Fragment>
                                    <Row className="mb-1">
                                      <Col>
                                        <strong>
                                          {organization.primaryUserFullName}
                                        </strong>
                                      </Col>
                                    </Row>
                                    <Row className="mb-1">
                                      <Col>
                                        <strong>PH: </strong>
                                        <a
                                          href={`tel:${
                                            organization.primaryUserPhoneNumber ||
                                            organization.companyPhoneNumber
                                          }`}
                                        >
                                          {organization.primaryUserPhoneNumber ||
                                            organization.companyPhoneNumber}
                                        </a>
                                      </Col>
                                    </Row>
                                    <Row className="mb-1">
                                      <Col>
                                        <strong>Email: </strong>
                                        <a
                                          href={`mailto:${
                                            organization.primaryUserEmail ||
                                            organization.companyEmail
                                          }`}
                                        >
                                          {organization.primaryUserEmail ||
                                            organization.companyEmail}
                                        </a>
                                      </Col>
                                    </Row>
                                  </Fragment>
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    {organization.companyTagline && (
                      <Row className="mt-4 mb-1 tagline">
                        <Col xs="12">
                          <strong>"{organization.companyTagline}"</strong>
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
