import React, { Fragment, useState, useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  Table,
  ButtonGroup,
  Alert as Alert2,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import {
  api,
  constants,
  UserContext,
  filterHelpers,
  helpers,
  dateHelpers,
} from "../utils";
import {
  DateFilter,
  FilterText,
  FilterableScreen,
  FilterSet,
  Pager,
  FilterMultiSelect,
  FilterSwitch,
  SHBSpinner,
  ScheduleModal
} from "../components";
import { DesktopContainer } from "../layouts";

export default function PrivateJobs(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const isSysAdmin = currentUser.isSysAdmin;
  const isCompany = currentUser.isCompanyUser;
  const isHaulerUser = currentUser.isHaulerUser;
  const isDispatcher =
    currentUser.currentRoleId === constants.ROLE_IDS.DISPATCHER ||
    currentUser.isHaulerAdminUser;
  const isDriver = currentUser.currentRoleId === constants.ROLE_IDS.DRIVER;
  const [message, setMessage] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectToJob, setRedirectToJob] = useState(null);
  const [jobToSchedule, setJobToSchedule] = useState(null);

  let hideJobEditButtons = isHaulerUser || isSysAdmin;
  let isOpenJobSearch = false;
  if (isHaulerUser) {
    isOpenJobSearch = true;
  }

  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const sortField = null;
  const sortDirection = null;

  useEffect(refreshData, [
    filters,
    pageNumber,
    currentUser.currentOrgId,
    isCompany,
    isOpenJobSearch,
  ]);

  function refreshData() {
    setLoading(true);
    let payload = {
      SortField: sortField,
      SortDirection: sortDirection,
      PageNumber: pageNumber,
      IsOpenJobSearch: isOpenJobSearch,
      CurrentOrgId: currentUser.currentOrgId,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Job/PrivateJobList", payload)
      .then((response) => {
        setPaginatedList({
          list: _.map(response.data.list, (l) => {
            l.jobTypeName = _.find(constants.JOB_TYPES, jt => jt.value === l.jobTypeId)?.label;
            return l;
          }),
          pageNumber: response.data.pageNumber,
          totalPages: response.data.totalPages,
        });
      })
      .catch(helpers.catchHandler)
      .finally(() => setLoading(false));
  }

  function onSchedule(jobId) {
    const job = _.find(paginatedList.list, (l) => l.id === jobId);
    setJobToSchedule(job);
    setShowScheduleModal(true);
  }

  function onScheduleSuccess() {
    setJobToSchedule(null);
    setShowScheduleModal(false);
    refreshData();
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function currentFilterValue(name) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  function onMarkComplete(privateJobId) {
    if (
      !window.confirm(`Are you sure you'd like to set this job as completed?`)
    )
      return;

    api
      .post(`Job/CompletePrivateJob`, {privateJobId: privateJobId})
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job has been marked completed.",
            flavor: "alert-success",
          });
          refreshData();
        } else {
          setMessage({
            text: `An error ocurred. Job status cannot be changed at this time. ${response.data.message ? response.data.message : ''}`,
            flavor: "alert-danger",
          });
        }
      })
      .catch((error) => {
        setMessage({
          text: "There was an error change the status for this job.",
          flavor: "alert-danger",
        });
      });
  }

  function togglePrivateJobActive(privateJobId, reviving = false) {
    if (!reviving) {
      if (!window.confirm(`Are you sure you'd like to cancel this job?`))
        return;
    }
    api
      .delete(`Job/TogglePrivateJobActive/${privateJobId}`)
      .then((response) => {
        if (response.data.success) {
          refreshData();
        } else {
          if (reviving) {
            setMessage({
              text: "An error ocurred. Private Job cannot be revived at this time.",
              flavor: "alert-danger",
            });
          } else {
            setMessage({
              text: "An error ocurred. Private Job cannot be canceled at this time.",
              flavor: "alert-danger",
            });
          }
        }
      })
      .catch((error) => {
        if (reviving) {
          setMessage({
            text: "There was an error reviving this job.",
            flavor: "alert-danger",
          });
        } else {
          setMessage({
            text: "There was an error cancelling this job.",
            flavor: "alert-danger",
          });
        }
      });
  }

  return redirectToJob ? (
    <Redirect to={`/privatejob/${redirectToJob}`} />
  ) : (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Private Jobs"}>
                  {message ? (
                    <Alert2 className={message.flavor}>{message.text}</Alert2>
                  ) : null}
                  <FilterableScreen
                    refreshData={refreshData}
                    filters={
                      <Fragment>
                        <Row>
                          <Col xs="11" className="px-0">
                            <FilterSet
                              filters={filters}
                              clearFilters={() => setFilters([])}
                            >
                              <Row>
                                <Col xs="3">
                                  <FilterText
                                    filterName="idStartsWith"
                                    displayName="Job #"
                                    value={currentFilterValue("idStartsWith")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="jobTypeIds"
                                    displayName="Job Type"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "jobTypeIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={constants.JOB_TYPES}
                                  />
                                </Col>
                                {/* <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="jobStatusIds"
                                    displayName="Job Status"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "jobStatusIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={constants.JOB_STATUSES}
                                  />
                                </Col> */}
                                <Col xs="3">
                                  <FilterSwitch
                                    filterName="ActiveOnly"
                                    displayName="Active Only"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "ActiveOnly"
                                    )}
                                    onChangeCallback={(e) =>
                                      onFilterChange(e)
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="3">
                                  <FilterText
                                    filterName="fromCity"
                                    displayName="From City"
                                    value={currentFilterValue("fromCity")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="toCity"
                                    displayName="To City"
                                    value={currentFilterValue("toCity")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="fromZipCode"
                                    displayName="From Zip Code"
                                    value={currentFilterValue("fromZipCode")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="toZipCode"
                                    displayName="To Zip Code"
                                    value={currentFilterValue("toZipCode")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="requestedDateFrom"
                                    displayName="Requested Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "requestedDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="requestedDateTo"
                                    displayName="Requested Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "requestedDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="scheduledDateFrom"
                                    displayName="Scheduled Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "scheduledDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="scheduledDateTo"
                                    displayName="Scheduled Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "scheduledDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="completedDateFrom"
                                    displayName="Completed Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "completedDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="completedDateTo"
                                    displayName="Completed Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "completedDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                              </Row>
                            </FilterSet>
                          </Col>
                          <Col xs="1" className="pl-0 pr-4">
                            <ButtonGroup className="float-right mr-2">
                              <Button
                                onClick={refreshData}
                                title="Refresh"
                                color="dark"
                              >
                                <FontAwesomeIcon icon="sync" />
                              </Button>
                              <Button
                                className="projectSuccess"
                                tag={Link}
                                to="/privatejob/0"
                                title="Add New Job"
                              >
                                <FontAwesomeIcon icon="plus" />
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    }
                    pager={
                      <div className="float-right">
                        <Pager
                          pageNumber={
                            paginatedList?.pageNumber
                              ? paginatedList.pageNumber
                              : 0
                          }
                          totalPages={
                            paginatedList?.totalPages
                              ? paginatedList.totalPages
                              : 0
                          }
                          callBack={(newPageNumber) =>
                            setPageNumber(newPageNumber)
                          }
                        />
                      </div>
                    }
                  >
                    <Row className="p-0 m-0">
                      <Col xs="12">
                        <Table
                          striped
                          hover
                          size="sm"
                          responsive={false}
                          id="userTable"
                        >
                          <thead>
                            <tr>
                              <th>Job #</th>
                              {/* <th>Pickup Date</th> */}
                              <th>Requested By Date</th>
                              <th>Type</th>
                              <th>Origin</th>
                              <th>Destination</th>
                              <th>Completed At</th>
                              <th width="20%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedList?.list?.length ? (
                              _.map(paginatedList.list, (pj, index) => (
                                <tr
                                  key={pj.id}
                                  onClick={() => setRedirectToJob(pj.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>
                                    {pj.name}
                                  </td>
                                  <td>
                                    {dateHelpers.formatDateToShortDate(pj.requestedByDate)}
                                   </td>
                                   <td>
                                     <Badge
                                       color={helpers.getJobTypeBadgeColor(
                                         pj.jobTypeName
                                       )}
                                     >
                                       {pj.jobTypeName}
                                     </Badge>
                                  </td>
                                  <td>{`${pj.fromCity}, ${pj.fromStateAbbr} ${pj.fromZipCode}`}</td>
                                  <td>{`${pj.toCity}, ${pj.toStateAbbr} ${pj.toZipCode}`}</td>
                                  <td>{dateHelpers.formatDateToShortDate(pj.completedAt)}</td>
                                  <td>
                                    <ButtonGroup className="float-right">
                                      {pj.completedAt
                                        ? null
                                        : <React.Fragment>
                                            <Button
                                              size="sm"
                                              className="projectInfo"
                                              title="Complete job"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                onMarkComplete(pj.id);
                                              }}
                                            >
                                              <FontAwesomeIcon icon="flag-checkered" />{" "}
                                              {/* Complete Job */}
                                            </Button>
                                            <Button
                                              size="sm"
                                              className="projectSuccess"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                onSchedule(pj.id);
                                              }}
                                              title="Schedule job"
                                            >
                                              <FontAwesomeIcon icon="calendar-alt" />{" "}
                                              {/* Schedule */}
                                            </Button>
                                            <Button
                                              size="sm"
                                              className="projectPrimary"  //"projectPrimary minWidth85"
                                              tag={Link}
                                              to={`/privatejob/${pj.id}`}
                                              title="Edit job"
                                            >
                                              <FontAwesomeIcon icon="edit" />{" "}
                                              {/* Edit */}
                                            </Button>
                                            {pj.deactivatedAt ? (
                                              <Button
                                                size="sm"
                                                className="projectInfo"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  togglePrivateJobActive(pj.id, true);
                                                }}
                                              >
                                                <FontAwesomeIcon icon="recycle" />{" "}
                                                Revive
                                              </Button>
                                            ) : (
                                              <Fragment>
                                                <Button
                                                  size="sm"
                                                  className="projectDanger"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    togglePrivateJobActive(pj.id);
                                                  }}
                                                  title="Cancel job"
                                                >
                                                  <FontAwesomeIcon icon="times-circle" />{" "}
                                                  {/* Cancel Job */}
                                                </Button>
                                              </Fragment>
                                            )}
                                          </React.Fragment>
                                      }
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">No records found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                      {showScheduleModal && jobToSchedule && (
                        <ScheduleModal
                          showModal={showScheduleModal}
                          toggle={() =>
                            setShowScheduleModal(!showScheduleModal)
                          }
                          job={jobToSchedule}
                          onSuccess={() => onScheduleSuccess()}
                          isPrivate={true}
                        />
                      )}
                    </Row>
                  </FilterableScreen>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
