import _ from "lodash";

const ADMIN_TABS = {
  REFERENCES: "References",
  REPORTS: "Reports",
};

const THEME = {
  darkTheme: "darkTheme",
  lightTheme: "lightTheme",
};
const BREAKPOINTS = {
  mobile: 320,
  mobileLandscape: 480,
  smallDesktop: 768,
  tablet: 856,
  tabletLandscape: 992,
  desktop: 1080,
  desktopLarge: 1500,
  desktopWide: 1920,
};

const REPORT_PARAMETER_TYPES = {
  Text: 0,
  DateRange: 1,
  Date: 2,
  ApiList: 3,
  Integer: 4,
  Boolean: 5,
};

const RENDER_TYPES = {
  CSV: 1,
  PDF: 2,
  Excel: 3,
};

const LOCATION_TYPES = {
  Primary: 1,
  BillTo: 2,
  PayTo: 3,
  Inventory: 4,
};

const RIG_TYPES = [
  { value: 1, label: "Flat Bed" },
  { value: 2, label: "Dry Van" },
  { value: 3, label: "Side-Kit" },
  { value: 4, label: "Conestoga" },
  { value: 5, label: "Step Deck" },
  { value: 6, label: "Box Truck" },
  { value: 7, label: "Double Drop" },
  { value: 8, label: "Lowboy" },
  { value: 9, label: "Extendable Flat Bed" },
];

const PRODUCT_TYPES = [
  { value: 1, label: "Storage Building" },
  { value: 2, label: "Carport" },
  { value: 3, label: "Gazebo" },
  { value: 4, label: "Playhouse" },
  { value: 5, label: "Container" },
  { value: 6, label: "Other" },
];

const PRODUCT_STATUS = [
  { value: 1, label: "Sold" },
  { value: 2, label: "On Lease" },
  { value: 3, label: "Pre-Lease" },
  { value: 4, label: "Repo" },
  { value: 5, label: "Missing" },
  // { value: 6, label: "Destroyed" },
];

const SHED_STYLES = [
  { value: 1, label: "A-Frame" },
  { value: 2, label: "A-Roof" },
  { value: 3, label: "Alpine Cottage" },
  { value: 4, label: "Aluminium" },
  { value: 5, label: "Aluminium/Wood" },
  { value: 6, label: "Amish A-frame" },
  { value: 7, label: "Amish Barn" },
  { value: 8, label: "Amish Metal Building" },
  { value: 9, label: "Barn" },
  { value: 10, label: "Cabin" },
  { value: 11, label: "Carriage" },
  { value: 12, label: "Chicken Coop" },
  { value: 13, label: "Child's Cabin" },
  { value: 14, label: "Classic" },
  { value: 15, label: "Clubhouse" },
  { value: 16, label: "Cottage" },
  { value: 17, label: "Cottage Shed" },
  { value: 18, label: "Cottage/Dormer" },
  { value: 19, label: "Deluxe Cabin" },
  { value: 20, label: "Double Garage" },
  { value: 21, label: "Double Wide" },
  { value: 22, label: "Dutch Barn" },
  { value: 23, label: "Economy" },
  { value: 24, label: "Elite Cabin" },
  { value: 25, label: "Garage" },
  { value: 26, label: "Garden Shed" },
  { value: 27, label: "Gazebo" },
  { value: 28, label: "Greenhouse" },
  { value: 29, label: "Gym and Slide" },
  { value: 30, label: "High Wall Barn" },
  { value: 31, label: "Horse Barn" },
  { value: 32, label: "Lofted Barn" },
  { value: 33, label: "Lofted Barn Cabin" },
  { value: 34, label: "Lofted Cabin" },
  { value: 35, label: "Lofted Garage" },
  { value: 36, label: "Low Barn" },
  { value: 37, label: "Low Wall Barn" },
  { value: 38, label: "Metal Dutch Barn" },
  { value: 39, label: "Mini Barn" },
  { value: 40, label: "Octagon" },
  { value: 41, label: "Playhouse" },
  { value: 42, label: "Shed Row Horse Barn" },
  { value: 43, label: "Side Lofted" },
  { value: 44, label: "Side Lofted Barn" },
  { value: 45, label: "Tea House" },
  { value: 46, label: "Tots Tower" },
  { value: 47, label: "Two-Story" },
  { value: 48, label: "Utility" },
  { value: 49, label: "Watch Tower" },
  { value: 50, label: "Wood" },
];

const COLORS = [
  { value: 1, label: "Blue" },
  { value: 2, label: "Red" },
  { value: 3, label: "Green" },
  { value: 4, label: "White" },
  { value: 5, label: "Black" },
  { value: 6, label: "Other" }
];

const COLOR_IDS = {
  BLUE: 1,
  RED: 2,
  GREEN: 3,
  WHITE: 4,
  BLACK: 5,
  OTHER: 6,
};

const SORT_DIRECTION = {
  ASC: "Ascending",
  DESC: "Descending",
};

const ROOF_MATERIALS = [
  { value: 1, label: "Charcoal" },
  { value: 2, label: "Clay" },
  { value: 3, label: "Slate" },
  { value: 4, label: "Stone" },
  { value: 5, label: "White" },
];
const MATERIALS = [
  { value: 1, label: "Container" },
  { value: 2, label: "Greenhouse" },
  { value: 3, label: "Metal" },
  { value: 4, label: "Wood" },
];
const JOB_FEES = [
  { value: 1, label: "Knock Fee", defaultPrice: 101.33 },
  { value: 2, label: "Pickup Fee", defaultPrice: 388.65 },
  { value: 3, label: "Run-off Fee", defaultPrice: 105.77 },
  { value: 4, label: "Permit", defaultPrice: 400.22 },
  { value: 5, label: "Escort", defaultPrice: 750.27 },
  { value: 6, label: "Failed Attempt", defaultPrice: 500.05 },
  { value: 7, label: "Premium Immediate Action", defaultPrice: 200.99 },
  // { value: 8, label: "Other", defaultPrice: 0 },
];
const JOB_TYPES = [
  { value: 1, label: "Return" },
  { value: 2, label: "Retrieval" },
  { value: 3, label: "Off Lot" },
  { value: 4, label: "Move" },
  { value: 5, label: "MFG" },
  { value: 6, label: "RTO Delivery" },
];
const JOB_TYPE_IDS = {
  RETURN: 1,
  RETRIEVAL: 2,
  OFF_LOT: 3,
  MOVE: 4,
  MFG: 5,
  RTO_DELIVERY: 6,
};
const JOB_STATUSES = [
  { value: 1, label: "Open" },
  { value: 2, label: "Reserved" },
  { value: 3, label: "Scheduled" },
  { value: 4, label: "In Progress" },
  { value: 5, label: "Complete" },
  { value: 6, label: "Cancelled" },
];
const JOB_STATUS_IDS = {
  OPEN: 1,
  RESERVED: 2,
  SCHEDULED: 3,
  IN_PROGRESS: 4,
  COMPLETE: 5,
  CANCELLED: 6,
};
const JOB_STATUS_NAMES = {
  OPEN: "Open",
  RESERVED: "Reserved",
  SCHEDULED: "Scheduled",
  IN_PROGRESS: "In Progress",
  COMPLETE: "Complete",
  CANCELLED: "Cancelled",
};

const CHECKLIST_ITEM_TYPE_IDS = {
  ATTACHMENT: 1,
  BOOLEAN: 2,
  TEXT: 3,
  SIGNATURE: 4,
  PAYMENT: 5,
  GPS_COORDINATES: 6,
};

const REFERENCE_DATA_URL_LIST = [
  {
    maxNameLength: 50,
    elementName: "Material",
    pageTitle: "Materials",
    iconKey: "cog",
    toggleURL: "Reference/ToggleActiveMaterial",
    saveURL: "Reference/SaveUnitMaterial",
    listURL: "Reference/UnitMaterialList",
    reactPath: "/materials",
  },
  {
    maxNameLength: 50,
    elementName: "Rig Type",
    pageTitle: "Rig Types",
    iconKey: "truck",
    toggleURL: "Reference/ToggleRigTypeActive",
    saveURL: "Reference/SaveRigType",
    listURL: "Reference/RigTypeList",
    reactPath: "/rig_types",
  },
  {
    maxNameLength: 50,
    elementName: "Unit Type",
    pageTitle: "Unit Types",
    iconKey: "home",
    toggleURL: "Reference/ToggleUnitTypeActive",
    saveURL: "Reference/SaveUnitType",
    listURL: "Reference/UnitTypeList",
    reactPath: "/unit_types",
  },
  {
    maxNameLength: 50,
    elementName: "Shed Style",
    pageTitle: "Shed Styles",
    iconKey: "palette",
    toggleURL: "Reference/ToggleUnitStyleActive",
    saveURL: "Reference/SaveUnitStyle",
    listURL: "Reference/UnitStyleList",
    reactPath: "/shed_styles",
  },
  {
    maxNameLength: 50,
    elementName: "Color",
    pageTitle: "Colors",
    iconKey: "paint-brush",
    toggleURL: "Reference/ToggleUnitColorActive",
    saveURL: "Reference/SaveUnitColor",
    listURL: "Reference/UnitColorList",
    reactPath: "/colors",
  },
];

const REPORT_URL_LIST = [
  {
    api: "shipmentQuantity",
    url: "/shipmentQuantity",
    plural: null,
    pageTitle: "Quantity Of Shipments",
    data: [],
    keys: [
      { label: "Company", value: "companyName" },
      { label: "Pending Dispatch", value: "pendingDispatch" },
      { label: "Scheduled", value: "pendingPickup" },
      { label: "In Transit", value: "inTransit" },
      { label: "Delivered", value: "delivered" },
      { label: "Total", value: "shipmentCounts" },
    ],
    showCSVButton: true,
  },
];

const STATES = [
  "AL",
  "AK",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "FL",
  "DE",
  "GA",
  "ID",
  "HI",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const ROLE_NAMES = {
  REPORTING: "Reporting",
  COMPANY_ADMIN: "Administrator",
  DRIVER: "Driver",
  DISPATCHER: "Dispatcher",
  COMPANY_USER: "User",
  COMPANY_CLERK: "Clerk",
  SYSADMIN: "System Administrator",
};

const ROLE_IDS = {
  REPORTING: 7,
  DRIVER: 6,
  DISPATCHER: 5,
  COMPANY_USER: 4,
  COMPANY_CLERK: 3,
  COMPANY_ADMIN: 2,
  SYSADMIN: 1,
};

const ROLE_DICTIONARY = {
  7: ROLE_NAMES.REPORTING,
  6: ROLE_NAMES.DRIVER,
  5: ROLE_NAMES.DISPATCHER,
  2: ROLE_NAMES.COMPANY_ADMIN,
  4: ROLE_NAMES.COMPANY_USER,
  3: ROLE_NAMES.COMPANY_CLERK,
  1: ROLE_NAMES.SYSADMIN,
};

const HAULER_ROLES = [
  {
    value: ROLE_IDS.COMPANY_ADMIN,
    label: ROLE_NAMES.COMPANY_ADMIN,
    groupName: "Hauling",
  },
  {
    value: ROLE_IDS.DISPATCHER,
    label: ROLE_NAMES.DISPATCHER,
    groupName: "Hauling",
  },
  { value: ROLE_IDS.DRIVER, label: ROLE_NAMES.DRIVER, groupName: "Hauling" },
];

const COMPANY_ROLES = [
  {
    value: ROLE_IDS.COMPANY_ADMIN,
    label: ROLE_NAMES.COMPANY_ADMIN,
    groupName: "Companies",
  },
  {
    value: ROLE_IDS.COMPANY_USER,
    label: ROLE_NAMES.COMPANY_USER,
    groupName: "Companies",
  },
  {
    value: ROLE_IDS.COMPANY_CLERK,
    label: ROLE_NAMES.COMPANY_CLERK,
    groupName: "Companies",
  },
];

const ALL_ROLES = [
  {
    value: ROLE_IDS.SYSADMIN,
    label: ROLE_NAMES.SYSADMIN,
    groupName: "Administration",
  },
  {
    value: ROLE_IDS.COMPANY_ADMIN,
    label: ROLE_NAMES.COMPANY_ADMIN,
    groupName: "Hauling",
  },
  {
    value: ROLE_IDS.DISPATCHER,
    label: ROLE_NAMES.DISPATCHER,
    groupName: "Hauling",
  },
  { value: ROLE_IDS.DRIVER, label: ROLE_NAMES.DRIVER, groupName: "Hauling" },
  {
    value: ROLE_IDS.COMPANY_ADMIN,
    label: ROLE_NAMES.COMPANY_ADMIN,
    groupName: "Companies",
  },
  {
    value: ROLE_IDS.COMPANY_USER,
    label: ROLE_NAMES.COMPANY_USER,
    groupName: "Companies",
  },
  {
    value: ROLE_IDS.COMPANY_CLERK,
    label: ROLE_NAMES.COMPANY_CLERK,
    groupName: "Companies",
  },
];

const ADMIN_TASK_ROLE_IDS = [
  //CAN PERFORM COMPANY ADMIN TASKS
  ROLE_IDS.DISPATCHER,
  ROLE_IDS.COMPANY_USER,
  ROLE_IDS.COMPANY_CLERK,
  ROLE_IDS.COMPANY_ADMIN,
  ROLE_IDS.SYSADMIN,
];

const DOCUMENT_TYPE_LIST = [
  { value: 1, label: "Insurance" },
  { value: 2, label: "Hauling Contract" },
  { value: 3, label: "MVR" },
  { value: 4, label: "DOT Number" },
  { value: 5, label: "W9" },
  { value: 6, label: "CDL" },
  { value: 7, label: "Delivery Image" },
  { value: 8, label: "Front Image" },
  { value: 9, label: "Left Side Image" },
  { value: 10, label: "Right Side Image" },
  { value: 11, label: "Rear Image" },
  { value: 12, label: "Interior Image" },
  { value: 13, label: "Serial Image" },
  { value: 14, label: "User Profile Image" },
  { value: 15, label: "Company Profile Image" },
];

const DOCUMENT_TYPE_IDS = {
  USER_PROFILE_IMAGE: 14,
  COMPANY_PROFILE_IMAGE: 15,
  SIGNATURE: 16
};

const ORG_TYPE_NAMES = {
  RTO: "RTO",
  MANUFACTURER: "Manufacturer",
  HAULER_FIRM: "Hauler Firm",
};

const ORG_TYPE_IDS = {
  RTO: 1,
  MANUFACTURER: 2,
  HAULER_FIRM: 3,
};

const ORG_TYPE_LIST = [
  { value: ORG_TYPE_IDS.RTO, label: ORG_TYPE_NAMES.RTO },
  { value: ORG_TYPE_IDS.MANUFACTURER, label: ORG_TYPE_NAMES.MANUFACTURER },
  { value: ORG_TYPE_IDS.HAULER_FIRM, label: ORG_TYPE_NAMES.HAULER_FIRM },
];

const EMPTY_USER = {
  id: -1,
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  username: "",
  cdlNumber: "",
  roles: [],
  editing: true,
  password: "",
  cdlState: "",
};

const EMPTY_LOCATION = {
  id: -1,
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: null,
  zip: "",
  locationType: null,
  editing: true,
};

const EMPTY_ORG_SUBSCRIPTION = {
  id: -1,
  org: null,
  subscriptionType: null,
  startDate: null,
  endDate: null,
  numberOfMemberList: _.map(HAULER_ROLES.slice(), (r) => ({
    id: -1,
    role: r,
    amount: r.value === ROLE_IDS.COMPANY_ADMIN ? 1 : 0,
  })),
  editing: true,
};

const EMPTY_ORGANIZATION = {
  id: -1,
  name: "",
  aboutUs: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: null,
  zip: "",
  phoneNumber: "",
  companyType: null,
  unitTypes: [],
  rigTypes: [],
  password: "",
  passwordAgain: "",
  einNumber: "",
  alternateEIN: "",
  cardToken: "",
  userList: [],
  locations: [],
  subscriptionType: null,

  //only used for registration
  primaryContactFirstName: "",
  primaryContactMiddleName: "",
  primaryContactLastName: "",
  primaryContactPhoneNumber: "",
  primaryContactEmail: "",
  primaryContactUsername: "",

  hasInternalDispatcher: true,

  //only used for registration
  dispatcherFirstName: "",
  dispatcherLastName: "",
  dispatcherTitle: "",
  dispatcherPhoneNumber: "",
  dispatcherFaxNumber: "",
  dispatcherEmail: "",

  sameAccountingAddress: true,
  sameAccountingContact: true,
  accountingName: "",
  accountingAddressLine1: "",
  accountingAddressLine2: "",
  accountingCity: "",
  accountingState: null,
  accountingZip: "",
  accountingContactFirstName: "",
  accountingContactLastName: "",
  accountingContactTitle: "",
  accountingContactPhoneNumber: "",
  accountingContactFaxNumber: "",
  accountingContactEmail: "",
  status: "Pending Registration",
};

const EMPTY_DEFAULT_FEE = {
  id: -1,
  feeType: null,
  jobType: null,
  amount: "",
  editing: true,
};

const EMPTY_CHECK_LIST_ITEM = {
  id: -1,
  itemType: null,
  name: "",
  documentType: null,
  jobType: null,
  isRequired: false,
  seq: null,
  prompt: "",
  defaultBooleanValue: false,
  regExValidation: "",
  editing: true,
};

const PHRASES = [
  "Buying cinder blocks...",
  "Leveling site...",
  "Avoiding power lines...",
];

const SUBSCRIPTION_STATUSES = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const INVOICE_STATUSES = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Paid" },
  { value: 3, label: "Declined" },
  { value: 4, label: "Cancelled" },
  { value: 5, label: "Delivered" },
];

const INVOICE_STATUS_IDS = {
  pending: 1,
  paid: 2,
  declined: 3,
  cancelled: 4,
  delivered: 5,
};

const CALENDAR_VIEW_TYPES = {
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
}

const constants = {
  CALENDAR_VIEW_TYPES: CALENDAR_VIEW_TYPES,
  THEME: THEME,
  TEN_SECONDS_MS: 10000,
  ONE_MINUTES_MS: 60000,
  FIVE_MINUTES_MS: 300000,
  TEN_MINUTES_MS: 600000,
  TWENTY_MINUTES_MS: 1200000,
  TWENTY_THREE_HOURS_MS: 82800000,
  DEFAULT_TIMEZONE: "Eastern Standard Time",
  REPORT_PARAMETER_TYPES: REPORT_PARAMETER_TYPES,
  STATES: _.map(STATES, (s) => ({ label: s, value: s })),
  SORT_DIRECTION: SORT_DIRECTION,
  ROLE_NAMES: ROLE_NAMES,
  ROLE_IDS: ROLE_IDS,
  ROLE_DICTIONARY: ROLE_DICTIONARY,
  BREAKPOINTS: BREAKPOINTS,
  RIG_TYPES: RIG_TYPES,
  PRODUCT_TYPES: PRODUCT_TYPES,
  JOB_STATUSES: JOB_STATUSES,
  JOB_STATUS_IDS: JOB_STATUS_IDS,
  JOB_STATUS_NAMES: JOB_STATUS_NAMES,
  PRODUCT_STATUS: PRODUCT_STATUS,
  SHED_STYLES: SHED_STYLES,
  COLORS: COLORS,
  COLOR_IDS: COLOR_IDS,
  ROOF_MATERIALS: ROOF_MATERIALS,
  MATERIALS: MATERIALS,
  JOB_TYPES: JOB_TYPES,
  JOB_TYPE_IDS: JOB_TYPE_IDS,
  JOB_FEES: JOB_FEES,
  ALL_ROLES: ALL_ROLES,
  HAULER_ROLES: HAULER_ROLES,
  COMPANY_ROLES: COMPANY_ROLES,
  REFERENCE_DATA_URL_LIST: REFERENCE_DATA_URL_LIST,
  REPORT_URL_LIST: REPORT_URL_LIST,
  RENDER_TYPES: RENDER_TYPES,
  ADMIN_TABS: ADMIN_TABS,
  EMPTY_ORGANIZATION,
  EMPTY_USER,
  EMPTY_LOCATION,
  EMPTY_DEFAULT_FEE,
  EMPTY_CHECK_LIST_ITEM,
  LOCATION_TYPES,
  ORG_TYPE_NAMES,
  ORG_TYPE_IDS,
  ORG_TYPE_LIST,
  EMPTY_ORG_SUBSCRIPTION,
  ADMIN_TASK_ROLE_IDS,
  PHRASES,
  SUBSCRIPTION_STATUSES,
  DOCUMENT_TYPE_IDS,
  DOCUMENT_TYPE_LIST,
  CHECKLIST_ITEM_TYPE_IDS,
  MIME_XLSX:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  MIME_PDF: "application/pdf",
  INVOICE_STATUSES,
  INVOICE_STATUS_IDS,
};

export default constants;
