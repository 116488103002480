/* 
  NOTE: creating new document upload (as opposed to DocumentUpload.js)
  Eventually want to replace all occurences of DocumentUpload.js (but did not want to break current code)
*/

import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Button, Row, Table, ButtonGroup } from "reactstrap";
import Alert from "react-s-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import { api, UserContext, helpers } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapsibleCardSection } from ".";
import Dropzone from "react-dropzone";
import classnames from "classnames";

export default function JobDocumentUpload(props) {
  const userCtx = useContext(UserContext);
  const [documentTypeList, setDocumentTypeList] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [existingDocuments, setExistingDocuments] = useState(props.existingDocuments);
  const [showUploadModule, setShowUploadModule] = useState(false);
  const excludeDocTypeIdList = props.excludeDocTypeIdList || null;

  useEffect(() => {
    let isMounted = true;

    api
      .fetch(`public/DocumentTypeList`)
      .then((response) => {
        if (response.data) {
          if (isMounted) {
            let list = _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            });
            if (excludeDocTypeIdList && excludeDocTypeIdList.length) {
              list = _.filter(list, (l) => {
                return !excludeDocTypeIdList.contains(l.value);
              });
            }
            setDocumentTypeList(list);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [excludeDocTypeIdList]);

  useEffect(() => {
    let isMounted = true;

    if (props.existingDocuments !== null && isMounted) {
      setExistingDocuments(props.existingDocuments);
    }

    return () => {
      isMounted = false;
    };
  }, [props.existingDocuments]);

  function deleteDocument(index) {
    if (!window.confirm("Are you sure you want to delete this document?"))
      return;
    let list = existingDocuments.slice();
    let id = list[index].id;
    api
      .delete(`${props.deleteUrl}/${id}`)
      .then((response) => {
        if (response && response.data.success) {
          Alert.success("Document was deleted.");
          props.refresh();
        } else {
          Alert.error(response.data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert("There was an error retrieving document types");
        } else {
          console.error(error);
        }
      });
  }

  function deleteNewDocument(index) {
    let newDocumentsTemp = props.newDocumentsToUpload.slice();
    newDocumentsTemp.splice(index, 1);
    props.setNewDocumentsToUpload(newDocumentsTemp);
    
  }

  return (
    <CollapsibleCardSection
      startOpen={true}
      cardName={props.cardName}
      cardBody={
        <Fragment>
          <Row className="p-2">
            <Col className="">
              {showUploadModule && (
                <span>
                  Select <i>Document Type</i> before attaching file
                </span>
              )}
              <Button
                size="sm"
                onClick={() => setShowUploadModule(!showUploadModule)}
                className={classnames(
                  { projectSuccess: !showUploadModule },
                  "float-right"
                )}
              >
                {showUploadModule ? "Cancel" : "Upload"}
              </Button>
            </Col>
          </Row>

          {showUploadModule && (
            <Fragment>
              <Row className="p-2 mt-2">
                <Col xs="4">
                  Document Type:
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    components={makeAnimated()}
                    options={props.documentTypeList || documentTypeList}
                    onChange={(e) => setSelectedDocumentType(e)}
                    value={selectedDocumentType || null}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    onCloseResetsInput={false}
                    classNamePrefix="react-select"
                  />
                </Col>
              </Row>
              <Row className="p-2 mt-2">
                <Col>
                  <Dropzone
                    accept={props.mimeTypes || "image/*,application/pdf,.psd"}
                    onDrop={(acceptedFiles) => {
                      if (!acceptedFiles) {
                        Alert.warning("Document format is not accepted");
                        return;
                      }
                       if (!selectedDocumentType) {
                        Alert.error("Document Type is required");
                        return;
                      }

                      let newDocument = {
                        orgId: props.orgId,
                        jobId: props.jobId,
                        id: userCtx.currentUser.id,
                        documentTypeId: selectedDocumentType.value,
                        documentTypeName: selectedDocumentType.label,
                        file: acceptedFiles[0],
                        name: acceptedFiles[0].name,
                        mimeType: acceptedFiles[0].type,
                      }

                      let newDocumentsToUploadTemp = props.newDocumentsToUpload.slice();
                      newDocumentsToUploadTemp.push(newDocument);
                      props.setNewDocumentsToUpload(newDocumentsToUploadTemp);

                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "dashed 1px #000000",
                            padding: "3em",
                            borderRadius: "5px",
                          }}
                        >
                          <input {...getInputProps()} />
                          <Row className="mb-2" style={{ textAlign: "center" }}>
                            <Col>
                              <FontAwesomeIcon icon="upload" size="4x" />
                            </Col>
                          </Row>
                          <h5>
                            {`Drag file here to upload (or click here to choose file)`}
                          </h5>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </Fragment>
          )}
          {/* New Documents */}
          <Row className="mb-2">
            <Col>
             <b>New Documents</b>
              <Table>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.newDocumentsToUpload && props.newDocumentsToUpload.length > 0 ? (
                    props.newDocumentsToUpload.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span title={d.name || d.fileName}>
                              {helpers.truncateStringOverLength(
                                d.name || d.fileName,
                                20,
                                true
                              )}
                            </span>
                          </div>
                          <div className="dim-text">{d.mimeType}</div>
                        </td>
                        <td>{d.documentTypeName}</td>
                        <td>
                          <ButtonGroup className="float-right">
                            <Button
                              size="sm"
                              className="danger"
                              onClick={() => deleteNewDocument(index)}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No documents found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* Existing Documents */}
          {existingDocuments && existingDocuments.length > 0 &&
          <Row>
            <Col>
              <b>Existing Documents</b>
              <Table>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    {existingDocuments.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span title={d.name || d.fileName}>
                              {helpers.truncateStringOverLength(
                                d.name || d.fileName,
                                20,
                                true
                              )}
                            </span>
                          </div>
                          <div className="dim-text">{d.mimeType}</div>
                        </td>
                        <td>{d.documentTypeName}</td>
                        <td>
                          <ButtonGroup className="float-right">
                            <Button
                              className="primary"
                              size="sm"
                              title="View Document"
                              onClick={() =>
                                window.open(
                                  d.signedURL || d.signedUrl,
                                  "_blank"
                                )
                              }
                            >
                              <FontAwesomeIcon icon="external-link-alt" />
                            </Button>
                            <Button
                              size="sm"
                              className="danger"
                              onClick={() => deleteDocument(index)}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
      }
        </Fragment>
      }
    />
  );
}
