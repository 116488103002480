import React, { useState, useContext, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Col, Button, Row, ButtonGroup, Table, Input } from "reactstrap";
import DebounceInput from "react-debounce-input";
import Alert from "react-s-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import { api, helpers, constants, UserContext, dateHelpers } from "../utils";
import {
  CollapsibleCardSection,
  DocumentUpload,
  OrgAccessModule,
  ProfileImageRender,
} from "../components";
import { DesktopContainer } from "../layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const emptyRigType = {
  id: 0,
  type: null,
  qty: 1,
  editing: true,
};

const HaulerFirmString = "Hauler Firm";

export default function CompanyProfile(props) {
  const userCtx = useContext(UserContext);
  const [organization, setOrganization] = useState(null);
  const [currentCompanyType, setCurrentOrgType] = useState(null);
  const [organizationUsers, setOrganizationUsers] = useState(null);
  const [companyLocations, setCompanyLocations] = useState(null);
  const [prestineLocation, setPrestineLocation] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [locationTypeList, setLocationTypeList] = useState([]);
  const [allowOrgAccessList, setAllowOrgAccessList] = useState(null);
  const [denyOrgAccessList, setDenyOrgAccessList] = useState(null);
  const [orgDocumentList, setOrgDocumentList] = useState(null);
  const [addNewRigType, setAddNewRigType] = useState(false);
  const [newRigTypeName, setNewRigTypeName] = useState("");

  //Company related
  const [orgTypeList, setCompanyTypeList] = useState([]);

  //Hauler Firm related
  const [haulerRigTypes, setHaulerRigTypes] = useState(null);
  const [prestineRigType, setPrestineRigType] = useState(null);
  const [rigTypeList, setRigTypeList] = useState([]);

  const [profileImage, setProfileImage] = useState(null);

  const routeOrgId = props?.computedMatch?.params?.haulerFirmId
    ? parseInt(props.computedMatch.params.haulerFirmId, 10)
    : props?.orgId
    ? props.orgId
    : userCtx?.currentUser?.currentOrgId
    ? userCtx?.currentUser?.currentOrgId
    : 0;

  const getRigTypes = () =>
    api.fetch("public/RigTypeList").then((r) => ({
      rigTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getOrgTypes = () =>
    api.fetch("public/OrgTypeList").then((r) => ({
      orgTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getStateList = () =>
    api.fetch("public/StateList").then((r) => ({
      stateList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getLocationTypes = () =>
    api.fetch("public/LocationTypeList").then((r) => ({
      locationTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));

  const saveNewRigType = () => {
    if (!newRigTypeName) {
      setAddNewRigType(false);
      return;
    }

    api
      .post("reference/SaveRigType", { name: newRigTypeName })
      .then((response) => {
        if (response && response.data && response.data.success) {
          setNewRigTypeName("");
          setAddNewRigType(false);

          getRigTypes().then((response) =>
            setRigTypeList(response.rigTypeList)
          );
        } else {
          Alert.error(response.data.message);
        }
      })
      .catch((error) =>
        Alert.error("There was an error saving the new rig type")
      );
  };

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(getRigTypes());
    apiCalls.push(getOrgTypes());
    apiCalls.push(getStateList());
    apiCalls.push(getLocationTypes());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        setRigTypeList(aggResults.rigTypeList);
        setCompanyTypeList(aggResults.orgTypeList);
        setStateList(aggResults.stateList);
        setLocationTypeList(aggResults.locationTypeList);
      })
      .catch((error) =>
        Alert.error("There was an error loading reference data")
      );
  }, [userCtx.currentUser]);

  useEffect(refreshCompanyDetails, [routeOrgId, stateList]);

  function refreshAccessLists() {
    api
      .fetch(`Org/GetOrgDetails/${routeOrgId}`)
      .then((response) => {
        setDenyOrgAccessList(
          response.data.denyAccessList
            ? _.map(response.data.denyAccessList, (t) => {
                t.value = t.partnerOrgId;
                t.label = t.partnerOrgName;
                t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                return t;
              })
            : []
        );
        setAllowOrgAccessList(
          response.data.allowAccessList
            ? _.map(response.data.allowAccessList, (t) => {
                t.value = t.partnerOrgId;
                t.label = t.partnerOrgName;
                t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                return t;
              })
            : []
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function refreshCompanyDetails() {
    api
      .fetch(`Org/GetOrgDetails/${routeOrgId}`)
      .then((response) => {
        if (response.data) {
          let organization = response.data.organization;
          organization.orgType = {
            label: organization.orgTypeName,
            value: organization.orgTypeId,
          };
          organization.subscriptionType = {
            label: organization.subscriptionTypeName,
            value: organization.subscriptionTypeId,
          };
          setCurrentOrgType(organization.orgType);
          setOrganization(organization);
          setCompanyLocations(
            _.map(response.data.locations, (l) => {
              l.locationType = {
                label: l.locationTypeName,
                value: l.locationTypeId,
              };
              l.state = _.find(stateList, (s) => {
                return s.value === l.stateId;
              }); //{ label: l.stateName, value: l.stateId };
              l.editing = false;
              return l;
            })
          );
          let userList = _.chain(response.data.userList)
            .filter((u) => {
              return u.roleTypeIdList.includes(
                constants.ROLE_IDS.COMPANY_ADMIN
              );
            })
            .map((u) => ({ value: u.id, label: u.fullName }))
            .value();
          organization.primaryContact = _.find(
            userList,
            (u) => u.value === organization.primaryUserId
          );

          setOrganizationUsers(userList);

          setHaulerRigTypes(
            _.map(response.data.orgRigTypes, (d) => ({
              id: d.id,
              type: { label: d.rigTypeName, value: d.rigTypeId },
              qty: d.qty,
              label: d.rigTypeName,
              value: d.rigTypeId,
              editing: false,
            }))
          );
          setDenyOrgAccessList(
            response.data.denyAccessList
              ? _.map(response.data.denyAccessList, (t) => {
                  t.value = t.partnerOrgId;
                  t.label = t.partnerOrgName;
                  t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                  return t;
                })
              : []
          );
          setAllowOrgAccessList(
            response.data.allowAccessList
              ? _.map(response.data.allowAccessList, (t) => {
                  t.value = t.partnerOrgId;
                  t.label = t.partnerOrgName;
                  t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                  return t;
                })
              : []
          );
          setOrgDocumentList(
            response.data.documents
              ? _.map(response.data.documents, (t) => {
                  return t;
                })
              : []
          );
          setProfileImage(
            _.find(
              response.data.documents,
              (d) =>
                d.documentTypeId ===
                constants.DOCUMENT_TYPE_IDS.COMPANY_PROFILE_IMAGE
            )
          );
        }
      })
      .catch(() => {
        Alert("There was an error retrieving company details");
      });
  }

  function saveCompanyProfile() {
    let warnings = [];
    if (!organization.name || _.trim(organization.name).length < 2) {
      warnings.push("Please provide a company name");
    }
    if (!organization.email || !helpers.emailIsValid(organization.email)) {
      warnings.push("Please provide a valid company email.");
    }
    if (!organization.phoneNumber) {
      warnings.push("Please provide a company phone number.");
    }
    if (!organization.ein) {
      warnings.push("Please provide a EIN number.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }

    let payload = {
      Organization: {
        Id: organization.id,
        Name: organization.name,
        Email: organization.email,
        PhoneNumber: organization.phoneNumber,
        AboutUs: organization.aboutUs,
        Tagline: organization.tagline,
        EIN: organization.ein,
        OrgTypeId: organization.orgType ? organization.orgType.value : null,
        SubscriptionTypeId: organization.subscriptionType
          ? organization.subscriptionType.value
          : null,
        PrimaryUserId: organization.primaryContact
          ? organization.primaryContact.value
          : null,
        PaymenyGatewayApiLoginId: organization.apiLoginId,
        PaymenyGatewayTransactionKey: organization.apiTransactionKey,
        PaymentProcessorId: organization.paymentProcessor
          ? organization.paymentProcessor.value
          : null,
      },
    };
    api
      .post(`useradmin/SaveOrgDetails`, payload)
      .then((response) => {
        if (response.data.success) {
          refreshCompanyDetails();
          Alert.success("Your profile has been updated.");
        } else {
          Alert.error(response.data.message || "An error occurred.");
        }
      })
      .catch(helpers.catchHandler);
  }

  function submitButton(className) {
    return (
      <Row>
        <Col>
          <Button
            className={className}
            size="sm"
            color="primary"
            onClick={() => saveCompanyProfile()}
          >
            Save Profile
          </Button>
        </Col>
      </Row>
    );
  }

  function onRigTypeSelect(index, selection, name) {
    let list = Object.assign([], haulerRigTypes);
    list[index][name] = selection;
    setHaulerRigTypes(list);
  }
  async function addToRigTypes() {
    let list = Object.assign([], haulerRigTypes);
    list.unshift(Object.assign({}, emptyRigType));
    await setHaulerRigTypes(list);
    //  await setNewRigType(Object.assign({}, emptyRigType));
  }
  function editRigType(index) {
    let list = Object.assign([], haulerRigTypes);
    let prestine = Object.assign({}, list[index]);
    list[index].editing = true;
    setPrestineRigType(prestine);
    setHaulerRigTypes(list);
  }
  function saveRigType(index) {
    let list = Object.assign([], haulerRigTypes);
    let rig = Object.assign({}, list[index]);

    let warnings = [];
    if (
      rig &&
      _.some(list, (r) => {
        return r.type.label === rig.type.label;
      })
    ) {
      warnings.push(
        "Rig Type already exist. Please update the existing quantity"
      );
    }
    if (!rig.qty || rig.qty < 0) {
      warnings.push("Please enter a valid quantity.");
    }
    if (!rig.type) {
      warnings.push("Please select a rig type.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }
    let payload = {
      Id: rig.id,
      Qty: rig.qty,
      RigTypeId: rig.type ? rig.type.value : null,
      OrgId: routeOrgId,
    };
    api
      .post(`org/SaveOrgRigType`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Rig types have been updated.");
          refreshCompanyDetails();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function onRigTypeInputChange(index, event) {
    let list = Object.assign([], haulerRigTypes);
    list[index][event.target.name] = event.target.value;
    setHaulerRigTypes(list);
  }
  function onCancelEdit(index) {
    let list = Object.assign([], haulerRigTypes);
    if (list[index].id > 0) {
      list[index] = Object.assign({}, prestineRigType);
      list[index].editing = false;
    } else {
      list = _.reject(list, (l, idx) => {
        return index === idx;
      });
    }
    setPrestineRigType(null);
    setHaulerRigTypes(list);
  }
  async function deleteFromRigTypes(index) {
    if (
      !window.confirm(
        "Are you sure you want to delete this rig type? Deletes are final."
      )
    )
      return;
    let list = Object.assign([], haulerRigTypes);
    let rig = list[index];

    api.delete(`org/DeleteHaulerFirmRig/${rig.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Rig has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Rig cannot be deleted at this time.");
      }
    });
  }

  function onLocationInputChange(e, index, name) {
    let list = companyLocations.slice();
    list[index][name] = e && e.target ? e.target.value : e;
    setCompanyLocations(list);
  }
  function addLocation() {
    let list = Object.assign([], companyLocations);
    list.unshift(constants.EMPTY_LOCATION);
    setCompanyLocations(list);
  }
  function editLocation(index) {
    const list = companyLocations.slice();
    setPrestineLocation(Object.assign({}, list[index]));
    list[index].editing = true;
    setCompanyLocations(list);
  }
  function cancelEditLocation(index) {
    let list = companyLocations.slice();
    let prestine = Object.assign({}, prestineLocation);
    if (list[index].id === -1) {
      list.splice(index, 1);
    } else {
      list[index] = prestine;
      list[index].editing = false;
    }
    setCompanyLocations(list);
    setPrestineLocation(null);
  }
  function saveLocation(index) {
    const list = companyLocations.slice();
    let location = Object.assign({}, list[index]);
    let warnings = [];

    if (!location.locationType) {
      warnings.push("Please provide a location type.");
    }
    if (!location.state) {
      warnings.push("Please provide a state.");
    }
    if (
      !location.streetAddress1 ||
      _.trim(!location.streetAddress1).length < 2
    ) {
      warnings.push("Please provide a address line 1.");
    }
    if (!location.zipCode || _.trim(!location.zipCode).length < 2) {
      warnings.push("Please provide a zip code.");
    }
    if (!location.city || _.trim(!location.city).length < 2) {
      warnings.push("Please provide a city.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }

    let payload = {
      Id: location.id,
      orgId: routeOrgId,
      StreetAddress1: location.streetAddress1,
      StreetAddress2: location.streetAddress2,
      City: location.city,
      StateId: location.state ? location.state.value : null,
      ZipCode: location.zipCode,
      LocationTypeId: location.locationType
        ? location.locationType.value
        : null,
      TypeOfLocation: location.locationType
        ? location.locationType.value
        : null,
    };
    api
      .post(`org/SaveLocation`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Locations have been updated.");
          refreshCompanyDetails();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function deleteLocation(index) {
    if (!window.confirm("Are you sure you want to delete this location?"))
      return;
    const list = Object.assign([], companyLocations);
    let location = list[index];

    api.post(`org/ToggleLocationActive/${location.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Location has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error(
          "An error occurred. Location cannot be deleted at this time."
        );
      }
    });
  }
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={""}>
                {organization && (
                  <Fragment>
                    <Row className="mb-2">
                      <Col>
                        {organization === null ? null : (
                          <CollapsibleCardSection
                            startOpen
                            cardName={helpers.cardHeaderName(
                              "Hauler Firm Profile",
                              "address-card"
                            )}
                            cardBody={
                              <Fragment>
                                <Row>
                                  {
                                    <ProfileImageRender
                                      profileImage={profileImage}
                                      name={`${organization.name}`}
                                      isCompany
                                    />
                                  }
                                  <Col>
                                    <Row className="mb-2">
                                      <Col xs="6">
                                        {currentCompanyType?.label &&
                                          currentCompanyType.label !==
                                            HaulerFirmString && (
                                            <Row className="mb-2">
                                              <Col xs="12">
                                                Company Type{" "}
                                                {helpers.requiredStar()}
                                                <Select
                                                  closeMenuOnSelect={true}
                                                  isMulti={false}
                                                  components={makeAnimated()}
                                                  options={orgTypeList}
                                                  onChange={(e) =>
                                                    setOrganization({
                                                      ...organization,
                                                      orgType: e,
                                                    })
                                                  }
                                                  isDisabled={true}
                                                  value={
                                                    organization.orgType || null
                                                  }
                                                  onBlurResetsInput={false}
                                                  onSelectResetsInput={false}
                                                  onCloseResetsInput={false}
                                                  classNamePrefix="react-select"
                                                />
                                              </Col>
                                            </Row>
                                          )}
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Hauler Firm Name {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  name: e.target.value,
                                                })
                                              }
                                              value={organization.name || ""}
                                              className="form-control"
                                              maxLength={50}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Email {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="email"
                                              id="email"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  email: e.target.value,
                                                })
                                              }
                                              value={organization.email || ""}
                                              className="form-control"
                                              // debounceTimeout={320}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Phone Number{" "}
                                            {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="phoneNumber"
                                              id="phoneNumber"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  phoneNumber: e.target.value,
                                                })
                                              }
                                              value={
                                                organization.phoneNumber || ""
                                              }
                                              className="form-control"
                                              maxLength={20}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            EIN Number
                                            <Input
                                              type="text"
                                              name="ein"
                                              id="ein"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  ein: e.target.value,
                                                })
                                              }
                                              value={organization.ein || ""}
                                              className="form-control"
                                              maxLength={64}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Primary Company Contact{" "}
                                            {helpers.requiredStar()}{" "}
                                            <span className="information-small">
                                              (Admins)
                                            </span>
                                            <Select
                                              closeMenuOnSelect={true}
                                              isMulti={false}
                                              components={makeAnimated()}
                                              options={organizationUsers}
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  primaryContact: e,
                                                })
                                              }
                                              value={
                                                organization.primaryContact ||
                                                null
                                              }
                                              onBlurResetsInput={false}
                                              onSelectResetsInput={false}
                                              onCloseResetsInput={false}
                                              classNamePrefix="react-select"
                                            />
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col xs="6">
                                        <Row className="mb-1 px-2">
                                          <Col>
                                            <div>
                                              About Us {helpers.requiredStar()}
                                            </div>
                                            <Input
                                              type="textarea"
                                              name="aboutUs"
                                              id="aboutUs"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  aboutUs: e.target.value,
                                                })
                                              }
                                              //onChange={(event) => setOrganization(event)}
                                              value={organization.aboutUs}
                                              className="form-control minHeight150"
                                              maxLength={500}
                                              placeholder="Description of your company"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-1 px-2">
                                          <Col>
                                            <div>
                                              Tagline {helpers.requiredStar()}
                                            </div>
                                            <Input
                                              type="textarea"
                                              name="tagline"
                                              id="tagline"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  tagline: e.target.value,
                                                })
                                              }
                                              value={organization.tagline}
                                              className="form-control minHeight150"
                                              placeholder="Company Tagline"
                                              maxLength={255}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    {submitButton("float-right")}
                                  </Col>
                                </Row>
                              </Fragment>
                            }
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">
                        <OrgAccessModule
                          cardName={helpers.cardHeaderName(
                            "I do business with only these companies",
                            "user-plus"
                          )}
                          orgList={allowOrgAccessList}
                          isAllowAccess
                          refreshOrg={refreshAccessLists}
                          orgId={routeOrgId}
                          showCompanies
                        />
                      </Col>
                      <Col xs="6">
                        <OrgAccessModule
                          cardName={helpers.cardHeaderName(
                            "I do business with any company EXCEPT these",
                            "user-times"
                          )}
                          orgList={denyOrgAccessList}
                          isAllowAccess={false}
                          refreshOrg={refreshAccessLists}
                          orgId={routeOrgId}
                          showCompanies
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">
                        <CollapsibleCardSection
                          startOpen
                          cardName={helpers.cardHeaderName(
                            "Locations",
                            "map-marked"
                          )}
                          cardBody={
                            <Fragment>
                              <Row className="mb-2 align-items-end">
                                <Col xs="12">
                                  <Row className="mb-1">
                                    <Col>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th width="50%">Location</th>
                                            <th>Type</th>
                                            <th>
                                              <Button
                                                size="sm"
                                                className="projectSuccess float-right"
                                                onClick={() => addLocation()}
                                                title="Add"
                                              >
                                                <FontAwesomeIcon icon="plus" />{" "}
                                                Add
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {companyLocations &&
                                          companyLocations.length > 0
                                            ? companyLocations.map(
                                                (l, index) => (
                                                  <tr key={`location-${index}`}>
                                                    {l.editing ? (
                                                      <Fragment>
                                                        <td colSpan="2">
                                                          <Row className="mb-1">
                                                            <Col>
                                                              Address Line 1
                                                              {helpers.requiredStar()}
                                                              <DebounceInput
                                                                type="text"
                                                                name="streetAddress1"
                                                                id="streetAddress1"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "streetAddress1"
                                                                  )
                                                                }
                                                                value={
                                                                  l.streetAddress1 ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                debounceTimeout={
                                                                  300
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row className="mb-1">
                                                            <Col>
                                                              Address Line 2
                                                              <DebounceInput
                                                                type="text"
                                                                name="streetAddress2"
                                                                id="streetAddress2"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "streetAddress2"
                                                                  )
                                                                }
                                                                value={
                                                                  l.streetAddress2 ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                debounceTimeout={
                                                                  300
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                            <Col xs="5">
                                                              City
                                                              {helpers.requiredStar()}
                                                              <DebounceInput
                                                                type="text"
                                                                name="city"
                                                                id="city"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "city"
                                                                  )
                                                                }
                                                                value={
                                                                  l.city || ""
                                                                }
                                                                className="form-control"
                                                                debounceTimeout={
                                                                  300
                                                                }
                                                              />
                                                            </Col>
                                                            <Col xs="3">
                                                              State
                                                              {helpers.requiredStar()}
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                isMulti={false}
                                                                components={makeAnimated()}
                                                                options={
                                                                  stateList
                                                                }
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "state"
                                                                  )
                                                                }
                                                                value={
                                                                  l.state ||
                                                                  null
                                                                }
                                                                onBlurResetsInput={
                                                                  false
                                                                }
                                                                onSelectResetsInput={
                                                                  false
                                                                }
                                                                onCloseResetsInput={
                                                                  false
                                                                }
                                                                classNamePrefix="react-select"
                                                              />
                                                            </Col>
                                                            <Col xs="4">
                                                              Zip
                                                              {helpers.requiredStar()}
                                                              <DebounceInput
                                                                type="text"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "zipCode"
                                                                  )
                                                                }
                                                                value={
                                                                  organization.zipCode ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                debounceTimeout={
                                                                  300
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col xs="6">
                                                              Location Type
                                                              {helpers.requiredStar()}
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                isMulti={false}
                                                                components={makeAnimated()}
                                                                options={
                                                                  locationTypeList
                                                                }
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "locationType"
                                                                  )
                                                                }
                                                                value={
                                                                  l.locationType ||
                                                                  null
                                                                }
                                                                onBlurResetsInput={
                                                                  false
                                                                }
                                                                onSelectResetsInput={
                                                                  false
                                                                }
                                                                onCloseResetsInput={
                                                                  false
                                                                }
                                                                classNamePrefix="react-select"
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </td>
                                                        <td>
                                                          <ButtonGroup className="float-right">
                                                            <Button
                                                              size="sm"
                                                              className="secondary"
                                                              onClick={() =>
                                                                cancelEditLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="reply" />
                                                            </Button>
                                                            <Button
                                                              size="sm"
                                                              className="primary"
                                                              onClick={() =>
                                                                saveLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="save" />
                                                            </Button>
                                                          </ButtonGroup>
                                                        </td>
                                                      </Fragment>
                                                    ) : (
                                                      <Fragment>
                                                        <td>
                                                          {l.streetAddress1 && (
                                                            <div>
                                                              {l.streetAddress1}
                                                            </div>
                                                          )}
                                                          {l.streetAddress2 && (
                                                            <div>
                                                              {l.streetAddress2}
                                                            </div>
                                                          )}
                                                          <div>
                                                            {l.city},{" "}
                                                            {l.state &&
                                                              l.state
                                                                .label}{" "}
                                                            {l.zipCode}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {l.locationType &&
                                                            l.locationType
                                                              .label}
                                                        </td>
                                                        <td>
                                                          <ButtonGroup className="float-right">
                                                            <Button
                                                              size="sm"
                                                              className="primary"
                                                              onClick={() =>
                                                                editLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="edit" />
                                                            </Button>
                                                            <Button
                                                              size="sm"
                                                              className="danger"
                                                              onClick={() =>
                                                                deleteLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="times" />
                                                            </Button>
                                                          </ButtonGroup>
                                                        </td>
                                                      </Fragment>
                                                    )}
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Fragment>
                          }
                        />
                      </Col>
                      <Col xs="6">
                        <CollapsibleCardSection
                          startOpen
                          cardName={helpers.cardHeaderName(
                            "Rig Types",
                            "truck-loading"
                          )}
                          cardBody={
                            <>
                              <Row className="mt-2">
                                <Col>
                                  <i>
                                    Don't see the rig type you need in the rig
                                    type list when adding a rig type?
                                  </i>
                                  <p style={{ marginBottom: "0" }}>
                                    <button
                                      className="btn btn-link"
                                      onClick={() => setAddNewRigType(true)}
                                      title="Add A New Rig Type"
                                    >
                                      Click here to add a new rig type
                                    </button>
                                  </p>
                                </Col>
                              </Row>
                              {addNewRigType && (
                                <Row className="mt-2">
                                  <Col>
                                    <Input
                                      placeholder="Enter New Rig Type Name"
                                      value={newRigTypeName}
                                      onChange={(e) =>
                                        setNewRigTypeName(e.target.value)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <ButtonGroup className="float-right">
                                      <Button
                                        size="sm"
                                        className="secondary"
                                        onClick={() => setAddNewRigType(false)}
                                        title="Cancel"
                                      >
                                        <FontAwesomeIcon icon="reply" />
                                      </Button>
                                      <Button
                                        size="sm"
                                        className="primary"
                                        onClick={() => saveNewRigType()}
                                        title="Save New Rig Type"
                                      >
                                        <FontAwesomeIcon icon="save" />
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                              )}
                              <hr />
                              <Row className="mt-2">
                                <Col>
                                  <div>
                                    <i>
                                      At least one is required
                                      {helpers.requiredStar()}
                                    </i>
                                  </div>
                                  <Row className="mb-2 align-items-end">
                                    <Col xs="12">
                                      <Table
                                        striped
                                        hover
                                        size="sm"
                                        responsive={false}
                                        id="rigTypesTable"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Rig Type</th>
                                            <th>Qty</th>
                                            <th>
                                              <Button
                                                size="sm"
                                                className="projectSuccess float-right"
                                                onClick={() => addToRigTypes()}
                                                title="Add"
                                              >
                                                <FontAwesomeIcon icon="plus" />{" "}
                                                Add
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {haulerRigTypes &&
                                          haulerRigTypes.length > 0
                                            ? _.map(
                                                haulerRigTypes,
                                                (r, index) => (
                                                  <Fragment>
                                                    <tr key={index}>
                                                      {r.editing ? (
                                                        <Fragment>
                                                          <td width="50%">
                                                            <Select
                                                              closeMenuOnSelect={
                                                                true
                                                              }
                                                              value={
                                                                r.type || null
                                                              }
                                                              name="type"
                                                              options={
                                                                rigTypeList
                                                              }
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                onRigTypeSelect(
                                                                  index,
                                                                  event,
                                                                  "type"
                                                                )
                                                              }
                                                              isDisabled={
                                                                r.id > 0
                                                              }
                                                            />
                                                          </td>
                                                          <td width="10%">
                                                            <DebounceInput
                                                              type="number"
                                                              name="qty"
                                                              id="qty"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                onRigTypeInputChange(
                                                                  index,
                                                                  event
                                                                )
                                                              }
                                                              value={r.qty}
                                                              className="form-control"
                                                              debounceTimeout={
                                                                300
                                                              }
                                                              placeholder="Qty"
                                                            />
                                                          </td>
                                                          <td>
                                                            <ButtonGroup className="float-right">
                                                              <Button
                                                                size="sm"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  onCancelEdit(
                                                                    index
                                                                  )
                                                                }
                                                                title="Cancel"
                                                              >
                                                                <FontAwesomeIcon icon="reply" />
                                                              </Button>
                                                              <Button
                                                                size="sm"
                                                                className="primary"
                                                                onClick={() =>
                                                                  saveRigType(
                                                                    index
                                                                  )
                                                                }
                                                                title="Save"
                                                              >
                                                                <FontAwesomeIcon icon="save" />
                                                              </Button>
                                                            </ButtonGroup>
                                                          </td>
                                                        </Fragment>
                                                      ) : (
                                                        <Fragment>
                                                          <td>
                                                            <span>
                                                              {r.type &&
                                                                r.type.label}
                                                            </span>
                                                          </td>
                                                          <td>
                                                            <span>{r.qty}</span>
                                                          </td>
                                                          <td>
                                                            <ButtonGroup className="float-right">
                                                              <Button
                                                                size="sm"
                                                                className="projectPrimary"
                                                                onClick={() =>
                                                                  editRigType(
                                                                    index
                                                                  )
                                                                }
                                                                title="Edit"
                                                              >
                                                                <FontAwesomeIcon icon="edit" />
                                                              </Button>
                                                              <Button
                                                                size="sm"
                                                                className="projectDanger"
                                                                onClick={() =>
                                                                  deleteFromRigTypes(
                                                                    index
                                                                  )
                                                                }
                                                                title="Remove from quota"
                                                              >
                                                                <FontAwesomeIcon icon="times-circle" />
                                                              </Button>
                                                            </ButtonGroup>
                                                          </td>
                                                        </Fragment>
                                                      )}
                                                    </tr>
                                                  </Fragment>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        {orgDocumentList === null ? null : (
                          <DocumentUpload
                            cardName={helpers.cardHeaderName(
                              "Documents",
                              "upload"
                            )}
                            documentList={orgDocumentList}
                            uploadUrl={`org/SaveOrgDocument`}
                            deleteUrl={`org/DeleteOrgDocument`}
                            orgId={routeOrgId}
                            refresh={refreshCompanyDetails}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* STRIPE DETAILS 
                    <Row>
                      <Col>
                        <SubscriptionModule
                          title={currentCompanyType && currentCompanyType.label}
                          orgId={routeOrgId}
                          isAdmin={userCtx?.currentUser?.isSysAdmin}
                          showDetailView={true}
                        />
                      </Col>
                    </Row>
                    */}
                  </Fragment>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
