import React from "react";
import { Row, Col, Container } from "reactstrap";

export default function DesktopContainer(props) {
  return (
    <Container fluid className="desktopContainer px-1">
      <Row className="m-0 p-0 fullHeight ">
        <Col className="m-0">
          <Row className="m-0 px-1">
            <Col xs="10" className="my-2 p-0">
              {props.screenName && (
                <h3 className="primaryDarkText">{props.screenName}</h3>
              )}
            </Col>
            <Col xs="2" className="m-0 p-0"></Col>
          </Row>
          <Row className="m-0 p-0 fullHeight">
            <Col className="m-0 p-0">{props.children}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
