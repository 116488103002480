import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import { api, constants } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapsibleCardSection } from ".";
import { Button, ButtonGroup, Table } from "reactstrap";
import Alert from "react-s-alert";

const emptyVeto = {
  id: -1,
  partnerOrg: null,
  editing: true,
};

export default function OrgAccessModule(props) {
  // const userCtx = useContext(UserContext);
  const [orgList, setOrgList] = useState(props.orgList ? props.orgList : []);
  const [showAddRow, setShowAddRow] = useState(false);
  const [partnerOrgList, setPartnerOrgList] = useState([]);

  const saveUrl = "org/SaveOrgAccess";
  const type = props.isAllowAccess ? "Allow Access" : "Deny Access";

  useEffect(() => {
    const getListURL = props.showCompanies
      ? "org/CompanySelectList"
      : "general/GetHaulingCompanyList";
    const payload = props.showCompanies
      ? { exceptOrgTypeId: constants.ORG_TYPE_IDS.HAULER_FIRM }
      : {};
    api
      .post(getListURL, payload)
      .then((response) => {
        setPartnerOrgList(
          _.map(response.data, (d) => ({
            ...d,
            label: d.name,
            value: d.id,
          }))
        );
      })
      .catch((error) => {
        if (props.showError) {
          Alert("There was an error retrieving subscription types");
        } else {
          console.error(error);
        }
      });
    setOrgList(props.orgList);
  }, [props.orgList, props.showCompanies, props.showError]);

  useEffect(() => {
    if (props.orgList !== null) {
      setOrgList(props.orgList);
    }
  }, [props.orgList]);

  function addRow() {
    let list = Object.assign([], orgList);
    list.unshift(Object.assign({}, emptyVeto));
    setOrgList(list);
    setShowAddRow(!showAddRow);
  }

  function onVetoChange(selection, index, fieldName) {
    let list = Object.assign([], orgList);
    list[index][fieldName] = selection;
    setOrgList(list);
  }

  function onRemoveOrg(index) {
    let list = orgList.slice();
    if (list[index] === undefined) return;
    if (list[index].id < 0) {
      list = _.reject(list, (l, idx) => {
        return idx === index;
      });
      setOrgList(list);
      setShowAddRow(false);
      return;
    }
    api
      .post("org/DeleteOrgAccess", {
        Id: list[index].id,
        OrgId: list[index].orgId,
        PartnerOrgId: list[index].partnerOrgId,
        IsAllowAccess: props.isAllowAccess,
      })
      .then((response) => {
        if (response?.data?.success) {
          setShowAddRow(false);
          Alert.success(`${type} list has been updated`);
          props.refreshOrg();
        } else {
          Alert.error(
            response.data.message ||
              "An error occurred. List cannot be updated at this time."
          );
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert(`There was an error updating ${type} list`);
        } else {
          console.error(error);
        }
      });
  }

  function onSubmit(index) {
    let list = orgList.slice();
    let item = Object.assign({}, list[index]);

    let payload = {
      OrgId: props.orgId,
      PartnerOrgId: item.partnerOrg.value,
      IsAllowAccess: props.isAllowAccess,
    };

    api
      .post(saveUrl, payload)
      .then((response) => {
        if (response?.data?.success) {
          setShowAddRow(false);
          Alert.success(`${type} list has been updated`);
          props.refreshOrg();
        } else {
          Alert.error(
            response.data.message ||
              "An error occurred. List cannot be updated at this time."
          );
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert(`There was an error updating ${type} list`);
        } else {
          console.error(error);
        }
      });
  }
  return (
    <CollapsibleCardSection
      startOpen
      cardClass={"mt-2"}
      cardName={props.cardName}
      cardBody={
        <Fragment>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>CreatedAt</th>
                <th>
                  <Button
                    size="sm"
                    title="Add New"
                    className="projectSuccess float-right"
                    disabled={showAddRow}
                    onClick={() => addRow()}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {orgList && orgList.length > 0 ? (
                orgList.map((i, index) => (
                  <tr key={index}>
                    {i.editing ? (
                      <Fragment>
                        <td colSpan="2">
                          <Select
                            closeMenuOnSelect={true}
                            isMulti={false}
                            components={makeAnimated()}
                            options={partnerOrgList}
                            onChange={(e) =>
                              onVetoChange(e, index, "partnerOrg")
                            }
                            value={i.partnerOrg || null}
                            onBlurResetsInput={false}
                            onSelectResetsInput={false}
                            onCloseResetsInput={false}
                            classNamePrefix="react-select"
                          />
                        </td>
                        <td>
                          <ButtonGroup className="float-right">
                            <Button
                              size="sm"
                              className="secondary"
                              onClick={() => onRemoveOrg(index)}
                              title="Cancel"
                            >
                              <FontAwesomeIcon icon="reply" />
                            </Button>
                            <Button
                              size="sm"
                              className="projectPrimary"
                              title="Save"
                              onClick={() => onSubmit(index)}
                            >
                              <FontAwesomeIcon icon="save" />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <td>{i.partnerOrgName}</td>
                        <td>{i.createdAt}</td>
                        <td>
                          <ButtonGroup className="float-right">
                            <Button
                              size="sm"
                              className="projectDanger float-right"
                              title="Remove"
                              onClick={() => onRemoveOrg(index)}
                            >
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </Fragment>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No records found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Fragment>
      }
    />
  );
}
