import React from "react";
import { Col, Row } from "reactstrap";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";

export default function ChecklistImageUpload(props) {
  return (
    <Row className="p-2 mt-2">
      <Col>
        <Dropzone
          accept={props.mimeTypes || "image/*"} //"image/*,application/pdf,.psd"
          onDrop={(acceptedFiles) => {
            if (!acceptedFiles) {
              Alert.warning("Document format is not accepted");
              return;
            }
            const files = acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            );
            if (!props.documentTypeId) {
              Alert.error("Document Type is required");
              return;
            }
            props.refresh(files[0]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "dashed 1px #000000",
                  padding: "3em",
                  borderRadius: "5px",
                }}
              >
                <input {...getInputProps()} />
                <Row className="mb-2" style={{ textAlign: "center" }}>
                  <Col>
                    <FontAwesomeIcon icon="upload" size="4x" />
                  </Col>
                </Row>
                <h5>
                  {`Drag file here to upload (or click here to choose file)`}
                </h5>
              </div>
            </section>
          )}
        </Dropzone>
      </Col>
    </Row>
  );
}
