import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Alert from "react-s-alert";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Table,
  Input,
  Alert as Alert2,
  Card,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { api, helpers, UserContext, constants, dateHelpers } from "../utils";
import {
  CollapsibleCardSection,
  TextNotificationModal,
  DocumentUpload,
  DocumentUploader,
  OrgTypeahead,
  SHBSpinner,
  ChecklistImageUpload,
  AddressAutocomplete,
  EstimatedTimeOfDay,
  JobSummaryModal,
  JobDocumentUpload,
} from "../components";
import { DesktopContainer } from "../layouts";
import { GiConsoleController } from "react-icons/gi";

const { JOB_STATUS_NAMES } = constants;

const emptyJob = {
  id: 0,
  jobType: { value: 6, label: "RTO Delivery" },
  referenceNumber: "",
  compensationAmountInDollars: "0",
  requestedByDate: null,
  scheduledForDate: null,
  pickupContactFirstName: "",
  pickupContactLastName: "",
  pickupContactPhoneNumber: "",
  pickupContactEmail: "",
  dropoffContactFirstName: "",
  dropoffContactLastName: "",
  dropoffContactPhoneNumber: "",
  dropoffContactEmail: "",
  // unitType: { value: 4, label: "Utility" },
  manufacturer: "",
  serialNumber: "",
  // unitStyle: null,
  // unitMaterial: { value: 4, label: "Wood" },
  lengthInFeet: "",
  widthInFeet: "",
  heightInFeet: "",
  roofColor: { value: 1, label: "Blue" },
  wallColor: { value: 1, label: "Blue" },
  trimColor: { value: 1, label: "Blue" },
  otherRoofColor: "",
  otherWallColor: "",
  otherTrimColor: "",
  note: "",
  jobStatus: { value: 2, label: JOB_STATUS_NAMES.RESERVED },
  estimatedPickupTimeOfDay: null
  //orgId: currentUser ? currentUser.orgId : null
};

export default function PrivateJob(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const userOrgId = userCtx.currentUser.currentOrgId;
  const isCompanyUser = userCtx.currentUser.isCompanyUser;
  const isHaulerFirm =
    userCtx.currentUser.isHaulerAdminUser ||
    userCtx.currentUser.isDispatcherUser;
  const isHaulerUser = userCtx.currentUser.isHaulerUser;

  const privateJobId =
    props &&
      props.computedMatch &&
      props.computedMatch.params &&
      props.computedMatch.params.id
      ? parseInt(props.computedMatch.params.id, 10)
      : 0;

  const [job, setJob] = useState({
    ...emptyJob,
    orgId: currentUser ? currentUser.orgId : null,
    createdByOrgPrimaryContactFirstName: userCtx.currentUser.firstName,
    createdByOrgPrimaryContactLastName: userCtx.currentUser.lastName,
    createdByOrgPrimaryContactNumber: userCtx.currentUser.phoneNumber,
    createdByOrgEmail: userCtx.currentUser.email,
  });

  const [showScheduleInfo, setShowScheduleInfo] = useState(false);
  const [driversList, setDriversList] = useState(null);
  // const [driverOptions, setDriverOptions] = useState([]);
  // const [orgRigs, setOrgRigs] = useState([]);
  const [message, setMessage] = useState(null);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [stateList, setStateList] = useState([]);
  // const [unitTypeList, setUnitTypeList] = useState([]);
  // const [unitStyleList, setUnitStyleList] = useState([]);
  // const [unitMaterialList, setUnitMaterialList] = useState([]);
  const [unitColorList, setUnitColorList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [jobRigs, setJobRigs] = useState([]);
  const [typeaheadFromAddress, setTypeaheadFromAddress] = useState("");
  const [typeaheadToAddress, setTypeaheadToAddress] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [jobComplete, setJobComplete] = useState(false);
  const [fromAddress, setFromAddress] = useState({
    fromStreetAddress1: "",
    fromStreetAddress2: "",
    fromCity: "",
    fromState: null,
    fromZipCode: "",
    pickupLat: null,
    pickupLong: null,
  });
  const [toAddress, setToAddress] = useState({
    toAddress1: "",
    toAddress2: "",
    toCity: "",
    toState: null,
    toZipCode: "",
    dropoffLat: null,
    dropoffLong: null,
  });
  const [newDocumentsToUpload, setNewDocumentsToUpload] = useState([]);

  const getPrivateJobDocuments = () =>
    api.fetch(`Job/GetPrivateJobDocuments/${privateJobId}`).then((r) => {
      const jobEdit = Object.assign({}, job);
      jobEdit.documents = r.data;
      setJob(jobEdit);
    });


  useEffect(() => {
    // load job data
    setLoading(true);

    const getJob = () =>
      api.fetch(`Job/GetPrivateJob/${privateJobId}`).then((r) => ({
        job: r.data,
      }));

    let apiCalls = [];
    if (privateJobId > 0) {
      apiCalls.push(getJob());
    }
    apiCalls.push(api.getJobTypes());
    apiCalls.push(api.getStates());
    apiCalls.push(api.getUnitTypes());
    apiCalls.push(api.getUnitStyles());
    apiCalls.push(api.getUnitMaterials());
    apiCalls.push(api.getUnitColors());
    apiCalls.push(api.getOrgDrivers(userOrgId));
    // if (isHaulerUser) {
    //   apiCalls.push(getRigs());
    //   apiCalls.push(getHaulerDrivers());
    // }
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        const tempJob = aggResults.job
          ? Object.assign({}, aggResults.job)
          : null;
        if (tempJob) {
          let formattedJob = {
            id: tempJob.id,
            // orgId: tempJob.orgId,
            // orgName: tempJob.orgName,
            jobStatus: helpers.resolveValue(
              null,
              tempJob.jobStatusId,
              tempJob.jobStatusName
            ),
            assignedDriverId: tempJob.assignedDriverId,
            estimatedPickupTimeOfDay: tempJob.estimatedPickupTimeOfDay,
            jobType: _.find(constants.JOB_TYPES, jt => jt.value === tempJob.jobTypeId),
            referenceNumber: helpers.nullableString(tempJob.referenceNumber),
            compensationAmountInDollars: tempJob.compensationAmountInDollars,
            requestedByDate: dateHelpers.dateFormatForClient(tempJob.requestedByDate),
            scheduledForDate: dateHelpers.dateFormatForClient(tempJob.scheduledForDate),
            pickupContactFirstName: helpers.nullableString(tempJob.pickupContactFirstName),
            pickupContactLastName: helpers.nullableString(tempJob.pickupContactLastName),
            pickupContactPhoneNumber: helpers.nullableString(tempJob.pickupContactPhoneNumber),
            pickupContactEmail: helpers.nullableString(tempJob.pickupContactEmail),
            dropoffContactFirstName: helpers.nullableString(tempJob.dropoffContactFirstName),
            dropoffContactLastName: helpers.nullableString(tempJob.dropoffContactLastName),
            dropoffContactPhoneNumber: helpers.nullableString(tempJob.dropoffContactPhoneNumber),
            dropoffContactEmail: helpers.nullableString(tempJob.dropoffContactEmail),
            // unitType: helpers.resolveValue(
            //   null,
            //   tempJob.unitTypeId,
            //   tempJob.unitTypeName
            // ),
            manufacturer: helpers.nullableString(tempJob.manufacturer),
            serialNumber: helpers.nullableString(tempJob.serialNumber),
            // unitStyle: helpers.resolveValue(
            //   null,
            //   tempJob.unitStyleId,
            //   tempJob.unitStyleName
            // ),
            // unitMaterial: helpers.resolveValue(
            //   null,
            //   tempJob.unitMaterialId,
            //   tempJob.unitMaterialName
            // ),
            lengthInFeet: tempJob.lengthInFeet?.toFixed(2),
            widthInFeet: tempJob.widthInFeet?.toFixed(2),
            heightInFeet: tempJob.heightInFeet?.toFixed(2),
            wallColor: helpers.resolveValue(
              null,
              tempJob.wallColorId,
              tempJob.wallColorName
            ),
            trimColor: helpers.resolveValue(
              null,
              tempJob.trimColorId,
              tempJob.trimColorName
            ),
            roofColor: helpers.resolveValue(
              null,
              tempJob.roofColorId,
              tempJob.roofColorName
            ),
            otherRoofColor: helpers.nullableString(tempJob.otherRoofColor),
            otherWallColor: helpers.nullableString(tempJob.otherWallColor),
            otherTrimColor: helpers.nullableString(tempJob.otherTrimColor),
            note: helpers.nullableString(tempJob.note),
            documents: tempJob.documents
          };
          setJob(formattedJob);
          if (formattedJob.jobStatus.label === constants.JOB_STATUS_NAMES.SCHEDULED
            || formattedJob.jobStatus.label === constants.JOB_STATUS_NAMES.COMPLETE) {
            setShowScheduleInfo(true);
          }
          setJobComplete(tempJob.completedAt ? true : false);
          setFromAddress({
            fromStreetAddress1: helpers.nullableString(tempJob.fromStreetAddress1),
            fromStreetAddress2: helpers.nullableString(tempJob.fromStreetAddress2),
            fromCity: helpers.nullableString(tempJob.fromCity),
            fromZipCode: helpers.nullableString(tempJob.fromZipCode),
            fromState: helpers.resolveValue(
              null,
              tempJob.fromStateId,
              tempJob.fromStateAbbr
            ),
            pickupLat: tempJob.pickupLat,
            pickupLong: tempJob.pickupLong,
          });
          setToAddress({
            toAddress1: helpers.nullableString(tempJob.toAddress1),
            toAddress2: helpers.nullableString(tempJob.toAddress2),
            toCity: helpers.nullableString(tempJob.toCity),
            toZipCode: helpers.nullableString(tempJob.toZipCode),
            toState: helpers.resolveValue(
              null,
              tempJob.toStateId,
              tempJob.toStateAbbr
            ),
            dropoffLat: tempJob.dropoffLat,
            dropoffLong: tempJob.dropoffLong,
          });
        } else {
          if (aggResults.orgPrimaryUser) {
            setJob({
              ...job,
              createdByOrgPrimaryContactFirstName:
                aggResults.orgPrimaryUser.firstName,
              createdByOrgPrimaryContactLastName:
                aggResults.orgPrimaryUser.lastName,
            });
          }
        }
        setJobTypeList(aggResults.jobTypes);
        setStateList(aggResults.states);
        // setUnitTypeList(aggResults.unitTypes);
        // setUnitStyleList(aggResults.unitStyles);
        // setUnitMaterialList(aggResults.unitMaterials);
        setUnitColorList(aggResults.unitColors);
        if (tempJob) {
          // job already exists
          // setJobRigs(aggResults.jobRigs);
        } else {
          // new job
        }
        if (aggResults.drivers) {
          setDriversList(aggResults.drivers);
        }
      })

      .catch((error) => {
        console.log('error', error);
        setMessage({
          flavor: "alert-danger",
          text: "There was an error loading job data.",
        });
      })
      .finally(() => setLoading(false));
  }, [isCompanyUser, isHaulerUser, userOrgId, privateJobId]);

  function onChangeJobType(selection) {
    const jobEdit = Object.assign({}, job);
    jobEdit.jobType = selection;
    setJob(jobEdit);
  }

  function changeJobField(e) {
    const jobEdit = Object.assign({}, job);
    jobEdit[e.target.name] = e.target.value;
    setJob(jobEdit);
  }

  function onDateChange(selected, field) {
    const editJob = Object.assign({}, job);
    editJob[field] = selected;
    setJob(editJob);
  }

  function changeJobSelect(selection, label) {
    const jobEdit = Object.assign({}, job);
    jobEdit[label] = selection;
    setJob(jobEdit);
  }

  function convertDocToFormData(doc, jobId) {
    const formData = new FormData();
    formData.append("file", doc.file);
    formData.append("orgId", doc.orgId);
    formData.append("jobId", jobId);
    formData.append("id", doc.id);
    formData.append("documentTypeId", doc.documentTypeId);
    return formData;
  }

  const uploadAllDocuments = (documents, jobId) => {
    return new Promise(async (resolve, reject) => {
      let allUploaded = true;
      for (let doc of documents) {
        // each document upload will happen one at a time. Might need to change this to allow to run parallelly in future
        let formData = convertDocToFormData(doc, jobId)
        await api
          .post_form_data("Job/SavePrivateJobDocument", formData)
          .then((response) => {
            if (response.data && response.data.success) {
              // on success ?
            } else {
              allUploaded = false;
            }

          })
          .catch(() => allUploaded = false)
      }
      if (allUploaded) resolve("All uploaded");
      else reject("All documents could not be uploaded");
    })
  }

  function objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File || root.endsWith("File")) {
          //TODO
          // if (data instanceof File || (data && data.uri && data.name && data.type)) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }

    appendFormData(obj, rootName);

    return formData;
  }

  function onSaveJob() {
    window.scroll(0, 0);
    let warnings = [];
    if (!job.jobStatus) {
      warnings.push("Valid job status is required."); // in case of bad data
    }
    if (!job.jobType) {
      warnings.push("Valid job type is required."); // in case of bad data
    }
    if (!fromAddress.fromStreetAddress1) {
      warnings.push("Valid origin address is required.");
    }
    if (!fromAddress.fromCity) {
      warnings.push("Valid origin city is required.");
    }
    if (!fromAddress.fromState) {
      warnings.push("Valid origin state is required.");
    }
    if (!fromAddress.fromZipCode) {
      warnings.push("Valid origin zip code is required.");
    }
    if (!toAddress.toAddress1) {
      warnings.push("Valid destination address is required.");
    }
    if (!toAddress.toCity) {
      warnings.push("Valid destination city is required.");
    }
    if (!toAddress.toState) {
      warnings.push("Valid destination state is required.");
    }
    if (!toAddress.toZipCode) {
      warnings.push("Valid destination zip code is required.");
    }
    if (!job.manufacturer || _.trim(job.manufacturer).length < 2) {
      warnings.push("Please provide a valid manufacturer.");
    }
    if (!job.lengthInFeet) {
      warnings.push("Building length in feet is required.");
    }
    if (!job.widthInFeet) {
      warnings.push("Building width in feet is required.");
    }
    if (!job.heightInFeet) {
      warnings.push("Building height in feet is required.");
    }
    if (showScheduleInfo) {
      if (!job.assignedDriverId) {
        warnings.push("Please assign a driver to the schedule.");
      }
      if (!job.estimatedPickupTimeOfDay) {
        warnings.push("Please select an estimated pickup time.");
      }
      if (!job.scheduledForDate) {
        warnings.push("Please select a schedule date.");
      }
    }

    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    let payload = {
      id: job.id,
      orgId: job.orgId ? job.orgId : userOrgId,
      typeOfJobStatus: job.jobStatus.value,
      jobTypeId: job.jobType.value,
      referenceNumber: job.referenceNumber,
      compensationAmount: Number(job.compensationAmountInDollars),
      requestedByDate: dateHelpers.formatDateForServer(job.requestedByDate),
      scheduledForDate: dateHelpers.formatDateForServer(job.scheduledForDate),
      pickupContactFirstName: job.pickupContactFirstName,
      pickupContactLastName: job.pickupContactLastName,
      pickupContactPhoneNumber: job.pickupContactPhoneNumber,
      pickupContactEmail: job.pickupContactEmail,
      dropoffContactFirstName: job.dropoffContactFirstName,
      dropoffContactLastName: job.dropoffContactLastName,
      dropoffContactPhoneNumber: job.dropoffContactPhoneNumber,
      dropoffContactEmail: job.dropoffContactEmail,
      fromStreetAddress1: fromAddress.fromStreetAddress1,
      fromStreetAddress2: fromAddress.fromStreetAddress2,
      fromCity: fromAddress.fromCity,
      fromStateId: fromAddress.fromState ? fromAddress.fromState.value : null,
      fromZipCode: fromAddress.fromZipCode,
      // pickupLat: fromAddress.pickupLat ? fromAddress.pickupLat : null,
      // pickupLong: fromAddress.pickupLong ? fromAddress.pickupLong : null,
      toAddress1: toAddress.toAddress1,
      toAddress2: toAddress.toAddress2,
      toCity: toAddress.toCity,
      toStateId: toAddress.toState ? toAddress.toState.value : null,
      toZipCode: toAddress.toZipCode,
      dropoffLat: toAddress.dropoffLat ? toAddress.dropoffLat : null,
      dropoffLong: toAddress.dropoffLong ? toAddress.dropoffLong : null,
      // unitTypeId: job.unitType.value,
      manufacturer: job.manufacturer,
      serialNumber: job.serialNumber,
      // unitStyleId:
      //   job.unitStyle && job.unitStyle.value ? job.unitStyle.value : null,
      // unitMaterialId: job.unitMaterial.value,
      lengthInFeet: job.lengthInFeet,
      widthInFeet: job.widthInFeet,
      heightInFeet: job.heightInFeet,
      wallColorId: job.wallColor.value,
      trimColorId: job.trimColor.value,
      roofColorId: job.roofColor.value,
      estimatedPickupTimeOfDay: job.estimatedPickupTimeOfDay,
      assignedDriverId: job.assignedDriverId,
      otherWallColor: job.wallColor.value == constants.COLOR_IDS.OTHER ? job.otherWallColor : null,
      otherTrimColor: job.trimColor.value == constants.COLOR_IDS.OTHER ? job.otherTrimColor : null,
      otherRoofColor: job.roofColor.value == constants.COLOR_IDS.OTHER ? job.otherRoofColor : null,
      note: job.note
    };

    if (showScheduleInfo) {
      payload.typeOfJobStatus = constants.JOB_STATUS_IDS.SCHEDULED
    }
    if (!showScheduleInfo && !payload.assignedDriverId) {
      payload.assignedDriverId = currentUser.id
    }
    if (!showScheduleInfo && !payload.estimatedPickupTimeOfDay) {
      payload.estimatedPickupTimeOfDay = "AM"
    }

    let formPayload = objectToFormData(payload, null, []);

    api
      .post("Job/SavePrivateJob", formPayload)
      .then((response) => {
        if (response.data && response.data.success) {
          uploadAllDocuments(newDocumentsToUpload, response.data.message.id).then((response) => {
            setMessage({
              text: "Private Job has been updated successfully! You will be redirected momentarily.",
              flavor: "alert-success",
            });
            setTimeout(() => {
              setRedirect(true);
            }, 2000);
          })
            .catch((error) => {
              setMessage({ text: error, flavor: "alert-danger" });

            });
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }



  if (redirect) {
    return <Redirect to={`/privatejobs`} />;
  }

  if (!loading) {
    return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          switch (currentBreakpoint) {
            case breakpoints.mobile:
              return <div>{props.children}</div>;
            case breakpoints.desktop:
            default:
              return (
                <DesktopContainer
                  screenName={
                    privateJobId > 0 ? (
                      <Fragment>
                        <span>{`Private Job #${privateJobId}`} </span>
                        <i className="fontSize85 float-right">
                          <i className="fontSize85">
                            {job.jobStatus ? job.jobStatus.label : null}
                          </i>
                        </i>
                      </Fragment>
                    ) : (
                      `New Private Job`
                    )
                  }
                >
                  {message ? (
                    <Alert2 className={message.flavor}>{message.text}</Alert2>
                  ) : null}
                  <Row className="mb-2">
                    <Col xs="12" className="mx-0 px-0">
                      <CollapsibleCardSection
                        cardName={"Job Details"}
                        cardBody={
                          <Fragment>
                            <Row className="mb-4 mt-2">
                              <Col xs="3">
                                Job Type {helpers.requiredStar()}
                                <Select
                                  name="jobType"
                                  id="jobType"
                                  closeMenuOnSelect={true}
                                  value={job.jobType}
                                  options={jobTypeList}
                                  onChange={(event) => onChangeJobType(event)}
                                  isDisabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                PO or Reference # (optional)
                                <Input
                                  type="text"
                                  name="referenceNumber"
                                  id="referenceNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.referenceNumber}
                                  className="form-control"
                                  maxLength="255"
                                  disabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Compensation Amount
                                <Input
                                  type="number"
                                  name="compensationAmountInDollars"
                                  id="compensationAmountInDollars"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.compensationAmountInDollars}
                                  className="form-control"
                                  disabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Requested By
                                <div className="customDatePickerWidth">
                                  <DatePicker
                                    name="requestedByDate"
                                    selected={job.requestedByDate}
                                    className="form-control"
                                    placeholderText="Select date"
                                    onChange={(e) =>
                                      onDateChange(e, "requestedByDate")
                                    }
                                    disabled={jobComplete}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs="6">
                                <h5>Pickup Contact Information</h5>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs="3">
                                Pickup Contact First Name
                                <Input
                                  type="text"
                                  name="pickupContactFirstName"
                                  id="pickupContactFirstName"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.pickupContactFirstName}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Pickup Contact Last Name
                                <Input
                                  type="text"
                                  name="pickupContactLastName"
                                  id="pickupContactLastName"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.pickupContactLastName}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Pickup Contact Phone Number
                                <Input
                                  type="text"
                                  name="pickupContactPhoneNumber"
                                  id="pickupContactPhoneNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.pickupContactPhoneNumber}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="12"
                                />
                              </Col>
                              <Col xs="3">
                                Pickup Contact Email
                                <Input
                                  type="text"
                                  name="pickupContactEmail"
                                  id="pickupContactEmail"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.pickupContactEmail}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="320"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-4">
                              <Col xs="6">
                                <h5>Dropoff Contact Information</h5>
                              </Col>
                            </Row>
                            <Row className="mb-5 mt-2">
                              <Col xs="3">
                                Dropoff Contact First Name
                                <Input
                                  type="text"
                                  name="dropoffContactFirstName"
                                  id="dropoffContactFirstName"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.dropoffContactFirstName}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Dropoff Contact Last Name
                                <Input
                                  type="text"
                                  name="dropoffContactLastName"
                                  id="dropoffContactLastName"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.dropoffContactLastName}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="50"
                                />
                              </Col>
                              <Col xs="3">
                                Dropoff Contact Phone Number
                                <Input
                                  type="text"
                                  name="dropoffContactPhoneNumber"
                                  id="dropoffContactPhoneNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.dropoffContactPhoneNumber}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="12"
                                />
                              </Col>
                              <Col xs="3">
                                Dropoff Contact Email
                                <Input
                                  type="text"
                                  name="dropoffContactEmail"
                                  id="dropoffContactEmail"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.dropoffContactEmail}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="320"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="6">
                                <Row className="mb-2">
                                  <Col xs="5">
                                    <h5>
                                      Origin Address {helpers.requiredStar()}
                                    </h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <AddressAutocomplete
                                      id="fromAddressAutocomplete"
                                      value={typeaheadFromAddress}
                                      onChange={(e) =>
                                        setTypeaheadFromAddress(e.target.value)
                                      }
                                      disabled={jobComplete}
                                      getAddressInfo={({
                                        city,
                                        streetNumber,
                                        route,
                                        zip,
                                        lat,
                                        lng,
                                        stateAbbr,
                                      }) => {
                                        let tempState = null;

                                        if (stateAbbr) {
                                          tempState = _.find(
                                            stateList,
                                            (s) => s.label === stateAbbr
                                          );
                                        }

                                        setFromAddress({
                                          ...fromAddress,
                                          fromStreetAddress1: `${streetNumber} ${route}`,
                                          fromCity: city,
                                          fromZipCode: zip,
                                          pickupLat: lat,
                                          pickupLong: lng,
                                          fromState: tempState,
                                        });
                                        setTypeaheadFromAddress("");
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Fragment>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="fromStreetAddress1"
                                        onChange={(e) =>
                                          setFromAddress({
                                            ...fromAddress,
                                            fromStreetAddress1: e.target.value,
                                          })
                                        }
                                        value={fromAddress.fromStreetAddress1}
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={jobComplete}
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="fromStreetAddress2"
                                        onChange={(e) =>
                                          setFromAddress({
                                            ...fromAddress,
                                            fromStreetAddress2: e.target.value,
                                          })
                                        }
                                        value={fromAddress.fromStreetAddress2}
                                        className="form-control"
                                        placeholder="Address Line 2 (optional)"
                                        disabled={jobComplete}
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                                <Row className="mb-3">
                                  <Col xs="6">
                                    <Input
                                      type="text"
                                      name="fromCity"
                                      onChange={(e) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromCity: e.target.value,
                                        })
                                      }
                                      value={fromAddress.fromCity}
                                      className="form-control"
                                      placeholder="City"
                                      disabled={jobComplete}
                                      maxLength="255"
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <Select
                                      name="fromState"
                                      closeMenuOnSelect={true}
                                      value={fromAddress.fromState}
                                      options={stateList}
                                      onChange={(x) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromState: x,
                                        })
                                      }
                                      isDisabled={jobComplete}
                                    />
                                  </Col>
                                  <Col xs="4">
                                    <Input
                                      type="text"
                                      name="fromZipCode"
                                      onChange={(e) =>
                                        setFromAddress({
                                          ...fromAddress,
                                          fromZipCode: e.target.value,
                                        })
                                      }
                                      value={fromAddress.fromZipCode}
                                      className="form-control"
                                      placeholder="Zip"
                                      disabled={jobComplete}
                                      maxLength="11"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="6">
                                <Row className="mb-2">
                                  <Col xs="5">
                                    <h5>
                                      Destination Address{" "}
                                      {helpers.requiredStar()}
                                    </h5>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <AddressAutocomplete
                                      id="toAddressAutocomplete"
                                      value={typeaheadToAddress}
                                      onChange={(e) =>
                                        setTypeaheadToAddress(e.target.value)
                                      }
                                      disabled={jobComplete}
                                      getAddressInfo={({
                                        city,
                                        streetNumber,
                                        route,
                                        zip,
                                        lat,
                                        lng,
                                        stateAbbr,
                                      }) => {
                                        let tempState = null;

                                        if (stateAbbr) {
                                          tempState = _.find(
                                            stateList,
                                            (s) => s.label === stateAbbr
                                          );
                                        }

                                        setToAddress({
                                          ...toAddress,
                                          toAddress1: `${streetNumber} ${route}`,
                                          toCity: city,
                                          toZipCode: zip,
                                          dropoffLat: lat,
                                          dropoffLong: lng,
                                          toState: tempState || null,
                                        });
                                        setTypeaheadToAddress("");
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Fragment>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="toAddress1"
                                        onChange={(e) =>
                                          setToAddress({
                                            ...toAddress,
                                            toAddress1: e.target.value,
                                          })
                                        }
                                        value={toAddress.toAddress1}
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={jobComplete}
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-1">
                                    <Col>
                                      <Input
                                        type="text"
                                        name="toAddress2"
                                        onChange={(e) =>
                                          setToAddress({
                                            ...toAddress,
                                            toAddress2: e.target.value,
                                          })
                                        }
                                        value={toAddress.toAddress2}
                                        className="form-control"
                                        placeholder="Address Line 2 (optional)"
                                        disabled={jobComplete}
                                        maxLength="255"
                                      />
                                    </Col>
                                  </Row>
                                </Fragment>
                                <Row className="mb-3">
                                  <Col xs="6">
                                    <Input
                                      type="text"
                                      name="toCity"
                                      onChange={(e) =>
                                        setToAddress({
                                          ...toAddress,
                                          toCity: e.target.value,
                                        })
                                      }
                                      value={toAddress.toCity}
                                      className="form-control"
                                      placeholder="City"
                                      disabled={jobComplete}
                                      maxLength="255"
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <Select
                                      name="toState"
                                      closeMenuOnSelect={true}
                                      value={toAddress.toState}
                                      options={stateList}
                                      onChange={(x) =>
                                        setToAddress({
                                          ...toAddress,
                                          toState: x,
                                        })
                                      }
                                      isDisabled={jobComplete}
                                    />
                                  </Col>
                                  <Col xs="4">
                                    <Input
                                      type="text"
                                      name="toZipCode"
                                      onChange={(e) =>
                                        setToAddress({
                                          ...toAddress,
                                          toZipCode: e.target.value,
                                        })
                                      }
                                      value={toAddress.toZipCode}
                                      className="form-control"
                                      placeholder="Zip"
                                      disabled={jobComplete}
                                      maxLength="11"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-4">
                              <Col xs="6">
                                <h5>Building Information</h5>
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              {/* <Col xs="3">
                                Unit Type {helpers.requiredStar()}
                                <Select
                                  name="unitType"
                                  id="unitType"
                                  closeMenuOnSelect={true}
                                  value={job.unitType}
                                  options={unitTypeList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitType")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col> */}
                              <Col xs="3">
                                Manufacturer {helpers.requiredStar()}
                                <Input
                                  type="text"
                                  name="manufacturer"
                                  id="manufacturer"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.manufacturer}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="255"
                                />
                              </Col>
                              <Col xs="3">
                                Serial # (optional)
                                <Input
                                  type="text"
                                  name="serialNumber"
                                  id="serialNumber"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.serialNumber}
                                  className="form-control"
                                  disabled={jobComplete}
                                  maxLength="255"
                                />
                              </Col>
                              {/* <Col xs="3">
                                Style (optional)
                                <Select
                                  name="unitStyle"
                                  id="unitStyle"
                                  closeMenuOnSelect={true}
                                  value={job.unitStyle}
                                  options={unitStyleList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitStyle")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col> */}
                            </Row>
                            <Row className="mb-2 align-items-end">
                              {/* <Col xs="3">
                                Material {helpers.requiredStar()}
                                <Select
                                  name="unitMaterial"
                                  id="unitMaterial"
                                  closeMenuOnSelect={true}
                                  value={job.unitMaterial}
                                  options={unitMaterialList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "unitMaterial")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col> */}
                              <Col xs="3">
                                Length (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="lengthInFeet"
                                  id="lengthInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.lengthInFeet}
                                  className="form-control"
                                  disabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Width (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="widthInFeet"
                                  id="widthInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.widthInFeet}
                                  className="form-control"
                                  disabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Height (ft) {helpers.requiredStar()}
                                <Input
                                  type="number"
                                  name="heightInFeet"
                                  id="heightInFeet"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.heightInFeet}
                                  className="form-control"
                                  disabled={jobComplete}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                Wall Color {helpers.requiredStar()}
                                <Select
                                  name="wallColor"
                                  id="unitColor"
                                  closeMenuOnSelect={true}
                                  value={job.wallColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "wallColor")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Roof Color {helpers.requiredStar()}
                                <Select
                                  name="roofColor"
                                  id="roofColor"
                                  closeMenuOnSelect={true}
                                  value={job.roofColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "roofColor")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col>
                              <Col xs="3">
                                Trim Color {helpers.requiredStar()}
                                <Select
                                  name="trimColor"
                                  id="trimColor"
                                  closeMenuOnSelect={true}
                                  value={job.trimColor}
                                  options={unitColorList}
                                  onChange={(event) =>
                                    changeJobSelect(event, "trimColor")
                                  }
                                  isDisabled={jobComplete}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 align-items-end">
                              <Col xs="3">
                                {job.wallColor?.value == constants.COLOR_IDS.OTHER
                                  ? <Input
                                    type="text"
                                    name="otherWallColor"
                                    id="otherWallColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherWallColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                  : null
                                }
                              </Col>
                              <Col xs="3">
                                {job.roofColor?.value == constants.COLOR_IDS.OTHER
                                  ? <Input
                                    type="text"
                                    name="otherRoofColor"
                                    id="otherRoofColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherRoofColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                  : null
                                }
                              </Col>
                              <Col xs="3">
                                {job.trimColor?.value == constants.COLOR_IDS.OTHER
                                  ? <Input
                                    type="text"
                                    name="otherTrimColor"
                                    id="otherTrimColor"
                                    onChange={(event) => changeJobField(event)}
                                    value={job.otherTrimColor}
                                    className="form-control"
                                    disabled={jobComplete}
                                    maxLength="75"
                                  />
                                  : null
                                }
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-4">
                              <Col xs="6">
                                <h5>Notes</h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Input
                                  type="textarea"
                                  style={{ width: "60%", height: "200px" }}
                                  name="note"
                                  id="note"
                                  onChange={(event) => changeJobField(event)}
                                  value={job.note}
                                  className="form-control"
                                  maxLength="500"
                                  disabled={jobComplete}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2 mt-4">
                              <Col>
                                Add Scheduling Info?
                                <Switch
                                  className="ml-2"
                                  name="addScheduling"
                                  onChange={(e) =>
                                    setShowScheduleInfo(!showScheduleInfo)
                                  }
                                  checked={showScheduleInfo}
                                //disabled={isHaulerUser}
                                />
                              </Col>
                            </Row>
                            {
                              showScheduleInfo
                                ? <>
                                  <Row className="mb-2 mt-4">
                                    <Col xs="6">
                                      <h5>Notes</h5>
                                    </Col>
                                  </Row>
                                  <Row className="pb-2">
                                    <Col>
                                      Assigned Driver {helpers.requiredStar()}
                                      <Select
                                        closeMenuOnSelect={true}
                                        value={job && _.find(driversList, d => d.value === job.assignedDriverId)}
                                        name="driver"
                                        options={driversList}
                                        onChange={(e) =>
                                          setJob({ ...job, assignedDriverId: e.value })
                                        }
                                        isDisabled={jobComplete}
                                      />
                                    </Col>
                                    <Col>
                                      Scheduled For
                                      <div className="customDatePickerWidth">
                                        <DatePicker
                                          name="scheduledForDate"
                                          selected={job.scheduledForDate}
                                          className="form-control"
                                          placeholderText="Select date"
                                          onChange={(e) =>
                                            onDateChange(e, "scheduledForDate")
                                          }
                                          disabled={jobComplete}
                                        />
                                      </div>
                                    </Col>
                                    <Col >
                                      Estimated Pickup Time Of Day {helpers.requiredStar()}
                                      <EstimatedTimeOfDay
                                        value={job.estimatedPickupTimeOfDay}
                                        onChange={
                                          (e) => setJob({ ...job, estimatedPickupTimeOfDay: e.target.value })
                                        }
                                        disabled={jobComplete}
                                      />
                                    </Col>
                                  </Row>
                                </>
                                : null
                            }
                            {/* Private Job Document Upload */}
                       
                              <Col xs="12" className="mx-0 px-0 my-4">
                                <JobDocumentUpload
                                  cardName={helpers.cardHeaderName(
                                    "Documents",
                                    "upload"
                                  )}
                                  newDocumentsToUpload={newDocumentsToUpload}
                                  setNewDocumentsToUpload={setNewDocumentsToUpload}
                                  existingDocuments={job.documents}
                                  uploadUrl={`Job/SavePrivateJobDocument`}
                                  deleteUrl={`Job/DeletePrivateJobDocument`}
                                  jobId={job.id}
                                  refresh={getPrivateJobDocuments}
                                />
                              </Col>
                         

                            {/* <Row className="mb-3">
                              {jobInProgress && (
                                <Col>
                                  <Button
                                    className="projectPrimary float-right"
                                    title="Complete Job"
                                    onClick={() => onCompleteJob()}
                                  >
                                    <FontAwesomeIcon icon="flag-checkered" />{" "}
                                    Complete Job
                                  </Button>
                                </Col>
                              )}
                            </Row> */}
                            <Row className="mb-2 align-items-end">
                              <Col>
                                <ButtonGroup className="float-right">
                                  <Button
                                    color="secondary"
                                    tag={Link}
                                    to={"/privatejobs"}
                                  >
                                    Cancel
                                  </Button>
                                  {jobComplete
                                    ? null
                                    : <Button
                                      className="projectPrimary btn btn-primary"
                                      onClick={() => onSaveJob()}
                                    >
                                      Save Job
                                    </Button>
                                  }
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </Fragment>
                        }
                      />
                    </Col>
                  </Row>


                </DesktopContainer>
              );
          }
        }}
      </Media>
    );
  } else {
    return (
      <SHBSpinner
        on={loading}
        className="spinnerCenter"
        phrases={constants.PHRASES}
      >
        <DesktopContainer screenName={`Edit Job`} />
      </SHBSpinner>
    );
  }
}

