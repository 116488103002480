import React, { useState, useContext, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Col, Button, Row, ButtonGroup, Table, Input, Label } from "reactstrap";
import DebounceInput from "react-debounce-input";
import Alert from "react-s-alert";
import Select from "react-select";
import Switch from "react-switch";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import { api, helpers, constants, UserContext, dateHelpers } from "../utils";
import {
  CollapsibleCardSection,
  DocumentUpload,
  OrgAccessModule,
  ProfileImageRender,
} from "../components";
import { DesktopContainer } from "../layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const emptyRigType = {
  id: 0,
  type: null,
  qty: 1,
  editing: true,
};

const HaulerFirmString = "Hauler Firm";
const DocumentItemTypeName = "Attachment";
const BooleanItemTypeName = "Boolean";
const TextItemTypeName = "Text";

export default function CompanyProfile(props) {
  const userCtx = useContext(UserContext);
  const user = userCtx.currentUser;
  const [organization, setOrganization] = useState(null);
  const [currentCompanyType, setCurrentOrgType] = useState(null);
  const [organizationUsers, setOrganizationUsers] = useState(null);
  const [companyLocations, setCompanyLocations] = useState(null);
  const [prestineLocation, setPrestineLocation] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [locationTypeList, setLocationTypeList] = useState([]);
  const [allowOrgAccessList, setAllowOrgAccessList] = useState(null);
  const [denyOrgAccessList, setDenyOrgAccessList] = useState(null);
  const [orgDocumentList, setOrgDocumentList] = useState(null);
  const [feeTypeList, setFeeTypeList] = useState(null);
  const [checkListItemTypeList, setCheckListItemTypeList] = useState(null);
  const [documentTypeList, setDocumentTypeList] = useState(null);
  const [jobTypeList, setJobTypeList] = useState(null);
  //Company related
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [orgTypeList, setCompanyTypeList] = useState([]);
  const [companyUnitTypes, setCompanyUnitTypes] = useState(null);
  const [defaultFeeList, setDefaultFeeList] = useState(null);
  const [prestineFee, setPrestineFee] = useState(null);
  const [checkListItems, setCheckListItems] = useState(null);
  const [prestineItem, setPrestineItem] = useState(null);

  //Hauler Firm related
  const [haulerRigTypes, setHaulerRigTypes] = useState(null);
  const [prestineRigType, setPrestineRigType] = useState(null);
  const [rigTypeList, setRigTypeList] = useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [isReordering, setIsReordering] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const routeOrgId = props?.computedMatch?.params?.companyId
    ? parseInt(props.computedMatch.params.companyId, 10)
    : props?.orgId
    ? props.orgId
    : userCtx?.currentUser?.currentOrgId
    ? userCtx?.currentUser?.currentOrgId
    : 0;

  const getCheckListItemTypes = () =>
    api.fetch("public/CheckListItemTypeList").then((r) => ({
      checkListItemTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getJobTypes = () =>
    api.fetch(`public/JobTypeList`).then((r) => ({
      jobTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getDocumentTypes = () =>
    api.fetch(`public/DocumentTypeList`).then((r) => ({
      documentTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getFeeTypes = () =>
    api.fetch("public/FeeTypeList").then((r) => ({
      feeTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getRigTypes = () =>
    api.fetch("public/RigTypeList").then((r) => ({
      rigTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getOrgTypes = () =>
    api.fetch("public/OrgTypeList").then((r) => ({
      orgTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getUnitTypes = () =>
    api.fetch("public/UnitTypeList").then((r) => ({
      unitTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getStateList = () =>
    api.fetch("public/StateList").then((r) => ({
      stateList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));
  const getLocationTypes = () =>
    api.fetch("public/LocationTypeList").then((r) => ({
      locationTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(getCheckListItemTypes());
    apiCalls.push(getJobTypes());
    apiCalls.push(getDocumentTypes());
    apiCalls.push(getFeeTypes());
    apiCalls.push(getRigTypes());
    apiCalls.push(getOrgTypes());
    apiCalls.push(getUnitTypes());
    apiCalls.push(getStateList());
    apiCalls.push(getLocationTypes());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        setJobTypeList(aggResults.jobTypeList);
        setStateList(aggResults.states);
        setUnitTypeList(aggResults.unitTypes);
        setDocumentTypeList(aggResults.documentTypeList);
        setFeeTypeList(aggResults.feeTypeList);
        setRigTypeList(aggResults.rigTypeList);
        setCompanyTypeList(aggResults.orgTypeList);
        setUnitTypeList(aggResults.unitTypeList);
        setStateList(aggResults.stateList);
        setLocationTypeList(aggResults.locationTypeList);
        setCheckListItemTypeList(aggResults.checkListItemTypeList);
      })
      .catch((error) =>
        Alert.error("There was an error loading reference data")
      );
  }, [userCtx.currentUser]);

  useEffect(refreshCompanyDetails, [rigTypeList, routeOrgId, stateList]);

  useEffect(refreshChecklistItems, [selectedJobType, routeOrgId]);

  function refreshChecklistItems() {
    if (!selectedJobType) {
      setCheckListItems(null);
      return;
    }

    api
      .fetch(
        `org/GetOrganizationJobTypeCheckListItems/${routeOrgId}/jobType/${selectedJobType.value}`
      )
      .then((response) => {
        setCheckListItems(
          _.map(response.data, (c) => {
            c.itemType = {
              value: c.checkListItemTypeId,
              label: c.checkListItemTypeName,
            };
            c.documentType = {
              value: c.documentTypeId,
              label: c.documentTypeName,
            };
            c.jobType = { value: c.jobTypeId, label: c.jobTypeName };
            c.editing = false;
            return c;
          })
        );
        setIsReordering(false);
      })
      .catch(() =>
        Alert.error("There was an error switching checklist job type")
      );
  }

  function refreshCompanyDetails() {
    api
      .fetch(`Org/GetOrgDetails/${routeOrgId}`)
      .then((response) => {
        if (response.data) {
          let organization = response.data.organization;
          organization.orgType = {
            label: organization.orgTypeName,
            value: organization.orgTypeId,
          };
          organization.subscriptionType = {
            label: organization.subscriptionTypeName,
            value: organization.subscriptionTypeId,
          };
          organization.primaryContact = {
            label: organization.primaryContactName,
            value: organization.primaryUserId,
          };
          organization.primaryLocation = response?.data?.locations?.length
            ? _.find(response.data.locations, (l) => {
                return l.typeOfLocation === constants.LOCATION_TYPES.Primary;
              })
            : null;
          setCurrentOrgType(organization.orgType);
          setOrganization(organization);
          setCompanyLocations(
            _.map(response.data.locations, (l) => {
              l.locationType = {
                label: l.locationTypeName,
                value: l.locationTypeId,
              };
              l.state = _.find(stateList, (s) => {
                return s.value === l.stateId;
              }); //{ label: l.stateName, value: l.stateId };
              l.editing = false;
              return l;
            })
          );
          let userList = _.chain(response.data.userList)
            .filter((u) => {
              return u.roleTypeIdList.includes(
                constants.ROLE_IDS.COMPANY_ADMIN
              );
            })
            .map((u) => ({ value: u.id, label: u.fullName }))
            .value();
          setOrganizationUsers(userList);
          setCompanyUnitTypes(
            _.map(response.data.orgUnitTypes, (t) => {
              t.value = t.unitTypeId;
              t.label = t.unitTypeName;
              return t;
            })
          );
          setHaulerRigTypes(
            _.map(response.data.orgRigTypes, (d) => {
              let rig = _.find(rigTypeList, (r) => {
                return r.value === d.rigTypeId;
              });
              d.type = { label: rig.label, value: d.rigTypeId };
              d.label = rig.label;
              d.value = d.rigTypeId;
              d.editing = false;
              return d;
            })
          );
          setDenyOrgAccessList(
            response.data.denyAccessList
              ? _.map(response.data.denyAccessList, (t) => {
                  t.value = t.partnerOrgId;
                  t.label = t.partnerOrgName;
                  t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                  return t;
                })
              : []
          );
          setAllowOrgAccessList(
            response.data.allowAccessList
              ? _.map(response.data.allowAccessList, (t) => {
                  t.value = t.partnerOrgId;
                  t.label = t.partnerOrgName;
                  t.createdAt = dateHelpers.toMDYDateString(t.createdAt);
                  return t;
                })
              : []
          );
          setDefaultFeeList(
            response.data.defaultFees
              ? _.map(response.data.defaultFees, (t) => {
                  t.jobType = helpers.resolveValue(
                    null,
                    t.typeOfJob,
                    t.typeOfJobName
                  );
                  t.feeType = helpers.resolveValue(
                    null,
                    t.feeTypeId,
                    t.feeTypeName
                  );
                  t.amount = t.amount / 100;
                  return t;
                })
              : []
          );
          setOrgDocumentList(
            response.data.documents
              ? _.map(response.data.documents, (t) => {
                  return t;
                })
              : []
          );
          setProfileImage(
            _.find(
              response.data.documents,
              (d) =>
                d.documentTypeId ===
                constants.DOCUMENT_TYPE_IDS.COMPANY_PROFILE_IMAGE
            )
          );

          if (
            organization.orgTypeId === constants.ORG_TYPE_IDS.RTO ||
            organization.orgTypeId === constants.ORG_TYPE_IDS.MANUFACTURER
          ) {
            api
              .fetch(`Org/GetOrganizationPaymentInformation/${routeOrgId}`)
              .then((x) => {
                if (x && x.data) {
                  setPaymentInfo(x.data);
                }
              });
          }
        }
      })
      .catch((error) => {
        Alert("There was an error retrieving company details");
      });
  }

  function saveCompanyProfile() {
    let warnings = [];
    if (!organization.name || _.trim(organization.name).length < 2) {
      warnings.push("Please provide a company name");
    }
    if (!organization.email || !helpers.emailIsValid(organization.email)) {
      warnings.push("Please provide a valid company email.");
    }
    if (!organization.phoneNumber) {
      warnings.push("Please provide a company phone number.");
    }
    if (!organization.ein) {
      warnings.push("Please provide a EIN number.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }

    let payload = {
      Organization: {
        Id: organization.id,
        Name: organization.name,
        Email: organization.email,
        PhoneNumber: organization.phoneNumber,
        AboutUs: organization.aboutUs,
        Tagline: organization.tagline,
        EIN: organization.ein,
        OrgTypeId: organization.orgType ? organization.orgType.value : null,
        SubscriptionTypeId: organization.subscriptionType
          ? organization.subscriptionType.value
          : null,
        PrimaryUserId: organization.primaryContact
          ? organization.primaryContact.value
          : null,
        PaymenyGatewayApiLoginId: organization.apiLoginId,
        PaymenyGatewayTransactionKey: organization.apiTransactionKey,
        PaymentProcessorId: organization.paymentProcessor
          ? organization.paymentProcessor.value
          : null,
      },
    };
    api
      .post(`useradmin/SaveOrgDetails`, payload)
      .then((response) => {
        if (response.data.success) {
          refreshCompanyDetails();
          Alert.success("Your profile has been updated.");
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }

  function savePaymentSettings() {
    let warnings = [];

    if (paymentInfo.allowPayments) {
      if (!paymentInfo.stripeCustomerId) {
        warnings.push("Please provide a Stripe Customer Identifier");
      }
      if (!paymentInfo.stripePublicKey) {
        warnings.push("Please provide a Stripe Public Key");
      }
      if (!paymentInfo.stripeSecretKey) {
        warnings.push("Please provide a Stripe Secret Key");
      }
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }

    let payload = {
      OrganizationId: routeOrgId,
      AllowPayments: paymentInfo.allowPayments,
      StripeCustomerId: paymentInfo.stripeCustomerId,
      StripeSecretKey: paymentInfo.stripeSecretKey,
      StripePublicKey: paymentInfo.stripePublicKey
    };
    api
      .post(`Org/SetOrganizationPaymentInformation`, payload)
      .then((response) => {
        if (response) {
          Alert.success("The payment settings have been saved.");
          return;
        } else {
          Alert.error("An error occurred while saving payment settings.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }

  function submitButton(className) {
    return (
      <Row>
        <Col>
          <Button
            className={className}
            size="sm"
            color="primary"
            onClick={() => saveCompanyProfile()}
          >
            Save Profile
          </Button>
        </Col>
      </Row>
    );
  }

  function onRigTypeSelect(index, selection, name) {
    let list = Object.assign([], haulerRigTypes);
    list[index][name] = selection;
    setHaulerRigTypes(list);
  }
  async function addToRigTypes() {
    let list = Object.assign([], haulerRigTypes);
    list.unshift(Object.assign({}, emptyRigType));
    await setHaulerRigTypes(list);
    //  await setNewRigType(Object.assign({}, emptyRigType));
  }
  function editRigType(index) {
    let list = Object.assign([], haulerRigTypes);
    let prestine = Object.assign({}, list[index]);
    list[index].editing = true;
    setPrestineRigType(prestine);
    setHaulerRigTypes(list);
  }
  function saveRigType(index) {
    let list = Object.assign([], haulerRigTypes);
    let rig = Object.assign({}, list[index]);
    setHaulerRigTypes(list);

    let warnings = [];
    if (!rig.qty || rig.qty < 0) {
      warnings.push("Please enter a valid quantity.");
    }
    if (!rig.type) {
      warnings.push("Please select a rig type.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }
    let payload = {
      Id: rig.id,
      Qty: rig.qty,
      RigTypeId: rig.type ? rig.type.value : null,
      OrgId: routeOrgId,
    };
    api
      .post(`org/SaveOrgRigType`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Rig types have been updated.");
          refreshCompanyDetails();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function onRigTypeInputChange(index, event) {
    let list = Object.assign([], haulerRigTypes);
    list[index][event.target.name] = event.target.value;
    setHaulerRigTypes(list);
  }
  function onCancelEdit(index) {
    let list = Object.assign([], haulerRigTypes);
    list[index] = Object.assign({}, prestineRigType);
    list[index].editing = false;
    setPrestineRigType(null);
    setHaulerRigTypes(list);
  }
  async function deleteFromRigTypes(index) {
    if (
      !window.confirm(
        "Are you sure you want to delete this rig type? Deletes are final."
      )
    )
      return;
    let list = Object.assign([], haulerRigTypes);
    let rig = list[index];

    api.delete(`org/DeleteHaulerFirmRig/${rig.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Rig has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Rig cannot be deleted at this time.");
      }
    });
  }

  function addUnitType(selection) {
    let list = Object.assign([], companyUnitTypes);
    let existingType = _.find(list, (l) => {
      return l.value === selection.value;
    });
    if (existingType !== undefined) return;
    let payload = {
      Id: 0,
      OrgId: routeOrgId,
      UnitTypeId: selection.value,
    };
    api.post(`org/SaveOrgUnitType`, payload).then((response) => {
      if (response?.data?.success) {
        Alert.success("Unit has been saved");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Unit cannot be deleted at this time.");
      }
    });
  }
  function deleteUnitType(index) {
    if (!window.confirm("Are you sure you want to delete this unit type?"))
      return;
    const list = Object.assign([], companyUnitTypes);
    let unit = list[index];

    api.delete(`org/DeleteOrgUnitType/${unit.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Unit has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Unit cannot be deleted at this time.");
      }
    });
  }

  function onLocationInputChange(e, index, name) {
    let list = companyLocations.slice();
    list[index][name] = e && e.target ? e.target.value : e;
    setCompanyLocations(list);
  }
  function addLocation() {
    let list = Object.assign([], companyLocations);
    list.unshift(constants.EMPTY_LOCATION);
    setCompanyLocations(list);
  }
  function editLocation(index) {
    const list = companyLocations.slice();
    setPrestineLocation(Object.assign({}, list[index]));
    list[index].editing = true;
    setCompanyLocations(list);
  }
  function cancelEditLocation(index) {
    let list = companyLocations.slice();
    let prestine = Object.assign({}, prestineLocation);
    if (list[index].id === -1) {
      list.splice(index, 1);
    } else {
      list[index] = prestine;
      list[index].editing = false;
    }
    setCompanyLocations(list);
    setPrestineLocation(null);
  }
  function saveLocation(index) {
    const list = companyLocations.slice();
    let location = Object.assign({}, list[index]);
    let warnings = [];

    if (!location.locationType) {
      warnings.push("Please provide a location type.");
    }
    if (!location.state) {
      warnings.push("Please provide a state.");
    }
    if (
      !location.streetAddress1 ||
      _.trim(!location.streetAddress1).length < 2
    ) {
      warnings.push("Please provide a address line 1.");
    }
    if (!location.zipCode || _.trim(!location.zipCode).length < 2) {
      warnings.push("Please provide a zip code.");
    }
    if (!location.city || _.trim(!location.city).length < 2) {
      warnings.push("Please provide a city.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }

    let payload = {
      Id: location.id,
      orgId: routeOrgId,
      StreetAddress1: location.streetAddress1,
      StreetAddress2: location.streetAddress2,
      City: location.city,
      StateId: location.state ? location.state.value : null,
      ZipCode: location.zipCode,
      LocationTypeId: location.locationType
        ? location.locationType.value
        : null,
      TypeOfLocation: location.locationType
        ? location.locationType.value
        : null,
    };
    api
      .post(`org/SaveLocation`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Locations have been updated.");
          refreshCompanyDetails();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function deleteLocation(index) {
    if (!window.confirm("Are you sure you want to delete this location?"))
      return;
    const list = Object.assign([], companyLocations);
    let location = list[index];

    api.post(`org/ToggleLocationActive/${location.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Location has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error(
          "An error occurred. Location cannot be deleted at this time."
        );
      }
    });
  }

  function addFee() {
    let list = Object.assign([], defaultFeeList);
    list.unshift(Object.assign({}, constants.EMPTY_DEFAULT_FEE));
    setDefaultFeeList(list);
  }
  function editFee(index) {
    let list = Object.assign([], defaultFeeList);
    setPrestineFee(Object.assign({}, list[index]));
    list[index].editing = true;
    setDefaultFeeList(list);
  }
  function cancelFeeEdit(index) {
    let list = Object.assign([], defaultFeeList);
    let prestine = Object.assign({}, prestineFee);
    if (list[index].id === -1) {
      list.splice(index, 1);
    } else {
      list[index] = prestine;
      list[index].editing = false;
    }
    setDefaultFeeList(list);
    setPrestineFee(null);
  }
  function onFeeInputChange(e, index, name) {
    let list = Object.assign([], defaultFeeList);
    list[index][name] = e && e.target ? e.target.value : e;
    setDefaultFeeList(list);
  }
  function saveFee(index) {
    let list = Object.assign([], defaultFeeList);
    let fee = Object.assign({}, list[index]);
    let warnings = [];
    if (!fee.feeType) {
      warnings.push("Please provide a fee type.");
    }

    if (!fee.amount || _.trim(!fee.amount).length < 2) {
      warnings.push("Please provide a valid fee amount.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }
    let payload = {
      Id: fee.id,
      OrgId: routeOrgId,
      Amount: fee.amount !== 0 ? Number(fee.amount * 100) : fee.amount,
      FeeTypeId: fee.feeType ? fee.feeType.value : null,
      JobTypeId: fee.jobType ? fee.jobType.value : null,
    };
    api
      .post(`org/SaveDefaultFee`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Fee has been saved.");
          refreshCompanyDetails();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function deleteFee(index) {
    if (!window.confirm("Are you sure you want to delete this fee type?"))
      return;
    let list = Object.assign([], defaultFeeList);
    let fee = list[index];

    api.post(`org/ToggleDefaultFeeActive/${fee.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Fee has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Fee cannot be deleted at this time.");
      }
    });
  }

  function deleteCkItem(index) {
    if (!window.confirm("Are you sure you want to delete this item?")) return;
    let list = Object.assign([], checkListItems);
    let item = list[index];

    api.post(`org/ToggleCheckListItemActive/${item.id}`).then((response) => {
      if (response?.data?.success) {
        Alert.success("Item has been deleted");
        refreshCompanyDetails();
      } else {
        Alert.error("An error occurred. Item cannot be deleted at this time.");
      }
    });
  }
  function saveCkItem(index) {
    let list = Object.assign([], checkListItems);
    let item = list[index];
    let warnings = [];
    if (!item.itemType) {
      warnings.push("Please provide a item type.");
    }
    if (!selectedJobType) {
      warnings.push("Please provide a job type.");
    }
    if (!item.name || _.trim(!item.name).length < 2) {
      warnings.push("Please provide a valid name.");
    }
    if (!item.prompt || _.trim(!item.prompt).length < 2) {
      warnings.push("Please provide a valid prompt.");
    }
    if (
      item.itemType &&
      item.itemType.label === DocumentItemTypeName &&
      !item.documentType
    ) {
      warnings.push("Please provide a document type.");
    }
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      return;
    }
    if(item.itemType && item.itemType.value === constants.CHECKLIST_ITEM_TYPE_IDS.SIGNATURE) {
      item.documentType = {
          value: constants.DOCUMENT_TYPE_IDS.SIGNATURE
      }
    }
    let payload = {
      Id: item.id,
      OrgId: routeOrgId,
      Name: item.name,
      Prompt: item.prompt,
      CheckListItemTypeId: item.itemType ? item.itemType.value : null,
      DocumentTypeId: item.documentType ? item.documentType.value : null,
      JobTypeId: selectedJobType.value,
      IsRequired: item.isRequired,
      DefaultBooleanValue: item.defaultBooleanValue,
      RegExValidation: item.regExValidation,
      Seq:
        item.id === 0
          ? checkListItems && checkListItems.length
            ? checkListItems.length + 1
            : 1
          : index + 1,
    };
    api
      .post(`org/SaveCheckListItem`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Item has been saved.");
          refreshChecklistItems();
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }
  function addCheckListItem() {
    let list = Object.assign([], checkListItems);
    list.unshift(Object.assign({}, constants.EMPTY_CHECK_LIST_ITEM));
    setCheckListItems(list);
  }
  function editCkItem(index) {
    let list = Object.assign([], checkListItems);
    setPrestineItem(Object.assign({}, list[index]));
    list[index].editing = true;
    setCheckListItems(list);
  }
  function cancelCKItemEdit(index) {
    let list = Object.assign([], checkListItems);
    let prestine = Object.assign({}, prestineItem);
    if (list[index].id === -1) {
      list.splice(index, 1);
    } else {
      list[index] = prestine;
      list[index].editing = false;
    }
    setCheckListItems(list);
    setPrestineItem(null);
  }
  function onCkItemInputChange(e, index, fieldName) {
    let list = Object.assign([], checkListItems);
    list[index][fieldName] = e && e.target ? e.target.value : e;
    setCheckListItems(list);
  }
  function onCkItemBoolChange(index, fieldName) {
    let list = Object.assign([], checkListItems);
    list[index][fieldName] = !list[index][fieldName];
    setCheckListItems(list);
  }
  function reorderChecklistItem(oldIndex, newIndex) {
    if (
      !checkListItems ||
      !checkListItems.length ||
      checkListItems.length < 2
    ) {
      return;
    }
    const item = checkListItems.slice(oldIndex, oldIndex + 1)[0];
    let newArray = checkListItems.filter((x, index) => index !== oldIndex);
    newArray.splice(newIndex, 0, item);
    setCheckListItems(newArray);
  }
  function saveNewOrder() {
    if (!checkListItems || !selectedJobType) {
      setIsReordering(false);
      return;
    }

    const ids = checkListItems.map((x) => x.id);
    api
      .post(
        `org/ReorderOrganizationJobTypeCheckListItems/${routeOrgId}/jobType/${selectedJobType.value}`,
        JSON.stringify(ids)
      )
      .then((response) => {
        setCheckListItems(
          _.map(response.data, (c) => {
            c.itemType = {
              value: c.checkListItemTypeId,
              label: c.checkListItemTypeName,
            };
            c.documentType = {
              value: c.documentTypeId,
              label: c.documentTypeName,
            };
            c.jobType = { value: c.jobTypeId, label: c.jobTypeName };
            c.editing = false;
            return c;
          })
        );
        setIsReordering(false);
      })
      .catch(() => {
        Alert.error("There was an error re-ordering checklist items");
        setIsReordering(false);
      });
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={""}>
                {organization && (
                  <Fragment>
                    <Row className="mb-2">
                      <Col>
                        {organization === null ? null : (
                          <CollapsibleCardSection
                            startOpen
                            cardName={helpers.cardHeaderName(
                              "Company Profile",
                              "address-card"
                            )}
                            cardBody={
                              <Fragment>
                                <Row>
                                  {
                                    <ProfileImageRender
                                      profileImage={profileImage}
                                      name={`${organization.name}`}
                                      isCompany
                                    />
                                  }
                                  <Col>
                                    <Row className="mb-2">
                                      <Col xs="6">
                                        {currentCompanyType?.label &&
                                          currentCompanyType.label !==
                                            HaulerFirmString && (
                                            <Row className="mb-2">
                                              <Col xs="12">
                                                Company Type{" "}
                                                {helpers.requiredStar()}
                                                <Select
                                                  closeMenuOnSelect={true}
                                                  isMulti={false}
                                                  components={makeAnimated()}
                                                  options={orgTypeList}
                                                  onChange={(e) =>
                                                    setOrganization({
                                                      ...organization,
                                                      orgType: e,
                                                    })
                                                  }
                                                  isDisabled={true}
                                                  value={
                                                    organization.orgType || null
                                                  }
                                                  onBlurResetsInput={false}
                                                  onSelectResetsInput={false}
                                                  onCloseResetsInput={false}
                                                  classNamePrefix="react-select"
                                                />
                                              </Col>
                                            </Row>
                                          )}
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Name {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  name: e.target.value,
                                                })
                                              }
                                              value={organization.name || ""}
                                              className="form-control"
                                              maxLength={50}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Email {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="email"
                                              id="email"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  email: e.target.value,
                                                })
                                              }
                                              value={organization.email || ""}
                                              className="form-control"
                                              maxLength={320}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Phone Number{" "}
                                            {helpers.requiredStar()}
                                            <Input
                                              type="text"
                                              name="phoneNumber"
                                              id="phoneNumber"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  phoneNumber: e.target.value,
                                                })
                                              }
                                              value={
                                                organization.phoneNumber || ""
                                              }
                                              className="form-control"
                                              maxLength={20}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            EIN Number
                                            <Input
                                              type="text"
                                              name="ein"
                                              id="ein"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  ein: e.target.value,
                                                })
                                              }
                                              value={organization.ein || ""}
                                              className="form-control"
                                              maxLength={64}
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-2">
                                          <Col xs="12">
                                            Primary Company Contact{" "}
                                            {helpers.requiredStar()}{" "}
                                            <span className="information-small">
                                              (Admins)
                                            </span>
                                            <Select
                                              closeMenuOnSelect={true}
                                              isMulti={false}
                                              components={makeAnimated()}
                                              options={organizationUsers}
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  primaryContact: e,
                                                })
                                              }
                                              value={
                                                organization.primaryContact ||
                                                null
                                              }
                                              onBlurResetsInput={false}
                                              onSelectResetsInput={false}
                                              onCloseResetsInput={false}
                                              classNamePrefix="react-select"
                                            />
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col xs="6">
                                        <Row className="mb-1 px-2">
                                          <Col>
                                            <div>
                                              About Us {helpers.requiredStar()}
                                            </div>
                                            <Input
                                              type="textarea"
                                              name="aboutUs"
                                              id="aboutUs"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  aboutUs: e.target.value,
                                                })
                                              }
                                              //onChange={(event) => setOrganization(event)}
                                              value={organization.aboutUs}
                                              className="form-control minHeight150"
                                              maxLength={500}
                                              placeholder="Description of your company"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="mb-1 px-2">
                                          <Col>
                                            <div>
                                              Tagline {helpers.requiredStar()}
                                            </div>
                                            <Input
                                              type="textarea"
                                              name="tagline"
                                              id="tagline"
                                              onChange={(e) =>
                                                setOrganization({
                                                  ...organization,
                                                  tagline: e.target.value,
                                                })
                                              }
                                              //onChange={(event) => setOrganization(event)}
                                              value={organization.tagline}
                                              className="form-control minHeight150"
                                              maxLength={255}
                                              placeholder="Company Tagline"
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    {submitButton("float-right")}
                                  </Col>
                                </Row>
                              </Fragment>
                            }
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="">
                      <Col>
                        {user?.includeHaulerFirmModule && (
                          <Row className="">
                            <Col>
                              {haulerRigTypes === null ? null : (
                                <CollapsibleCardSection
                                  startOpen
                                  cardName={helpers.cardHeaderName(
                                    "Rig Types",
                                    "truck-loading"
                                  )}
                                  cardBody={
                                    <Row className="mt-2">
                                      <Col>
                                        <div>
                                          <i>
                                            At least one is required
                                            {helpers.requiredStar()}
                                          </i>
                                        </div>
                                        <Row className="mb-2 align-items-end">
                                          <Col xs="12">
                                            <Table
                                              striped
                                              hover
                                              size="sm"
                                              responsive={false}
                                              id="rigTypesTable"
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Rig Type</th>
                                                  <th>Qty</th>
                                                  <th>
                                                    <Button
                                                      size="sm"
                                                      className="projectSuccess float-right"
                                                      onClick={() =>
                                                        addToRigTypes()
                                                      }
                                                      title="Add"
                                                    >
                                                      <FontAwesomeIcon icon="plus" />{" "}
                                                      Add
                                                    </Button>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {haulerRigTypes &&
                                                haulerRigTypes.length > 0
                                                  ? _.map(
                                                      haulerRigTypes,
                                                      (r, index) => (
                                                        <Fragment>
                                                          <tr key={index}>
                                                            {r.editing ? (
                                                              <Fragment>
                                                                <td width="50%">
                                                                  <Select
                                                                    closeMenuOnSelect={
                                                                      true
                                                                    }
                                                                    value={
                                                                      r.type ||
                                                                      null
                                                                    }
                                                                    name="type"
                                                                    options={
                                                                      rigTypeList
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      onRigTypeSelect(
                                                                        index,
                                                                        event,
                                                                        "type"
                                                                      )
                                                                    }
                                                                    isDisabled={
                                                                      r.id > 0
                                                                    }
                                                                  />
                                                                </td>
                                                                <td width="10%">
                                                                  <DebounceInput
                                                                    type="number"
                                                                    name="qty"
                                                                    id="qty"
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      onRigTypeInputChange(
                                                                        index,
                                                                        event
                                                                      )
                                                                    }
                                                                    value={
                                                                      r.qty
                                                                    }
                                                                    className="form-control"
                                                                    debounceTimeout={
                                                                      300
                                                                    }
                                                                    placeholder="Qty"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  <ButtonGroup className="float-right">
                                                                    <Button
                                                                      size="sm"
                                                                      className="secondary"
                                                                      onClick={() =>
                                                                        onCancelEdit(
                                                                          index
                                                                        )
                                                                      }
                                                                      title="Cancel"
                                                                    >
                                                                      <FontAwesomeIcon icon="reply" />
                                                                    </Button>
                                                                    <Button
                                                                      size="sm"
                                                                      className="primary"
                                                                      onClick={() =>
                                                                        saveRigType(
                                                                          index
                                                                        )
                                                                      }
                                                                      title="Add"
                                                                    >
                                                                      <FontAwesomeIcon icon="save" />
                                                                    </Button>
                                                                  </ButtonGroup>
                                                                </td>
                                                              </Fragment>
                                                            ) : (
                                                              <Fragment>
                                                                <td>
                                                                  <span>
                                                                    {r.type &&
                                                                      r.type
                                                                        .label}
                                                                  </span>
                                                                </td>
                                                                <td>
                                                                  <span>
                                                                    {r.qty}
                                                                  </span>
                                                                </td>
                                                                <td>
                                                                  <ButtonGroup className="float-right">
                                                                    <Button
                                                                      size="sm"
                                                                      className="projectPrimary"
                                                                      onClick={() =>
                                                                        editRigType(
                                                                          index
                                                                        )
                                                                      }
                                                                      title="Edit"
                                                                    >
                                                                      <FontAwesomeIcon icon="edit" />
                                                                    </Button>
                                                                    <Button
                                                                      size="sm"
                                                                      className="projectDanger"
                                                                      onClick={() =>
                                                                        deleteFromRigTypes(
                                                                          index
                                                                        )
                                                                      }
                                                                      title="Remove from quota"
                                                                    >
                                                                      <FontAwesomeIcon icon="times-circle" />
                                                                    </Button>
                                                                  </ButtonGroup>
                                                                </td>
                                                              </Fragment>
                                                            )}
                                                          </tr>
                                                        </Fragment>
                                                      )
                                                    )
                                                  : null}
                                              </tbody>
                                            </Table>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        )}
                        {user?.includeCompanyAdminModule && (
                          <Row className="mb-2">
                            <Col>
                              {companyUnitTypes === null ? null : (
                                <CollapsibleCardSection
                                  startOpen
                                  cardName={helpers.cardHeaderName(
                                    "Unit Types",
                                    "warehouse"
                                  )}
                                  cardBody={
                                    <Row>
                                      <Col>
                                        <Row>
                                          <Col xs="12">
                                            <Table>
                                              <thead>
                                                <tr>
                                                  <th>Type</th>
                                                  <th width="30%">
                                                    <Select
                                                      closeMenuOnSelect={true}
                                                      components={makeAnimated()}
                                                      options={unitTypeList}
                                                      onChange={(selection) =>
                                                        addUnitType(selection)
                                                      }
                                                      value={null}
                                                      classNamePrefix="react-select"
                                                      placeholder="Select to Add"
                                                    />
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {companyUnitTypes &&
                                                companyUnitTypes.length > 0
                                                  ? companyUnitTypes.map(
                                                      (type, index) => (
                                                        <tr
                                                          key={`unitType-${index}`}
                                                        >
                                                          <td>{type.label}</td>
                                                          <td>
                                                            <ButtonGroup className="float-right">
                                                              <Button
                                                                size="sm"
                                                                className="danger"
                                                                onClick={() =>
                                                                  deleteUnitType(
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon="times" />
                                                              </Button>
                                                            </ButtonGroup>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )
                                                  : null}
                                              </tbody>
                                            </Table>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        {allowOrgAccessList === null ? null : (
                          <OrgAccessModule
                            cardName={helpers.cardHeaderName(
                              "I do business with only these hauler firms",
                              "user-plus"
                            )}
                            orgList={allowOrgAccessList}
                            isAllowAccess={true}
                            refreshOrg={() => refreshCompanyDetails()}
                            orgId={routeOrgId}
                          />
                        )}
                      </Col>
                      <Col xs="6">
                        {denyOrgAccessList === null ? null : (
                          <OrgAccessModule
                            cardName={helpers.cardHeaderName(
                              "I do business with any hauler firms EXCEPT these",
                              "user-times"
                            )}
                            orgList={denyOrgAccessList}
                            isAllowAccess={false}
                            refreshOrg={() => refreshCompanyDetails()}
                            orgId={routeOrgId}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="6">
                        <CollapsibleCardSection
                          startOpen
                          cardClass={"mt-2"}
                          cardName={helpers.cardHeaderName(
                            "Locations",
                            "map-marked"
                          )}
                          cardBody={
                            <Fragment>
                              <Row className="mb-2 align-items-end">
                                <Col xs="12">
                                  <Row className="mb-1">
                                    <Col>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th width="50%">Location</th>
                                            <th>Type</th>
                                            <th>
                                              <Button
                                                size="sm"
                                                className="projectSuccess float-right"
                                                onClick={() => addLocation()}
                                                title="Add"
                                                disabled={
                                                  companyLocations &&
                                                  _.some(
                                                    companyLocations,
                                                    (s) => {
                                                      return s.editing;
                                                    }
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon icon="plus" />{" "}
                                                Add
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {companyLocations &&
                                          companyLocations.length > 0
                                            ? companyLocations.map(
                                                (l, index) => (
                                                  <tr key={`location-${index}`}>
                                                    {l.editing ? (
                                                      <Fragment>
                                                        <td colSpan="2">
                                                          <Row className="mb-1">
                                                            <Col>
                                                              Address Line 1
                                                              {helpers.requiredStar()}
                                                              <Input
                                                                type="text"
                                                                name="streetAddress1"
                                                                id="streetAddress1"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "streetAddress1"
                                                                  )
                                                                }
                                                                value={
                                                                  l.streetAddress1 ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                maxLength={150}
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row className="mb-1">
                                                            <Col>
                                                              Address Line 2
                                                              <Input
                                                                type="text"
                                                                name="streetAddress2"
                                                                id="streetAddress2"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "streetAddress2"
                                                                  )
                                                                }
                                                                value={
                                                                  l.streetAddress2 ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                maxLength={150}
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row className="mb-2">
                                                            <Col xs="5">
                                                              City
                                                              {helpers.requiredStar()}
                                                              <Input
                                                                type="text"
                                                                name="city"
                                                                id="city"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "city"
                                                                  )
                                                                }
                                                                value={
                                                                  l.city || ""
                                                                }
                                                                className="form-control"
                                                                maxLength={150}
                                                              />
                                                            </Col>
                                                            <Col xs="3">
                                                              State
                                                              {helpers.requiredStar()}
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                isMulti={false}
                                                                components={makeAnimated()}
                                                                options={
                                                                  stateList
                                                                }
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "state"
                                                                  )
                                                                }
                                                                value={
                                                                  l.state ||
                                                                  null
                                                                }
                                                                onBlurResetsInput={
                                                                  false
                                                                }
                                                                onSelectResetsInput={
                                                                  false
                                                                }
                                                                onCloseResetsInput={
                                                                  false
                                                                }
                                                                classNamePrefix="react-select"
                                                              />
                                                            </Col>
                                                            <Col xs="4">
                                                              Zip
                                                              {helpers.requiredStar()}
                                                              <Input
                                                                type="text"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "zipCode"
                                                                  )
                                                                }
                                                                value={
                                                                  l.zipCode ||
                                                                  ""
                                                                }
                                                                className="form-control"
                                                                maxLength={15}
                                                              />
                                                            </Col>
                                                          </Row>
                                                          <Row>
                                                            <Col xs="6">
                                                              Location Type
                                                              {helpers.requiredStar()}
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                isMulti={false}
                                                                components={makeAnimated()}
                                                                options={
                                                                  locationTypeList
                                                                }
                                                                onChange={(e) =>
                                                                  onLocationInputChange(
                                                                    e,
                                                                    index,
                                                                    "locationType"
                                                                  )
                                                                }
                                                                value={
                                                                  l.locationType ||
                                                                  null
                                                                }
                                                                onBlurResetsInput={
                                                                  false
                                                                }
                                                                onSelectResetsInput={
                                                                  false
                                                                }
                                                                onCloseResetsInput={
                                                                  false
                                                                }
                                                                classNamePrefix="react-select"
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </td>
                                                        <td>
                                                          <ButtonGroup className="float-right">
                                                            <Button
                                                              size="sm"
                                                              className="secondary"
                                                              onClick={() =>
                                                                cancelEditLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="reply" />
                                                            </Button>
                                                            <Button
                                                              size="sm"
                                                              className="primary"
                                                              onClick={() =>
                                                                saveLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="save" />
                                                            </Button>
                                                          </ButtonGroup>
                                                        </td>
                                                      </Fragment>
                                                    ) : (
                                                      <Fragment>
                                                        <td>
                                                          {l.streetAddress1 && (
                                                            <div>
                                                              {l.streetAddress1}
                                                            </div>
                                                          )}
                                                          {l.streetAddress2 && (
                                                            <div>
                                                              {l.streetAddress2}
                                                            </div>
                                                          )}
                                                          <div>
                                                            {l.city},{" "}
                                                            {l.state &&
                                                              l.state
                                                                .label}{" "}
                                                            {l.zipCode}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {l.locationType &&
                                                            l.locationType
                                                              .label}
                                                        </td>
                                                        <td>
                                                          <ButtonGroup className="float-right">
                                                            <Button
                                                              size="sm"
                                                              className="primary"
                                                              onClick={() =>
                                                                editLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="edit" />
                                                            </Button>
                                                            <Button
                                                              size="sm"
                                                              className="danger"
                                                              onClick={() =>
                                                                deleteLocation(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <FontAwesomeIcon icon="times" />
                                                            </Button>
                                                          </ButtonGroup>
                                                        </td>
                                                      </Fragment>
                                                    )}
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Fragment>
                          }
                        />
                      </Col>
                      {user?.includeCompanyAdminModule && (
                        <Col xs="6">
                          <CollapsibleCardSection
                            startOpen
                            cardClass={"mt-2"}
                            cardName={helpers.cardHeaderName(
                              "Default Fees",
                              "hand-holding-usd"
                            )}
                            cardBody={
                              <Fragment>
                                <Row className="mb-2 align-items-end">
                                  <Col xs="12">
                                    <Row className="mb-1">
                                      <Col>
                                        <Table>
                                          <thead>
                                            <tr>
                                              <th>Job Type</th>
                                              <th>Fee Type</th>
                                              <th>Amount</th>
                                              <th>
                                                <Button
                                                  size="sm"
                                                  className="projectSuccess float-right"
                                                  onClick={() => addFee()}
                                                  title="Add"
                                                  disabled={
                                                    defaultFeeList &&
                                                    _.some(
                                                      defaultFeeList,
                                                      (s) => {
                                                        return s.editing;
                                                      }
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon icon="plus" />{" "}
                                                  Add
                                                </Button>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {defaultFeeList &&
                                            defaultFeeList.length > 0
                                              ? defaultFeeList.map(
                                                  (f, index) => (
                                                    <tr
                                                      key={`defFees-${index}`}
                                                    >
                                                      {f.editing ? (
                                                        <Fragment>
                                                          <td>
                                                            <Row>
                                                              <Col>
                                                                Job Type
                                                                {helpers.requiredStar()}
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    true
                                                                  }
                                                                  isMulti={
                                                                    false
                                                                  }
                                                                  components={makeAnimated()}
                                                                  options={
                                                                    jobTypeList
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    onFeeInputChange(
                                                                      e,
                                                                      index,
                                                                      "jobType"
                                                                    )
                                                                  }
                                                                  value={
                                                                    f.jobType ||
                                                                    null
                                                                  }
                                                                  onBlurResetsInput={
                                                                    false
                                                                  }
                                                                  onSelectResetsInput={
                                                                    false
                                                                  }
                                                                  onCloseResetsInput={
                                                                    false
                                                                  }
                                                                  classNamePrefix="react-select"
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </td>
                                                          <td>
                                                            <Row>
                                                              <Col>
                                                                Fee Type
                                                                {helpers.requiredStar()}
                                                                <Select
                                                                  closeMenuOnSelect={
                                                                    true
                                                                  }
                                                                  isMulti={
                                                                    false
                                                                  }
                                                                  components={makeAnimated()}
                                                                  options={
                                                                    feeTypeList
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    onFeeInputChange(
                                                                      e,
                                                                      index,
                                                                      "feeType"
                                                                    )
                                                                  }
                                                                  value={
                                                                    f.feeType ||
                                                                    null
                                                                  }
                                                                  onBlurResetsInput={
                                                                    false
                                                                  }
                                                                  onSelectResetsInput={
                                                                    false
                                                                  }
                                                                  onCloseResetsInput={
                                                                    false
                                                                  }
                                                                  classNamePrefix="react-select"
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </td>
                                                          <td>
                                                            <Row className="mb-1">
                                                              <Col>
                                                                Amount
                                                                {helpers.requiredStar()}
                                                                <DebounceInput
                                                                  type="number"
                                                                  name="amount"
                                                                  id="amount"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    onFeeInputChange(
                                                                      e,
                                                                      index,
                                                                      "amount"
                                                                    )
                                                                  }
                                                                  value={
                                                                    f.amount ||
                                                                    ""
                                                                  }
                                                                  className="form-control"
                                                                  debounceTimeout={
                                                                    300
                                                                  }
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </td>
                                                          <td>
                                                            <ButtonGroup className="float-right">
                                                              <Button
                                                                size="sm"
                                                                className="secondary"
                                                                onClick={() =>
                                                                  cancelFeeEdit(
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon="reply" />
                                                              </Button>
                                                              <Button
                                                                size="sm"
                                                                className="primary"
                                                                onClick={() =>
                                                                  saveFee(index)
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon="save" />
                                                              </Button>
                                                            </ButtonGroup>
                                                          </td>
                                                        </Fragment>
                                                      ) : (
                                                        <Fragment>
                                                          <td>
                                                            {f.jobType &&
                                                              f.jobType.label}
                                                          </td>
                                                          <td>
                                                            {f.feeType &&
                                                              f.feeType.label}
                                                          </td>
                                                          <td>
                                                            {f.amountDisplay}
                                                          </td>
                                                          <td>
                                                            <ButtonGroup className="float-right">
                                                              <Button
                                                                size="sm"
                                                                className="primary"
                                                                onClick={() =>
                                                                  editFee(index)
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon="edit" />
                                                              </Button>
                                                              <Button
                                                                size="sm"
                                                                className="danger"
                                                                onClick={() =>
                                                                  deleteFee(
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon="times" />
                                                              </Button>
                                                            </ButtonGroup>
                                                          </td>
                                                        </Fragment>
                                                      )}
                                                    </tr>
                                                  )
                                                )
                                              : null}
                                          </tbody>
                                        </Table>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Fragment>
                            }
                          />
                        </Col>
                      )}
                    </Row>

                    <Row>
                      {user?.includeHaulerFirmModule ? null : (
                        <Fragment>
                          <Col xs="6">
                            <CollapsibleCardSection
                              startOpen
                              cardClass={"mt-2"}
                              cardName={helpers.cardHeaderName(
                                "Job Check List Items",
                                "tasks"
                              )}
                              cardBody={
                                <Fragment>
                                  <Row>
                                    <Col>
                                      <Select
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        components={makeAnimated()}
                                        options={jobTypeList}
                                        onChange={(e) => setSelectedJobType(e)}
                                        value={selectedJobType}
                                        onBlurResetsInput={false}
                                        onSelectResetsInput={false}
                                        onCloseResetsInput={false}
                                        classNamePrefix="react-select"
                                      />
                                    </Col>
                                    <Col>
                                      {checkListItems &&
                                        checkListItems.length > 1 &&
                                        !isReordering && (
                                          <Button
                                            onClick={() =>
                                              setIsReordering(true)
                                            }
                                          >
                                            Re-order
                                          </Button>
                                        )}
                                      {checkListItems &&
                                        checkListItems.length > 1 &&
                                        isReordering && (
                                          <Button
                                            onClick={() => saveNewOrder()}
                                          >
                                            Save New Order
                                          </Button>
                                        )}
                                    </Col>
                                  </Row>
                                  <Row className="mb-2 align-items-end">
                                    <Col xs="12">
                                      <Row className="mb-1">
                                        <Col>
                                          <Table>
                                            <thead>
                                              <tr>
                                                <th>Type</th>
                                                <th>Name</th>
                                                <th>Document Type</th>
                                                <th>
                                                  <Button
                                                    size="sm"
                                                    className="projectSuccess float-right"
                                                    onClick={() =>
                                                      addCheckListItem()
                                                    }
                                                    title="Add"
                                                    disabled={
                                                      checkListItems &&
                                                      _.some(
                                                        checkListItems,
                                                        (s) => {
                                                          return s.editing;
                                                        }
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon icon="plus" />{" "}
                                                    Add
                                                  </Button>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {checkListItems &&
                                              checkListItems.length > 0
                                                ? checkListItems.map(
                                                    (c, index) => (
                                                      <tr
                                                        key={`checkListItems-${index}`}
                                                      >
                                                        {c.editing ? (
                                                          <Fragment>
                                                            <td colSpan="3">
                                                              <Row className="mb-1">
                                                                <Col>
                                                                  Name
                                                                  {helpers.requiredStar()}
                                                                  <Input
                                                                    type="text"
                                                                    name="name"
                                                                    id="itemName"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      onCkItemInputChange(
                                                                        e,
                                                                        index,
                                                                        "name"
                                                                      )
                                                                    }
                                                                    value={
                                                                      c.name ||
                                                                      ""
                                                                    }
                                                                    className="form-control"
                                                                    maxLength={
                                                                      255
                                                                    }
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              <Row>
                                                                <Col>
                                                                  Item Type
                                                                  {helpers.requiredStar()}
                                                                  <Select
                                                                    closeMenuOnSelect={
                                                                      true
                                                                    }
                                                                    isMulti={
                                                                      false
                                                                    }
                                                                    components={makeAnimated()}
                                                                    options={
                                                                      checkListItemTypeList
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      onCkItemInputChange(
                                                                        e,
                                                                        index,
                                                                        "itemType"
                                                                      )
                                                                    }
                                                                    value={
                                                                      c.itemType ||
                                                                      null
                                                                    }
                                                                    onBlurResetsInput={
                                                                      false
                                                                    }
                                                                    onSelectResetsInput={
                                                                      false
                                                                    }
                                                                    onCloseResetsInput={
                                                                      false
                                                                    }
                                                                    classNamePrefix="react-select"
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              <Row>
                                                                <Col>
                                                                  Document Type
                                                                  <Select
                                                                    closeMenuOnSelect={
                                                                      true
                                                                    }
                                                                    isMulti={
                                                                      false
                                                                    }
                                                                    components={makeAnimated()}
                                                                    options={
                                                                      documentTypeList
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      onCkItemInputChange(
                                                                        e,
                                                                        index,
                                                                        "documentType"
                                                                      )
                                                                    }
                                                                    value={
                                                                      c.documentType ||
                                                                      null
                                                                    }
                                                                    onBlurResetsInput={
                                                                      false
                                                                    }
                                                                    onSelectResetsInput={
                                                                      false
                                                                    }
                                                                    onCloseResetsInput={
                                                                      false
                                                                    }
                                                                    isDisabled={
                                                                      c.itemType &&
                                                                      c.itemType
                                                                        .label !==
                                                                        DocumentItemTypeName
                                                                    }
                                                                    classNamePrefix="react-select"
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              <Row>
                                                                <Col>
                                                                  RegExValidation
                                                                  <Input
                                                                    type="text"
                                                                    name="regExValidation"
                                                                    id="regExValidation"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      onCkItemInputChange(
                                                                        e,
                                                                        index,
                                                                        "regExValidation"
                                                                      )
                                                                    }
                                                                    value={
                                                                      c.regExValidation ||
                                                                      ""
                                                                    }
                                                                    className="form-control"
                                                                    maxLength={
                                                                      100
                                                                    }
                                                                    title=""
                                                                    disabled={
                                                                      c.itemType &&
                                                                      c.itemType
                                                                        .label !==
                                                                        TextItemTypeName
                                                                    }
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              <Row className="mb-1">
                                                                <Col>
                                                                  Prompt
                                                                  {helpers.requiredStar()}
                                                                  <Input
                                                                    //type="text"
                                                                    name="prompt"
                                                                    id="prompt"
                                                                    type="textarea"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      onCkItemInputChange(
                                                                        e,
                                                                        index,
                                                                        "prompt"
                                                                      )
                                                                    }
                                                                    value={
                                                                      c.prompt ||
                                                                      ""
                                                                    }
                                                                    className="form-control"
                                                                    maxLength={
                                                                      255
                                                                    }
                                                                    placeholder="Text to be displayed on job completion"
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              <Row>
                                                                <Col>
                                                                  <div>
                                                                    Default
                                                                    Boolean
                                                                    Value
                                                                  </div>
                                                                  <Switch
                                                                    name="defaultBooleanValue"
                                                                    onChange={() =>
                                                                      onCkItemBoolChange(
                                                                        index,
                                                                        "defaultBooleanValue"
                                                                      )
                                                                    }
                                                                    checked={
                                                                      c.defaultBooleanValue
                                                                    }
                                                                    disabled={
                                                                      c.itemType &&
                                                                      c.itemType
                                                                        .label !==
                                                                        BooleanItemTypeName
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col>
                                                                  <div>
                                                                    Is Required?
                                                                    {helpers.requiredStar()}
                                                                  </div>
                                                                  <Switch
                                                                    name="isRequired"
                                                                    onChange={() =>
                                                                      onCkItemBoolChange(
                                                                        index,
                                                                        "isRequired"
                                                                      )
                                                                    }
                                                                    checked={
                                                                      c.isRequired
                                                                    }
                                                                  />
                                                                </Col>
                                                              </Row>
                                                            </td>
                                                            <td>
                                                              <ButtonGroup className="float-right">
                                                                <Button
                                                                  size="sm"
                                                                  className="secondary"
                                                                  onClick={() =>
                                                                    cancelCKItemEdit(
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon icon="reply" />
                                                                </Button>
                                                                <Button
                                                                  size="sm"
                                                                  className="primary"
                                                                  onClick={() =>
                                                                    saveCkItem(
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon icon="save" />
                                                                </Button>
                                                              </ButtonGroup>
                                                            </td>
                                                          </Fragment>
                                                        ) : (
                                                          <Fragment>
                                                            <td>
                                                              {isReordering &&
                                                                index > 0 && (
                                                                  <Button
                                                                    style={{
                                                                      marginRight:
                                                                        ".5rem",
                                                                    }}
                                                                    color="primary"
                                                                    outline
                                                                    size="sm"
                                                                    onClick={() =>
                                                                      reorderChecklistItem(
                                                                        index,
                                                                        index -
                                                                          1
                                                                      )
                                                                    }
                                                                  >
                                                                    ↑
                                                                  </Button>
                                                                )}
                                                              {isReordering &&
                                                                index !==
                                                                  checkListItems.length -
                                                                    1 && (
                                                                  <Button
                                                                    style={{
                                                                      marginRight:
                                                                        ".5rem",
                                                                    }}
                                                                    color="primary"
                                                                    outline
                                                                    size="sm"
                                                                    onClick={() =>
                                                                      reorderChecklistItem(
                                                                        index,
                                                                        index +
                                                                          1
                                                                      )
                                                                    }
                                                                  >
                                                                    ↓
                                                                  </Button>
                                                                )}
                                                              {c.itemType &&
                                                                c.itemType
                                                                  .label}
                                                              <div>
                                                                <span className="text-royal">
                                                                  Job Type:{" "}
                                                                </span>
                                                                {
                                                                  c.jobType
                                                                    .label
                                                                }
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {helpers.truncateStringOverLength(
                                                                c.name,
                                                                20,
                                                                true
                                                              )}
                                                            </td>
                                                            <td>
                                                              {c.documentType &&
                                                                c.documentType
                                                                  .label}
                                                            </td>
                                                            <td>
                                                              <ButtonGroup className="float-right">
                                                                <Button
                                                                  size="sm"
                                                                  className="primary"
                                                                  onClick={() =>
                                                                    editCkItem(
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon icon="edit" />
                                                                </Button>
                                                                <Button
                                                                  size="sm"
                                                                  className="danger"
                                                                  onClick={() =>
                                                                    deleteCkItem(
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon icon="times" />
                                                                </Button>
                                                              </ButtonGroup>
                                                            </td>
                                                          </Fragment>
                                                        )}
                                                      </tr>
                                                    )
                                                  )
                                                : null}
                                            </tbody>
                                          </Table>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Fragment>
                              }
                            />
                          </Col>
                        </Fragment>
                      )}

                      <Col xs="6" className="mt-2">
                        {orgDocumentList === null ? null : (
                          <DocumentUpload
                            cardName={helpers.cardHeaderName(
                              "Documents",
                              "upload"
                            )}
                            documentList={orgDocumentList}
                            uploadUrl={`org/SaveOrgDocument`}
                            deleteUrl={`org/DeleteOrgDocument`}
                            orgId={routeOrgId}
                            refresh={refreshCompanyDetails}
                          />
                        )}
                      </Col>
                    </Row>
                    {user.includeCompanyAdminModule && paymentInfo && (
                      <Row>
                        <Col xs="6">
                          <CollapsibleCardSection
                            startOpen
                            cardClass={"mt-2"}
                            cardName={helpers.cardHeaderName(
                              "Payment Settings",
                              "hand-holding-usd"
                            )}
                            cardBody={
                              <Row className="mb-2 align-items-end">
                                <Col xs="12">
                                  <Row className="mb-2">
                                    <Col xs="12">
                                      <p>Enable Payments (via Stripe)</p>
                                      <Switch
                                        onChange={() =>
                                          setPaymentInfo({
                                            ...paymentInfo,
                                            allowPayments:
                                              !paymentInfo.allowPayments,
                                          })
                                        }
                                        checked={paymentInfo.allowPayments}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs="12">
                                      <Label>Stripe Company Identifier</Label>
                                      <Input
                                        value={paymentInfo.stripeCustomerId}
                                        onChange={(e) =>
                                          setPaymentInfo({
                                            ...paymentInfo,
                                            stripeCustomerId: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs="12">
                                      <Label>Stripe Public Key</Label>
                                      <Input
                                        value={paymentInfo.stripePublicKey}
                                        onChange={(e) =>
                                          setPaymentInfo({
                                            ...paymentInfo,
                                            stripePublicKey: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs="12">
                                      <Label>Stripe Secret Key</Label>
                                      <Input
                                        value={paymentInfo.stripeSecretKey}
                                        onChange={(e) =>
                                          setPaymentInfo({
                                            ...paymentInfo,
                                            stripeSecretKey: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Button
                                      style={{float: "right"}}
                                        size="sm"
                                        color="primary"
                                        onClick={() => savePaymentSettings()}
                                      >
                                        Save Payment Settings
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
