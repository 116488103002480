import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Card,
  CardBody,
  Col,
  Button,
  Row,
  ButtonGroup,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert";
import {
  api,
  helpers,
  UserContext,
  filterHelpers,
  dateHelpers,
  constants
} from "../utils";
import _ from "lodash";
import {
  FilterableScreen,
  FilterSet,
  FilterSwitch,
  FilterSelect,
  Pager,
  RatingModule,
} from "../components";
import { DesktopContainer } from "../layouts";

export default function CompanyRatings(props) {
  const userCtx = useContext(UserContext);
  // const [orgList, setOrgList] = useState([]);
  const [haulerList, setHaulerList] = useState([]);
  const [manufacturerRTOList, setManufacturerRTOList] = useState([]);
  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
  ]);
  const [pageNumber, setPageNumber] = useState(1);

  const getOrgList = () =>
    api
      .post("org/CompanySelectList", {
        ActiveOnly: true,
      })
      .then((r) => ({
        // orgList: _.map(r.data, (d) => {
        //   return { ...d, label: d.name, value: d.id };
        // }),
        haulerList: _.chain(r.data)
          .filter((d) => {
            return d.orgTypeId === constants.ORG_TYPE_IDS.HAULER_FIRM
          })
          .map((d) => ({ ...d, label: d.name, value: d.id }))
          .value(),
          manufacturerRTOList: _.chain(r.data)
          .filter((d) => {
            return d.orgTypeId !== constants.ORG_TYPE_IDS.HAULER_FIRM
          })
          .map((d) => ({ ...d, label: d.name, value: d.id }))
          .value()
      }));

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(getOrgList());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        // setOrgList(aggResults.orgList);
        setHaulerList(aggResults.haulerList);
        setManufacturerRTOList(aggResults.manufacturerRTOList);
      })
      .catch((error) =>
        Alert.error("There was an error loading reference data")
      );
  }, [userCtx.currentUser]);

  useEffect(refreshData, [filters, pageNumber]);

  function refreshData() {
    let payload = {
      sortField: null,
      sortDirection: null,
      pageNumber: pageNumber,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Admin/GetManufacturerOrRTORatings", payload)
      .then((response) => {
        let plist = response.data;
        plist.list = _.map(plist.list, (r) => {
          return {
            performedForOrg: {
              label: r.organizationName,
              value: r.orgId,
            },
            performedByHauler: {      // not the greatest way to get this, but should work
              label: r.jobDriverOrgName,  
              value: r.jobDriverOrgId,
            },
            performedByUser: {
              label: r.createdByUserFullName,
              value: r.createdByUserId,
            },
            score: r.score,
            comment: r.comment,
            id: r.id,
            deactivatedAt: r.deactivatedAt,
            createdAt: r.createdAt
          };
        });
        setPaginatedList(plist);
      })
      .catch(helpers.catchHandler);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function onToggleCommentView(index) {
    let pList = Object.assign({}, paginatedList);
    pList.list[index].viewing = !pList.list[index].viewing;
    setPaginatedList(pList);
  }

  function onToggleActive(index, type) {
    let plist = Object.assign({}, paginatedList);
    let item = plist.list[index];
    if (
      !window.confirm(
        `Are you sure you want to ${type.toLowerCase()} this rating?`
      )
    )
      return;

    api
      .post(`Admin/ToggleRatingActive/${item.id}`)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success(`Rating has been ${type.toLowerCase()}d`);
          refreshData();
        } else {
          Alert.error(response.data.message || "An error occurred");
        }
      })
      .catch((error) => Alert.error("There was an error updating rating"));
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer>
                <FilterableScreen
                  filters={
                    <Fragment>
                      <Row>
                        <Col xs="10" className="px-0">
                          <FilterSet
                            filters={filters}
                            clearFilters={() => setFilters([])}
                          >
                            <Row>
                              <Col xs="3">
                                <FilterSelect
                                  filterName="PerformedForOrgId"
                                  displayName="Performed For Company"
                                  filteredDisplay="Performed For Company"
                                  options={helpers.addAnyOption(manufacturerRTOList)}
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "PerformedForOrgId"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  clearValue={() =>
                                    onFilterChange({
                                      filterName: "PerformedForOrgId",
                                      value: null,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                <FilterSelect
                                  filterName="PerformedByHaulerId"
                                  displayName="Performed By Hauler Firm"
                                  filteredDisplay="Performed By Hauler Firm"
                                  options={helpers.addAnyOption(haulerList)}
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "OrgId"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  clearValue={() =>
                                    onFilterChange({
                                      filterName: "PerformedByHaulerId",
                                      value: null,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                <FilterSwitch
                                  filterName="ActiveOnly"
                                  displayName="Active Only"
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "ActiveOnly"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                            </Row>
                          </FilterSet>
                        </Col>
                        <Col xs="2" className="pl-0 pr-5">
                          <ButtonGroup className="float-right">
                            <Button
                              onClick={refreshData}
                              title="Refresh"
                              color="dark"
                            >
                              <FontAwesomeIcon icon="sync" />
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  pager={
                    <div className="float-right">
                      <Pager
                        pageNumber={
                          paginatedList?.pageNumber
                            ? paginatedList.pageNumber
                            : 0
                        }
                        totalPages={
                          paginatedList?.totalPages
                            ? paginatedList.totalPages
                            : 0
                        }
                        callBack={(newPageNumber) =>
                          setPageNumber(newPageNumber)
                        }
                      />
                    </div>
                  }
                >
                  <Row className="p-0 m-0">
                    <Col xs="12">
                      <Table
                        striped
                        hover
                        size="sm"
                        responsive={false}
                        id="ratingTable"
                      >
                        <thead>
                          <tr>
                            <th>Performed For</th>
                            <th>Performed By</th>
                            <th>Score</th>
                            {/* <th>No Show?</th> */}
                            <th>Entered At</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedList.list && paginatedList.list.length ? (
                            _.map(paginatedList.list, (r, index) => (
                              <Fragment key={r.id}>
                                <tr className="p-2">
                                  <td>
                                    <div>
                                      Company:{" "}
                                      {r.performedForOrg.value && (
                                        <Link
                                          to={`/companyProfile/${r.performedForOrg.value}`}
                                        >
                                          {r.performedForOrg.label}
                                        </Link>
                                      )}
                                    </div>
                                    {/* <div>
                                      User:{" "}
                                      {r.performedForUser.value && (
                                        <Link
                                          to={`/user/${r.performedForUser.value}`}
                                        >
                                          {r.performedForUser.label}
                                        </Link>
                                      )}
                                    </div> */}
                                  </td>
                                  <td>
                                    <div>
                                      Hauling Firm:{" "}
                                      <Link
                                        to={`/companyProfile/${r.performedByHauler.value}`}
                                      >
                                        {r.performedByHauler.label}
                                      </Link>
                                    </div>
                                    <div>
                                      User:{" "}
                                      <Link
                                        to={`/user/${r.performedByUser.value}`}
                                      >
                                        {r.performedByUser.label}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <RatingModule
                                      score={r.score}
                                      showRatingOnly={true}
                                    />
                                  </td>
                                  {/* <td>
                                    {r.noShow && (
                                      <FontAwesomeIcon
                                        className={classnames({
                                          "text-danger": r.noShow,
                                        })}
                                        icon={"check-circle"}
                                      />
                                    )}
                                  </td> */}
                                  <td>
                                    {dateHelpers.dateTimeFormat(
                                      r.createdAt,
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <ButtonGroup className="float-right">
                                      {r.deactivatedAt ? (
                                        <Button
                                          size="sm"
                                          className="projectInfo float-right"
                                          onClick={() =>
                                            onToggleActive(index, "Revive")
                                          }
                                          title={`Revive rating`}
                                        >
                                          <FontAwesomeIcon icon="recycle" />
                                        </Button>
                                      ) : (
                                        <Fragment>
                                          <Button
                                            size="sm"
                                            className="projectPrimary float-right"
                                            onClick={() =>
                                              onToggleCommentView(index)
                                            }
                                            title={`View Comment`}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                r.viewing ? "eye-slash" : "eye"
                                              }
                                            />
                                          </Button>

                                          <Button
                                            size="sm"
                                            className="projectDanger float-right"
                                            onClick={() =>
                                              onToggleActive(index, "Delete")
                                            }
                                            title={`Delete rating`}
                                          >
                                            <FontAwesomeIcon icon="trash-alt" />
                                          </Button>
                                        </Fragment>
                                      )}
                                    </ButtonGroup>
                                  </td>
                                </tr>
                                {r.viewing && (
                                  <tr>
                                    <td colSpan="6">
                                      <Card>
                                        <CardBody>
                                          <div className="bold text-royal">
                                            Comment
                                          </div>
                                          <div>{r.comment}</div>
                                        </CardBody>
                                      </Card>
                                    </td>
                                  </tr>
                                )}
                              </Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </FilterableScreen>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
