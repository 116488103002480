import React from "react";
import { Row, Col, Container } from "reactstrap";
import { CompanyRegistration, HaulerRegistration } from ".";

export default function Register(props) {
  const type = props?.computedMatch?.params?.type;

  return (
    <Container>
      <Row className="mt-3 align-items-center">
        <Col sm="12">
          {type && type === "haulerFirm" && <HaulerRegistration />}
          {type && type === "company" && <CompanyRegistration />}
        </Col>
      </Row>
    </Container>
  );
}
