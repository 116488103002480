import React from "react";
import { Row, Col } from "reactstrap";

export default function Footer(props) {
  return (
    <div className={`footerDesktop footer text-white`}>
      <Row className="m-0 px-0 pt-3">
        <Col xs="1" />
        <Col xs="8" className="px-5"></Col>
        <Col xs="3" className="text-right">
          SHB &#169; 2021
        </Col>
      </Row>
    </div>
  );
}
