import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { DesktopContainer } from "../layouts";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import Alert from "react-s-alert";
import { api, dateHelpers, UserContext } from "../utils";
import { useInterval } from "../hooks/useInterval";

const ChatBubble = ({ message, myId }) => {
  const sentByMe = message.sentById === myId;
  return (
    <div
      title={dateHelpers.dateTimeFormat(message.createdAt)}
      className={`chatBubble ${
        sentByMe ? "chatBubbleSend" : "chatBubbleReceive"
      }`}
    >{`${message.sentByFullName} - ${message.message}`}</div>
  );
};

export default function Chats() {
  const [chats, setChats] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState([]);
  const [userAutoComplete, setUserAutoComplete] = useState("");
  const userContext = useContext(UserContext);
  const [messagePagingAvailable, setMessagePagingAvailable] = useState(false);

  const loadUsers = (inputValue, callback) => {
    if (!inputValue || !inputValue.length || inputValue.length < 3) {
      return;
    }

    api
      .fetch(`chat/UserTypeahead/${inputValue}`)
      .then((response) => {
        let list = _.map(response.data, (d) => {
          return { label: d.name, value: d.id };
        });
        callback(list);
      })
      .catch((_error) => {
        Alert.error("There was an error finding the person");
      });
  };

  const loadChatMessagesWithReset = () => {
    if (!selectedChat) {
      return;
    }

    api
      .fetch(`chat/GetChatMessages/${selectedChat.id}/1`)
      .then((response) => {
        if (response.data) {
          setMessagePagingAvailable(
            response.data.totalPages > response.data.pageNumber
          );

          setChatMessages(response.data.list);
        }
      })
      .catch((_error) => {
        Alert.error("There was an error loading chat messages");
      });
  };

  const loadChatMessages = (chatId) => {
    if (!chatId) {
      return;
    }

    api
      .fetch(`chat/GetChatMessages/${chatId}/1`)
      .then((response) => {
        if (response.data) {
          //add new messages to the front of the list
          const newMessages = _.differenceBy(
            response.data.list,
            chatMessages,
            "id"
          );
          const messages = newMessages.slice().concat(chatMessages);
          setChatMessages(_.orderBy(messages, ["id"], ["desc"]));
        }
      })
      .catch((_error) => {
        Alert.error("There was an error loading chat messages");
      });
  };

  const loadMoreMessages = () => {
    if (!selectedChat || !chatMessages || chatMessages.length === 0) {
      return;
    }

    const minId = _.minBy(chatMessages, "id").id;
    api
      .fetch(`chat/GetEarlierChatMessages/${selectedChat.id}/${minId}`)
      .then((response) => {
        const messages = chatMessages.slice().concat(response.data.list);
        setChatMessages(messages);
        setMessagePagingAvailable(
          response.data.totalPages > response.data.pageNumber
        );
      })
      .catch((_error) => {
        Alert.error("There was an error fetching more chat messages");
      });
  };

  const loadChats = () => {
    api
      .fetch(`chat/GetMostRecentChats`)
      .then((response) => {
        if (response.data) {
          setChats(response.data.list);
        }
      })
      .catch((_error) => {
        Alert.error("There was an error loading chats");
      });
  };

  const sendMessage = () => {
    if (!selectedUser && !selectedChat) {
      Alert("No chat or person is selected");
      return;
    }
    if (!message) {
      Alert("Message cannot be empty");
      return;
    }

    const isNew = !selectedChat;

    var url = isNew ? "chat/CreateChat" : "chat/SendMessage";

    var data = isNew
      ? {
          UserId: selectedUser.value,
          Message: message,
        }
      : {
          ChatId: selectedChat.id,
          Message: message,
        };

    api
      .post(url, data)
      .then((response) => {
        setMessage("");
        if (isNew) {
          setSelectedChat(response.data);
        } else {
          setChatMessages(
            _.orderBy(
              [].concat([response.data]).concat(chatMessages),
              ["id"],
              ["desc"]
            )
          );
        }
      })
      .catch((_error) => {
        Alert.error("There was an error sending message");
      });
  };

  const getChatName = (chat) => {
    if (!userContext || !chat) {
      return "";
    }
    return userContext.currentUser.id === chat.user1Id
      ? chat.user2FullName
      : chat.user1FullName;
  };

  const isChatNew = (chat) => {
    if (!userContext || !chat) {
      return false;
    }

    if (selectedChat && selectedChat.id === chat.id) {
      return false;
    }

    return userContext.currentUser.id === chat.user1Id
      ? !chat.user1Read
      : !chat.user2Read;
  };

  useEffect(() => {
    if (selectedUser) {
      api
        .fetch(`chat/GetChatWithUser/${selectedUser.value}`)
        .then((response) => {
          if (response.data) {
            setSelectedChat(response.data);
          } else {
            setChatMessages([]);
            setSelectedChat(null);
          }
        })
        .catch((_error) => {
          Alert.error("There was an error finding the chat");
          setChatMessages([]);
          setSelectedChat(null);
        });
    }
  }, [selectedUser]);

  useEffect(loadChatMessagesWithReset, [selectedChat]);

  useEffect(loadChats, []);

  useInterval(() => {
    if (selectedChat) {
      loadChatMessages(selectedChat.id);
    }
  }, 10000);

  useInterval(() => {
    loadChats();
  }, 60000);

  return (
    <DesktopContainer screenName={"Chats"}>
      <Row className="mt-2 mb-2">
        <Col xs="4">
          <AsyncSelect
            loadOptions={_.debounce(
              (inputValue, callBack) => loadUsers(inputValue, callBack),
              500
            )}
            placeholder={"Enter person to chat"}
            onChange={(e) => setSelectedUser(e)}
            value={selectedUser}
            inputValue={userAutoComplete}
            onInputChange={(e) => setUserAutoComplete(e)}
            isClearable={true}
          />
          {chats && chats.length > 0 && (
            <h5 style={{ marginTop: "2.5rem" }}>
              Click on a chat for messages:
            </h5>
          )}
          {chats &&
            chats.length > 0 &&
            chats.map((x) => (
              <Row key={x.id} onClick={() => setSelectedChat(x)}>
                <Col
                  className={`chat ${isChatNew(x) ? "chatNew" : ""}`}
                >{`${getChatName(x)} - ${dateHelpers.dateTimeFormat(
                  x.lastMessageAt
                )}`}</Col>
              </Row>
            ))}
        </Col>
        <Col xs="8">
          {selectedUser && !selectedChat && (
            <>
              <Row>
                <Col>
                  There is no chat with {selectedUser.label} yet. Enter a
                  message below to start one with them:
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Button onClick={() => sendMessage()} color="primary">
                    Start Chat
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {selectedChat && (
            <>
              <Row>
                <Col>
                  <Input
                    type="textarea"
                    placeholder="Enter chat message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    className="mt-2"
                    onClick={() => sendMessage()}
                    color="primary"
                  >
                    Send Message
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>Chat with {getChatName(selectedChat)}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="chatMessagesContainer">
                    {chatMessages &&
                      chatMessages.map((x) => (
                        <Row key={x.id}>
                          <Col>
                            <ChatBubble
                              message={x}
                              myId={userContext.currentUser.id}
                            />
                          </Col>
                        </Row>
                      ))}
                    {messagePagingAvailable && (
                      <Button
                        onClick={() => loadMoreMessages()}
                        style={{ margin: "0 auto" }}
                        color="link"
                      >
                        More
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </DesktopContainer>
  );
}
