import React, { useState, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import { api, helpers, constants } from "../utils";
import { CollapsibleCardSection, MemberMouseLookupModal } from "../components";
import { DesktopContainer } from "../layouts";
import DebounceInput from "react-debounce-input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import Alert from "react-s-alert";

const emptyRigType = {
  id: 0,
  type: null,
  qty: 1,
  editing: true,
};

const HaulerFirm = "Hauler Firm";

export default function HaulerRegistration(props) {
  const [org, setOrganization] = useState(
    Object.assign({}, constants.EMPTY_ORGANIZATION)
  );
  const [stateList, setStateList] = useState([]);
  const [rigTypeList, setRigTypeList] = useState([]);
  const [redirectToHome, setRedirectToHome] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [saving, setSaving] = useState(false);
  const [mmEmail, setMmEmail] = useState("");
  const [showMMModal, setShowMMModal] = useState(false);

  useEffect(() => {
    api
      .fetch("public/StateList")
      .then((response) => {
        if (response.data && response.data.length) {
          setStateList(
            _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
    api
      .fetch("public/RigTypeList")
      .then((response) => {
        if (response.data && response.data.length) {
          setRigTypeList(
            _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
    api
      .fetch("public/OrgTypeList")
      .then((response) => {
        if (response.data && response.data.length) {
          let companyType = _.find(response.data, (d) => {
            return d.name === HaulerFirm;
          });
          setCompanyType(companyType);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setOrganization(org);
  }, [org]);

  function registerButton(className) {
    return (
      <Row className="mt-2">
        {GetMMButton("float-left mb-2")}
        <Col>
          <ButtonGroup className={className}>
            <Link to="/">
              <Button size="sm" color="link">
                {"Cancel"}
              </Button>
            </Link>
            <Button
              size="sm"
              color="primary"
              //disabled={org && !org.cardToken}
              onClick={() => submitRegistration()}
              title={"Submit"}
              disabled={saving}
            >
              {saving && <FontAwesomeIcon icon="spinner" spin />}
              {"Register"}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  }

  function GetMMButton(className) {
    return (
        <Col>
          <ButtonGroup className={className}>
            <Button
              size="sm"
              color="primary"
              //disabled={org && !org.cardToken}
              onClick={() => setShowMMModal(!showMMModal)}
              //title={""}
              disabled={saving}
            >
              {/* {saving && <FontAwesomeIcon icon="spinner" spin />} */}
              {"Get MemberMouse Account Info"}
            </Button>
          </ButtonGroup>
        </Col>
    );
  }

  function formatLocations() {
    let list = [];
    let primaryLocation = {
      StreetAddress1: org.addressLine1,
      StreetAddress2: org.addressLine2,
      City: org.city,
      StateId: org.state ? org.state.value : null,
      ZipCode: org.zip,
      LocationTypeId: constants.LOCATION_TYPES.Primary,
    };

    list.push(primaryLocation);

    if (org.sameAccountingAddress) {
      let billToLocation = Object.assign({}, primaryLocation);
      billToLocation.LocationTypeId = constants.LOCATION_TYPES.BillTo;
      list.push(billToLocation);
    } else {
      list.push({
        StreetAddress1: org.accountingAddressLine1,
        StreetAddress2: org.accountingAddressLine2,
        City: org.accountingCity,
        StateId: org.accountingState ? org.accountingState.value : null,
        ZipCode: org.accountingZip,
        LocationTypeId: constants.LOCATION_TYPES.BillTo,
      });
    }
    return list;
  }

  function onRigTypeSelect(index, selection, name) {
    const orgCopy = Object.assign({}, org);
    orgCopy.rigTypes[index][name] = selection;
    setOrganization(orgCopy);
  }

  async function addToRigTypes() {
    let orgCopy = Object.assign({}, org);
    orgCopy.rigTypes.unshift(Object.assign({}, emptyRigType));
    await setOrganization(orgCopy);
  }

  function saveRigType(index) {
    let orgCopy = Object.assign({}, org);

    if (!orgCopy.rigTypes[index].type) {
      Alert.error("Please select a rig type");
      return;
    }
    if (!orgCopy.rigTypes[index].qty < 0) {
      Alert.error("Please enter a valid quantity.");
      return;
    }

    orgCopy.rigTypes[index].editing = false;
    setOrganization(orgCopy);
  }
  function editRigType(index, event, fieldName) {
    let orgCopy = Object.assign({}, org);
    orgCopy.rigTypes[index][fieldName] = event.target.value;
    setOrganization(orgCopy);
  }

  async function deleteFromRigTypes(index) {
    const orgCopy = Object.assign({}, org);
    orgCopy.rigTypes.splice(index, 1);
    await setOrganization(orgCopy);
  }

  function GetMMInfo(email) {
    api.post(`public/GetMMUser/${email}`)
    .then((response) => {
      if(response && response.success){
        let mmUser = response.message;
        org.primaryContactFirstName = mmUser.first_name || "";
        org.primaryContactMiddleName =  ""; // no last name field for membermouse
        org.primaryContactLastName = mmUser.last_name || "";
        org.primaryContactEmail = mmUser.email || "";
        org.primaryContactUsername = mmUser.username || "";
        org.primaryContactPhoneNumber = mmUser.phone || "";
        org.isPrimaryContact = true;
      }
    })
  }

  function submitRegistration() {
    setSaving(true);
    let warnings = [];
    if (!org.name || _.trim(org.name).length < 2) {
      warnings.push("Please provide a company name");
    }
    if (!org.email || !helpers.emailIsValid(org.email)) {
      warnings.push("Please provide a valid company email.");
    }
    if (!org.phoneNumber) {
      warnings.push("Please provide a company phone number.");
    }
    if (!org.addressLine1) {
      warnings.push("Please provide a company address line 1.");
    }
    if (!org.city) {
      warnings.push("Please provide a City.");
    }
    if (!org.state) {
      warnings.push("Please provide a State.");
    }
    if (!org.zip) {
      warnings.push("Please provide a Zip.");
    }
    if (!org.einNumber || org.einNumber.length < 9) {
      warnings.push("Please provide a valid EIN number.");
    }
    //if (!org.einNumber && !org.alternateEIN) {
    //  warnings.push('Please provide a EIN number or a valid SSN.')
    //}
    //if (!org.cardToken) {
    //  warnings.push('Please provide a valid payment method.')
    //}
    if (
      !org.primaryContactFirstName ||
      _.trim(org.primaryContactFirstName).length < 2
    ) {
      warnings.push("Please provide a valid first name for primary contact.");
    }
    if (
      !org.primaryContactLastName ||
      _.trim(org.primaryContactLastName).length < 2
    ) {
      warnings.push("Please provide a valid last name for primary contact.");
    }
    if (
      !org.primaryContactPhoneNumber ||
      _.trim(org.primaryContactPhoneNumber).length < 10
    ) {
      warnings.push("Please provide a valid phone number for primary contact.");
    }
    if (
      !org.primaryContactEmail ||
      !helpers.emailIsValid(org.primaryContactEmail)
    ) {
      warnings.push("Please provide a valid email for primary contact.");
    }
    if (!org.password || _.trim(org.password).length < 8) {
      warnings.push(
        "Please provide a valid password of at least 8 characters."
      );
    }
    if (!org.passwordAgain || _.trim(org.passwordAgain).length < 8) {
      warnings.push(
        "Please provide your valid confirmation password of at least 8 characters."
      );
    }
    if (org.password !== org.passwordAgain) {
      warnings.push("Your password and confirmation password do not match.");
    }
    //if (!org.subscriptionType) {
    //  warnings.push('Please select a subcription plan.')
    //}
    if (warnings.length) {
      Alert.error(warnings.join(" "));
      setSaving(false);
      return;
    }

    let user = {
      FirstName: org.primaryContactFirstName,
      MiddleName: org.primaryContactMiddleName,
      LastName: org.primaryContactLastName,
      Email: org.primaryContactEmail,
      Username:
        org.primaryContactUsername !== ""
          ? org.primaryContactUsername
          : org.primaryContactEmail,
      PhoneNumber: org.primaryContactPhoneNumber,
      Password: org.password,
      IsPrimaryContact: org.isPrimaryContact,
    };

    let haulerFirm = {
      Id: org.id,
      Name: org.name,
      Email: org.email,
      PhoneNumber: org.phoneNumber,
      AboutUs: org.aboutUs,
      EIN: org.einNumber,
      AlternateEIN: org.alternateEIN,
      //SubscriptionTypeId: _.find(subscriptionTypes, t => { return t.label === "Monthly"; }).value,
      OrgTypeId: companyType ? companyType.id : null,
    };
    let payload = {
      User: user,
      Company: haulerFirm,
      Locations: formatLocations(),
      RigTypes:
        org.rigTypes && org.rigTypes.length
          ? _.map(org.rigTypes, (t) => {
              return {
                RigTypeId: t.type.value,
                Name: t.type.label,
                Qty: parseInt(t.qty, 10),
              };
            })
          : [],
    };

    api
      .post(`Public/RegisterOrg`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success(
            "Your registration is now complete! You will now be redirected to the logon screen."
          );
          setTimeout(() => setRedirectToHome("/"), 3000);
        } else {
          Alert.error(response.data.message || "An error occurred.");
          setSaving(false);
        }
      })
      .catch(helpers.catchHandler);
  }

  if (redirectToHome !== null) {
    return <Redirect to={redirectToHome} />;
  }

  const screenName = (
    <span>
      <FontAwesomeIcon icon="building" /> Hauler Firm Registration{" "}
    </span>
  );
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={screenName}>
                {org && (
                  <Fragment>
                    {registerButton("float-right mb-2")}
                    {
                      <MemberMouseLookupModal
                        open={showMMModal}
                        title = "Get MemberMouse Information"
                        onClickCallback={GetMMInfo}
                        onClose={() => setShowMMModal(!showMMModal)}
                      />
                    }

                    {/* HAULER DETAILS */}
                    <Row className="mb-3">
                      <Col>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col>
                                <h5>Hauler Firm Profile</h5>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-1">
                              <Col>
                                Hauler Firm Name {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="name"
                                  id="name"
                                  maxLength={50}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      name: e.target.value,
                                    })
                                  }
                                  value={org.name || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-1">
                              <Col>
                                Email {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="email"
                                  id="email"
                                  maxLength={320}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      email: e.target.value,
                                    })
                                  }
                                  value={org.email || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-1">
                              <Col>
                                Address Line 1 {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      addressLine1: e.target.value,
                                    })
                                  }
                                  value={org.addressLine1 || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-1">
                              <Col>
                                Address Line 2
                                <DebounceInput
                                  type="text"
                                  name="addressLine2"
                                  id="addressLine2"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      addressLine2: e.target.value,
                                    })
                                  }
                                  value={org.addressLine2 || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col xs="5">
                                City {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="city"
                                  id="city"
                                  maxLength={150}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      city: e.target.value,
                                    })
                                  }
                                  value={org.city || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                              <Col xs="3">
                                State {helpers.requiredStar()}
                                <Select
                                  closeMenuOnSelect={true}
                                  isMulti={false}
                                  components={makeAnimated()}
                                  options={stateList}
                                  onChange={(e) =>
                                    setOrganization({ ...org, state: e })
                                  }
                                  value={org.state || null}
                                  onBlurResetsInput={false}
                                  onSelectResetsInput={false}
                                  onCloseResetsInput={false}
                                  classNamePrefix="react-select"
                                />
                              </Col>
                              <Col xs="4">
                                Zip {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="zip"
                                  id="zip"
                                  maxLength={15}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      zip: e.target.value,
                                    })
                                  }
                                  value={org.zip || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="12">
                                Phone Number {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="phoneNumber"
                                  id="phoneNumber"
                                  maxLength={20}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                  value={org.phoneNumber || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="12">
                                EIN Number {helpers.requiredStar()}
                                <DebounceInput
                                  type="text"
                                  name="einNumber"
                                  id="einNumber"
                                  maxLength={64}
                                  onChange={(e) =>
                                    setOrganization({
                                      ...org,
                                      einNumber: e.target.value,
                                    })
                                  }
                                  value={org.einNumber || ""}
                                  className="form-control"
                                  debounceTimeout={300}
                                />
                              </Col>
                            </Row>
                            {/*  <Row className="mb-3">*/}
                            {/*    <Col xs="12">*/}
                            {/*      SSN Number {helpers.requiredStar()}*/}
                            {/*      <DebounceInput*/}
                            {/*        type="password"*/}
                            {/*        name="alternateEIN"*/}
                            {/*        id="alternateEIN"*/}
                            {/*        maxLength={64}*/}
                            {/*        onChange={(e) => setOrganization({ ...org, alternateEIN: e.target.value })}*/}
                            {/*        value={org.alternateEIN || ""}*/}
                            {/*        className="form-control"*/}
                            {/*        debounceTimeout={300}*/}
                            {/*      />*/}
                            {/*    </Col>*/}
                            {/*  </Row>*/}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    {/* BILL TO ADDRESS */}
                    <Row className="mb-3">
                      <Col>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col>
                                <h5>Bill To Address</h5>
                              </Col>
                              <Col>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={org.sameAccountingAddress}
                                      onChange={() =>
                                        setOrganization({
                                          ...org,
                                          sameAccountingAddress:
                                            !org.sameAccountingAddress,
                                        })
                                      }
                                    />{" "}
                                    Same as company address
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardHeader>
                          {!org.sameAccountingAddress && (
                            <CardBody>
                              <Row className="mb-1">
                                <Col>
                                  Address Line 1 {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="accountingAddressLine1"
                                    id="accountingAddressLine1"
                                    maxLength={150}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        accountingAddressLine1: e.target.value,
                                      })
                                    }
                                    value={org.accountingAddressLine1 || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                    // placeholder="123 Main St"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-1">
                                <Col>
                                  Address Line 2
                                  <DebounceInput
                                    type="text"
                                    name="accountingAddressLine2"
                                    id="accountingAddressLine2"
                                    maxLength={150}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        accountingAddressLine2: e.target.value,
                                      })
                                    }
                                    value={org.accountingAddressLine2 || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                    // placeholder="Address Line 2 (optional)"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col xs="5">
                                  City {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="accountingCity"
                                    id="accountingCity"
                                    maxLength={150}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        accountingCity: e.target.value,
                                      })
                                    }
                                    value={org.accountingCity || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                    // placeholder="Tupelo"
                                  />
                                </Col>
                                <Col xs="3">
                                  State {helpers.requiredStar()}
                                  <Select
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    components={makeAnimated()}
                                    options={stateList}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        accountingState: e,
                                      })
                                    }
                                    value={org.accountingState || null}
                                    onBlurResetsInput={false}
                                    onSelectResetsInput={false}
                                    onCloseResetsInput={false}
                                    classNamePrefix="react-select"
                                  />
                                </Col>
                                <Col xs="4">
                                  Zip {helpers.requiredStar()}
                                  <DebounceInput
                                    type="text"
                                    name="accountingZip"
                                    id="accountingZip"
                                    maxLength={15}
                                    onChange={(e) =>
                                      setOrganization({
                                        ...org,
                                        accountingZip: e.target.value,
                                      })
                                    }
                                    value={org.accountingZip || ""}
                                    className="form-control"
                                    debounceTimeout={300}
                                    // placeholder="35888"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          )}
                        </Card>
                      </Col>
                    </Row>

                    {/* PRIMARY USER */}
                    <Row className={"mb-2"}>
                      <Col>
                        <CollapsibleCardSection
                          cardName={"Primary Contact Information"}
                          cardBody={
                            <Fragment>
                              <div className="mb-2  pl-2 pr-2">
                                <i>
                                  This will be your first user for your
                                  organization
                                </i>
                              </div>
                              <div className="p-2">
                                <Row className="mb-1">
                                  <Col xs="4">
                                    First Name {helpers.requiredStar()}
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactFirstName"
                                      id="primaryContactFirstName"
                                      maxLength={50}
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          primaryContactFirstName:
                                            e.target.value,
                                        })
                                      }
                                      value={org.primaryContactFirstName || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                  <Col xs="4">
                                    Middle Name
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactMiddleName"
                                      id="primaryContactMiddleName"
                                      maxLength={50}
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          primaryContactMiddleName:
                                            e.target.value,
                                        })
                                      }
                                      value={org.primaryContactMiddleName || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>

                                  <Col xs="4">
                                    Last Name {helpers.requiredStar()}
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactLastName"
                                      id="primaryContactLastName"
                                      maxLength={50}
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          primaryContactLastName:
                                            e.target.value,
                                        })
                                      }
                                      value={org.primaryContactLastName || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    Phone Number {helpers.requiredStar()}
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactPhoneNumber"
                                      id="primaryContactPhoneNumber"
                                      maxLength={20}
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          primaryContactPhoneNumber:
                                            e.target.value,
                                        })
                                      }
                                      value={
                                        org.primaryContactPhoneNumber || ""
                                      }
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    Username {helpers.requiredStar()}
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactUsername"
                                      id="primaryContactUsername"
                                      maxLength={255}
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          primaryContactUsername:
                                            e.target.value,
                                        })
                                      }
                                      value={org.primaryContactUsername || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    Email {helpers.requiredStar()}
                                    <DebounceInput
                                      type="text"
                                      name="primaryContactEmail"
                                      id="primaryContactEmail"
                                      maxLength={320}
                                      onChange={(e) => {
                                        const fieldValue = e.target.value;
                                        let newOrg = {
                                          ...org,
                                          primaryContactEmail: fieldValue,
                                        };
                                        if (fieldValue) {
                                          const minLen = _.min([
                                            fieldValue.length,
                                            org.primaryContactUsername
                                              ? org.primaryContactUsername
                                                  .length
                                              : 0,
                                          ]);
                                          if (
                                            minLen === 0 ||
                                            _.startsWith(
                                              org.primaryContactUsername,
                                              fieldValue.substring(0, minLen)
                                            )
                                          ) {
                                            newOrg.primaryContactUsername =
                                              fieldValue;
                                          }
                                        }
                                        setOrganization(newOrg);
                                      }}
                                      value={org.primaryContactEmail || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    Password {helpers.requiredStar()}
                                    <DebounceInput
                                      type="password"
                                      name="password"
                                      id="password"
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          password: e.target.value,
                                        })
                                      }
                                      value={org.password || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    Retype Password {helpers.requiredStar()}
                                    <DebounceInput
                                      type="password"
                                      name="passwordAgain"
                                      id="passwordAgain"
                                      onChange={(e) =>
                                        setOrganization({
                                          ...org,
                                          passwordAgain: e.target.value,
                                        })
                                      }
                                      value={org.passwordAgain || ""}
                                      className="form-control"
                                      debounceTimeout={300}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          }
                        />
                      </Col>
                    </Row>

                    {/* RIG TYPES */}
                    <Row className="mt-2">
                      <Col>
                        {/*RIG TYPES*/}
                        <CollapsibleCardSection
                          cardName={"Rig Types"}
                          cardBody={
                            <Row className="mt-2 p-2">
                              <Col>
                                <div>
                                  <i>
                                    At least one is required{" "}
                                    {helpers.requiredStar()}
                                  </i>
                                </div>
                                <Row className="mb-2 align-items-end">
                                  <Col xs="12">
                                    <Table
                                      striped
                                      hover
                                      size="sm"
                                      responsive={false}
                                      id="rigTypesTable"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Rig Type</th>
                                          <th>Qty</th>
                                          <th width="8%">
                                            <Button
                                              size="sm"
                                              className="projectSuccess"
                                              onClick={() => addToRigTypes()}
                                              title="Add"
                                            >
                                              <FontAwesomeIcon icon="plus" />{" "}
                                              Add
                                            </Button>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {org.rigTypes && org.rigTypes.length > 0
                                          ? _.map(org.rigTypes, (r, index) => (
                                              <Fragment>
                                                <tr>
                                                  {r.editing ? (
                                                    <Fragment>
                                                      <td width="50%">
                                                        <Select
                                                          closeMenuOnSelect={
                                                            true
                                                          }
                                                          value={r.type || null}
                                                          name="type"
                                                          options={rigTypeList}
                                                          onChange={(event) =>
                                                            onRigTypeSelect(
                                                              index,
                                                              event,
                                                              "type"
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td width="10%">
                                                        <DebounceInput
                                                          type="number"
                                                          name="qty"
                                                          id="qty"
                                                          onChange={(event) =>
                                                            editRigType(
                                                              index,
                                                              event,
                                                              "qty"
                                                            )
                                                          }
                                                          value={r.qty}
                                                          className="form-control"
                                                          debounceTimeout={300}
                                                          placeholder="Qty"
                                                        />
                                                      </td>
                                                      <td>
                                                        <ButtonGroup className="float-right">
                                                          <Button
                                                            size="sm"
                                                            className="primary"
                                                            onClick={() =>
                                                              saveRigType(index)
                                                            }
                                                            title="Add"
                                                          >
                                                            <FontAwesomeIcon icon="save" />
                                                          </Button>
                                                        </ButtonGroup>
                                                      </td>
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment>
                                                      <td>
                                                        <span>
                                                          {r.type &&
                                                            r.type.label}
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>{r.qty}</span>
                                                      </td>
                                                      <td>
                                                        <ButtonGroup className="float-right">
                                                          <Button
                                                            size="sm"
                                                            className="projectDanger"
                                                            onClick={() =>
                                                              deleteFromRigTypes(
                                                                index
                                                              )
                                                            }
                                                            title="Remove from quote"
                                                          >
                                                            <FontAwesomeIcon icon="times-circle" />
                                                          </Button>
                                                        </ButtonGroup>
                                                      </td>
                                                    </Fragment>
                                                  )}
                                                </tr>
                                              </Fragment>
                                            ))
                                          : null}
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          }
                        />
                      </Col>
                    </Row>
                    {/* STRIPE DETAILS */}
                    {/*<SubscriptionModule*/}
                    {/*  title={organizationType}*/}
                    {/*  organization={org}*/}
                    {/*  setOrganization={(e) => setOrganization({ ...org, cardToken: e })}*/}
                    {/*  haulerFirmId={0}*/}
                    {/*  registrationMode={true}*/}
                    {/*  setSubscription={(e) => setOrganization({ ...org, subscriptionType: e })}*/}
                    {/*/>*/}

                    {registerButton("float-right")}
                  </Fragment>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
