import React, { useState, useEffect, Fragment } from "react";
import Rating from "react-rating";
import DebounceInput from "react-debounce-input";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { api, helpers } from "../utils";
import Alert from "react-s-alert";
import { RiTruckLine, RiTruckFill } from "react-icons/ri";
import Switch from "react-switch";

const emptyRate = {
  id: -1,
  job: null,
  OrgId: null,
  score: null,
  comment: "",
  hide: false,
};
export default function RatingModule(props) {
  const [job, setJob] = useState(null);
  const [rating, setRating] = useState(null);
  useEffect(() => {
    if (!props.showRating) {
      setRating(Object.assign({}, emptyRate));
    }
    setJob(props.job);
  }, [props.job, props.showRating]);

  useEffect(() => {
    setJob(props.job);
  }, [props.job]);

  function onSave() {
    if (!job || (job && !job.id)) {
      Alert.warning("Job details is missing.");
      return;
    }
    if (!rating) {
      Alert.warning("Rating details is missing.");
      return;
    }

    if (!rating.score) {
      Alert.warning("Rating score is required.");
      return;
    }
    let payload = {
      Id: rating.id,
      JobId: job.id,
      OrgId: props.forCompany ? job.orgId : job.assignedToOrgId,
      Score: rating.score,
      UserId: job.driverId,
      Comment: rating.comment,
      Hide: rating.hide,  // field should be called noShow
    };

    if(props.forCompany) {
      api
      .post("General/SaveOrgRating", payload) // Presently used to save only company ratings, not hauler firm ratings
      .then((response) => {
        if (response.data && response.data.success) {
          Alert.success("Rating was successfully saved.");
          if (props.onSuccess) {
            props.onSuccess();
          }
        } else {
          Alert.error(
            "An error occurred, rating cannot be saved at this time."
          );
        }
      })
      .catch(helpers.catchHandler);
    } else {
      api
      .post("General/SaveUserRating", payload)  // Presently used to save hauler firm ratings
      .then((response) => {
        if (response.data && response.data.success) {
          Alert.success("Rating was successfully saved.");
          if (props.onSuccess) {
            props.onSuccess();
          }
        } else {
          Alert.error(
            "An error occurred, rating cannot be saved at this time."
          );
        }
      })
      .catch(helpers.catchHandler);
    }
  }

  return (
    <Fragment>
      {props.showRatingOnly ? (
        <div
          className={props.className}
          title={`Current Rating: ${props.score}`}
        >
          <Rating
            readonly
            initialRating={props.score}
            emptySymbol={
              <RiTruckLine className="ratingSizeSmall align-self-center  text-royal " />
            }
            fullSymbol={
              <RiTruckFill className="ratingSizeSmall align-self-center text-royal " />
            }
          />
        </div>
      ) : (
        rating &&
        job && (
          <Modal
            isOpen={props.showModal}
            toggle={props.toggle}
            className={props.className}
          >
            <ModalHeader>
              Rate{" "}
              {props.forCompany
                ? `${job.orgName} `
                : `Service Provided By ${job.assignedToOrgName} `}{" "}
            </ModalHeader>
            <ModalBody>
              <div>
                <div>
                  <span className="text-royal bold">
                    Job Details - {job.jobTypeName}
                  </span>
                </div>
                <Row className="mt-2">
                  <Col>
                    <span className="text-royal">From Location</span>
                    <span className="ml-2">
                      <div>
                        {job.fromStreetAddress1
                          ? `${job.fromStreetAddress1} `
                          : null}
                      </div>
                      <div>
                        {job.fromStreetAddress2
                          ? `${job.fromStreetAddress2} `
                          : null}
                      </div>
                      <div>
                        {job.fromCity ? `${job.fromCity}, ` : ""}{" "}
                        {job.fromStateName ? `${job.fromStateName} ` : ""}{" "}
                        {job.fromZip ? `${job.fromZip}` : null}
                      </div>
                    </span>
                  </Col>
                  <Col>
                    <span className="text-royal">To Location</span>
                    <span className="ml-2">
                      <div>{job.toAddress1 ? `${job.toAddress1} ` : null}</div>
                      <div>{job.toAddress2 ? `${job.toAddress2} ` : null}</div>
                      <div>
                        {job.toCity ? `${job.toCity}, ` : null}{" "}
                        {job.toStateName ? `${job.toStateName} ` : null}{" "}
                        {job.toZipCode ? `${job.toZipCode}` : null}
                      </div>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span className="text-royal bold">Score</span>
                    <div title={rating.score}>
                      <Rating
                        stop={5}
                        fractions={1}
                        initialRating={rating.score}
                        onClick={(e) => setRating({ ...rating, score: e })}
                        emptySymbol={
                          <RiTruckLine className="leftMenuButtons align-self-center  text-royal" />
                        }
                        fullSymbol={
                          <RiTruckFill className="leftMenuButtons align-self-center text-royal" />
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span className="text-royal bold">Comment</span>
                    <DebounceInput
                      element="textarea"
                      name="comment"
                      id="comment"
                      onChange={(event) =>
                        setRating({ ...rating, comment: event.target.value })
                      }
                      value={rating.comment || ""}
                      className="form-control"
                      debounceTimeout={300}
                    />
                  </Col>
                </Row>
                {!props.forCompany && (
                  <Row className="mt-2">
                    <Col>
                      <div>
                        <span className="text-royal bold">Was No Show?</span>
                      </div>
                      <Switch
                        onChange={(checked) =>
                          setRating({ ...rating, hide: checked })
                        }
                        checked={rating.hide || false}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup className="">
                <Button
                  size="sm"
                  className="projectSecondary"
                  onClick={() => props.toggle()}
                >
                  Cancel
                </Button>
                <Button size="sm" className="primary" onClick={() => onSave()}>
                  Submit
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        )
      )}
    </Fragment>
  );
}
