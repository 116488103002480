import React, { Fragment, useState, useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Alert from "react-s-alert";
import { Media } from "react-breakpoints";
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import {
  Col,
  Button,
  Row,
  Table,
  ButtonGroup,
  Alert as Alert2,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import {
  api,
  constants,
  UserContext,
  filterHelpers,
  helpers,
  dateHelpers,
} from "../utils";
import {
  DateFilter,
  FilterText,
  FilterableScreen,
  FilterSet,
  Pager,
  FilterMultiSelect,
  FilterSwitch,
  RatingModule,
  ScheduleModal,
  SHBSpinner,
} from "../components";
import { DesktopContainer } from "../layouts";

const getInitialJobStatus = (jobStatusId) => {
  if (!jobStatusId) return null;

  const selectedFilter = _.find(
    constants.JOB_STATUSES,
    (x) => x.value === parseInt(jobStatusId, 10)
  );

  if (!selectedFilter) return null;

  return {
    filterName: "jobStatusIds",
    filteredDisplay: `Job Status: ${selectedFilter.label}`,
    labels: [selectedFilter.label],
    value: [selectedFilter.value],
  };
};

const EMPTY_SCHEDULE_CRITERIA = {
    orgId: 0,      
    startsAfter: "",
    startsBefore: "",
    timezone: "",
    activeOnly: true,
  };

export default function ScheduleViewer(props) {
  const today = new Date();
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const isSysAdmin = currentUser.isSysAdmin;
  const isCompany = currentUser.isCompanyUser;
  const isHaulerUser = currentUser.isHaulerUser;
  const isDispatcher =
    currentUser.currentRoleId === constants.ROLE_IDS.DISPATCHER ||
    currentUser.isHaulerAdminUser;
  const isDriver = currentUser.currentRoleId === constants.ROLE_IDS.DRIVER;
  const isCompanyAdmin = currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN && isCompany;  // admin role can be hauler or company admins
  const isCompanyClerk = currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_CLERK;
  const [message, setMessage] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [redirectToJob, setRedirectToJob] = useState(null);
  const jobStatusId =
    props.computedMatch.params && props.computedMatch.params.jobStatusId
      ? props.computedMatch.params.jobStatusId
      : props.location &&
        props.location.state &&
        props.location.state.jobStatusId
      ? props.location.state.jobStatusId
      : null;

  const initialJobStatus = getInitialJobStatus(jobStatusId);

  const [currentJobs, setCurrentJobs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarView, setCalendarView] = useState(Views.MONTH);
  const [selectedJob, setSelectedJob] = useState();
  let initialScheduleCriteria =  {
    timezone: userCtx && userCtx.currentUser && userCtx.currentUser.timezone, 
    jobId: selectedJob && selectedJob.value,
    // startsAfter: selectedDate ? selectedDate : formatDateTimeForServer(moment().day(0).toDate()),    // set intial range start to the sunday of the current week
    // startsBefore: selectedDate ? selectedDate : formatDateTimeForServer(moment().day(6).toDate())       // set intial range end to the saturday of the current week
    startsAfter: selectedDate ? selectedDate : formatDateTimeForServer(moment().startOf("month").subtract(5, "days").format('YYYY-MM-DD hh:mm')),    // set intial range start to the sunday of the current week
    startsBefore: selectedDate ? selectedDate : formatDateTimeForServer(moment().endOf("month").add(5, "days").format('YYYY-MM-DD hh:mm'))       // set intial range end to the saturday of the current week
  };
  const [scheduleCriteria, setScheduleCriteria] = useState(Object.assign(EMPTY_SCHEDULE_CRITERIA, initialScheduleCriteria));
  const locales = {
    'en-US': enUS,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  
  useEffect(refreshData, 
    [scheduleCriteria, calendarView]);

  function refreshData() {
    setLoading(true);
    let apiCalls = [];
    apiCalls.push(getJobs());
    apiCalls.push(getPrivateJobs());
    Promise.all(apiCalls)
    .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        const jobList = aggResults.jobs 
            ? aggResults.jobs
            : [];
        const privateJobList = aggResults.privateJobs 
        ? aggResults.privateJobs
        : [];
        let jl = [];
        let pjl = [];
        if(jobList) {
          // jl = _.map(jobList, job => {
          //   return {
          //     title: job.name,
          //     allDay: false,
          //     start:  new Date(resolveJobStart(job)),
          //     end: new Date(resolveJobEnd(job)),
          //     jobStatusId: job.jobStatusId,
          //     driverHexColor: job.driverHexColor,
          //     isPrivateJob: false,
          //     id: job.id
          //   //   tooltip: `${job.fromStreetAddress1} - ${job.toAddress1}`
          //   }
          // });
          _.each(jobList, job => {
            jl.push({
              title: job.name,
              allDay: false,
              start:  new Date(job.estimatedPickupAt),
              end: new Date(job.estimatedPickupAt),
              jobStatusId: job.jobStatusId,
              driverHexColor: job.driverHexColor,
              isPrivateJob: false,
              id: job.id
            //   tooltip: `${job.fromStreetAddress1} - ${job.toAddress1}`
            });
            if(job.estimatedDropOffAt !== job.estimatedPickupAt) {
              jl.push({
                title: job.name,
                allDay: false,
                start:  new Date(job.estimatedDropOffAt),
                end: new Date(job.estimatedDropOffAt),
                jobStatusId: job.jobStatusId,
                driverHexColor: job.driverHexColor,
                isPrivateJob: false,
                id: job.id
              //   tooltip: `${job.fromStreetAddress1} - ${job.toAddress1}`
              });
            }
          });
        }
        if(privateJobList) {
          pjl = _.map(privateJobList, pJob => {
            return {
              title: pJob.name,
              allDay: false,
              start:  new Date(pJob.scheduledForDate),
              end: new Date(pJob.scheduledForDate),
              jobStatusId: pJob.jobStatusId,
              driverHexColor: pJob.driverHexColor,
              isPrivateJob: true,
              id: pJob.id
            //   tooltip: `${job.fromStreetAddress1} - ${job.toAddress1}`
            }
          });            
        }
        let joinedList = jl.concat(pjl);
        setCurrentJobs(joinedList);
    }).catch((error) =>
    Alert.error("There was an error loading reference data")
    )
    .finally( 
        ()=>{setLoading(false);} 
    );
  }

  function getJobs(){
    let payload = {
        SortField: null,
        SortDirection: null,
        PageNumber: 1,
        CurrentOrgId: currentUser.currentOrgId,
        DateRangeFrom: scheduleCriteria.startsAfter,
        DateRangeTo: scheduleCriteria.startsBefore,
        // RequestedDateFrom: scheduleCriteria.startsAfter,
        // RequestedDateTo:scheduleCriteria.startsBefore,
        // ScheduledDateFrom: scheduleCriteria.startsAfter,
        // ScheduledDateTo: scheduleCriteria.startsBefore,
        // CompletedDateFrom: scheduleCriteria.startsAfter,
        // CompletedDateTo: scheduleCriteria.startsBefore,
        //IsOpenJobSearch: isOpenJobSearch,
      };
      //_.each(filters, (filter) => (payload[filter.filterName] = filter.value));
      return api
        .post("Job/GetScheduleJobs", payload)
        .then((response) => {
            let jobObject ={};
            if(response && response.data && response.data.success) {
                jobObject = {
                    jobs: (response.data.list.slice())
                };
            }
            return jobObject;
        })
        .catch(helpers.catchHandler);
  }

  function getPrivateJobs(){
    let payload = {
        SortField: null,
        SortDirection: null,
        PageNumber: 1,
        CurrentOrgId: currentUser.currentOrgId,
        DateRangeFrom: scheduleCriteria.startsAfter,
        DateRangeTo: scheduleCriteria.startsBefore,
        // RequestedDateFrom: scheduleCriteria.startsAfter,
        // RequestedDateTo:scheduleCriteria.startsBefore,
        // ScheduledDateFrom: scheduleCriteria.startsAfter,
        // ScheduledDateTo: scheduleCriteria.startsBefore,
        // CompletedDateFrom: scheduleCriteria.startsAfter,
        // CompletedDateTo: scheduleCriteria.startsBefore,
        //IsOpenJobSearch: isOpenJobSearch,
      };
      //_.each(filters, (filter) => (payload[filter.filterName] = filter.value));
      return api
        .post("Job/GetPrivateScheduleJobs", payload)
        .then((response) => {
            let privateJobObject ={};
            if(response && response.data && response.data.success) {
                privateJobObject = {
                    privateJobs: (response.data.list.slice())
                };
            }
            return privateJobObject;
        })
        .catch(helpers.catchHandler);
  }

  function resolveJobStart(job) {
      switch (job.jobStatusId){
            case constants.JOB_STATUS_IDS.OPEN:
                return job.createdAt;
            case constants.JOB_STATUS_IDS.RESERVED:
                return job.reservedAt ? job.reservedAt : job.createdAt;
            case constants.JOB_STATUS_IDS.SCHEDULED:
                return job.scheduledAt 
                ? job.scheduledAt 
                : job.reservedAt
                    ? job.reservedAt
                    : job.createdAt;
            case constants.JOB_STATUS_IDS.COMPLETE:
                return job.scheduledAt 
                ? job.scheduledAt 
                : job.reservedAt 
                    ? job.reservedAt 
                    : job.createdAt;
      }
  }


  function resolveJobEnd(job) {
      switch (job.jobStatusId){
            case constants.JOB_STATUS_IDS.OPEN:
                return job.requestedByDate;
            case constants.JOB_STATUS_IDS.RESERVED:
                return job.reservedAt 
                  ? (job.requestedByDate > job.reservedAt ? job.requestedByDate : job.reservedAt)
                  : job.requestedByDate;
            case constants.JOB_STATUS_IDS.SCHEDULED:
                return job.requestedByDate > (job.scheduledAt ? job.scheduledAt : job.scheduledForDate)  
                ? job.requestedByDate 
                : (job.scheduledAt ? job.scheduledAt : job.scheduledForDate);
            case constants.JOB_STATUS_IDS.COMPLETE:
                return job.completedAt;
      }
  }

  function formatDateTimeForServer(dateTime) {
    if (moment.isMoment(dateTime)) return dateTime.format("YYYY-MM-DDTHH:mm:ss")
    return moment(dateTime).format("YYYY-MM-DDTHH:mm:ss");
  }

  function calendarRangesChanged(dateRange, type) {
    // month: gives dateRange gives object with start and end key/value pairs
    // week: gives array of 7 elements, start to end dates
    // day: gives array of one element which is the day
  
    let scheduleCriteriaEdit = Object.assign({}, scheduleCriteria);

    // type is undefined when using 'today', 'back', 'next' buttons
    if(!type) {
      type = calendarView;
      // setSelectedDate("");
    } else {
      setCalendarView(type);
    }

    switch(type) {
      case constants.CALENDAR_VIEW_TYPES.MONTH:
        scheduleCriteriaEdit.startsAfter = formatDateTimeForServer(dateRange.start);
        scheduleCriteriaEdit.startsBefore = formatDateTimeForServer(dateRange.end);
        break;
      case constants.CALENDAR_VIEW_TYPES.WEEK:
        scheduleCriteriaEdit.startsAfter = formatDateTimeForServer(dateRange[0]);
        scheduleCriteriaEdit.startsBefore = formatDateTimeForServer(dateRange[dateRange.length - 1]);
        break;
      case constants.CALENDAR_VIEW_TYPES.DAY:
        scheduleCriteriaEdit.startsAfter = formatDateTimeForServer(dateRange[0]);
        scheduleCriteriaEdit.startsBefore = formatDateTimeForServer(moment(dateRange[0]).add(24, "hours"));
        break;
    }
    
    setScheduleCriteria(scheduleCriteriaEdit);
  }

  const jobClicked = (job) => {
      let joblink = job.isPrivateJob
      ? `/privatejob/${job.id}`
      : `/job/${job.id}`; 
      setRedirectToJob(joblink)
  };

  const onNavigate = (newDate) => setSelectedDate(newDate);

  return redirectToJob ? (
    <Redirect to={redirectToJob} />
  ) : (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Schedule"}>
                  {message ? (
                    <Alert2 className={message.flavor}>{message.text}</Alert2>
                  ) : null}
                  <div style={{padding: "15px"}}>
                        <Calendar
                        showMultiDayTimes={true}
                        onView={setCalendarView}
                        onRangeChange={(dateRange, type) => calendarRangesChanged(dateRange, type)}
                        //step={60} // time increments in minutes
                        selectable
                        localizer={localizer}
                        events={currentJobs}
                        onNavigate={onNavigate}
                        defaultDate={selectedDate ? selectedDate : today}
                        view={calendarView}
                        date={selectedDate}
                        onSelectEvent={jobClicked}
                        startAccessor="start"
                        endAccessor="end"
                        popup={true}
                        views={{ month: true, week: true, day: true }}
                        eventPropGetter={(event, start, end, isSelected) => ({
                            event,
                            start,
                            end,
                            isSelected,
                            style: 
                                {backgroundColor: event.driverHexColor} // if driverHexColor does not exist, then react big calendar defaults to a blue
                        })}
                        style={{ height: 500,width: '95%' }}
                        />
                    </div>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
