import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*
  colSize: *optional* 1-12, default of 4
  routePath: navigate to this path upon click
  title: *optional* visible string
  count: *optional* number to display along with the title
*/
const LinkPanel = props => {
  return (
    <Col
      xs="6"
      sm={props.colSize || "4"}
      className="px-4"
    >
      <Link to={props.routePath} className="no-decoration">
        <Row className="expand-md link-panel mb-3">
          {props.iconKey && 
            <Col sm="3" className="icon-col pt-2">
              <FontAwesomeIcon
                icon={props.iconKey}
                className="linkIcon larger"
              />
            </Col>
          }
          <Col>
            <div className="title">{props.title}</div>
            {props.count !== undefined ? (
              <div className="count">{props.count}</div>
            ) : null}
          </Col>
        </Row>
      </Link>
    </Col>
  );
}

export default LinkPanel;
