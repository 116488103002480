import React, { useState, useEffect } from "react";
import Alert from "react-s-alert";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { api } from "../utils";

export default function OrgTypeahead(props) {
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {}, []);

  const loadOrgs = (inputValue, callback) => {
    if (!inputValue || !inputValue.length || inputValue.length < 3) {
      return;
    }
    let payload = {
      Name: inputValue,
      OrgTypeId: props.orgTypeId,
      CurrentOrgId: props.currentOrgId,
      ActiveOnly: props.activeOnly,
    };

    api
      .post(`Org/CompanySelectList`, payload)
      .then((response) => {
        let list = _.map(response.data, (d) => {
          return { ...d, label: d.name, value: d.id };
        });
        callback(list);
      })
      .catch((error) => {
        Alert.error("There was an error loading the company list");
      });
  };
  return (
    <div>
      <AsyncSelect
        loadOptions={loadOrgs}
        placeholder={"Type company name"}
        onChange={(e) => props.onChange(e)}
        value={props.value}
        inputValue={inputValue}
        onInputChange={(e) => setInputValue(e)}
        isDisabled={props.isDisabled}
        isClearable={props.isClearable}
      />
    </div>
  );
}
