import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Button, Row, Table, ButtonGroup } from "reactstrap";
import Alert from "react-s-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import _ from "lodash";
import { api, UserContext, helpers } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapsibleCardSection } from "../components";
import Dropzone from "react-dropzone";
import classnames from "classnames";

export default function DocumentUpload(props) {
  const userCtx = useContext(UserContext);
  const [documentTypeList, setDocumentTypeList] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [uploadProcessing, setUploadProcessing] = useState(null);
  const [documentList, setDocumentList] = useState(props.documentList);
  const [showUploadModule, setShowUploadModule] = useState(false);
  const excludeDocTypeIdList = props.excludeDocTypeIdList || null;

  useEffect(() => {
    let isMounted = true;

    api
      .fetch(`public/DocumentTypeList`)
      .then((response) => {
        if (response.data) {
          if (isMounted) {
            let list = _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            });
            if (excludeDocTypeIdList && excludeDocTypeIdList.length) {
              list = _.filter(list, (l) => {
                return !excludeDocTypeIdList.contains(l.value);
              });
            }
            setDocumentTypeList(list);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      isMounted = false;
    };
  }, [excludeDocTypeIdList]);

  useEffect(() => {
    let isMounted = true;

    if (props.documentList !== null && isMounted) {
      setDocumentList(props.documentList);
    }

    return () => {
      isMounted = false;
    };
  }, [props.documentList]);

  function deleteDocument(index) {
    if (!window.confirm("Are you sure you want to delete this document?"))
      return;
    let list = documentList.slice();
    let id = list[index].id;
    api
      .delete(`${props.deleteUrl}/${id}`)
      .then((response) => {
        if (response && response.data.success) {
          Alert.success("Document was deleted.");
          props.refresh();
        } else {
          Alert.error(response.data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert("There was an error retrieving document types");
        } else {
          console.error(error);
        }
      });
  }

  return (
    <CollapsibleCardSection
      startOpen={true}
      cardName={props.cardName}
      cardBody={
        <Fragment>
          <Row className="p-2">
            <Col className="">
              {showUploadModule && (
                <span>
                  Select <i>Document Type</i> before attaching file
                </span>
              )}
              <Button
                size="sm"
                onClick={() => setShowUploadModule(!showUploadModule)}
                className={classnames(
                  { projectSuccess: !showUploadModule },
                  "float-right"
                )}
              >
                {showUploadModule ? "Cancel" : "Upload"}
              </Button>
            </Col>
          </Row>

          {showUploadModule && (
            <Fragment>
              <Row className="p-2 mt-2">
                <Col xs="4">
                  Document Type:
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    components={makeAnimated()}
                    options={props.documentTypeList || documentTypeList}
                    onChange={(e) => setSelectedDocumentType(e)}
                    value={selectedDocumentType || null}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    onCloseResetsInput={false}
                    classNamePrefix="react-select"
                  />
                  {uploadProcessing && (
                    <FontAwesomeIcon
                      icon="spinner"
                      spin
                      className="float-right projectPrimary"
                    />
                  )}
                </Col>
              </Row>
              <Row className="p-2 mt-2">
                <Col>
                  <Dropzone
                    accept={props.mimeTypes || "image/*,application/pdf,.psd"}
                    onDrop={(acceptedFiles) => {
                      if (!acceptedFiles) {
                        Alert.warning("Document format is not accepted");
                        return;
                      }
                      setUploadProcessing(true);

                      const formData = new FormData();
                      formData.append("file", acceptedFiles[0]);
                      props.orgId && formData.append("orgId", props.orgId);
                      props.jobId && formData.append("jobId", props.jobId);
                      formData.append("id", userCtx.currentUser.id);
                      if (!selectedDocumentType) {
                        Alert.error("Document Type is required");
                        return;
                      }
                      formData.append(
                        "documentTypeId",
                        selectedDocumentType.value
                      );
                      api
                        .post_form_data(props.uploadUrl, formData)
                        .then((response) => {
                          if (response.data.success) {
                            Alert.success("Document was uploaded");
                            setSelectedDocumentType(null);
                            if (props.refresh) {
                              props.refresh();
                            }
                          } else {
                            Alert.error(
                              response.data.message ||
                                "An error occurred while processing upload."
                            );
                            setUploadProcessing(false);
                            // setUploadResults(null);
                          }
                        })
                        .catch((error) => {
                          Alert.error(
                            "There was an error processing uploading your file."
                          );
                          setUploadProcessing(false);
                        });
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "dashed 1px #000000",
                            padding: "3em",
                            borderRadius: "5px",
                          }}
                        >
                          <input {...getInputProps()} />
                          <Row className="mb-2" style={{ textAlign: "center" }}>
                            <Col>
                              <FontAwesomeIcon icon="upload" size="4x" />
                            </Col>
                          </Row>
                          <h5>
                            {`Drag file here to upload (or click here to choose file)`}
                          </h5>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </Fragment>
          )}
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>File</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documentList && documentList.length > 0 ? (
                    documentList.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span title={d.name || d.fileName}>
                              {helpers.truncateStringOverLength(
                                d.name || d.fileName,
                                20,
                                true
                              )}
                            </span>
                          </div>
                          <div className="dim-text">{d.mimeType}</div>
                        </td>
                        <td>{d.documentTypeName}</td>
                        <td>
                          <ButtonGroup className="float-right">
                            <Button
                              className="primary"
                              size="sm"
                              title="View Document"
                              onClick={() =>
                                window.open(
                                  d.signedURL || d.signedUrl,
                                  "_blank"
                                )
                              }
                            >
                              <FontAwesomeIcon icon="external-link-alt" />
                            </Button>
                            <Button
                              size="sm"
                              className="danger"
                              onClick={() => deleteDocument(index)}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No documents found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Fragment>
      }
    />
  );
}
