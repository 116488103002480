import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DebounceInput from "react-debounce-input";

export default function MemberMouseLookupModal(props) {
  const [email, setEmail] = useState("");
  function closeModal() {
    props.onClose && props.onClose();
  }

  return (
    <Modal isOpen={props.open} className="minWidth750 lightTheme">
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <Row className="mb-2">
          <Col xs="12">
            Email
            <DebounceInput
              type="text"
              name="email"
              id="email"
              maxLength={50}
              onChange={(e) =>
                setEmail(e.target.value)
              }
              value={email}
              className="form-control"
              debounceTimeout={300}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={
                () => props.onClickCallback(email)}
            >
              Get Info
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="float-right">
          <Button onClick={() => closeModal()} size="sm">
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}
