import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Badge,
  Table,
  Label,
  Alert,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import DebounceInput from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import _ from "lodash";
import Switch from "react-switch";
import { api, helpers, UserContext, constants } from "../utils";
import { DesktopContainer } from "../layouts";
import { SketchPicker, CirclePicker, BlockPicker, CompactPicker } from 'react-color'

const roleGroupStyles = {
  alignItems: "center",
  fontSize: 14,
  fontWeight: "bold",
  textDecoration: "underline",
  margin: "2px 0 0 0",
};

const formatRoleGroupLabel = (data) => (
  <div style={roleGroupStyles}>
    <span>{data.label}</span>
  </div>
);

const resolveRoleContextDescription = (ur) => {
  if (ur.typeOfOrg === constants.ORG_TYPE_IDS.HAULER_FIRM) {
    return (
      <span>
        {ur.orgName} <Badge color="dark">Hauling Firm</Badge>
      </span>
    );
  }
  if (
    ur.typeOfOrg === constants.ORG_TYPE_IDS.RTO ||
    ur.typeOfOrg === constants.ORG_TYPE_IDS.MANUFACTURER
  ) {
    return (
      <span>
        {ur.orgName} <Badge color="primary">Company</Badge>
      </span>
    );
  }
  return <span>-</span>;
};
const resolveOrgList = (list, currentUser) => {
  if (!currentUser.isSysAdmin) {
    list = _.filter(list, (l) => {
      return l.value === currentUser.orgId;
    });
  }
  return list;
};

const resolveAvailableRoles = (currentUser) => {
  let roleList = constants.ALL_ROLES.slice();
  if (!_.includes(currentUser.roleTypeIdList, constants.ROLE_IDS.SYSADMIN)) {
    roleList = _.reject(
      roleList,
      (r) => r.value === constants.ROLE_IDS.SYSADMIN
    );
    let haulerAdmin = _.find(currentUser.roles, (r) => {
      return (
        r.roleId === constants.ROLE_IDS.COMPANY_ADMIN &&
        r.typeOfOrg === constants.ORG_TYPE_IDS.HAULER_FIRM &&
        r.orgId === currentUser.orgId
      );
    });
    if (haulerAdmin !== undefined) {
      roleList = constants.HAULER_ROLES.slice();
    }

    let companyAdmin = _.find(currentUser.roles, (r) => {
      return (
        r.roleId === constants.ROLE_IDS.COMPANY_ADMIN &&
        r.typeOfOrg !== constants.ORG_TYPE_IDS.HAULER_FIRM &&
        r.orgId === currentUser.orgId
      );
    });
    if (companyAdmin !== undefined) {
      roleList = constants.COMPANY_ROLES.slice();
    }
  }
  return _.sortBy(roleList, "label");
};

const groupRoleOptions = (roleList) => {
  // groups with 1 option each
  let groupedRoles = _.chain(roleList.slice())
    .groupBy((r) => r.groupName)
    .toPairs()
    .map((kv) => {
      return {
        label: kv[0],
        options: kv[1],
      };
    })
    .value();
  let filteredGroupedRoles = [];
  // consolidate all options under a single group
  _.each(groupedRoles, (g) => {
    // if this group contains at least 1 role among those desired include it in the filtered output
    if (
      _.some(
        g.options,
        (role) => _.findIndex(roleList, (r2) => r2.value === role.value) >= 0
      )
    ) {
      // filter the contained roles to only those desired
      g.options = _.filter(
        g.options,
        (role) => _.findIndex(roleList, (r2) => r2.value === role.value) >= 0
      );
      filteredGroupedRoles.push(g);
    }
  });
  return filteredGroupedRoles;
};

const DEFAULT_DRIVER_COLOR = "#0C797D";

const defaultNewUser = {
  id: 0,
  isPrimaryContact: true,
  timezone: constants.DEFAULT_TIMEZONE,
    roles: [],
    driverHexColor: DEFAULT_DRIVER_COLOR,
};

export default function User(props) {
  const userCtx = useContext(UserContext);
  const routeUserId = props?.computedMatch?.params?.id
    ? parseInt(props.computedMatch.params.id, 10)
    : null;
  const [user, setUser] = useState(defaultNewUser);
  const [companies, setCompanies] = useState([]);
  const [haulerFirms, sethaulerFirms] = useState([]);
  const [haulerRoleLimits, setHaulerRoleLimits] = useState(null);
  const [timezoneList, setTimezoneList] = useState([]);
  const [message, setMessage] = useState(null);
  const [roleSelectionState, setRoleSelectionState] = useState(null);
  const [redirectTo, setRedirectTo] = useState("");
  const [files, setFiles] = useState([]);
  const isNewUser = !routeUserId;
  const currentUser = userCtx?.currentUser ? userCtx.currentUser : null;
  const { current: availableRoles } = useRef(
    currentUser ? resolveAvailableRoles(currentUser) : []
  );
  const { current: canMaintainCompanies } = useRef(
    currentUser
      ? currentUser.isSysAdmin ||
          _.some(currentUser.roles, (r) => {
            return (
              r.roleId === constants.ROLE_IDS.COMPANY_ADMIN &&
              (r.typeOfOrg === constants.ORG_TYPE_IDS.RTO ||
                r.typeOfOrg === constants.ORG_TYPE_IDS.MANUFACTURER)
            );
          })
      : false
  );
  const { current: canMaintainhaulerFirms } = useRef(
    currentUser
      ? currentUser.isSysAdmin ||
          _.some(currentUser.roles, (r) => {
            return (
              r.roleId === constants.ROLE_IDS.COMPANY_ADMIN &&
              r.typeOfOrg === constants.ORG_TYPE_IDS.HAULER_FIRM
            );
          })
      : false
  );

  const canMaintainRoles = currentUser.isAdminUser;
  const atLeast1CompanyRole =
    (user.orgTypeName === "RTO" || user.orgTypeName === "Manufacturer") &&
    _.some(user.roles, (r) =>
      _.some(constants.COMPANY_ROLES, (x) => x.value === r.typeOfUserRole)
    );
  const atLeast1haulerFirmRole =
    user.orgTypeName === "Hauler Firm" &&
    _.some(user.roles, (r) =>
      _.some(constants.HAULER_ROLES, (x) => x.value === r.typeOfUserRole)
    );
  const mouseUser = (theUser) => {
    if(theUser.id < 1) return;
    api
      .fetch(`Mouse/GetUser/${theUser.memberMouseId}`)
      .then((r) => console.log("response", r));
  }
  
  
  const loadData = () => {
    const getUser = () =>
      api
        .fetch(`UserAdmin/User/${routeUserId}`)
        .then((r) => ({ user: r.data }));
    
    let apiCalls = [];
    apiCalls.push(api.getTimezones());
    if (routeUserId) {
      apiCalls.push(getUser());
    }
    if(!routeUserId && currentUser.orgTypeName === constants.ORG_TYPE_NAMES.HAULER_FIRM) {
      apiCalls.push(api.getAvailableRoles(currentUser.orgId));
    }
    if (canMaintainCompanies) {
      apiCalls.push(api.getCompanies());
    }
    if (canMaintainhaulerFirms) {
      apiCalls.push(api.getHaulerFirms());
    }
    //apiCalls.push(api.getCurrentOrgAvailableRoles())
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        //setAvailableOrgRoles(aggResults.availableOrgRoles);
        const haulerFirms = aggResults.haulerFirms
          ? aggResults.haulerFirms
          : [];
        const availableHaulerRoles = aggResults.availableHaulerRoles
        ? aggResults.availableHaulerRoles
        : [];
        if (haulerFirms) {
          sethaulerFirms(haulerFirms);
        }
        if(availableHaulerRoles) {
          setHaulerRoleLimits(availableHaulerRoles);
        }
        const companies = aggResults.companies ? aggResults.companies : [];
        setCompanies(companies);
        setTimezoneList(aggResults.timezones ? aggResults.timezones : []);
        const existingUser = aggResults.user ? aggResults.user : null;
        if (existingUser) {
          existingUser.company = existingUser.companyid
            ? _.find(companies, (c) => c.id === existingUser.companyid)
            : null;
          existingUser.haulerFirm = existingUser.haulerFirmId
            ? _.find(haulerFirms, (c) => c.id === existingUser.haulerFirmId)
            : null;
          existingUser.cdlState = existingUser.cdlState
            ? _.find(constants.STATES, (s) => s.value === existingUser.cdlState)
            : null;
          if(existingUser && existingUser.memberMouseId){
            //mouseUser(existingUser);
          }
          setUser(existingUser);
        }
      })
      .catch((error) =>
        setMessage({
          flavor: "alert-danger",
          text: "There was an error loading user data",
        })
      );
  };

  useEffect(loadData, [
    canMaintainCompanies,
    canMaintainhaulerFirms,
    routeUserId,
  ]);
  
  function onChange(fieldName, fieldValue) {
    let newUser = Object.assign({}, user);
    newUser[fieldName] = fieldValue;
    if (fieldName === "email" && isNewUser) {
      newUser.username = fieldValue;
    }
    setUser(newUser);
  }

  function onRemoveRole(selectedRole) {
    let newUser = Object.assign({}, user);
    newUser.roles = _.reject(
      user.roles,
      (r) =>
        r.typeOfUserRole === selectedRole.typeOfUserRole &&
        r.haulerFirmId === selectedRole.haulerFirmId &&
        r.companyId === selectedRole.companyId
    );
    setUser(newUser);
  }

  function onAddRole() {
    if(!RoleIsAvailable()) return;
    if (!roleSelectionState?.newRole?.typeSelection) {
      setMessage({
        flavor: "projectWarning",
        text: "Select a role type before attempting to add the role.",
      });
      return;
    }
    if (
      roleSelectionState?.newRole?.typeSelection.value !==
        constants.ROLE_IDS.SYSADMIN &&
      roleSelectionState.newRole.company === null &&
      roleSelectionState.newRole.haulerFirm === null
    ) {
      setMessage({
        flavor: "projectWarning",
        text: `Select a context/applicability for this ${roleSelectionState.newRole.typeSelection.label} role before attempting to add it.`,
      });
      return;
    }
    const newCompanyId = roleSelectionState.newRole.company
      ? roleSelectionState.newRole.company.value
      : null;
    const newHaulerFirmId = roleSelectionState.newRole.haulerFirm
      ? roleSelectionState.newRole.haulerFirm.value
      : null;
    if (
      _.some(
        user.roles,
        (ur) =>
          ur.typeOfUserRole ===
            roleSelectionState.newRole.typeSelection.value &&
          ((newCompanyId !== null && ur.orgId === newCompanyId) ||
            (newHaulerFirmId !== null && ur.orgId === newHaulerFirmId))
      )
    ) {
      setMessage({
        flavor: "projectWarning",
        text: "This role has already been given.",
      });
      return;
    }
    if (
      roleSelectionState.newRole.typeSelection.value ===
        constants.ROLE_IDS.SYSADMIN &&
      _.some(
        user.roles,
        (ur) => ur.typeOfUserRole === constants.ROLE_IDS.SYSADMIN
      )
    ) {
      setMessage({
        flavor: "projectWarning",
        text: "The system administrator role has already been given.",
      });
      return;
    }
    let newUserRoles = user.roles.slice();
    let newUser = Object.assign({}, user);
    if (newCompanyId) {
      newUserRoles.push({
        typeOfUserRole: roleSelectionState.newRole.typeSelection.value,
        typeOfOrg: roleSelectionState.newRole.company.orgTypeId,
        orgId: newCompanyId,
        orgName: roleSelectionState.newRole.company.label,
      });
      if (!user.orgId) {
        newUser = { ...user, orgId: newCompanyId };
      }
    } else if (newHaulerFirmId) {
      newUserRoles.push({
        typeOfUserRole: roleSelectionState.newRole.typeSelection.value,
        typeOfOrg: roleSelectionState.newRole.haulerFirm.orgTypeId,
        orgId: newHaulerFirmId,
        orgName: roleSelectionState.newRole.haulerFirm.label,
      });
      if (!user.orgId) {
        newUser = { ...user, orgId: newHaulerFirmId };
      }
    } else if (
      roleSelectionState.newRole.typeSelection.value ===
      constants.ROLE_IDS.SYSADMIN
    ) {
      newUserRoles.push({
        typeOfUserRole: roleSelectionState.newRole.typeSelection.value,
        typeOfOrg: null,
        orgId: null,
        orgName: null,
      });
    }
    newUser.roles = newUserRoles;
    setUser(newUser);
    setRoleSelectionState({
      ...roleSelectionState,
      newRole: {
        typeSelection: roleSelectionState.newRole.typeSelection,
        company: null,
        haulerFirm: null,
      },
    });
  }

  function onRoleSelectChange(fieldName, selection) {
    let newRoleState = {
      typeSelection: roleSelectionState?.newRole?.typeSelection,
      company: null,
      haulerFirm: null,
    };
    let canSelectCompany = roleSelectionState?.canSelectCompany
      ? roleSelectionState.canSelectCompany
      : false;
    let canSelecthaulerFirm = roleSelectionState?.canSelecthaulerFirm
      ? roleSelectionState.canSelecthaulerFirm
      : false;
    if (fieldName === "typeSelection") {
      const isHaulerUser = user.orgTypeName === "Hauler Firm";
      const isCompanyUser =
        user.orgTypeName === "RTO" || user.orgTypeName === "Manufacturer";
      if (
        selection.value === constants.ROLE_IDS.COMPANY_USER ||
        selection.value === constants.ROLE_IDS.COMPANY_CLERK ||
        (isCompanyUser && selection.value === constants.ROLE_IDS.COMPANY_ADMIN)
      ) {
        canSelectCompany = true;
        canSelecthaulerFirm = false;
        if (companies && companies.length === 1) {
          newRoleState.company = companies[0];
        }
      } else if (
        selection.value === constants.ROLE_IDS.DRIVER ||
        selection.value === constants.ROLE_IDS.DISPATCHER ||
        (isHaulerUser && selection.value === constants.ROLE_IDS.COMPANY_ADMIN)
      ) {
        canSelectCompany = false;
        canSelecthaulerFirm = true;
        if (haulerFirms && haulerFirms.length === 1) {
          newRoleState.haulerFirm = haulerFirms[0];
        }
      } else if (selection.value === constants.ROLE_IDS.SYSADMIN) {
        canSelectCompany = false;
        canSelecthaulerFirm = false;
      }
    }
    newRoleState[fieldName] = selection;
    setRoleSelectionState({
      newRole: newRoleState,
      canSelectCompany: canSelectCompany,
      canSelecthaulerFirm: canSelecthaulerFirm,
    });
  }

  function validate() {
    let warnings = [];
    if (!user.firstName || _.trim(user.firstName).length < 2) {
      warnings.push("Please provide a valid first name.");
    }
    if (!user.lastName || _.trim(user.lastName).length < 2) {
      warnings.push("Please provide a valid last name.");
    }
    if (!user.username || _.trim(user.username).length < 2) {
      warnings.push("Please provide a username.");
    }
    if ((!user.password || user.password.length < 8) && user.id === 0) {
      warnings.push("Please provide a password of at least 8 characters.");
    }
    if (!user.email || !helpers.emailIsValid(user.email)) {
      warnings.push("Please provide a valid email.");
    }
    if (!user.roles || user.roles.length === 0) {
      warnings.push("Please select at least 1 role for the user.");
    } else if (
      _.some(user.roles, (r) => constants.ROLE_IDS.DRIVER === r.typeOfUserRole)
    ) {
      if (!user.cdlNumber || _.trim(user.cdlNumber).length < 2) {
        warnings.push("Please provide a CDL number.");
      }
      if (!user.cdlState) {
        warnings.push("Please provide a CDL state.");
      }
    }
    if (
      atLeast1haulerFirmRole &&
      !user.orgId &&
      !_.some(
        user.roles,
        (r) =>
          r.orgId !== null && r.typeOfOrg === constants.ORG_TYPE_IDS.HAULER_FIRM
      )
    ) {
      warnings.push("Select a primary hauling firm.");
    }
    if (
      atLeast1CompanyRole &&
      !user.orgId &&
      !_.some(
        user.roles,
        (r) =>
          r.orgId !== null &&
          (r.typeOfOrg === constants.ORG_TYPE_IDS.RTO ||
            r.typeOfOrg === constants.ORG_TYPE_IDS.MANUFACTURER)
      )
    ) {
      warnings.push("Select a primary company.");
    }
    return warnings;
  }

  function onSave() {
    window.scroll(0, 0);
    const warnings = validate();
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    let payload = Object.assign({}, user);
    if (!payload.id) {
      delete payload.id;
    }
    if (payload.cdlState) {
      payload.cdlState = payload.cdlState.value;
    }
    if (payload.haulerFirm) {
      payload.OrgId = payload.haulerFirm.value;
      delete payload.haulerFirm;
    }
    if (payload.company) {
      payload.OrgId = payload.company.value;
      delete payload.company;
    }
    payload.Roles = _.map(payload.roles, (r, index) => ({
      RoleId: r.typeOfUserRole,
      OrgId: r.orgId,
      IsDefaultContext:
        r.isDefaultContext || (payload?.roles?.length < 2 && index === 0),
    }));
    api
      .post("UserAdmin/SaveUser", payload)
      .then((response) => {
        if (response.data.success) {
          setUser({ ...user, id: response.data.message.id });
          if (files.length) {
            let p2 = new FormData();
            p2.append("id", response.data.message.id);
            p2.append("file", files[0]);
            api.post_form_data("UserAdmin/SaveDLImage", p2).then((r2) => {
              if (r2.data.success) {
                setRedirectTo("/users/admin");
              } else {
                setMessage({ text: r2.data.message, flavor: "alert-danger" });
              }
            });
          } else {
            setRedirectTo("/users/admin");
          }
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onFileDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length) {
      setMessage({
        text: "One or more files were rejected.",
        flavor: "alert-danger",
      });
      return;
    }
    let newFiles = files.slice();
    newFiles.push(acceptedFiles[0]);
    setFiles(newFiles);
  }

  function onDeleteCDL(documentId) {
    api
      .post("UserAdmin/DeleteDocument/" + documentId, {})
      .then((response) => {
        if (response.data.success) {
          setUser({
            ...user,
            documents: _.reject(user.documents, (doc) => doc.id === documentId),
          });
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  function adminHaulerFirmOptions (haulerFirmOptions) {
    return (
      _.filter(haulerFirmOptions, hf => {
        return _.some(currentUser.roles, role => {
                  return (role.orgId === hf.value)
                })
      })
    );
  }

  const isDriverUser = _.some(
    user.roles,
    (r) => constants.ROLE_IDS.DRIVER === r.typeOfUserRole
  );
  const newSelectedRole = roleSelectionState?.newRole?.typeSelection
    ? _.find(
        constants.roles,
        (r) => r.value === roleSelectionState.newRole.typeSelection.value
      )
    : null;
  let newSelectedRoleContextDescription = null;
  if (newSelectedRole && roleSelectionState?.newRole?.company) {
    newSelectedRoleContextDescription = `: for the ${roleSelectionState.newRole.company.label} Company`;
  } else if (newSelectedRole && roleSelectionState?.newRole?.haulerFirm) {
    newSelectedRoleContextDescription = `: for the ${roleSelectionState.newRole.haulerFirm.label} Hauling Firm`;
  }
  const fullName = user?.lastName
    ? `${user.firstName} ${user.lastName}`
    : "User";
  if (redirectTo) {
    return <Redirect to="/users/admin" />;
  }

  function RoleIsAvailable(){
    if(currentUser.orgTypeName === constants.ORG_TYPE_NAMES.HAULER_FIRM 
      && roleSelectionState?.newRole?.typeSelection) {
      if(roleSelectionState?.newRole?.typeSelection.value === constants.ROLE_IDS.COMPANY_ADMIN
        && haulerRoleLimits.availableAdmins <= 0) {
          setMessage({
            text: "Current subcription does not allow for more admin roles.",
            flavor: "alert-danger",
          });
          return false;
      }
      if(roleSelectionState?.newRole?.typeSelection.value === constants.ROLE_IDS.DISPATCHER
        && haulerRoleLimits.availableDispatchers <= 0) {
          setMessage({
            text: "Current subcription does not allow for more dispatcher roles.",
            flavor: "alert-danger",
          });
          return false;
      }
      if(roleSelectionState?.newRole?.typeSelection.value === constants.ROLE_IDS.DRIVER
        && haulerRoleLimits.availableDrivers <= 0) {
          setMessage({
            text: "Current subcription does not allow for more driver roles.",
            flavor: "alert-danger",
          });
          return false;
      }
      return true;
    }
    return true;
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={`User Details`}>
                {message ? (
                  <Alert className={message.flavor}>{message.text}</Alert>
                ) : null}
                <Row className="mb-3">
                  <Col xs="8">
                    <Card>
                      <CardBody>
                        <Row className="mb-1">
                          <Col xs="3">
                            First Name {helpers.requiredStar()}
                            <DebounceInput
                              type="text"
                              name="firstName"
                              id="firstName"
                              maxLength="30"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.firstName || ""}
                              className="form-control"
                              debounceTimeout={300}
                            />
                          </Col>
                          <Col xs="3">
                            Middle Name
                            <DebounceInput
                              type="text"
                              name="middleName"
                              id="middleName"
                              maxLength="30"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.middleName || ""}
                              className="form-control"
                              debounceTimeout={300}
                            />
                          </Col>
                          <Col xs="3">
                            Last Name {helpers.requiredStar()}
                            <DebounceInput
                              type="text"
                              name="lastName"
                              id="lastName"
                              maxLength="30"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.lastName || ""}
                              className="form-control"
                              debounceTimeout={300}
                            />
                          </Col>
                          <Col xs="3">
                            <div className="mb-1">Primary Contact</div>
                            <Switch
                              onChange={(e) => onChange("isPrimaryContact", e)}
                              checked={user.isPrimaryContact || false}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col xs="3">
                            Phone #
                            <DebounceInput
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              maxLength="20"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.phoneNumber || ""}
                              className="form-control"
                              debounceTimeout={300}
                            />
                          </Col>
                          <Col xs="3">
                            Timezone
                            <Select
                              options={timezoneList}
                              value={_.find(
                                timezoneList,
                                (x) => x.value === user.timezone
                              )}
                              onChange={(option) =>
                                onChange("timezone", option.value)
                              }
                            />
                          </Col>
                          {isNewUser ? (
                            <Col xs="3">
                              Password {helpers.requiredStar()}
                              <DebounceInput
                                type="password"
                                name="password"
                                id="password"
                                maxLength="30"
                                onChange={(event) =>
                                  onChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                value={user.password || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                          ) : null}
                          {atLeast1CompanyRole ? (
                            <Col xs="3">
                              Primary Company {helpers.requiredStar()}
                              <Select
                                name="company"
                                id="company"
                                isClearable={true}
                                closeMenuOnSelect={true}
                                value={_.find(
                                  companies,
                                  (c) => c.value === user.orgId
                                )}
                                options={companies}
                                onChange={(option) =>
                                  onChange(
                                    "orgId",
                                    option ? option.value : null
                                  )
                                }
                              />
                            </Col>
                          ) : null}
                          {atLeast1haulerFirmRole ? (
                            <Col xs="3">
                              Primary Hauling Firm {helpers.requiredStar()}
                              <Select
                                name="haulerFirm"
                                id="haulerFirm"
                                isClearable={true}
                                closeMenuOnSelect={true}
                                value={_.find(
                                  haulerFirms,
                                  (c) => c.value === user.orgId
                                )}
                                options={adminHaulerFirmOptions(haulerFirms)}
                                onChange={(option) =>
                                  onChange(
                                    "orgId",
                                    option ? option.value : null
                                  )
                                }
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="mb-1">
                          <Col xs="6">
                            Email {helpers.requiredStar()}
                            <DebounceInput
                              type="text"
                              name="email"
                              id="email"
                              maxLength="250"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.email || ""}
                              className="form-control"
                              debounceTimeout={300}
                              disabled={user.memberMouseId}
                            />
                          </Col>
                          <Col xs="6">
                            Username {helpers.requiredStar()}
                            <DebounceInput
                              type="text"
                              name="username"
                              id="username"
                              maxLength="250"
                              onChange={(event) =>
                                onChange(event.target.name, event.target.value)
                              }
                              value={user.username || ""}
                              className="form-control"
                              debounceTimeout={300}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {isDriverUser && (
                  <Row className="mb-3">
                    <Col xs="8">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h5>Driver Information</h5>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row className="mb-3">
                            <Col>
                              Driver display color
                              <div style={{display: "flex"}}>
                                <CompactPicker
                                   color={user.driverHexColor || DEFAULT_DRIVER_COLOR}
                                  onChangeComplete={(color) => onChange("driverHexColor", color.hex)}
                                />
                                <div className="ml-3" style={{width: "100px", height: "100px", backgroundColor: user.driverHexColor}}></div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              CDL Number {helpers.requiredStar()}
                              <DebounceInput
                                type="text"
                                name="cdlNumber"
                                id="cdlNumber"
                                onChange={(event) =>
                                  onChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                                value={user.cdlNumber || ""}
                                className="form-control"
                                debounceTimeout={300}
                              />
                            </Col>
                            <Col>
                              CDL State {helpers.requiredStar()}
                              <Select
                                name="cdlState"
                                id="cdlState"
                                closeMenuOnSelect={true}
                                value={user.cdlState}
                                options={constants.STATES}
                                onChange={(event) =>
                                  onChange("cdlState", event)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Card>
                                <CardBody>
                                  <CardTitle className="text-center">
                                    Drop files or click on the icon below
                                  </CardTitle>
                                  <Dropzone
                                    className="col-xs-4"
                                    onDrop={onFileDrop}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section>
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <p
                                            style={{ textAlign: "center" }}
                                            className={classnames({
                                              "text-success": files.length > 0,
                                            })}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                files.length
                                                  ? "check"
                                                  : "file-upload"
                                              }
                                              size="6x"
                                            />
                                          </p>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <h5 className="m-0 p-0">Documents</h5>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th width="20%" />
                                  </tr>
                                </thead>
                                <tbody>
                                  {user.documents
                                    ? user.documents.map((doc) => (
                                        <tr key={`doc-${doc.id}`}>
                                          <td>{doc.fileName}</td>
                                          <td>
                                            <ButtonGroup className="float-right">
                                              <a
                                                href={doc.signedURL}
                                                className="btn btn-sm projectInfo minWidth85"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View
                                              </a>
                                              <Button
                                                size="sm"
                                                className="projectDanger minWidth85"
                                                title="Deactivate"
                                                onClick={() =>
                                                  onDeleteCDL(doc.id)
                                                }
                                              >
                                                <FontAwesomeIcon icon="times-circle" />
                                              </Button>
                                            </ButtonGroup>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {canMaintainRoles && (
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h5>Adjust {fullName} Roles</h5>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row className="pt-3">
                            <Col>
                              <Label className="mb-0">Roles</Label>
                              <Select
                                options={groupRoleOptions(availableRoles)}
                                formatGroupLabel={formatRoleGroupLabel}
                                value={
                                  roleSelectionState?.newRole?.typeSelection
                                }
                                onChange={(option) =>
                                  onRoleSelectChange("typeSelection", option)
                                }
                              />
                              {roleSelectionState &&
                              roleSelectionState.newRole ? (
                                <div
                                  style={{
                                    margin: "4px",
                                    padding: "4px",
                                    backgroundColor: "#f2f5ff",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <b>
                                    {
                                      roleSelectionState.newRole.typeSelection
                                        .label
                                    }
                                  </b>
                                  {newSelectedRoleContextDescription}
                                </div>
                              ) : null}
                            </Col>
                            {roleSelectionState &&
                            canMaintainCompanies &&
                            roleSelectionState.canSelectCompany ? (
                              <Col>
                                <Label className="mb-0">Company</Label>
                                <Select
                                  options={resolveOrgList(
                                    companies,
                                    currentUser
                                  )}
                                  value={roleSelectionState?.newRole?.company}
                                  onChange={(option) =>
                                    onRoleSelectChange("company", option)
                                  }
                                />
                              </Col>
                            ) : null}
                            {roleSelectionState &&
                            canMaintainhaulerFirms &&
                            roleSelectionState.canSelecthaulerFirm ? (
                              <Col>
                                <Label className="mb-0">Hauling Firm</Label>
                                <Select
                                  closeMenuOnSelect={false}
                                  options={resolveOrgList(
                                    haulerFirms,
                                    currentUser
                                  )}
                                  value={
                                    roleSelectionState?.newRole?.haulerFirm
                                  }
                                  onChange={(option) =>
                                    onRoleSelectChange("haulerFirm", option)
                                  }
                                />
                              </Col>
                            ) : null}
                            <Col sm="1" className="pt-4">
                              <Button
                                className="projectSuccess float-right"
                                onClick={onAddRole}
                                title="Add this role"
                              >
                                <FontAwesomeIcon icon="plus" />
                              </Button>
                            </Col>
                          </Row>
                          <Row className="pt-3">
                            <Col>
                              <Table size="sm">
                                <thead>
                                  <tr>
                                    <th>Role Name</th>
                                    <th>Context</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.sortBy(
                                    user.roles,
                                    (r) =>
                                      constants.ROLE_DICTIONARY[
                                        r.typeOfUserRole
                                      ]
                                  ).map((r, index) => (
                                    <tr
                                      key={`${r.typeOfUserRole}-${index}-${r.orgId}`}
                                    >
                                      <td>
                                        {
                                          constants.ROLE_DICTIONARY[
                                            r.typeOfUserRole
                                          ]
                                        }
                                      </td>
                                      <td>
                                        {resolveRoleContextDescription(r)}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => onRemoveRole(r)}
                                          className="projectDanger"
                                          size="sm"
                                          title="Remove"
                                        >
                                          <FontAwesomeIcon icon="times-circle" />
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Row className="my-4">
                  <Col xs="12">
                    <Row>
                      <Col className="text-right">
                        <ButtonGroup>
                          <Button
                            color="secondary"
                            tag={Link}
                            to="/users/admin"
                          >
                            Cancel
                          </Button>
                          <Button className="projectPrimary" onClick={onSave}>
                            Save
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
