import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactBreakpoints from "react-breakpoints";
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Row, Col, Container } from "reactstrap";
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleUp, faAngleDown, faFilter, faExclamationTriangle, faSearch, faClipboardCheck,
  faArrowCircleRight, faCheckCircle, faDownload, faHourglass, faHourglassHalf, faBan,
  faPlusCircle, faEdit, faTimesCircle, faTrash, faRecycle, faUser, faFileUpload, faPrint,
  faAngleDoubleDown, faFileCsv, faUnlockAlt, faMapMarkedAlt, faPalette,
  faComments, faImages, faSave, faHome, faEye, faCheck, faPlus, faBackspace, faMinus,
  faGripLines, faTimes, faTruck, faPaintBrush, faTrashAlt, faMask, faAward, faCog, faCogs,
  faInfoCircle, faReply, faBars, faUserCircle, faMoneyCheckAlt, faDollarSign, faFileInvoiceDollar, faBuilding,
  faEnvelope,
  faMapMarked,
  faKey,
  faStar,
  faStarHalf,
  faWarehouse,
  faUsers,
  faAddressCard,
  faTruckLoading,
  faUpload,
  faUserTimes,
  faUserPlus,
  faUserSlash,
  faUserTag,
  faHandHoldingUsd,
  faTasks,
  faExternalLinkAlt,
  faCheckDouble,
  faStarHalfAlt,
  faEyeSlash,
  faCalendarAlt,
  faCalendarTimes,
  faUndo,
  faFlagCheckered,
  faDirections,
  faSync,
  faSearchLocation
} from '@fortawesome/free-solid-svg-icons';

import { constants } from "./utils";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/index.scss';

const rootElement = document.getElementById('root');

// add font awesome icons to be used in the app below 
library.add(faEye, faAngleUp, faAngleDown, faFilter, faExclamationTriangle, faSearch,
  faClipboardCheck, faCheckCircle, faDownload, faHourglass, faHourglassHalf, faBan,
  faArrowCircleRight, faPlusCircle, faAngleDoubleDown, faFileCsv, faEdit, faTimesCircle,
  faTrash, faRecycle, faInfoCircle, faMapMarkedAlt, faPalette, faUnlockAlt,
  faUser, faFileUpload, faPrint, faComments, faImages, faSave, faHome, faCheck, faPlus,
  faBackspace, faMinus, faGripLines, faTimes, faTruck, faPaintBrush,
  faTrashAlt, faReply, faMask, faAward, faCog, faCogs, faUpload,
  faBars, faUserCircle, faMoneyCheckAlt, faDollarSign, faFileInvoiceDollar, faBuilding,
  faEnvelope, faMapMarked, faKey, faStar, faStarHalf, faWarehouse, faUsers, faAddressCard, faTruckLoading,
  faUserTimes, faUserPlus, faUserSlash, faUserTag, faHandHoldingUsd, faTasks, faExternalLinkAlt,
  faCheckDouble, faStarHalfAlt, faEyeSlash, faCalendarAlt, faCalendarTimes, faUndo, faFlagCheckered, faDirections, faSync,
  faSearchLocation
);

const {
  BREAKPOINTS
} = constants;

const goBack = () => {
  window.history.back();
};

const AppFallBackComponent = ({ componentStack, error }) => {
  console.log('componentStack :\n', componentStack, '\n\n error : \n\n', error);
  return (
    <Container>
      <Row className="m-5">
        <Col>
          <h4>Something went wrong on our side.</h4>
        </Col>
      </Row>
      <Row className="m-5">
        <Col>
          <h4>Please use your browser&#39;s back button to return to the previous screen.</h4>
        </Col>
      </Row>
      <Row className="m-5">
        <Col>
          <h4><Button onClick={goBack}>Or click here</Button></h4>
        </Col>
      </Row>
    </Container>
  );
};

ReactDOM.render(
  <ReactBreakpoints breakpoints={BREAKPOINTS}>
    <Router>
      <ErrorBoundary FallbackComponent={AppFallBackComponent}>
        <App />
      </ErrorBoundary>
    </Router>
  </ReactBreakpoints>,
rootElement);


registerServiceWorker();

