import React, { Fragment, useState, useEffect } from "react";
import { Media } from "react-breakpoints";
import { Row, Col } from "reactstrap";
import { api, constants } from "../utils";
import { FilterableScreen, SHBSpinner, StatCard } from "../components";
import { DesktopContainer } from "../layouts";
import Alert from "react-s-alert";

export default function DashboardAdmin(props) {
  const [rtoCount, setRtoCount] = useState([]);
  const [manufacturerCount, setManufacturerList] = useState([]);
  const [haulerCount, setHaulerCount] = useState([]);
  const [openJobs, setOpenJobs] = useState([]);
  const [activeUserCount, setActiveUserCount] = useState([]);
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDashboardData();
  }, []);

  function loadDashboardData() {
    setLoading(true);
    api
      .post(`org/GetAdminDashboardData`)
      .then((response) => {
        if (response && response.data && response.data.success == false) {
          Alert.error("There was an error retrieving dashboard data");
        }
        if (response && response.data) {
          setHaulerCount(response.data.haulerFirmCount);
          setRtoCount(response.data.rtoCount);
          setManufacturerList(response.data.manufacturerCount);
          setOpenJobs(response.data.openJobs);
          setScheduledJobs(response.data.scheduledJobs);
          setCompletedJobs(response.data.completedJobs);
          setActiveUserCount(response.data.userCount);
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Admin Dashboard"}>
                  <FilterableScreen
                    filters={<Fragment></Fragment>}
                    pager={null}
                  >
                    <Row>
                      <Col xs="12" md="9">
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Scheduled Jobs"}
                            count={scheduledJobs}
                            link={`/jobs/admin/${constants.JOB_STATUS_IDS.SCHEDULED}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Open Jobs"}
                            count={openJobs}
                            link={`/jobs/admin/${constants.JOB_STATUS_IDS.OPEN}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Completed Jobs"}
                            count={completedJobs}
                            link={`/jobs/admin/${constants.JOB_STATUS_IDS.COMPLETE}`}
                            xs="12"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Manufacturers"}
                            count={manufacturerCount}
                            link={`/org/companies/admin/${constants.ORG_TYPE_IDS.MANUFACTURER}`}
                            xs="6"
                          />
                          <StatCard
                            cardName={"RTO Companies"}
                            count={rtoCount}
                            link={`/org/companies/admin/${constants.ORG_TYPE_IDS.RTO}`}
                            xs="6"
                          />
                        </Row>
                        <Row className="p-0 m-0 mb-2">
                          <StatCard
                            cardName={"Hauler Firms"}
                            count={haulerCount}
                            link={`/org/haulerFirms/admin`}
                            xs="6"
                          />
                          <StatCard
                            cardName={"Users"}
                            count={activeUserCount}
                            link={`/users/admin`}
                            xs="6"
                          />
                        </Row>
                      </Col>
                    </Row>
                  </FilterableScreen>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
