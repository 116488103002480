import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Button,
  CardBody,
  Row,
  Col,
  Label,
  ButtonGroup,
  Input
} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { api, helpers, dateHelpers, constants } from "../utils";
import { DateQuickSelect } from "../components";
import Alert from "react-s-alert";

export default function Reports(props) {
  const [flatReports, setFlatReports] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [parameterValues, setParameterValues] = useState({});
  const [selectedReport, setSelectedReport] = useState(null);
  const [defaultParameters] = useState({});

  useEffect(() => {
    api
      .fetch("report/reports")
      .then(response => {
        const reportGroups = _.map(response.data, rg => {
          return {
            ...rg,
            reports: _.map(_.filter(rg.reports, r => r.enabled), r => {
              return {
                ...r,
                groupName: rg.name,
                label: r.name, // ${r.description}`,
                value: r.name
              };
            }),
            label: rg.name,
            value: rg.name
          };
        });
        let flatReports = [];
        _.each(reportGroups, rg => {
          _.each(rg.reports, rpt => {
            flatReports.push(rpt);
          });
        });
        setFlatReports(flatReports);
      })
      .catch(error => console.error(error));
  }, []);

  function isFormValid(report, params) {
    let warnings = [];
    _.each(report.parameters, rp => {
      if (!rp.hidden && rp.required) {
        switch (rp.parameterType) {
          case constants.REPORT_PARAMETER_TYPES.Text:
            if (!rp.value) {
              warnings.push(`Enter a ${rp.prompts[0]} value`);
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.DateRange:
            if (!rp.value || !rp.value.length) {
              warnings.push("Select valid date from and date through values");
              // } else if (!moment.isMoment(rp.value[0].value) || !moment.isMoment(rp.value[1].value)) {
              //   warnings.push('Date from and date through must be valid dates');
            } else if (
              dateHelpers
                .getMomentFromString(rp.value[0])
                .isAfter(dateHelpers.getMomentFromString(rp.value[1]))
            ) {
              warnings.push("Date from cannot be after date through");
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Date:
            if (!rp.value) {
              warnings.push(`Select a valid ${rp.prompts[0]} value`);
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.ApiList:
            if (rp.multi) {
              if (!rp.value || !rp.value.length) {
                warnings.push(`Select one or more ${rp.prompts[0]} values`);
              }
            } else {
              if (!rp.value || !rp.value.value) {
                warnings.push(`Select a ${rp.prompts[0]} value`);
              }
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Integer:
            if (!rp.value || isNaN(parseInt(rp.value.value, 10))) {
              warnings.push(`Provide a valid ${rp.prompts[0]} value`);
            }
            break;
          default:
            console.error(`UNKNOWN parameter type [${rp.parameterType}] !!!!`);
            break;
        }
      }
    });
    if (warnings.length) {
      alert(warnings.join("\n"));
    }
    return warnings.length === 0;
  }

  function parseParameters(report, params) {
    let rawParameters = {};
    _.each(report.parameters, rp => {
      if (!rp.hidden) {
        switch (rp.parameterType) {
          case constants.REPORT_PARAMETER_TYPES.Text:
            rawParameters[rp.names[0]] = { label: "", value: rp.value || "" };
            break;
          case constants.REPORT_PARAMETER_TYPES.DateRange:
            if (rp.value) {
              // due to validation we know that these are valid moment values now
              rawParameters.date_range = {
                label: `${rp.value[0]} - ${rp.value[1]}`,
                value: [
                  { label: "Date From", value: rp.value[0] },
                  { label: "Date Through", value: rp.value[1] }
                ]
                // date_helpers.formatDateForServer(rp.value) + "|" + date_helpers.formatDateForServer(params[rp.names[1]])
              };
            } else {
              rawParameters.date_range = {
                label: "Date Range not set",
                value: [null, null]
              };
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Date:
            rawParameters[rp.names[0]] = { label: "", value: rp.value };
            break;
          case constants.REPORT_PARAMETER_TYPES.ApiList:
            if (rp.value) {
              if (rp.multi) {
                if (rp.value.length) {
                  rawParameters[rp.names[0]] = {
                    label: _.map(rp.value, x => x[rp.apiValueAttribute]).join(
                      ","
                    ),
                    value: _.map(rp.value, x => ({
                      label: x[rp.apiValueAttribute],
                      value: x[rp.apiKeyAttribute]
                    }))
                  };
                }
              } else {
                rawParameters[rp.names[0]] = {
                  label: rp.value[rp.apiValueAttribute],
                  value: rp.value[rp.apiKeyAttribute]
                };
              }
            } else {
              rawParameters[rp.names[0]] = null; // {label: "", value: null};
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Integer:
            if (rp.value) {
              rawParameters[rp.names[0]] = {
                label: "",
                value: parseInt(rp.value, 10)
              };
            } else {
              rawParameters[rp.names[0]] = { label: "", value: null };
            }
            break;
          case constants.REPORT_PARAMETER_TYPES.Boolean:
            rawParameters[rp.names[0]] = {
              label: "",
              value: rp.value === true
            };
            break;
          default:
            console.error(`UNKNOWN parameter type [${rp.parameterType}] !!!!`);
            break;
        }
      }
    });
    return rawParameters;
  }

  function generateReport() {
    if (!isFormValid(selectedReport, parameters)) return;
    const payload = {
      reportName: selectedReport.value,
      parameters: parseParameters(selectedReport, parameters),
      typeOfRender: constants.RENDER_TYPES.Excel
    };

    api
      .postWithExcelReturn("report/execute", payload)
      .then(response => {
        const fileName = `report.xlsx`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data, fileName);
        } else {
          const file = new Blob([response.data], { type: constants.MIME_XLSX });
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(file, fileName);
          } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
              var url = URL.createObjectURL(file);
              link.setAttribute("href", url);
              link.setAttribute("download", fileName);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }
      })
      .catch(e => {
        console.error(e);
        Alert.error("There was an error generating the report");
      });
  }

  function cancel() {
    setParameters(defaultParameters);
    setSelectedReport(null);
  }

  function onReportSelect(report) {
    let fetches = [];
    _.each(
      _.filter(
        report.parameters,
        p =>
          p.parameterType === constants.REPORT_PARAMETER_TYPES.ApiList &&
          !p.typeahead
      ),
      p => {
        fetches.push(() => {
          return api
            .fetch_raw(p.apiRoute, {})
            .then(response => {
              return {
                [`${p.names[0]}_options`]: _.map(response.data, x => ({
                  ...x,
                  value: x[p.apiKeyAttribute],
                  label: x[p.apiValueAttribute]
                }))
              };
            })
            .catch(error => console.error(error));
        });
      }
    );
    if (fetches.length) {
      Promise.all(_.map(fetches, f => f()))
        .then(aggregateResults => {
          setSelectedReport(report);
          let newParameterValues = {};
          aggregateResults.forEach(r => Object.assign(newParameterValues, r));
          setParameterValues(newParameterValues);
        })
        .catch(error => console.error(error));
    } else {
      setSelectedReport(report);
    }
  }

  function loadApiListOptions(
    inputValue,
    callBack,
    apiRoute,
    valueAttribute,
    labelAttribute
  ) {
    if (!inputValue || !inputValue.length || inputValue.length < 2) return;
    api
      .fetch_raw(apiRoute, { inputValue })
      .then(response => {
        callBack(
          _.map(response.data, x => ({
            ...x,
            value: x[valueAttribute],
            label: x[labelAttribute]
          }))
        );
      })
      .catch(error => console.error(error));
  }

  function setParameterValue(parameterName, value, parameterIndex = null) {
    let r = Object.assign({}, selectedReport);
    const paramToChangeIndex = _.findIndex(r.parameters, p =>
      p.names.includes(parameterName)
    );
    let paramToChange = r.parameters[paramToChangeIndex];
    if (parameterIndex !== null) {
      if (!paramToChange.value || paramToChange.value.constructor !== Array) {
        paramToChange.value = [null, null];
      }
      paramToChange.value[parameterIndex] = value;
    } else {
      paramToChange.value = value;
    }
    setSelectedReport(r);
  }

  function onDropdownSelection(parameterName, selection) {
    let r = Object.assign({}, selectedReport);
    const paramToChangeIndex = _.findIndex(r.parameters, p =>
      p.names.includes(parameterName)
    );
    let paramToChange = r.parameters[paramToChangeIndex];
    if (selection) {
      paramToChange.value = selection;
    } else {
      paramToChange.value = null;
      setParameterValues(
        Object.assign({}, parameterValues, {
          [`${parameterName}_input_value`]: ""
        })
      );
    }
    setSelectedReport(r);
  }

  function renderParameters(paramList) {
    let segments = [];
    _.each(paramList, p => {
      const segmentKey = `${p.names[0]}_param`;
      switch (p.parameterType) {
        case constants.REPORT_PARAMETER_TYPES.Boolean:
          segments.push(
            <Row className="mb-2" key={segmentKey}>
              <Col sm="4" className="pt-2">
                <Label>
                  {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                </Label>
              </Col>
              <Col>
                <Switch
                  className="ml-2 float-right p-0 m-0"
                  onChange={e => setParameterValue(p.names[0], e)}
                  checked={p.value || false}
                />
              </Col>
            </Row>
          );
          break;
        case constants.REPORT_PARAMETER_TYPES.Integer:
        case constants.REPORT_PARAMETER_TYPES.Text:
          segments.push(
            <Row className="mb-2" key={segmentKey}>
              <Col sm="4" className="pt-2">
                <Label>
                  {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                </Label>
              </Col>
              <Col>
                <Input
                  type="text"
                  value={p.value || ""}
                  onChange={e => setParameterValue(p.names[0], e.target.value)}
                  className="form-control"
                  maxLength="50"
                />
              </Col>
            </Row>
          );
          break;
        case constants.REPORT_PARAMETER_TYPES.Date:
          segments.push(
            <Row className="mb-2" key={segmentKey}>
              <Col sm="4" className="pt-2">
                <Label for="startDate">
                  {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                </Label>
              </Col>
              <Col>
                <DatePicker
                  selected={
                    p.value
                      ? dateHelpers
                          .getMomentFromString(p.value, dateHelpers.YMD)
                          .valueOf()
                      : null
                  }
                  onChange={e =>
                    setParameterValue(
                      p.names[0],
                      dateHelpers.formatDateForServer(e)
                    )
                  }
                  className="form-control"
                  // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[0], e))}
                />
              </Col>
            </Row>
          );
          break;
        case constants.REPORT_PARAMETER_TYPES.DateRange:
          segments.push(
            <Fragment key={segmentKey}>
              <Row className="mb-2">
                <Col sm="4" className="pt-2">
                  <Label for="startDate">
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={
                      p.value && p.value.length && p.value[0]
                        ? dateHelpers
                            .getMomentFromString(p.value[0], dateHelpers.YMD)
                            .valueOf()
                        : null
                    }
                    onChange={e =>
                      setParameterValue(
                        p.names[0],
                        dateHelpers.formatDateForServer(e),
                        0
                      )
                    }
                    className="form-control"
                    // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[0], e, 0))}
                  />
                  <DateQuickSelect
                    setStartDate={x =>
                      setParameterValue(
                        p.names[0],
                        dateHelpers.formatDateForServer(x),
                        0
                      )
                    }
                    setEndDate={x =>
                      setParameterValue(
                        p.names[0],
                        dateHelpers.formatDateForServer(x),
                        1
                      )
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm="4" className="pt-2">
                  <Label for="endDate">
                    {p.prompts[1]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={
                      p.value && p.value.length && p.value[1]
                        ? dateHelpers
                            .getMomentFromString(p.value[1], dateHelpers.YMD)
                            .valueOf()
                        : null
                    }
                    onChange={e =>
                      setParameterValue(
                        p.names[1],
                        dateHelpers.formatDateForServer(e),
                        1
                      )
                    }
                    className="form-control"
                    // onKeyDown={(event) => helpers.onDatePickerKeyDown(event, e => this.setParameterValue(p.names[1], e, 1))}
                  />
                </Col>
              </Row>
            </Fragment>
          );
          break;
        case constants.REPORT_PARAMETER_TYPES.ApiList:
          if (p.typeahead) {
            segments.push(
              <Row className="mb-2" key={segmentKey}>
                <Col xs="4">
                  <Label>
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <AsyncSelect
                    loadOptions={_.debounce(
                      (inputValue, callBack) =>
                        loadApiListOptions(
                          inputValue,
                          callBack,
                          p.apiRoute,
                          p.apiKeyAttribute,
                          p.apiValueAttribute
                        ),
                      500
                    )}
                    placeholder="Type Search Value"
                    // defaultOptions={true}
                    options={parameterValues[`${p.names[0]}_options`]}
                    onChange={e => onDropdownSelection(p.names[0], e)}
                    inputValue={parameterValues[`${p.names[0]}_input_value`]}
                    onInputChange={e =>
                      setParameterValues(
                        Object.assign({}, parameterValues, {
                          [`${p.names[0]}_input_value`]: e
                        })
                      )
                    }
                    value={parameterValues[`${p.names[0]}_selection`]}
                    isMulti={p.multi}
                    isDisabled={false}
                    isClearable={true}
                  />
                </Col>
              </Row>
            );
          } else {
            segments.push(
              <Row className="mb-2" key={segmentKey}>
                <Col xs="4">
                  <Label>
                    {p.prompts[0]} {p.required ? helpers.requiredStar() : null}
                  </Label>
                </Col>
                <Col>
                  <Select
                    value={p.value}
                    onChange={e => onDropdownSelection(p.names[0], e)}
                    options={parameterValues[`${p.names[0]}_options`]}
                    isMulti={p.multi}
                    isDisabled={false}
                    isClearable={true}
                  />
                </Col>
              </Row>
            );
          }
          break;
        default:
          console.error("unknown param type to render: ", p);
          break;
      }
    });
    return <Fragment>{segments.map(x => x)}</Fragment>;
  }

  return (
    <div>
      <Row style={{ paddingTop: "15px" }}>
        <Col xs={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Row>
                <Col xs="2" className="pt-2">
                  <Label>Report</Label>
                </Col>
                <Col xs="9">
                  <Select
                    value={selectedReport}
                    onChange={e => onReportSelect(e)}
                    options={flatReports}
                  />
                </Col>
              </Row>
              {selectedReport && (
                <React.Fragment>
                  <Row style={{ paddingTop: "15px" }}>
                    <Col className="report-description">
                      {selectedReport.description}
                    </Col>
                  </Row>
                  <hr />
                  {renderParameters(selectedReport.parameters)}
                  <Row>
                    <Col>
                      <ButtonGroup
                        className="float-right"
                        style={{ paddingTop: "15px" }}
                      >
                        <Button color="secondary" onClick={() => cancel()}>
                          Clear
                        </Button>
                        <Button
                          color="success"
                          onClick={() => generateReport()}
                        >
                          Run
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
