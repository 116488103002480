import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import _ from "lodash";

export default function AddressAutocomplete({
  placeholder = "Search for an address",
  getAddressInfo,
  disabled = false,
  value,
  onChange,
  id,
  style = {},
}) {
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  function parsePlaceDetails(details) {
    let addressInfo = {};
    if (details && details.address_components) {
      _.each(details.address_components, (c) => {
        if (_.includes(c.types, "postal_code")) {
          addressInfo.zip = c.long_name;
        }

        if (_.includes(c.types, "administrative_area_level_1")) {
          addressInfo.stateAbbr = c.short_name;
        }

        if (_.includes(c.types, "locality")) {
          addressInfo.city = c.long_name;
        }

        if (_.includes(c.types, "street_number")) {
          addressInfo.streetNumber = c.long_name;
        }

        if (_.includes(c.types, "route")) {
          addressInfo.route = c.long_name;
        }
      });
    } else {
      throw new Error("No address_components on place details result");
    }

    if (details && details.geometry && details.geometry.location) {
      addressInfo.lat = details.geometry.location.lat();
      addressInfo.lng = details.geometry.location.lng();
    } else {
      throw new Error("No location info on place details result");
    }

    getAddressInfo && getAddressInfo(addressInfo);
    setAutoCompleteValue("");
  }

  return (
    <ReactGoogleAutocomplete
      id={id}
      apiKey={"AIzaSyCvgNhKIzSF_dJ5aEUdLDwePMurzv3tRGk"} //TODO add to const
      style={{ marginBottom: 10, ...style }}
      className="form-control"
      onPlaceSelected={parsePlaceDetails}
      options={{
        types: ["address"],
        componentRestrictions: { country: ["us", 'ca'] },
      }}
      placeholder={placeholder}
      disabled={disabled}
      value={autoCompleteValue}
      onChange={(e) => setAutoCompleteValue(e.target.value)}
      autoComplete="off"
    />
  );
}
