import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import { Alert, Col, Button, Row, Table, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import classnames from "classnames";
import { api, helpers, UserContext, filterHelpers, constants } from "../utils";
import {
  FilterText,
  FilterableScreen,
  FilterSet,
  FilterSwitch,
  FilterSelect,
  FilterMultiSelect,
  Pager,
} from "../components";
import { DesktopContainer } from "../layouts";

const resolveRoleListDescription = (roles) => {
  return _.map(roles, (r) => constants.ROLE_DICTIONARY[r.typeOfUserRole]).join(
    ", "
  );
};

export default function Users(props) {
  const userCtx = useContext(UserContext);
  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
  ]);
  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [message, setMessage] = useState("");
  const [companies, setCompanies] = useState([]);
  const [haulingFirms, setHaulingFirms] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const rolesList = constants.ALL_ROLES.map((x) => {
    return { value: x.value, label: `${x.groupName} - ${x.label}` };
  });

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(api.getCompanies());
    apiCalls.push(api.getHaulerFirms());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        setHaulingFirms(aggResults.haulerFirms);
        setCompanies(aggResults.companies);
      })
      .catch((error) =>
        setMessage({
          flavor: "alert-danger",
          text: "There was an error loading user data",
        })
      );
  }, []);

  useEffect(refreshData, [filters, pageNumber]);

  function refreshData() {
    let payload = {
      sortField: null,
      sortDirection: null,
      pageNumber: pageNumber,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("UserAdmin/List", payload)
      .then((response) => {
        setPaginatedList(response.data);
      })
      .catch(helpers.catchHandler);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function onImpersonate(u) {
    api
      .post("UserAdmin/Impersonate", { username: u.username })
      .then((response) => {
        if (response.data.success) {
          userCtx.impersonate(response.data.user, response.data.user.token);
          setMessage("");
        } else {
          setMessage({ flavor: "danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onUnlockUser(selected) {
    api
      .post(`UserAdmin/UnlockUser/${selected.id}`)
      .then((response) => {
        if (response.data.success) {
          refreshData();
          setMessage("");
        } else {
          setMessage({ flavor: "danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onToggleMustChangePassword(selected) {
    api
      .post(`UserAdmin/ToggleMustChangePassword/${selected.id}`)
      .then((response) => {
        if (response.data.success) {
          refreshData();
          setMessage("");
        } else {
          setMessage({ flavor: "danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  function onToggleActive(selected) {
    api
      .post(`UserAdmin/ToggleUserActive/${selected.id}`)
      .then((response) => {
        if (response.data.success) {
          refreshData();
          setMessage("");
        } else {
          setMessage({ flavor: "danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={"Users"}>
                <FilterableScreen
                  filters={
                    <Row>
                      <Col xs="10">
                        <FilterSet
                          filters={filters}
                          clearFilters={() => setFilters([])}
                        >
                          <Row>
                            <Col xs="3">
                              <FilterText
                                filterName="Name"
                                displayName="Name"
                                value={filterHelpers.currentFilterValue(
                                  filters,
                                  "Name"
                                )}
                                onChangeCallback={(e) => onFilterChange(e)}
                              />
                            </Col>
                            <Col xs="1">
                              <FilterSwitch
                                filterName="ActiveOnly"
                                displayName="Active Only"
                                value={filterHelpers.currentFilterValue(
                                  filters,
                                  "ActiveOnly"
                                )}
                                onChangeCallback={(e) => onFilterChange(e)}
                              />
                            </Col>
                            <Col xs="3">
                              <FilterMultiSelect
                                filterName="MemberOfRoles"
                                displayName="Roles"
                                values={filterHelpers.currentFilterValue(
                                  filters,
                                  "MemberOfRoles"
                                )}
                                onChangeCallback={(e) => onFilterChange(e)}
                                options={rolesList}
                              />
                            </Col>
                            {userCtx?.currentUser?.isSysAdmin ? (
                              <Fragment>
                                <Col xs="3">
                                  <FilterSelect
                                    filterName="CompanyId"
                                    displayName="Company"
                                    options={helpers.addAnyOption(companies)}
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "CompanyId"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    clearValue={() =>
                                      onFilterChange({
                                        filterName: "CompanyId",
                                        value: null,
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterSelect
                                    filterName="HaulingFirmId"
                                    displayName="Hauling Firm"
                                    options={helpers.addAnyOption(haulingFirms)}
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "HaulingFirmId"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    clearValue={() =>
                                      onFilterChange({
                                        filterName: "HaulingFirmId",
                                        value: null,
                                      })
                                    }
                                  />
                                </Col>
                              </Fragment>
                            ) : null}
                          </Row>
                        </FilterSet>
                      </Col>
                      <Col xs="2" className="pl-0 pr-4">
                        <ButtonGroup className="float-right mr-2">
                          <Button
                            onClick={refreshData}
                            title="Refresh"
                            color="dark"
                          >
                            <FontAwesomeIcon icon="sync" />
                          </Button>
                          <Button
                            className="projectSuccess"
                            tag={Link}
                            to="/user/0"
                            title="Add"
                          >
                            <FontAwesomeIcon icon="plus" />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  }
                  pager={
                    <div className="float-right">
                      <Pager
                        pageNumber={
                          paginatedList?.pageNumber
                            ? paginatedList.pageNumber
                            : 0
                        }
                        totalPages={
                          paginatedList?.totalPages
                            ? paginatedList.totalPages
                            : 0
                        }
                        callBack={(newPageNumber) =>
                          setPageNumber(newPageNumber)
                        }
                      />
                    </div>
                  }
                >
                  <Row className="p-0 m-0">
                    <Col xs="12">
                      {message && (
                        <Alert className={message.flavor}>{message.text}</Alert>
                      )}
                      <Table
                        striped
                        hover
                        size="sm"
                        responsive={false}
                        id="userTable"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Organization</th>
                            <th>Organization Type</th>
                            <th>Roles</th>
                            <th></th>
                            <th width="40%">Info</th>
                            <th width="20%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(paginatedList.list, (u) => (
                            <tr key={`u-${u.id}`}>
                              <td width="20%">
                                <Link to={`/user/${u.id}`}>
                                  {u.firstName} {u.lastName}
                                </Link>
                                <div
                                  style={{
                                    fontStyle: "italic",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {u.username}
                                </div>
                              </td>
                              <td width="20%">
                                {u.orgName}
                              </td>
                              <td width="10%">
                                {u.orgTypeName}
                              </td>
                              <td width="15%">{resolveRoleListDescription(u.roles)}</td>
                              <td>
                                <span
                                  style={{ cursor: "pointer" }}
                                  className={classnames(
                                    {
                                      "text-warning": u.mustChangePassword,
                                      secondary: !u.mustChangePassword,
                                    },
                                    "clickable-icon"
                                  )}
                                  title={
                                    u.mustChangePassword
                                      ? "Must change password! - click to remove this requirement"
                                      : "Click to force change of password"
                                  }
                                  onClick={() => onToggleMustChangePassword(u)}
                                >
                                  <FontAwesomeIcon
                                    size="2x"
                                    icon="exclamation-triangle"
                                  />
                                </span>
                                {u.isLockedOut ? (
                                  <span
                                    className={"clickable-icon text-danger"}
                                    title="Currently locked out - click to unlock"
                                    onClick={() => onUnlockUser(u)}
                                  >
                                    <FontAwesomeIcon
                                      size="2x"
                                      icon="unlock-alt"
                                    />
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                <div>
                                  Last Logged On:{" "}
                                  <span style={{ fontStyle: "italic" }}>
                                    {u.lastLoggedOn ? u.lastLoggedOn : "Never"}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <ButtonGroup className="float-right">
                                  <Button
                                    size="sm"
                                    className="projectSecondary minWidth150"
                                    onClick={() => onImpersonate(u)}
                                  >
                                    <FontAwesomeIcon icon="mask" /> Impersonate
                                  </Button>
                                  {u.active ? (
                                    <Button
                                      size="sm"
                                      className="projectDanger"
                                      title="Deactivate"
                                      onClick={() => onToggleActive(u)}
                                    >
                                      <FontAwesomeIcon icon="times-circle" />
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      className="projectInfo"
                                      title="Revive"
                                      onClick={() => onToggleActive(u)}
                                    >
                                      <FontAwesomeIcon icon="recycle" />
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </FilterableScreen>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
