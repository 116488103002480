import { useState, useEffect } from 'react';

export function useAlert(msg) {
    const [message, setMessage] = useState(msg);

    useEffect(
        () => {
            if (message) {
                setTimeout(() => setMessage(null), 5000);
            }
        },
        [message]
    );

    return [message, setMessage];
}