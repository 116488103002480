import React from "react";
import { dateHelpers, constants } from "./";
import { RatingModule } from "../components";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "./api";

function lowerCase(str) {
  return str.toLowerCase();
}

function upperCase(str) {
  return str.toUpperCase();
}

const changeDateFormat = (
  date,
  changeDateFunction,
  fieldName = null,
  itemToEditId = null
) => {
  let formattedDate = null;
  if (dateHelpers.isDateValid(date)) {
    const dateString = dateHelpers.parseDatePickerDate(date, dateHelpers.MDY4);
    formattedDate = dateHelpers.getMomentFromString(dateString);
  }
  if (fieldName && itemToEditId) {
    changeDateFunction(formattedDate, fieldName, itemToEditId);
  } else if (fieldName) {
    changeDateFunction(formattedDate, fieldName);
  } else if (itemToEditId) {
    changeDateFunction(formattedDate, itemToEditId);
  } else {
    changeDateFunction(formattedDate);
  }
};

const onDatePickerKeyDown = (
  event,
  changeDateFunction,
  fieldName = null,
  itemToEditId = null
) => {
  if (event.which === 9 || event.which === 13) {
    // tab key or enter key
    const eventAction = event && event.target ? event.target.value : null;
    changeDateFormat(eventAction, changeDateFunction, fieldName, itemToEditId);
  }
};

const toggleListItemChecked = (id, list) => {
  const idx = _.findIndex(list, (x) => x.id === id);
  let newList = list.slice();
  newList[idx].checked = newList[idx].checked ? false : true;
  return list;
};

const helpers = {
  toggleListItemChecked: toggleListItemChecked,
  onDatePickerKeyDown: onDatePickerKeyDown,
  requiredStar() {
    return <span style={{ color: "red" }}>*</span>;
  },
  booleanListEntry: function (b) {
    return b === true
      ? { label: "Yes", value: true }
      : { label: "No", value: false };
  },
  resolveValue: function (obj, id, name) {
    if (obj) return obj;
    if (id === null) return null;
    return { value: id, label: name };
  },
  resolveValues: function (objs) {
    if (objs.length === 0) {
      return [];
    }
    const formattedObjects = [];
    _.forEach(objs, (obj) => {
      if (obj.id === null) return null;
      formattedObjects.push({ value: obj.id, label: obj.name });
    });
    return formattedObjects;
  },
  yesNoOptions: function () {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  },

  addAnyOption: (list) => {
    let newList = list.slice();
    newList.unshift({ label: "(Any)", value: null });
    return newList;
  },

  formatAddress: function (address) {
    const addressArray = [address.ln1, address.ln2, address.ln3, address.ln4];
    const compactList = _.compact(addressArray, (x) => x);

    compactList.push(address.city + ", " + address.state + " " + address.zip);
    return compactList;
  },

  formatPhoneNumber: function (phoneNumberString) {
    phoneNumberString = phoneNumberString.replace(/[^\d]+/g, "");
    if (phoneNumberString.length === 7) {
      phoneNumberString = phoneNumberString.replace(/(\d{3})(\d{4})/, "$1-$2");
    } else if (phoneNumberString.length === 10) {
      phoneNumberString = phoneNumberString.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
    } else if (phoneNumberString.length === 11) {
      phoneNumberString = phoneNumberString.replace(
        /^(1|)?(\d{3})(\d{3})(\d{4})/,
        "($2) $3-$4"
      );
    }
    return phoneNumberString;
  },

  browserExportCSVFile: function (csv, fileTitle) {
    const fname = fileTitle || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fname);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fname);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  downloadExport: function (data, fileName, mime) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const file = new Blob([data], { type: mime });
      var anchor = window.document.createElement("a");
      anchor.download = fileName;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(file);
      anchor.dataset.downloadurl = [mime, anchor.download, anchor.href].join(
        ":"
      );
      anchor.click();
    }
  },

  catchHandler: (e) => console.log(e),

  //use if statement to check against status code to redirect to sign-in screen if session token expires
  parseRequestTypeName: function (request_type_name) {
    const properCase = function (str) {
      return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
    };
    const removeUnderscore = function (str) {
      return str.replace(/_/g, " ");
    };
    const request_type_label = properCase(removeUnderscore(request_type_name));
    return request_type_label;
  },

  mustChangePassword: function (user) {
    if (!user) return false;
    return user.mustChangePassword;
  },

  //expecting arrayofKeysToCheck to be array of objects with keys "name" (name of the key on the object) and "label" (what you tell the user is missing)
  //example: [{name: "firstName", label: "first name"}, {name: "email", label: "e-mail address"}]
  isFormInvalid(object, arrayOfKeysToCheck) {
    const warnings = [];
    _.map(arrayOfKeysToCheck, (key) => {
      if (!object[key.name]) {
        warnings.push(key.label);
      }
    });

    if (warnings.length) {
      return "Please provide the following: " + warnings.join(", ");
    } else {
      return false;
    }
  },

  addIsEditingField(list, propName = null) {
    if (!list || !list.length || list.length === 0) {
      return [];
    }
    const isEditingList = _.map(list, (x) => {
      return {
        ...x,
        isEditing: false,
      };
    });
    return propName ? { [propName]: isEditingList } : isEditingList;
  },

  nullableString(s) {
    return s ? s : "";
  },
  emailIsValid: function (email) {
    /* eslint-disable no-useless-escape */
    const emailRe =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRe.test(email);
  },
  convertToPennies: function (currency) {
    return currency * 100;
  },
  formatCurrency(x) {
    return Number(x).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  },
  isMobile: function (window) {
    const smallerOption =
      window.document &&
      window.document.defaultView &&
      window.document.defaultView.innerWidth
        ? window.document.defaultView.innerWidth
        : window.screen.width;
    return smallerOption <= 680;
  },
  cardHeaderName: function cardHeaderName(cardName, iconName) {
    return (
      <span>
        <FontAwesomeIcon icon={iconName} className="mr-2" />
        {cardName}
      </span>
    );
  },
  getJobTypeBadgeColor(jobTypeName) {
    switch (jobTypeName) {
      case "Return":
        return "warning";
      case "Retrieval":
        return "warning";
      case "Off Lot":
        return "secondary";
      case "Move":
        return "secondary";
      case "MFG":
        return "info";
      case "RTO Delivery":
        return "info";
      default:
        return "info";
    }
  },
  getJobStatusBadgeColor(jobStatusName) {
    switch (jobStatusName) {
      case "Open":
        return "primary";
      case "Reserved":
        return "warning";
      case "Scheduled":
        return "warning";
      case "In Progress":
        return "warning";
      case "Complete":
        return "success";
      case "Cancelled":
        return "danger";
      default:
        return "primary";
    }
  },
  resolveUserRole(currentUser, forRoleName) {
    //need to find a uniformed way to determine current user and role
    switch (forRoleName) {
      case constants.ROLE_NAMES.COMPANY_ADMIN:
        return currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN;
      case constants.ROLE_NAMES.COMPANY_CLERK:
        return currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_CLERK;
      case constants.ROLE_NAMES.DISPATCHER:
        return currentUser.currentRoleId === constants.ROLE_IDS.DISPATCHER;
      default:
        return false;
    }
  },
  canEditCompanyProfile(currentUser, orgIdToEdit) {
    if (
      (currentUser.currentOrgId === orgIdToEdit &&
        currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN) ||
      currentUser.isSysAdmin
    ) {
      return true;
    }
    return false;
  },
  showStarRating(ratingScore) {
    return <RatingModule showRatingOnly={true} score={ratingScore || 0} />;
  },
  truncateStringOverLength(stringVal, truncateOverLength, addEllipsis) {
    if (
      stringVal &&
      stringVal.length &&
      stringVal.length > truncateOverLength
    ) {
      return `${stringVal.substring(0, truncateOverLength)}${
        addEllipsis ? "..." : ""
      }`;
    }
    return stringVal;
  },
  ensureMembershipLevelSize(orgId){
    api.fetch(`UserAdmin/AdjustOrgSize/${orgId}`)
      .then((r) => {
        //console.log("ensure membership response", r);
      })
  }
};
export default helpers;
