import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  Col,
  Button,
  Row,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Alert from "react-s-alert";
import { EstimatedTimeOfDay } from "../components";

import { api, UserContext, helpers } from "../utils";
import { GiJapaneseBridge } from "react-icons/gi";

const emptySchedule = {
  jobId: null,
  driver: null,
  estimatedPickupAt: null,
  estimatedDropoffAt: null,
  estimatedDropofTimeOfDay: null,
  estimatedPickupTimeOfDay: null,
};

export default function ScheduleModal(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const [job, setJob] = useState(false);
  const [driversList, setDriversList] = useState(null);
  const [scheduleJob, setScheduleJob] = useState(null);
  const [isPrivateJob, setIsPrivateJob] = useState(props.isPrivate ? props.isPrivate : false);


  useEffect(() => {
    api
      .post(`Org/GetOrgDrivers/${currentUser.currentOrgId}`)
      .then((response) => {
        if (response && response.data) {
          setDriversList(
            _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            })
          );
        }
      })
      .catch(helpers.catchHandler);
    formatScheduleJob(props.job);
  }, [currentUser.currentOrgId, props.job]);

  useEffect(() => {
    setJob(props.job);
  }, [props.job]);

  function formatScheduleJob(job) {
    if (job) {
      let scheduleJob =  isPrivateJob
        ? {
            jobId: job.id,
            driver: Object.assign({}, job.assignedDriver, {value: job.assignedDriver.id, label: job.assignedDriver.fullName}),
            scheduledForDate: job.scheduledForDate
              ? moment(job.scheduledForDate).toDate()
              : "",
            estimatedPickupTimeOfDay: job.estimatedPickupTimeOfDay,
          } 
        : {
            jobId: job.id,
            driver: job.driver,
            estimatedPickupAt: job.estimatedPickupAt
              ? moment(job.estimatedPickupAt).toDate()
              : "",
            estimatedDropoffAt: job.estimatedDropoffAt
              ? moment(job.estimatedDropoffAt).toDate()
              : "",
            estimatedDropofTimeOfDay: job.estimatedDropofTimeOfDay,
            estimatedPickupTimeOfDay: job.estimatedPickupTimeOfDay,
          };
      setScheduleJob(scheduleJob);
    } else {
      setScheduleJob(Object.assign({}, emptySchedule));
    }
  }

  function onSchedule() {
    if (!scheduleJob) {
      Alert.error("Schedule detail is required.");
      return;
    }
    if (!scheduleJob.driver) {
      Alert.error("Please assign a driver.");
      return;
    }
    if (!scheduleJob.estimatedPickupAt && !scheduleJob.scheduledForDate) {
      Alert.error("Estimated pick up is required.");
      return;
    }
    if (!job || !job.id) {
      Alert.error("No Job or Job Id Available");
      return;
    }
    if(!isPrivateJob) {
      if (!scheduleJob.estimatedDropoffAt) {
        Alert.error("Estimated drop off is required.");
        return;
      }
      if (
        moment(scheduleJob.estimatedDropoffAt).isBefore(
          scheduleJob.estimatedPickupAt
        )
      ) {
        Alert.error("Dropoff must be after pickup");
        return;
      }
    }

    let payload = isPrivateJob
    ? {
        JobId: job.id,
        DriverId: scheduleJob.driver ? scheduleJob.driver.value : null,
        AssignedToOrgId: currentUser.currentOrgId,
        ScheduledForDate: scheduleJob.scheduledForDate,
        EstimatedPickupTimeOfDay: scheduleJob.estimatedPickupTimeOfDay
      }
    : {
        JobId: job.id,
        DriverId: scheduleJob.driver ? scheduleJob.driver.value : null,
        AssignedToOrgId: currentUser.currentOrgId,
        EstimatedPickupAt: scheduleJob.estimatedPickupAt,
        EstimatedDropoffAt: scheduleJob.estimatedDropoffAt,
        EstimatedPickupTimeOfDay: scheduleJob.estimatedPickupTimeOfDay,
        EstimatedDropoffTimeOfDay: scheduleJob.estimatedDropoffTimeOfDay,
      };
    let endpoint = isPrivateJob ? "Job/SchedulePrivateJob": "Job/ScheduleJob";
    api.post(endpoint, payload).then((response) => {
      if (response.data && response.data.success) {
        Alert.success("Job has been scheduled.");
        props.onSuccess();
      } else {
        Alert.error("An error occurred. Job cannot be scheduled at this time.");
      }
    });
    //  .catch(helpers.catchHandler);
  }
  return (
    <Modal
      isOpen={props.showModal}
      toggle={props.toggle}
      backdrop={true}
      size="lg"
    >
      <ModalHeader>
        <FontAwesomeIcon icon="calendar-alt" /> Schedule Job
      </ModalHeader>
      <ModalBody>
        {scheduleJob !== null && (
          <Fragment>
            <Row className="mb-2">
              <Col xs="6">
                Assigned Driver {helpers.requiredStar()}
                <Select
                  closeMenuOnSelect={true}
                  value={scheduleJob && scheduleJob.driver}
                  name="driver"
                  options={driversList}
                  onChange={(e) =>
                    setScheduleJob({ ...scheduleJob, driver: e })
                  }
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                { isPrivateJob ? "Scheduled For" : "Estimated Pickup At"} {helpers.requiredStar()}
                <div>
                  <DatePicker
                    name="estimatedPickupAt"
                    selected={scheduleJob.estimatedPickupAt || scheduleJob.scheduledForDate}
                    className="form-control"
                    placeholderText="Select date"
                    onChange={(e) => (
                      isPrivateJob
                      ? setScheduleJob({...scheduleJob, scheduledForDate: e})
                      : setScheduleJob({ ...scheduleJob, estimatedPickupAt: e })
                    )}
                  />
                </div>
              </Col>
              {
                !isPrivateJob
                ? <Col>
                    Estimated Drop Off At {helpers.requiredStar()}
                    <div>
                      <DatePicker
                        name="estimatedDropoffAt"
                        selected={scheduleJob.estimatedDropoffAt}
                        className="form-control"
                        placeholderText="Select date"
                        onChange={(e) =>
                          setScheduleJob({ ...scheduleJob, estimatedDropoffAt: e })
                        }
                        disabled={isPrivateJob}
                      />
                    </div>
                  </Col>
                : null
              }
              
            </Row>
            <Row className="mb-2">
              <Col xs="6">
                Estimated Pickup Time of Day{" "}
                <EstimatedTimeOfDay
                  value={scheduleJob.estimatedPickupTimeOfDay || ""}
                  onChange={(e) =>
                    setScheduleJob({
                      ...scheduleJob,
                      estimatedPickupTimeOfDay: e.target.value,
                    })
                  }
                />
              </Col>
              {
                !isPrivateJob
                ? <Col xs="6">
                    Estimated Drop Off Time of Day
                    <EstimatedTimeOfDay
                      value={scheduleJob.estimatedDropoffTimeOfDay || ""}
                      onChange={(e) =>
                        setScheduleJob({
                          ...scheduleJob,
                          estimatedDropoffTimeOfDay: e.target.value,
                        })
                      }
                      disabled={isPrivateJob}
                    />
                  </Col>
                : null
              }
            </Row>
          </Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className="float-right">
          <Button
            size="sm"
            className="projectSecondary"
            onClick={props.toggle}
          >
            Cancel
          </Button>
          <Button size="sm" className="primary" onClick={onSchedule}>
            Submit
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}
