import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import { Col, Button, Row, Table, ButtonGroup, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import { api, constants, filterHelpers, helpers, dateHelpers } from "../utils";
import {
  DateFilter,
  FilterableScreen,
  FilterSet,
  Pager,
  FilterMultiSelect,
  SHBSpinner,
} from "../components";
import { DesktopContainer } from "../layouts";

export default function Invoices(props) {
  const [loading, setLoading] = useState(false);
  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [orgList, setOrgList] = useState([]);

  useEffect(refreshData, [filters, pageNumber]);

  useEffect(getOrgList, []);

  function refreshData() {
    setLoading(true);
    let payload = {
      PageNumber: pageNumber,
      PageSize: 25,
      MaxResults: 25,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Invoice/FindPaginatedInvoices", payload)
      .then((response) => {
        if (response && response.data) {
          setPaginatedList(response.data);
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => setLoading(false));
  }

  function getOrgList() {
    api
      .post("Org/CompanySelectList", { SortField: "NAME" })
      .then((response) => {
        if (response && response.data) {
          setOrgList(
            response.data.map((x) => {
              return {
                value: x.id,
                label: x.name,
              };
            })
          );
        }
      })
      .catch(helpers.catchHandler);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function generatePdf(invoiceId) {
    api
      .fetchWithPdfReturn(`Invoice/GetInvoicePdf/${invoiceId}`)
      .then((response) => {
        const fileName = `invoice_${invoiceId}.pdf`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response.data, fileName);
        } else {
          const file = new Blob([response.data], { type: constants.MIME_PDF });
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(file, fileName);
          } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
              var url = URL.createObjectURL(file);
              link.setAttribute("href", url);
              link.setAttribute("download", fileName);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }
      })
      .catch((e) => {
        console.error(e);
        Alert.error("There was an error loading the invoice pdf");
      });
  }

  function changeStatus(invoiceId, invoiceStatusId, deliverEmail) {
    if (
      !window.confirm(
        `Are you sure you'd like to change the status of the invoice?`
      )
    ) {
      return;
    }

    const payload = {
      InvoiceId: invoiceId,
      InvoiceStatusId: invoiceStatusId,
      DeliverEmail: !!deliverEmail,
    };

    api
      .post(`Invoice/ChangeInvoiceStatus/`, payload)
      .then(() => {
        refreshData();
      })
      .catch(() => {
        Alert.error("There was an error changing the invoice status");
      });
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Invoices"}>
                  <FilterableScreen
                    refreshData={refreshData}
                    filters={
                      <Fragment>
                        <Row>
                          <Col xs="10" className="px-0">
                            <FilterSet
                              filters={filters}
                              clearFilters={() => setFilters([])}
                            >
                              <Row>
                                <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="invoiceStatusIds"
                                    displayName="Invoice Status"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "invoiceStatusIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={constants.INVOICE_STATUSES}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="startDate"
                                    displayName="Invoice Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "startDate"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="endDate"
                                    displayName="Invoice Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "endDate"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="organizationIds"
                                    displayName="Organization"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "organizationIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={orgList}
                                  />
                                </Col>
                              </Row>
                            </FilterSet>
                          </Col>
                          <Col xs="2" className="pl-0 pr-4">
                            <ButtonGroup className="float-right mr-2">
                              <Link
                                className="btn btn-primary"
                                to="/uninvoicedlineitems"
                              >
                                Un-Invoiced Items
                              </Link>
                              <Button
                                onClick={refreshData}
                                title="Refresh"
                                color="dark"
                              >
                                <FontAwesomeIcon icon="sync" />
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    }
                    pager={
                      <div className="float-right">
                        <Pager
                          pageNumber={
                            paginatedList?.pageNumber
                              ? paginatedList.pageNumber
                              : 0
                          }
                          totalPages={
                            paginatedList?.totalPages
                              ? paginatedList.totalPages
                              : 0
                          }
                          callBack={(newPageNumber) =>
                            setPageNumber(newPageNumber)
                          }
                        />
                      </div>
                    }
                  >
                    <Row className="p-0 m-0">
                      <Col xs="12">
                        <Table striped hover size="sm" responsive={false}>
                          <thead>
                            <tr>
                              <th>Invoice #</th>
                              <th>Invoice Date</th>
                              <th>Status</th>
                              <th>For</th>
                              <th>Total</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedList?.list?.length ? (
                              _.map(paginatedList.list, (j, index) => (
                                <tr key={j.id}>
                                  <td>{j.id}</td>
                                  <td>
                                    {dateHelpers.formatDateToShortDate(
                                      j.createdAt
                                    )}
                                  </td>
                                  <td>{j.invoiceStatusName}</td>
                                  <td>{j.organizationName}</td>
                                  <td>{j.totalDisplay}</td>
                                  <td>
                                    generatePdf
                                    <ButtonGroup className="float-right">
                                      <Button
                                        size="sm"
                                        className="projectInfo minWidth85"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          generatePdf(j.id);
                                        }}
                                      >
                                        Invoice Pdf
                                      </Button>
                                      {j.invoiceStatusId ===
                                        constants.INVOICE_STATUS_IDS
                                          .pending && (
                                        <>
                                          <Button
                                            size="sm"
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .delivered,
                                                true
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon="email" />
                                            Deliver Email
                                          </Button>
                                          <Button
                                            size="sm"
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .delivered,
                                                false
                                              )
                                            }
                                          >
                                            Mark Delivered
                                          </Button>
                                          <Button
                                            size="sm"
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .cancelled,
                                                false
                                              )
                                            }
                                          >
                                            Mark Cancelled
                                          </Button>
                                        </>
                                      )}
                                      {j.invoiceStatusId ===
                                        constants.INVOICE_STATUS_IDS
                                          .delivered && (
                                        <>
                                          <Button
                                            size="sm"
                                            style={{
                                              backgroundColor: "green",
                                            }}
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .paid,
                                                false
                                              )
                                            }
                                          >
                                            Mark Paid
                                          </Button>
                                          <Button
                                            size="sm"
                                            style={{
                                              backgroundColor: "orange",
                                            }}
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .declined,
                                                false
                                              )
                                            }
                                          >
                                            Mark Declined
                                          </Button>
                                          <Button
                                            size="sm"
                                            onClick={() =>
                                              changeStatus(
                                                j.id,
                                                constants.INVOICE_STATUS_IDS
                                                  .cancelled,
                                                false
                                              )
                                            }
                                          >
                                            Mark Cancelled
                                          </Button>
                                        </>
                                      )}
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">No invoices found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </FilterableScreen>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
