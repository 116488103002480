import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import { Col, Button, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { api, filterHelpers } from "../utils";
import {
  FilterMultiSelect,
  FilterText,
  FilterableScreen,
  FilterSet,
} from "../components";
import { DesktopContainer } from "../layouts";
import Alert from "react-s-alert";

export default function Sheds(props) {
  const [filters, setFilters] = useState([]);
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [unitTypes, setUnitTypes] = useState([]);
  const [unitMaterials, setUnitMaterials] = useState([]);
  const [unitColors, setUnitColors] = useState([]);

  function refreshData() {
    let data = {
      PageNumber: pageNumber,
    };
    _.each(filters, (filter) => (data[filter.filterName] = filter.value));
    api
      .post("unit/ShedSightingList", data)
      .then((r) => {
        if (!r) {
          Alert.error("", "No response from server");
        }
        if (!r.data || !r.data.list) {
          Alert.error("", "Error loading list");
        }
        setPageNumber(r.data.pageNumber);
        setTotalPages(r.data.totalPages);
        if (pageNumber <= 1) {
          setList(r.data.list);
        } else {
          setList([].concat(list).concat(r.data.list));
        }
      })
      .catch((error) => {});
  }

  useEffect(refreshData, [pageNumber, filters]);

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(api.getUnitTypes());
    apiCalls.push(api.getUnitMaterials());
    apiCalls.push(api.getUnitColors());

    Promise.all(apiCalls).then((arrayResults) => {
      let aggResults = {};
      _.each(arrayResults, (x) => Object.assign(aggResults, x));
      setUnitTypes(aggResults.unitTypes);
      setUnitMaterials(aggResults.unitMaterials);
      setUnitColors(aggResults.unitColors);
    });
  }, []);

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
    setPageNumber(1);
  }

  function currentFilterValue(name) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  function openNavigationLink(oLat, oLng) {
    const scheme = "https://www.google.com/maps/dir/?api=1&parameters";
    const originLatLng = `&origin=${oLat},${oLng}`;
    // const destinationLatLng = `&destination=${dLat},${dLng}`;
    const url = `${scheme}${originLatLng}`;
    window.open(url,"_blank")
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={"Shed Sightings"}>
                <FilterableScreen
                  refreshData={refreshData}
                  filters={
                    <Fragment>
                      <Row>
                        <Col xs="10" className="px-0">
                          <FilterSet
                            filters={filters}
                            clearFilters={() => setFilters([])}
                          >
                            <Row>
                              <Col xs="4">
                                <FilterText
                                  filterName="serialNumber"
                                  displayName="Serial #"
                                  value={currentFilterValue("value")}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                              <Col xs="4">
                                <FilterMultiSelect
                                  filterName="unitTypeIds"
                                  displayName="Unit Type"
                                  values={filterHelpers.currentFilterValue(
                                    filters,
                                    "unitTypeIds"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  options={unitTypes}
                                />
                              </Col>
                              <Col xs="4">
                                <FilterMultiSelect
                                  filterName="unitMaterialIds"
                                  displayName="Unit Material"
                                  values={filterHelpers.currentFilterValue(
                                    filters,
                                    "unitMaterialIds"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  options={unitMaterials}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="3">
                                <FilterText
                                  filterName="manufacturer"
                                  displayName="Manufacturer"
                                  value={currentFilterValue("value")}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                              <Col xs="3">
                                <FilterMultiSelect
                                  filterName="wallColorIds"
                                  displayName="Wall Color"
                                  values={filterHelpers.currentFilterValue(
                                    filters,
                                    "wallColorIds"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  options={unitColors}
                                />
                              </Col>
                              <Col xs="3">
                                <FilterMultiSelect
                                  filterName="roofColorIds"
                                  displayName="Roof Color"
                                  values={filterHelpers.currentFilterValue(
                                    filters,
                                    "roofColorIds"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  options={unitColors}
                                />
                              </Col>
                              <Col xs="3">
                                <FilterMultiSelect
                                  filterName="trimColorIds"
                                  displayName="Trim Color"
                                  values={filterHelpers.currentFilterValue(
                                    filters,
                                    "trimColorIds"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  options={unitColors}
                                />
                              </Col>
                            </Row>
                          </FilterSet>
                        </Col>
                        <Col xs="2">
                          <Button
                            onClick={refreshData}
                            title="Refresh"
                            color="dark"
                            className="float-right mr-3"
                          >
                            <FontAwesomeIcon icon="sync" />
                          </Button>
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  pager={null}
                >
                  <Row className="p-0 m-0">
                    <Col xs="12">
                      <Table striped hover size="sm" responsive={false}>
                        <thead>
                          <tr>
                            <th>Serial #</th>
                            <th>Unit Type</th>
                            <th>Unit Material</th>
                            <th>Wall Color</th>
                            <th>Roof Color</th>
                            <th>Trim Color</th>
                            <th>Manufacturer</th>
                            <th>Dimensions</th>
                            <th>Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list && list.length > 0 ? (
                            _.map(list, (r, index) => (
                              <tr key={`row-${index}`}>
                                <td>
                                  <Link to={`/shed/${r.id}`}>
                                    {r.serialNumber}
                                  </Link>
                                </td>
                                <td>{r.unitTypeName}</td>
                                <td>{r.unitMaterialName}</td>
                                <td>{r.wallColorName}</td>
                                <td>{r.roofColorName}</td>
                                <td>{r.trimColorName}</td>
                                <td>{r.manufacturer}</td>
                                <td>{`${r.lengthInFeet || "-"} L X ${
                                  r.widthInFeet || "-"
                                } W X ${r.heightInFeet || "-"} H`}</td>
                                <td>
                                  <Button
                                    className="ml-2"
                                    color="link"
                                    onClick={() => openNavigationLink(
                                      r.latitude,
                                      r.longitude
                                    )}
                                    title="View location in new window"
                                  >
                                    <FontAwesomeIcon icon="search-location" />
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={"11"}>No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {totalPages > pageNumber && (
                        <button
                          className="btn btn-link"
                          onClick={() => setPageNumber(pageNumber + 1)}
                        >
                          Load more
                        </button>
                      )}
                    </Col>
                  </Row>
                </FilterableScreen>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
