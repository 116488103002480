import React from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProfileImageRender(props) {
  return (
    <Col xs="3" className="img-display">
      <Row className="center-text p-4">
        <Col>
          {props.profileImage && props.profileImage.signedURL ? (
            <img
              src={props.profileImage.signedURL}
              className="user-profile-pic rounded-circle"
              alt={`${props.name}`}
            />
          ) : (
            <FontAwesomeIcon
              icon="user-circle"
              id="userCircle"
              title="Upload company profile picture under the documents module"
            />
          )}
          <div className="text-royal bold mt-2">
            {props.isCompany ? "Company " : ""} Profile Picture
          </div>
          {!props.profileImage && (
            <div className="information-small mt-2">
              Upload company profile picture under the documents module
            </div>
          )}
        </Col>
      </Row>
    </Col>
  );
}
