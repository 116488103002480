import React, { useState, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Col, Card, CardImg, Row } from "reactstrap";
import { api } from "../utils";
import { CollapsibleCardSection } from "../components";
import { DesktopContainer } from "../layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var chance = require("chance").Chance();

export default function PublicProfile(props) {
  const userId =
    props &&
    props.computedMatch &&
    props.computedMatch.params &&
    props.computedMatch.params.id &&
    parseInt(props.computedMatch.params.id, 10);
  const [user, setUser] = useState(null);

  useEffect(() => {
    api
      .fetch(`useradmin/user/${userId}`)
      .then((response) => {
        if (response?.data) {
          setUser(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId]);

  const profileName = user !== null ? `${user.firstName} ${user.lastName}` : "";

  function showStarRating() {
    return (
      <span title="5 Star Rating">
        <FontAwesomeIcon icon="star" className="text-warning" />
        <FontAwesomeIcon icon="star" className="text-warning" />
        <FontAwesomeIcon icon="star" className="text-warning" />
        <FontAwesomeIcon icon="star" className="text-warning" />
        <FontAwesomeIcon icon="star" className="text-warning" />
      </span>
    );
  }
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={profileName}>
                {user && (
                  <Row>
                    <Col xs="6" className="mx-0 px-0">
                      <CollapsibleCardSection
                        cardName={"About"}
                        alwaysOpen={true}
                        cardBody={
                          <Fragment>
                            <Row className="mb-2 align-items-end">
                              <Col xs="12">
                                <Row className="mb-2">
                                  <Col>{showStarRating()}</Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs="12">
                                    <a href={`mailto:${user.email}`}>
                                      {user.email}
                                    </a>
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs="12">
                                    <a
                                      href={`tel:${
                                        user.phoneNumber ||
                                        user.driverPhoneNumber
                                      }`}
                                    >
                                      {user.phoneNumber ||
                                        user.driverPhoneNumber}
                                    </a>
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col xs="12">
                                    {user.tagLine ||
                                      "No data available for this user."}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Fragment>
                        }
                      />
                    </Col>
                    <Col>
                      <Card>
                        <CardImg
                          top
                          width="100%"
                          style={{ maxHeight: `400px` }}
                          src={`https://source.unsplash.com/1600x900/?${chance.animal()}`}
                          alt={`${user.firstName} ${user.lastName} ProfileImage`}
                        />
                      </Card>
                    </Col>
                  </Row>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
