import React, { useState, useEffect } from "react";
import { Media } from "react-breakpoints";
import { Button, Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import { api, dateHelpers } from "../utils";
import { DesktopContainer } from "../layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Shed(props) {
  const shedSightingId = props?.computedMatch?.params?.id
    ? parseInt(props.computedMatch.params.id, 10)
    : 0;

  const [shedSighting, setShedSighting] = useState(null);

  useEffect(() => {
    api.fetch(`Unit/GetShedSighting/${shedSightingId}`).then((response) => {
      setShedSighting(response.data);
    });
  }, [shedSightingId]);

  function openNavigationLink(oLat, oLng) {
    const scheme = "https://www.google.com/maps/dir/?api=1&parameters";
    const originLatLng = `&origin=${oLat},${oLng}`;
    // const destinationLatLng = `&destination=${dLat},${dLng}`;
    const url = `${scheme}${originLatLng}`;
    window.open(url,"_blank")
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={`Shed Sighting Details`}>
                {shedSighting && (
                  <>
                    <Row className="mb-4">
                      <Col xs="6">
                        <Row>
                          <Col>
                            <Card>
                              <CardHeader>
                                <Row>
                                  <Col>
                                    <h5>Shed Information</h5>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <Row className="mb-1">
                                  <Col>
                                    Serial #: {shedSighting.serialNumber}
                                  </Col>
                                  <Col>Type: {shedSighting.unitTypeName}</Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col>
                                    Material: {shedSighting.unitMaterialName}
                                  </Col>
                                  <Col>
                                    Wall Color: {shedSighting.wallColorName}
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col>
                                    Trim Color: {shedSighting.trimColorName}
                                  </Col>
                                  <Col>
                                    Roof Color: {shedSighting.roofColorName}
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col>
                                    Entered At:{" "}
                                    {dateHelpers.dateTimeFormat(
                                      shedSighting.createdAt
                                    )}
                                  </Col>
                                  <Col>
                                    Length (ft): {shedSighting.lengthInFeet}
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col>
                                    Width (ft): {shedSighting.widthInFeet}
                                  </Col>
                                  <Col>
                                    Height (ft): {shedSighting.heightInFeet}
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs="12">
                                    Comments: {shedSighting.comment}
                                  </Col>
                                </Row>
                                <Row  className="mb-3">
                                  <Col xs="12">
                                    Location: 
                                      <Button
                                        className="ml-2"
                                        color="link"
                                        onClick={() => openNavigationLink(
                                          shedSighting.latitude,
                                          shedSighting.longitude
                                        )}
                                        title="View location in new window"
                                      >
                                        <FontAwesomeIcon icon="search-location" />
                                      </Button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="6">
                        <Row>
                          <Col>
                            <Card>
                              <CardHeader>
                                <Row>
                                  <Col>
                                    <h5>Images</h5>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                {shedSighting.images &&
                                  shedSighting.images.map((x, index) => (
                                    <Row key={`image-${index}`}>
                                      <Col>{x.documentTypeName}</Col>
                                      <Col>
                                        <a
                                          href={x.signedURL}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          View
                                        </a>
                                      </Col>
                                    </Row>
                                  ))}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
