import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  Table,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { api, helpers, UserContext, filterHelpers, constants } from "../utils";
import {
  FilterText,
  FilterableScreen,
  FilterSet,
  FilterSwitch,
  Pager,
  RatingModule,
} from "../components";
import { DesktopContainer } from "../layouts";
import Alert from "react-s-alert";

export default function Organizations(props) {
  const moduleAccess = props?.computedMatch?.params?.module;
  const orgType = props?.computedMatch?.params?.orgType;
  const userCtx = useContext(UserContext);
  const isAdmin =
    moduleAccess === "admin" && userCtx.currentUser.includeAdminModule;
  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
    { filterName: "CurrentOrgId", value: userCtx?.currentUser?.currentOrgId },
  ]);
  const [pageNumber, setPageNumber] = useState(1);
  const orgTypeId =
    props.computedMatch.params && props.computedMatch.params.orgTypeId
      ? props.computedMatch.params.orgTypeId
      : props.location && props.location.state && props.location.state.orgTypeId
      ? props.location.state.orgTypeId
      : null;

  useEffect(refreshData, [
    filters,
    pageNumber,
    orgType,
    moduleAccess,
    orgTypeId,
  ]);

  function refreshData() {
    let payload = {
      sortField: null,
      sortDirection: null,
      pageNumber: pageNumber,
    };
    if (orgType === "haulerFirms" || orgType === "haulerFirm") {
      payload.OrgTypeId = constants.ORG_TYPE_IDS.HAULER_FIRM;
    } else {
      if (orgTypeId) {
        payload.OrgTypeId = orgTypeId;
      } else {
        payload.ExceptOrgTypeId = constants.ORG_TYPE_IDS.HAULER_FIRM;
      }
    }
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Org/OrgList", payload)
      .then((response) => {
        setPaginatedList(response.data);
      })
      .catch(helpers.catchHandler);
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function currentFilterValue(name) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  function saveOrgAccess(index, allowAccess) {
    let editList = Object.assign([], paginatedList.list);
    let payload = {
      OrgId: userCtx.currentUser.orgId,
      PartnerOrgId: editList[index].id,
      IsAllowAccess: allowAccess,
    };

    api
      .post("org/SaveOrgAccess", payload)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success(
            `Company has been ${
              allowAccess ? "added to allow list" : "blocked"
            }.`
          );
          refreshData();
        } else {
          Alert.error(response.data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert(`There was an error performing this operation.`);
        } else {
          console.error(error);
        }
      });
  }

  function deleteOrgAccess(index, allowAccess) {
    let editList = Object.assign([], paginatedList.list);

    let payload = {
      OrgId: userCtx.currentUser.orgId,
      PartnerOrgId: editList[index].id,
      IsAllowAccess: allowAccess,
    };

    api
      .post("org/DeleteOrgAccess", payload)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success(
            `Company has been ${
              allowAccess
                ? "removed from allow list"
                : "removed from blocked list"
            }.`
          );
          refreshData();
        } else {
          Alert.error(response.data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        if (props.showError) {
          Alert(`There was an error performing this operation.`);
        } else {
          console.error(error);
        }
      });
  }
  const routePath =
    orgType === "companies" ? "companyProfile" : "haulerFirmProfile";
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer
                screenName={
                  orgType === "haulerFirms" || orgType === "haulerFirm"
                    ? "Hauler Firms"
                    : orgTypeId
                    ? `${
                        orgTypeId === constants.ORG_TYPE_IDS.RTO.toString()
                          ? constants.ORG_TYPE_NAMES.RTO
                          : constants.ORG_TYPE_NAMES.MANUFACTURER
                      }s`
                    : "Companies"
                }
              >
                <FilterableScreen
                  filters={
                    <Fragment>
                      <Row>
                        <Col xs="10" className="px-0">
                          <FilterSet
                            filters={filters}
                            clearFilters={() => setFilters([])}
                          >
                            <Row>
                              <Col xs="4">
                                <FilterText
                                  filterName="Name"
                                  displayName="Name"
                                  value={currentFilterValue("Name")}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                              <Col xs="3">
                                <FilterSwitch
                                  filterName="ActiveOnly"
                                  displayName="Active Only"
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "ActiveOnly"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                            </Row>
                          </FilterSet>
                        </Col>
                        <Col xs="2" className="">
                          <Button
                            onClick={refreshData}
                            title="Refresh"
                            color="dark"
                            className="float-right mr-3"
                          >
                            <FontAwesomeIcon icon="sync" />
                          </Button>
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  //pager={null} //(<div className="float-right">PAGER</div>)
                  pager={
                    <div className="float-right">
                      <Pager
                        pageNumber={
                          paginatedList?.pageNumber
                            ? paginatedList.pageNumber
                            : 0
                        }
                        totalPages={
                          paginatedList?.totalPages
                            ? paginatedList.totalPages
                            : 0
                        }
                        callBack={(newPageNumber) =>
                          setPageNumber(newPageNumber)
                        }
                      />
                    </div>
                  }
                >
                  <Row className="p-0 m-0">
                    <Col xs="12">
                      <Table
                        striped
                        hover
                        size="sm"
                        responsive={false}
                        id="userTable"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>State</th>
                            <th>Type</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Phone</th>
                            {isAdmin && (
                              <Fragment>
                                <th>Registered</th>
                                <th width="20%"></th>
                              </Fragment>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedList.list &&
                          paginatedList.list.length > 0 ? (
                            _.map(paginatedList.list, (u, index) => {
                              return isAdmin ||
                                u.status !== "Pending Registration" ? (
                                <tr key={u.id}>
                                  <td>
                                    <div>
                                      <Link to={`/${routePath}/${u.id}`}>
                                        {u.companyName}
                                      </Link>
                                    </div>
                                    <div>
                                      <RatingModule
                                        showRatingOnly={true}
                                        score={u.orgTypeId == constants.ORG_TYPE_IDS.HAULER_FIRM
                                          ? u.haulerRatingScore || 0
                                          : u.companyRatingScore || 0
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>{u.stateName}</td>
                                  <td>{u.orgTypeName}</td>
                                  <td>{u.primaryUserFullName}</td>
                                  <td>
                                    {u.primaryUserEmail || u.companyEmail}
                                  </td>
                                  <td>
                                    {u.primaryUserPhoneNumber ||
                                      u.companyPhoneNumber}
                                  </td>
                                  {isAdmin ? (
                                    <Fragment>
                                      <td>
                                        {u.status === "Pending Registration" ? (
                                          <FontAwesomeIcon
                                            icon="hourglass-half"
                                            className="primaryText"
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon="check"
                                            className="successText"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {u.deactivatedAt ? (
                                          <Button
                                            size="sm"
                                            className="projectInfo minWidth105 float-right"
                                            // onClick={() => this.toggleActive(u, "activate")}
                                          >
                                            <FontAwesomeIcon icon="recycle" />{" "}
                                            Revive
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            className="projectDanger minWidth105 float-right"
                                            // onClick={() => this.toggleActive(u, "deactivate")}
                                          >
                                            <FontAwesomeIcon icon="times-circle" />{" "}
                                            Deactivate
                                          </Button>
                                        )}
                                      </td>
                                    </Fragment>
                                  ) : (
                                    <td>
                                      <ButtonGroup className="float-right">
                                        {u.allowedAccessAt && u.deniedAccessAt && (
                                          <Fragment>
                                            <FontAwesomeIcon
                                              icon="info-circle"
                                              className="mr-2 text-warning"
                                              size="2x"
                                              id={`${u.companyName}-${index}`}
                                            />
                                            <UncontrolledTooltip
                                              placement="left"
                                              target={`${u.companyName}-${index}`}
                                            >
                                              <span>
                                                {u.companyName} is currently
                                                listed in the allow and deny
                                                list for your company. In this
                                                case your denied list will be
                                                respected and {u.companyName}{" "}
                                                will NOT have access to jobs
                                                posted by your company.
                                              </span>
                                            </UncontrolledTooltip>
                                          </Fragment>
                                        )}
                                        {u.allowedAccessAt ? (
                                          <Button
                                            size="sm"
                                            className="projectSuccessOutline minWidth105 float-right"
                                            onClick={() =>
                                              deleteOrgAccess(index, true)
                                            }
                                            title={`Remove ${u.companyName} from having exclusive access to available jobs for your organization`}
                                          >
                                            <FontAwesomeIcon icon="undo" />{" "}
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            className="projectSuccess minWidth105 float-right"
                                            onClick={() =>
                                              saveOrgAccess(index, true)
                                            }
                                            title={`Grant ${u.companyName} exclusive access to available jobs for your organization`}
                                            disable={u.allowAccessAt}
                                          >
                                            <FontAwesomeIcon icon="check-double" />{" "}
                                          </Button>
                                        )}

                                        {u.deniedAccessAt ? (
                                          <Button
                                            size="sm"
                                            className="projectDangerOutline minWidth105 float-right"
                                            onClick={() =>
                                              deleteOrgAccess(index, false)
                                            }
                                            title={`UnBlock ${u.companyName}`}
                                          >
                                            <FontAwesomeIcon
                                              icon="undo"
                                              color="danger"
                                            />{" "}
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            className="projectDanger minWidth105 float-right"
                                            onClick={() =>
                                              saveOrgAccess(index, false)
                                            }
                                            title={`Block ${u.companyName} from working with your organization`}
                                            disable={u.deniedAccessAt}
                                          >
                                            <FontAwesomeIcon icon="ban" />{" "}
                                          </Button>
                                        )}
                                      </ButtonGroup>
                                    </td>
                                  )}
                                </tr>
                              ) : null;
                            })
                          ) : (
                            <tr>
                              <td colSpan={isAdmin ? "9" : "6"}>
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </FilterableScreen>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
