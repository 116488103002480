import React, { Fragment, useState, useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Col,
  Button,
  Row,
  Table,
  ButtonGroup,
  Alert as Alert2,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import {
  api,
  constants,
  UserContext,
  filterHelpers,
  helpers,
  dateHelpers,
} from "../utils";
import {
  DateFilter,
  FilterText,
  FilterableScreen,
  FilterSet,
  Pager,
  FilterMultiSelect,
  FilterSwitch,
  RatingModule,
  ScheduleModal,
  SHBSpinner,
} from "../components";
import { DesktopContainer } from "../layouts";

const getInitialJobStatus = (jobStatusId) => {
  if (!jobStatusId) return null;

  const selectedFilter = _.find(
    constants.JOB_STATUSES,
    (x) => x.value === parseInt(jobStatusId, 10)
  );

  if (!selectedFilter) return null;

  return {
    filterName: "jobStatusIds",
    filteredDisplay: `Job Status: ${selectedFilter.label}`,
    labels: [selectedFilter.label],
    value: [selectedFilter.value],
  };
};

export default function Jobs(props) {
  const userCtx = useContext(UserContext);
  const currentUser = userCtx.currentUser;
  const isSysAdmin = currentUser.isSysAdmin;
  const isCompany = currentUser.isCompanyUser;
  const isHaulerUser = currentUser.isHaulerUser;
  const isDispatcher =
    currentUser.currentRoleId === constants.ROLE_IDS.DISPATCHER ||
    currentUser.isHaulerAdminUser;
  const isDriver = currentUser.currentRoleId === constants.ROLE_IDS.DRIVER;
  const isCompanyAdmin = currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN && isCompany;  // admin role can be hauler or company admins
  const isCompanyClerk = currentUser.currentRoleId === constants.ROLE_IDS.COMPANY_CLERK;
  const [message, setMessage] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectToJob, setRedirectToJob] = useState(null);
  const jobStatusId =
    props.computedMatch.params && props.computedMatch.params.jobStatusId
      ? props.computedMatch.params.jobStatusId
      : props.location &&
        props.location.state &&
        props.location.state.jobStatusId
      ? props.location.state.jobStatusId
      : null;

  const initialJobStatus = getInitialJobStatus(jobStatusId);

  let canAddEditJobs = isCompanyAdmin || isCompanyClerk;
  let isOpenJobSearch = false;
  if (isHaulerUser) {
    isOpenJobSearch = true;
  }

  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState(
    initialJobStatus ? [initialJobStatus] : []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const sortField = null;
  const sortDirection = null;
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [jobToRate, setJobToRate] = useState(null);
  const [jobToSchedule, setJobToSchedule] = useState(null);
  useEffect(refreshData, [
    filters,
    pageNumber,
    currentUser.currentOrgId,
    isCompany,
    isOpenJobSearch,
  ]);

  function refreshData() {
    setLoading(true);
    let payload = {
      SortField: sortField,
      SortDirection: sortDirection,
      PageNumber: pageNumber,
      IsOpenJobSearch: isOpenJobSearch,
      CurrentOrgId: currentUser.currentOrgId,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Job/List", payload)
      .then((response) => {
        setPaginatedList({
          list: _.map(response.data.list, (l) => {
            l.driver = l.driverId
              ? { label: l.driverFullName, value: l.driverId }
              : null;
            l.jobStatusId =
              l.jobStatusId === constants.JOB_STATUS_IDS.RESERVED &&
              !l.underReservationGracePeriod && !currentUser.isSysAdmin && 
              l.reservedByOrgId !== currentUser.currentOrgId &&
              !isCompany
                ? constants.JOB_STATUS_IDS.OPEN
                : l.jobStatusId;
            l.jobStatusName =
              l.jobStatusName === constants.JOB_STATUS_NAMES.RESERVED &&
              !l.underReservationGracePeriod && !currentUser.isSysAdmin && 
              l.reservedByOrgId !== currentUser.currentOrgId &&
              !isCompany
                ? constants.JOB_STATUS_NAMES.OPEN
                : l.jobStatusName;
            return l;
          }),
          pageNumber: response.data.pageNumber,
          totalPages: response.data.totalPages,
        });
      })
      .catch(helpers.catchHandler)
      .finally(() => setLoading(false));
  }

  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function currentFilterValue(name) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  function toggleCanceljob(jobId, reviving = false) {
    if (!reviving) {
      if (!window.confirm(`Are you sure you'd like to cancel this job?`))
        return;
    }
    api
      .delete(`Job/ToggleCancelJob/${jobId}`)
      .then((response) => {
        if (response.data.success) {
          refreshData();
        } else {
          if (reviving) {
            setMessage({
              text: "An error ocurred. Job cannot be revived at this time.",
              flavor: "alert-danger",
            });
          } else {
            setMessage({
              text: "An error ocurred. Job cannot be canceled at this time.",
              flavor: "alert-danger",
            });
          }
        }
      })
      .catch((error) => {
        if (reviving) {
          setMessage({
            text: "There was an error reviving this job.",
            flavor: "alert-danger",
          });
        } else {
          setMessage({
            text: "There was an error cancelling this job.",
            flavor: "alert-danger",
          });
        }
      });
  }

  function onReserve(jobId) {
    if (!window.confirm(`Are you sure you'd like to reserve this job?`)) return;
    let payload = {
      JobId: jobId,
      ReservedByOrgId: currentUser.currentOrgId,
    };
    api
      .post(`Job/ReserveJob`, payload)
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job was successfully reserved.",
            flavor: "alert-success",
          });
          refreshData();
        } else {
          setMessage({
            text: "An error ocurred. Job cannot be reserved at this time.",
            flavor: "alert-danger",
          });
        }
      })
      .catch((error) => {
        setMessage({
          text: "There was an error reserving this job.",
          flavor: "alert-danger",
        });
      });
  }

  function onUnreserve(jobId) {
    if (!window.confirm(`Are you sure you'd like to unreserve this job?`))
      return;
    api
      .post(`Job/UnreserveJob/${jobId}`)
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job was successfully unreserved.",
            flavor: "alert-success",
          });
          refreshData();
        } else {
          setMessage({
            text: "An error ocurred. Job cannot be unreserved at this time.",
            flavor: "alert-danger",
          });
        }
      })
      .catch((error) => {
        setMessage({
          text: "There was an error unreserving this job.",
          flavor: "alert-danger",
        });
      });
  }

  function onUnschedule(jobId) {
    if (!window.confirm(`Are you sure you'd like to Unschedule this job?`))
      return;
    api
      .post(`Job/UnscheduleJob/${jobId}`)
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job was successfully unscheduled.",
            flavor: "alert-success",
          });
          refreshData();
        } else {
          setMessage({
            text: "An error ocurred. Job cannot be Unscheduled at this time.",
            flavor: "alert-danger",
          });
        }
      })
      .catch((error) => {
        setMessage({
          text: "There was an error unscheduling this job.",
          flavor: "alert-danger",
        });
      });
  }

  function onSchedule(jobId) {
    const job = _.find(paginatedList.list, (l) => l.id === jobId);
    setJobToSchedule(job);
    setShowScheduleModal(true);
  }

  function onRating(jobId) {
    const job = _.find(paginatedList.list, (j) => j.id === jobId);
    setJobToRate(job);
    setShowRatingModal(true);
  }

  function onCancelRating() {
    setJobToRate(null);
    setShowRatingModal(false);
  }

  function onRatingSuccess() {
    onCancelRating();
    refreshData();
  }

  function onScheduleSuccess() {
    setJobToSchedule(null);
    setShowScheduleModal(false);
    refreshData();
  }

  function onInProgress(jobId) {
    if (
      !window.confirm(`Are you sure you'd like to set this job as in progress?`)
    )
      return;
    api
      .post(`Job/SetJobInProgressJob/${jobId}`)
      .then((response) => {
        if (response.data.success) {
          setMessage({
            text: "Job is set to in progress.",
            flavor: "alert-success",
          });
          refreshData();
        } else {
          setMessage({
            text: "An error ocurred. Job status cannot be changed at this time.",
            flavor: "alert-danger",
          });
        }
      })
      .catch((error) => {
        setMessage({
          text: "There was an error change the status for this job.",
          flavor: "alert-danger",
        });
      });
  }

  return redirectToJob ? (
    <Redirect to={`/job/${redirectToJob}`} />
  ) : (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Jobs"}>
                  {message ? (
                    <Alert2 className={message.flavor}>{message.text}</Alert2>
                  ) : null}
                  <FilterableScreen
                    refreshData={refreshData}
                    filters={
                      <Fragment>
                        <Row>
                          <Col xs="11" className="px-0">
                            <FilterSet
                              filters={filters}
                              clearFilters={() => setFilters([])}
                            >
                              <Row>
                                <Col xs="3">
                                  <FilterText
                                    filterName="idStartsWith"
                                    displayName="Job #"
                                    value={currentFilterValue("idStartsWith")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="jobTypeIds"
                                    displayName="Job Type"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "jobTypeIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={constants.JOB_TYPES}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterMultiSelect
                                    filterName="jobStatusIds"
                                    displayName="Job Status"
                                    values={filterHelpers.currentFilterValue(
                                      filters,
                                      "jobStatusIds"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                    options={constants.JOB_STATUSES}
                                  />
                                </Col>
                                {isCompany && (
                                  <Col xs="3">
                                    <FilterSwitch
                                      filterName="ActiveOnly"
                                      displayName="Active Only"
                                      value={filterHelpers.currentFilterValue(
                                        filters,
                                        "ActiveOnly"
                                      )}
                                      onChangeCallback={(e) =>
                                        onFilterChange(e)
                                      }
                                    />
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col xs="3">
                                  <FilterText
                                    filterName="fromCity"
                                    displayName="From City"
                                    value={currentFilterValue("fromCity")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="toCity"
                                    displayName="To City"
                                    value={currentFilterValue("toCity")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="fromZipCode"
                                    displayName="From Zip Code"
                                    value={currentFilterValue("fromZipCode")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="3">
                                  <FilterText
                                    filterName="toZipCode"
                                    displayName="To Zip Code"
                                    value={currentFilterValue("toZipCode")}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="requestedDateFrom"
                                    displayName="Requested Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "requestedDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="requestedDateTo"
                                    displayName="Requested Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "requestedDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="scheduledDateFrom"
                                    displayName="Scheduled Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "scheduledDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="scheduledDateTo"
                                    displayName="Scheduled Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "scheduledDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="completedDateFrom"
                                    displayName="Completed Date From"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "completedDateFrom"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                                <Col xs="2">
                                  <DateFilter
                                    filterName="completedDateTo"
                                    displayName="Completed Date To"
                                    value={filterHelpers.currentFilterValue(
                                      filters,
                                      "completedDateTo"
                                    )}
                                    onChangeCallback={(e) => onFilterChange(e)}
                                  />
                                </Col>
                              </Row>
                            </FilterSet>
                          </Col>
                          <Col xs="1" className="pl-0 pr-4">
                            <ButtonGroup className="float-right mr-2">
                              <Button
                                onClick={refreshData}
                                title="Refresh"
                                color="dark"
                              >
                                <FontAwesomeIcon icon="sync" />
                              </Button>
                              {canAddEditJobs
                                ? <Button
                                    className="projectSuccess"
                                    tag={Link}
                                    to="/job/0"
                                    title="Add New Job"
                                  >
                                    <FontAwesomeIcon icon="plus" />
                                  </Button>
                                : null
                              }
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Fragment>
                    }
                    pager={
                      <div className="float-right">
                        <Pager
                          pageNumber={
                            paginatedList?.pageNumber
                              ? paginatedList.pageNumber
                              : 0
                          }
                          totalPages={
                            paginatedList?.totalPages
                              ? paginatedList.totalPages
                              : 0
                          }
                          callBack={(newPageNumber) =>
                            setPageNumber(newPageNumber)
                          }
                        />
                      </div>
                    }
                  >
                    <Row className="p-0 m-0">
                      <Col xs="12">
                        <Table
                          striped
                          hover
                          size="sm"
                          responsive={false}
                          id="userTable"
                        >
                          <thead>
                            <tr>
                              <th>Job #</th>
                              <th width="7%"></th>
                              {/* <th>Pickup Date</th> */}
                              <th>Requested By</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Origin</th>
                              <th>Destination</th>
                              {isCompany && <th>Assigned Hauler</th>}
                              {isDispatcher && <th>Assigned Driver</th>}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedList?.list?.length ? (
                              _.map(paginatedList.list, (j, index) => (
                                <tr
                                  key={j.id}
                                  onClick={() => setRedirectToJob(j.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>
                                    {j.name}
                                  </td>
                                  <td>
                                    {j.isExtreme && (
                                      <Badge
                                        color="danger"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        Extreme
                                      </Badge>
                                    )}
                                    {j.isPrivateHaulerJob && (
                                      <Badge
                                        color="primary"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        Private
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    {dateHelpers.formatDateToShortDate(
                                      j.requestedByDate
                                    )}
                                  </td>
                                  <td>
                                    <Badge
                                      color={helpers.getJobTypeBadgeColor(
                                        j.jobTypeName
                                      )}
                                    >
                                      {j.jobTypeName}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge
                                      color={helpers.getJobStatusBadgeColor(
                                        j.jobStatusName
                                      )}
                                    >
                                      {j.jobStatusName}
                                    </Badge>
                                  </td>
                                  <td>{`${j.fromCity}, ${j.fromStateName} ${j.fromZipCode}`}</td>
                                  <td>{`${j.toCity}, ${j.toStateName} ${j.toZipCode}`}</td>
                                  {isCompany && <td>{j.reservedByOrgName}</td>}
                                  {isDispatcher && <td>{j.driverFullName}</td>}
                                  <td>
                                    <ButtonGroup className="float-right">
                                      {((isHaulerUser &&
                                        j.completedByUserId ===
                                          currentUser.id &&
                                        !j.ratedByHauler) ||
                                        (!isHaulerUser &&
                                          currentUser.currentOrgId ===
                                            j.orgId &&
                                          !j.ratedByCompany)) &&
                                        j.typeOfJobStatus ===
                                          constants.JOB_STATUS_IDS.COMPLETE && (
                                          <Button
                                            size="sm"
                                            className="projectInfo"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              onRating(j.id);
                                            }}
                                            title="Rate"
                                          >
                                            <FontAwesomeIcon icon="star-half-alt" />{" "}
                                          </Button>
                                        )}
                                      {isDispatcher &&
                                        j.typeOfJobStatus ===
                                          constants.JOB_STATUS_IDS.SCHEDULED &&
                                        j.assignedToOrgId ===
                                          currentUser.currentOrgId && (
                                          <Button
                                            size="sm"
                                            className="projectDangerOutline"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              onUnschedule(j.id);
                                            }}
                                            title="Unschedule"
                                          >
                                            <FontAwesomeIcon icon="calendar-times" />{" "}
                                          </Button>
                                        )}
                                      {isDriver &&
                                        j.typeOfJobStatus ===
                                          constants.JOB_STATUS_IDS.SCHEDULED &&
                                        j.assignedToOrgId ===
                                          currentUser.currentOrgId &&
                                        j.driver.value === currentUser.id && (
                                          <Button
                                            size="sm"
                                            className="projectSuccessOutline"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              onInProgress(j.id);
                                            }}
                                            title="Mark in progress"
                                          >
                                            <FontAwesomeIcon icon="directions" />{" "}
                                          </Button>
                                        )}
                                      {j.typeOfJobStatus ===
                                        constants.JOB_STATUS_IDS.RESERVED &&
                                        isCompany && (
                                          <Fragment>
                                            {!j.underReservationGracePeriod && (
                                              <Fragment>
                                                <FontAwesomeIcon
                                                  icon="info-circle"
                                                  color="dark"
                                                  className="mr-2"
                                                  size="2x"
                                                  id={`${
                                                    j.jobStatusName
                                                  }${j.jobTypeName
                                                    .split(" ")
                                                    .join("")}${index}`}
                                                />
                                                <UncontrolledTooltip
                                                  placement="left"
                                                  target={`${
                                                    j.jobStatusName
                                                  }${j.jobTypeName
                                                    .split(" ")
                                                    .join("")}${index}`}
                                                >
                                                  <span>
                                                    This job has not been
                                                    scheduled. The reservation
                                                    grace period for this hauler
                                                    has expired. This job can be
                                                    now be reserved by another
                                                    hauling company.
                                                  </span>
                                                </UncontrolledTooltip>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        )}
                                      {j.typeOfJobStatus ===
                                        constants.JOB_STATUS_IDS.RESERVED &&
                                        j.reservedByOrgId ===
                                          currentUser.currentOrgId && (
                                          <Fragment>
                                            {isDispatcher &&
                                              !j.underReservationGracePeriod && (
                                                <Fragment>
                                                  <FontAwesomeIcon
                                                    icon="info-circle"
                                                    color="dark"
                                                    className="mr-2"
                                                    size="2x"
                                                    id={`${
                                                      j.jobStatusName
                                                    }${j.jobTypeName
                                                      .split(" ")
                                                      .join("")}${index}`}
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="left"
                                                    target={`${
                                                      j.jobStatusName
                                                    }${j.jobTypeName
                                                      .split(" ")
                                                      .join("")}${index}`}
                                                  >
                                                    <span>
                                                      Your reservation grace
                                                      period for this job has
                                                      expired. This job can be
                                                      now be reserved by another
                                                      hauling company. A job
                                                      cannot be reserved by
                                                      another hauling company if
                                                      it is scheduled.
                                                    </span>
                                                  </UncontrolledTooltip>
                                                </Fragment>
                                              )}
                                            <Button
                                              size="sm"
                                              className="projectDark"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                onSchedule(j.id);
                                              }}
                                              title="Schedule job"
                                            >
                                              <FontAwesomeIcon icon="calendar-alt" />{" "}
                                            </Button>
                                            <Button
                                              size="sm"
                                              className="projectWarning"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                onUnreserve(j.id);
                                              }}
                                              title="Unreserve"
                                            >
                                              <FontAwesomeIcon icon="reply" />{" "}
                                            </Button>
                                          </Fragment>
                                        )}
                                      {isDispatcher &&
                                        !isDriver &&
                                        ((!j.underReservationGracePeriod &&
                                          j.typeOfJobStatus ===
                                            constants.JOB_STATUS_IDS.RESERVED &&
                                          j.reservedByOrgId !==
                                            currentUser.currentOrgId) ||
                                          j.typeOfJobStatus ===
                                            constants.JOB_STATUS_IDS.OPEN) && (
                                          <Button
                                            size="sm"
                                            className="projectSuccess"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              onReserve(j.id);
                                            }}
                                            title="Reserve"
                                          >
                                            <FontAwesomeIcon icon="check-circle" />{" "}
                                          </Button>
                                        )}
                                      {(isHaulerUser || isDriver) &&
                                        j.typeOfJobStatus ===
                                          constants.JOB_STATUS_IDS.IN_PROGRESS &&
                                        j.assignedToOrgId ===
                                          currentUser.currentOrgId && (
                                          <Fragment>
                                            <Link
                                              to={{
                                                pathname: `/job/${j.id}`,
                                                state: { completingjob: true },
                                              }}
                                            >
                                              <Button
                                                size="sm"
                                                style={{backgroundColor: "#180375"}}
                                                title="Complete"
                                              >
                                                <FontAwesomeIcon icon="flag-checkered" />{" "}
                                              </Button>
                                            </Link>
                                          </Fragment>
                                        )}
                                      {canAddEditJobs 
                                        ? <Fragment>
                                            <Link to={`/job/${j.id}`}>
                                              <Button
                                                size="sm"
                                                className="projectPrimary"
                                                disabled={
                                                  !j.jobStatusName === "Open"
                                                }
                                                title="Edit"
                                              >
                                                <FontAwesomeIcon icon="edit" />{" "}
                                              </Button>
                                            </Link>
                                            {j.cancelledAt ? (
                                              <Button
                                                size="sm"
                                                style={{backgroundColor: "#10aa7b"}}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  toggleCanceljob(j.id, true);
                                                }}
                                                title="Revive"
                                              >
                                                <FontAwesomeIcon icon="recycle" />{" "}
                                              </Button>
                                            ) : (
                                              <Fragment>
                                                {j.typeOfJobStatus !==
                                                  constants.JOB_STATUS_IDS
                                                    .COMPLETE && (
                                                  <Button
                                                    size="sm"
                                                    className="projectDanger"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      toggleCanceljob(j.id);
                                                    }}
                                                    title="Cancel"
                                                  >
                                                    <FontAwesomeIcon icon="times-circle" />{" "}
                                                  </Button>
                                                )}
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        : null
                                      }
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9">No records found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                      {showRatingModal && (
                        <RatingModule
                          showModal={showRatingModal}
                          toggle={() => setShowRatingModal(!showRatingModal)}
                          job={jobToRate}
                          forCompany={isHaulerUser}
                          onSuccess={() => onRatingSuccess()}
                        />
                      )}
                      {showScheduleModal && jobToSchedule && (
                        <ScheduleModal
                          showModal={showScheduleModal}
                          toggle={() =>
                            setShowScheduleModal(!showScheduleModal)
                          }
                          job={jobToSchedule}
                          onSuccess={() => onScheduleSuccess()}
                        />
                      )}
                    </Row>
                  </FilterableScreen>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
