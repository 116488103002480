import React from "react";
import moment from "moment";
import { Input } from "reactstrap";

const DateQuickSelect = (props) => {
  const changeQuickSelect = (quickSelectType) => {
    switch (quickSelectType) {
      case "lastweek":
        props.setStartDate &&
          props.setStartDate(moment().subtract(1, "weeks").startOf("isoWeek"));
        props.setEndDate &&
          props.setEndDate(moment().subtract(1, "weeks").endOf("isoWeek"));
        break;
      case "weektodate":
        props.setStartDate && props.setStartDate(moment().startOf("isoWeek"));
        props.setEndDate && props.setEndDate(moment().startOf("day"));
        break;
      case "lastmonth":
        props.setStartDate &&
          props.setStartDate(moment().subtract(1, "months").startOf("month"));
        props.setEndDate &&
          props.setEndDate(moment().subtract(1, "months").endOf("month"));
        break;
      case "monthtodate":
        props.setStartDate &&
          props.setStartDate(moment().subtract(0, "months").startOf("month"));
        props.setEndDate && props.setEndDate(moment().startOf("day"));
        break;
      case "lastyear":
        props.setStartDate &&
          props.setStartDate(moment().subtract(1, "years").startOf("year"));
        props.setEndDate &&
          props.setEndDate(moment().subtract(1, "years").endOf("year"));
        break;
      case "yeartodate":
        props.setStartDate &&
          props.setStartDate(moment().subtract(0, "years").startOf("year"));
        props.setEndDate && props.setEndDate(moment().startOf("day"));
        break;
      default:
        return;
    }
  };

  return (
    <Input
      onChange={(e) => changeQuickSelect(e.target.value)}
      type="select"
      value=""
      style={props.style || { width: "15px", float: "right" }}
    >
      <option>-- Date Quick Select --</option>
      <option value="lastweek">Last Week</option>
      <option value="weektodate">Week To Date</option>
      <option value="lastmonth">Last Month</option>
      <option value="monthtodate">Month To Date</option>
      <option value="lastyear">Last Year</option>
      <option value="yeartodate">Year To Date</option>
    </Input>
  );
};

export default DateQuickSelect;
