import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Button, Row, ButtonGroup, Table } from "reactstrap";
import { api, helpers } from "../utils";
import { CollapsibleCardSection } from ".";
import Alert from "react-s-alert";

export default function EditUnitType(props) {
  const [organizationUnitTypes, setOrganizationUnitTypes] = useState(
    props.companyUnitTypes ? props.companyUnitTypes : []
  );
  const [unitTypeList, setUnitTypeList] = useState([]);
  const org = props.organization ? props.organization : {};
  const registrationMode = props.registrationMode;
  const setOrganization = props.setOrganization;

  useEffect(() => {
    api
      .fetch("public/UnitTypeList")
      .then((response) => {
        if (response.data && response.data.length) {
          setUnitTypeList(
            _.map(response.data, (d) => {
              d.label = d.name;
              d.value = d.id;
              return d;
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (registrationMode) {
      setOrganization(organizationUnitTypes);
    }
  }, [organizationUnitTypes, registrationMode]);

  useEffect(() => {
    setOrganizationUnitTypes(props.companyUnitTypes);
  }, [props.companyUnitTypes]);

  function deleteUnitType(index) {
    const newList = Object.assign([], organizationUnitTypes);
    if (!props.registrationMode) {
      if (!window.confirm("Are you sure you want to delete this unit type?"))
        return;
    }

    newList.splice(index, 1);
    setOrganizationUnitTypes(newList);

    if (!props.registrationMode) {
      saveUnit();
    }
  }

  function saveUnit() {
    let payload = {
      Organization: {
        Id: org.id,
        Name: org.name,
        Email: org.email,
        PhoneNumber: org.phoneNumber,
        AboutUs: org.aboutUs,
        EIN: org.einNumber,
        AlternateEIN: org.alternateEINNumber,
        TypeOfCompany: org.typeOfCompany,
      },
      CompanyUnitTypes:
        props.companyUnitTypes && props.companyUnitTypes.length
          ? _.map(props.companyUnitTypes, (r) => {
              return {
                Id: r.value,
                Name: r.label,
              };
            })
          : [],
    };
    api
      .post(`useradmin/SaveOrgDetails`, payload)
      .then((response) => {
        if (response.data.success) {
          Alert.success("Your profile has been updated.");
          return;
        } else {
          Alert.error(response.data.message || "An error occurred.");
          return;
        }
      })
      .catch(helpers.catchHandler);
  }

  function addUnitType(selection) {
    let list = Object.assign([], organizationUnitTypes);
    let existingType = _.find(list, (l) => {
      return l.value === selection.value;
    });
    if (existingType !== undefined) return;

    list.push(selection);
    setOrganizationUnitTypes(list);
    saveUnit();
  }

  return (
    <Fragment>
      <Row className="mt-2">
        <Col>
          <CollapsibleCardSection
            cardName={"Unit Types"}
            cardBody={
              <Row className="p-2">
                <Col>
                  {props.registrationMode ? (
                    <Fragment>
                      {/* <div><i>At least one is required{helpers.requiredStar()}</i></div> */}
                      <Row className="mb-2 align-items-end">
                        <Col xs="12">
                          <Select
                            closeMenuOnSelect={true}
                            isMulti
                            components={makeAnimated()}
                            options={unitTypeList}
                            onChange={(selection) =>
                              setOrganizationUnitTypes(selection)
                            }
                            value={organizationUnitTypes}
                            classNamePrefix="react-select"
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    <Row>
                      <Col xs="12">
                        <div>
                          <Table>
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th width="30%">
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={makeAnimated()}
                                    options={unitTypeList}
                                    onChange={(selection) =>
                                      addUnitType(selection)
                                    }
                                    value={null}
                                    classNamePrefix="react-select"
                                    placeholder="Select to Add"
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {organizationUnitTypes &&
                              organizationUnitTypes.length > 0
                                ? organizationUnitTypes.map((type, index) => (
                                    <tr key={`unitType-${index}`}>
                                      <td>{type.label}</td>
                                      <td>
                                        <ButtonGroup className="float-right">
                                          {/*<Button size="sm" className="primary" onClick={ }><FontAwesomeIcon icon="edit" /></Button>*/}
                                          <Button
                                            size="sm"
                                            className="danger"
                                            onClick={() =>
                                              deleteUnitType(index)
                                            }
                                          >
                                            <FontAwesomeIcon icon="times" />
                                          </Button>
                                        </ButtonGroup>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
}
