import React, { useState, useContext, useEffect } from "react";
import { Alert, Form, Row, Col, Container, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { api, helpers, UserContext } from "../utils";
import { useAlert } from "../hooks/useAlert";
import SHBLogo from "../components/images/shb_logo.jpeg";

export default function SignIn(props) {
  const userCtx = useContext(UserContext);
  const [username, setUserName] = useState(
    localStorage.getItem("lastUsername") || ""
  );
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useAlert(null);

  useEffect(() => {
    if (userCtx) {
      userCtx.setTheme("lightTheme");
    }
  }, [userCtx]);

  function handleUsername(e) {
    setUserName(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    api
      .post("public/authenticate", {
        Username: username,
        Password: password,
      })
      .then((response) => {
        if (response.data.success) {
          setAlert({ flavor: "alert-success", text: "Log-In Successful!" });
          userCtx.signIn(response.data.user);
        } else {
          setAlert({ flavor: "alert-danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  return (
    <Container>
      {alert ? (
        <Row className="mb-2">
          <Col>
            <Alert className={alert.flavor}>{alert.text}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row className="mt-5 align-items-center">
        <Col md="6" xs="12">
          <Row
            style={{
              backgroundColor: "#FFF",
              borderRadius: "8px",
              padding: "16px 20px",
            }}
          >
            <Col sm="12">
              <Row>
                <Col sm="12" className="text-center">
                  <h4>
                    <strong>Sign In</strong>
                  </h4>
                </Col>
              </Row>
              <Row className="minHeight225 mb-4">
                <Col xs="12">
                  <Form id="sign_in_form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Username</label>
                      <input
                        className="form-control"
                        autoFocus
                        type="text"
                        style={{ marginTop: "0" }}
                        value={username}
                        onChange={handleUsername}
                        placeholder="Enter username"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        type="password"
                        style={{ marginTop: "0" }}
                        value={password}
                        onChange={handlePassword}
                        placeholder="Enter password"
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        className="projectPrimary float-right"
                        style={{ marginTop: "0" }}
                      >
                        Sign In
                      </Button>
                    </div>
                    <div className="text-left mt-2">
                      <Link
                        to={{
                          pathname: "/forgot_password",
                          state: { email: username },
                          push: true,
                        }}
                      >
                        Misplaced your password?
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="text-center">
                  <h4>
                    <strong>Register</strong>
                  </h4>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="6" className="px-1">
                  <Button
                    type="submit"
                    className="projectPrimary float-right px-1"
                    outline
                    block
                    tag={Link}
                    to="/haulerRegistration"
                    //to="/register/haulerFirm"
                  >
                    <h5>
                      <strong>Shed Haulers</strong>
                    </h5>
                    <br />
                    Sign Up Here
                  </Button>
                </Col>
                <Col xs="6" className="px-1">
                  <Button
                    type="submit"
                    className="projectPrimary float-right px-1"
                    outline
                    block
                    tag={Link}
                    to="/register/company"
                  >
                    <h5>
                      <strong>RTOs &amp; Manufacturers</strong>
                    </h5>
                    <br />
                    Sign Up Here
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="6" className="text-center">
          <img id="SHBLogo" src={SHBLogo} alt="SHB Logo" width="500" />
        </Col>
      </Row>
    </Container>
  );
}
