import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Media } from "react-breakpoints";
import {
  Card,
  CardBody,
  Col,
  Button,
  Row,
  ButtonGroup,
  Table,
  Input,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "react-s-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  api,
  helpers,
  UserContext,
  filterHelpers,
  constants,
  dateHelpers,
} from "../utils";
import _ from "lodash";
import {
  FilterText,
  FilterableScreen,
  FilterSet,
  FilterSwitch,
  Pager,
  FilterSelect,
} from "../components";
import { DesktopContainer } from "../layouts";

export default function Subscriptions(props) {
  const userCtx = useContext(UserContext);
  const statusList = constants.SUBSCRIPTION_STATUSES;
  const [subscriptionTypeList, setSubscriptionTypeList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [prestineSubscription, setPrestineSubscription] = useState(null);
  const [paginatedList, setPaginatedList] = useState({
    list: [],
    pageNumber: 1,
    totalPages: 1,
  });
  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
  ]);
  const [pageNumber, setPageNumber] = useState(1);

  const getSubscriptionTypes = () =>
    api.fetch("public/SubscriptionTypeList").then((r) => ({
      subscriptionTypeList: _.map(r.data, (d) => {
        return { ...d, label: d.name, value: d.id };
      }),
    }));

  const getOrgList = () =>
    api
      .post("org/CompanySelectList", {
        ActiveOnly: true,
        OrgTypeId: constants.ORG_TYPE_IDS.HAULER_FIRM,
        HasSubscription: false,
      })
      .then((r) => ({
        orgList: _.map(r.data, (d) => {
          return { ...d, label: d.name, value: d.id };
        }),
      }));

  useEffect(() => {
    let apiCalls = [];
    apiCalls.push(getSubscriptionTypes(), getOrgList());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        setSubscriptionTypeList(aggResults.subscriptionTypeList);
        setOrgList(aggResults.orgList);
      })
      .catch((error) =>
        Alert.error("There was an error loading reference data")
      );
  }, [userCtx.currentUser]);

  useEffect(refreshData, [filters, pageNumber]);

  function refreshData() {
    let payload = {
      sortField: null,
      sortDirection: null,
      pageNumber: pageNumber,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    api
      .post("Admin/OrgSubscriptions", payload)
      .then((response) => {
        let plist = response.data;
        plist.list = _.map(plist.list, (o) => {
          o.org = { value: o.orgId, label: o.orgName, orgTypeId: o.orgTypeId };
          o.subscriptionType = {
            value: o.subscriptionTypeId,
            label: o.subscriptionTypeName,
          };
          o.endDate = new Date(dateHelpers.dateTimeFormat(o.endDate));
          o.startDate = new Date(dateHelpers.dateTimeFormat(o.startDate));
          o.editing = false;
          o.numberOfMemberList = _.map(o.numberOfMemberList, (n) => {
            n.role = helpers.resolveValue(null, n.roleId, n.roleName);
            return n;
          });

          return o;
        });
        setPaginatedList(plist);
      })
      .catch(helpers.catchHandler);
  }

  function refreshOrgSelectList() {
    let apiCalls = [];
    apiCalls.push(getOrgList());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        setOrgList(aggResults.orgList);
      })
      .catch((error) =>
        Alert.error("There was an error loading reference data")
      );
  }
  function onFilterChange(changedFilter) {
    const filterChanges = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterChanges);
  }

  function currentFilterValue(name) {
    const filterElement = _.find(filters, (f) => f.filterName === name);
    if (filterElement) {
      if (filterElement.value) {
        return filterElement.value;
      }
      return filterElement.values;
    }
    return "";
  }

  function onInputChange(e, index, fieldName) {
    let pList = Object.assign({}, paginatedList);
    pList.list[index][fieldName] = e && e.target ? e.target.value : e;
    setPaginatedList(pList);
  }

  function addNew() {
    let pList = Object.assign({}, paginatedList);
    pList.list.unshift(Object.assign({}, constants.EMPTY_ORG_SUBSCRIPTION));
    setPaginatedList(pList);
  }

  function onCancel(index) {
    let pList = Object.assign({}, paginatedList);
    if (pList.list[index].id < 0) {
      pList.list = _.reject(pList.list, (l, indx) => {
        return indx === index;
      });
    } else {
      pList.list[index] = prestineSubscription;
      pList.list[index].editing = false;
    }
    setPaginatedList(pList);
    setPrestineSubscription(null);
    refreshData();
  }

  function onEdit(index) {
    let pList = Object.assign({}, paginatedList);
    let prestine = Object.assign({}, pList.list[index]);

    setPrestineSubscription(prestine);
    pList.list[index].editing = true;
    setPaginatedList(pList);
  }

  function onRevive(index) {
    let plist = Object.assign({}, paginatedList);
    let item = plist.list[index];
    api
      .post(`admin/ToggleOrgSubscriptionActive/${item.id}`)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success("Subscription has been revived");
          refreshData();
        } else {
          Alert.error(response.data.message || "An error occurred");
        }
      })
      .catch((error) =>
        Alert.error("There was an error deleting subscription")
      );
  }

  function onDelete(index) {
    if (!window.confirm("Are you sure you want to delete this subscription?"))
      return;
    let plist = Object.assign({}, paginatedList);
    let item = plist.list[index];
    api
      .post(`admin/ToggleOrgSubscriptionActive/${item.id}`)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success("Subscription has been deleted");
          refreshData();
        } else {
          Alert.error(response.data.message || "An error occurred");
        }
      })
      .catch((error) =>
        Alert.error("There was an error deleting subscription")
      );
  }
  function onSave(index) {
    let plist = Object.assign({}, paginatedList);
    let item = plist.list[index];
    if (!item.org) {
      Alert.warning("Company is required.");
    }
    if (!item.subscriptionType) {
      Alert.warning("Subscription Type is required.");
    }
    if (!item.startDate) {
      Alert.warning("Start Date is required.");
    }
    if (!item.endDate) {
      Alert.warning("End Date is required.");
    }
    if (item.startDate && item.endDate && item.startDate > item.endDate) {
      Alert.warning("Please enter a valid start and end date range.");
    }
    if (item.numOfMember && !item.numOfMember.length) {
      Alert.warning(
        "Please enter the allotted number of member and roles available for this subscription"
      );
    }
    if (
      _.find(item.numberOfMemberList, (n) => {
        return n.role.value === constants.ROLE_IDS.COMPANY_ADMIN;
      }) === undefined
    ) {
      item.numberOfMemberList.push({
        id: -1,
        role: {
          value: constants.ROLE_IDS.COMPANY_ADMIN,
          label: constants.ROLE_NAMES.COMPANY_ADMIN,
        },
        amount: 1,
      });
    }
    let payload = {
      Id: item.id,
      OrgId: item.org ? item.org.value : null,
      SubscriptionTypeId: item.subscriptionType
        ? item.subscriptionType.value
        : null,
      StartDate: item.startDate,
      EndDate: item.endDate,
      NumberOfMemberList: _.map(item.numberOfMemberList, (m) => ({
        Id: m.id,
        OrgSubscriptionId: m.orgSubscriptionId ? m.orgSubscriptionId : item.id,
        RoleId: m.role ? m.role.value : null,
        Amount: m.amount ? parseInt(m.amount) : 0,
      })),
    };
    api
      .post("admin/SaveOrgSubscription", payload)
      .then((response) => {
        if (response?.data?.success) {
          Alert.success("Subscription has been saved");
          //setCompanyMember(null);
          refreshData();
          refreshOrgSelectList();
        } else {
          Alert.error(response.data.message || "An error occurred");
        }
      })
      .catch((error) => Alert.error("There was an error saving subscription"));
  }

  function onMemberInputChange(subIndex, value, roleIdx) {
    let plist = Object.assign({}, paginatedList);
    let memberList = plist.list[subIndex].numberOfMemberList;
    memberList[roleIdx].amount = value;
    plist.list[subIndex].numberOfMemberList = memberList;
    setPaginatedList(plist);
  }
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return <div>{props.children}</div>;
          case breakpoints.desktop:
          default:
            return (
              <DesktopContainer screenName={"Hauler Subscriptions"}>
                <FilterableScreen
                  filters={
                    <Fragment>
                      <Row>
                        <Col xs="10" className="px-0">
                          <FilterSet
                            filters={filters}
                            clearFilters={() => setFilters([])}
                          >
                            <Row>
                              <Col xs="3">
                                <FilterText
                                  filterName="Name"
                                  displayName="Company Name"
                                  value={currentFilterValue("Name")}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                              <Col xs="3">
                                <FilterSelect
                                  filterName="Status"
                                  displayName="Subscription Status"
                                  filteredDisplay="Status"
                                  options={helpers.addAnyOption(statusList)}
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "Status"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  clearValue={() =>
                                    onFilterChange({
                                      filterName: "Status",
                                      value: null,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs="3">
                                <FilterSelect
                                  filterName="SubscriptionTypeId"
                                  displayName="Subscription Type"
                                  filteredDisplay="Subscription Type"
                                  options={helpers.addAnyOption(
                                    subscriptionTypeList
                                  )}
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "SubscriptionTypeId"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                  clearValue={() =>
                                    onFilterChange({
                                      filterName: "SubscriptionTypeId",
                                      value: null,
                                    })
                                  }
                                />
                              </Col>

                              <Col xs="3">
                                <FilterSwitch
                                  filterName="ActiveOnly"
                                  displayName="Active Only"
                                  value={filterHelpers.currentFilterValue(
                                    filters,
                                    "ActiveOnly"
                                  )}
                                  onChangeCallback={(e) => onFilterChange(e)}
                                />
                              </Col>
                            </Row>
                          </FilterSet>
                        </Col>
                        <Col xs="2" className="pl-0 pr-5">
                          <ButtonGroup className="float-right">
                            <Button
                              onClick={refreshData}
                              title="Refresh"
                              color="dark"
                            >
                              <FontAwesomeIcon icon="sync" />
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  pager={
                    <div className="float-right">
                      <Pager
                        pageNumber={
                          paginatedList?.pageNumber
                            ? paginatedList.pageNumber
                            : 0
                        }
                        totalPages={
                          paginatedList?.totalPages
                            ? paginatedList.totalPages
                            : 0
                        }
                        callBack={(newPageNumber) =>
                          setPageNumber(newPageNumber)
                        }
                      />
                    </div>
                  }
                >
                  <Row className="p-0 m-0">
                    <Col xs="12">
                      <Table
                        striped
                        hover
                        size="sm"
                        responsive={false}
                        id="orgSubscriptionTable"
                      >
                        <thead>
                          {paginatedList &&
                            paginatedList.list &&
                            !_.some(paginatedList.list, (l) => {
                              return l.editing;
                            }) && (
                              <tr>
                                <th>Company</th>
                                <th>Subscription Status</th>
                                <th>Subscription Type</th>
                                <th>Expiration Date</th>
                                <th>
                                  <Button
                                    size="sm"
                                    className="projectSuccess mt-2 float-right"
                                    onClick={() => addNew()}
                                  >
                                    <FontAwesomeIcon icon="plus" /> Add New
                                    Subscription
                                  </Button>
                                </th>
                              </tr>
                            )}
                        </thead>
                        <tbody>
                          {paginatedList.list && paginatedList.list.length ? (
                            _.map(paginatedList.list, (o, subIndex) => (
                              <tr key={o.id}>
                                {o.editing ? (
                                  <Fragment>
                                    <td colSpan="4">
                                      <Card>
                                        <CardBody>
                                          <Row>
                                            <Col>
                                              {o.id < 0 &&
                                                orgList &&
                                                !orgList.length && (
                                                  <span className="text-danger mb-2">
                                                    There are no Companies
                                                    requiring subscriptions at
                                                    this time
                                                  </span>
                                                )}
                                              <Row className="mb-2">
                                                <Col>
                                                  Company
                                                  <Select
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    components={makeAnimated()}
                                                    options={orgList}
                                                    onChange={(e) =>
                                                      onInputChange(
                                                        e,
                                                        subIndex,
                                                        "org"
                                                      )
                                                    }
                                                    value={o.org || null}
                                                    onBlurResetsInput={false}
                                                    onSelectResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    classNamePrefix="react-select"
                                                    isDisabled={o.id > 0}
                                                  />
                                                </Col>
                                                <Col>
                                                  Subscription Type
                                                  <Select
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    components={makeAnimated()}
                                                    options={
                                                      subscriptionTypeList
                                                    }
                                                    onChange={(e) =>
                                                      onInputChange(
                                                        e,
                                                        subIndex,
                                                        "subscriptionType"
                                                      )
                                                    }
                                                    value={
                                                      o.subscriptionType || null
                                                    }
                                                    onBlurResetsInput={false}
                                                    onSelectResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    classNamePrefix="react-select"
                                                  />
                                                </Col>
                                                <Col>
                                                  Start Date
                                                  <div>
                                                    <DatePicker
                                                      name="startDate"
                                                      selected={o.startDate}
                                                      className="form-control"
                                                      placeholderText="Select date"
                                                      onChange={(e) =>
                                                        onInputChange(
                                                          e,
                                                          subIndex,
                                                          "startDate"
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                                <Col>
                                                  End Date
                                                  <div>
                                                    <DatePicker
                                                      name="endDate"
                                                      selected={o.endDate}
                                                      className="form-control"
                                                      placeholderText="Select date"
                                                      onChange={(e) =>
                                                        onInputChange(
                                                          e,
                                                          subIndex,
                                                          "endDate"
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row className="">
                                                <Col>
                                                  <span className="title-normal">
                                                    Add Number Of Users Per Role
                                                  </span>
                                                  <Card>
                                                    <CardBody>
                                                      <Row className="">
                                                        <Col>
                                                          <span className="information">
                                                            One admin user is
                                                            allotted by default
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                      <Row className="mt-2">
                                                        <Col xs="3">
                                                          <Table>
                                                            <thead>
                                                              <tr>
                                                                <th>Role</th>
                                                                <th>Amount</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {o.numberOfMemberList.map(
                                                                (
                                                                  r,
                                                                  roleIdx
                                                                ) => (
                                                                  <tr
                                                                    key={`m-${roleIdx}`}
                                                                  >
                                                                    <td>
                                                                      {
                                                                        r.role
                                                                          .label
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={
                                                                          r.amount ||
                                                                          ""
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          onMemberInputChange(
                                                                            subIndex,
                                                                            e
                                                                              .target
                                                                              .value,
                                                                            roleIdx
                                                                          )
                                                                        }
                                                                      />
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </Table>
                                                        </Col>
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </td>
                                    <td>
                                      <ButtonGroup className="float-right">
                                        <Button
                                          size="sm"
                                          className="projectSecondary float-right"
                                          onClick={() => onCancel(subIndex)}
                                          title={`Cancel Edit`}
                                        >
                                          <FontAwesomeIcon icon="reply" />
                                        </Button>
                                        {o.id < 0 &&
                                        orgList &&
                                        !orgList.length ? null : (
                                          <Button
                                            size="sm"
                                            className="projectPrimary float-right"
                                            onClick={() => onSave(subIndex)}
                                            title={`Save subscription for ${o.orgName}`}
                                          >
                                            <FontAwesomeIcon icon="save" />
                                          </Button>
                                        )}
                                      </ButtonGroup>
                                    </td>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <td>
                                      <Link to={`/companyProfile/${o.orgId}`}>
                                        {o.orgName}
                                      </Link>
                                    </td>
                                    <td>
                                      <Badge
                                        className={classnames({
                                          projectSuccess:
                                            o.isSubscriptionActive,
                                          projectDanger:
                                            !o.isSubscriptionActive,
                                        })}
                                      >
                                        {o.isSubscriptionActive
                                          ? "Active"
                                          : "Inactive"}
                                      </Badge>
                                    </td>
                                    <td>{o.subscriptionTypeName}</td>
                                    <td>
                                      {dateHelpers.dateTimeFormat(
                                        o.endDate,
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <ButtonGroup className="float-right">
                                        {o.deactivatedAt ? (
                                          <Button
                                            size="sm"
                                            className="projectInfo float-right"
                                            onClick={() => onRevive(subIndex)}
                                            title={`Revive subscription for ${o.orgName}`}
                                            disabled={_.some(
                                              paginatedList.list,
                                              (p) => p.editing
                                            )}
                                          >
                                            <FontAwesomeIcon icon="recycle" />
                                          </Button>
                                        ) : (
                                          <Fragment>
                                            <Button
                                              size="sm"
                                              className="projectPrimary float-right"
                                              onClick={() => onEdit(subIndex)}
                                              title={`Edit subscription for ${o.orgName}`}
                                              disabled={_.some(
                                                paginatedList.list,
                                                (p) => p.editing
                                              )}
                                            >
                                              <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            <Button
                                              size="sm"
                                              className="projectDanger float-right"
                                              onClick={() => onDelete(subIndex)}
                                              title={`Delete subscription for ${o.orgName}`}
                                              disabled={_.some(
                                                paginatedList.list,
                                                (p) => p.editing
                                              )}
                                            >
                                              <FontAwesomeIcon icon="trash-alt" />
                                            </Button>
                                          </Fragment>
                                        )}
                                      </ButtonGroup>
                                    </td>
                                  </Fragment>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </FilterableScreen>
              </DesktopContainer>
            );
        }
      }}
    </Media>
  );
}
