import React, { useState, useEffect, Fragment } from "react";
import { Media } from "react-breakpoints";
import { Col, Button, Input, Row, Table } from "reactstrap";
import Switch from "react-switch";
import Alert from "react-s-alert";
import { api, constants, helpers } from "../utils";
import { SHBSpinner } from "../components";
import { DesktopContainer } from "../layouts";
import _ from "lodash";

export default function UnInvoicedLineItems(props) {
  const [orgList, setOrgList] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(getUninvoicedLineItemsForOrg, [selectedOrgId]);

  useEffect(getOrgList, []);

  function getUninvoicedLineItemsForOrg() {
    if (!selectedOrgId) {
      return;
    }

    setLoading(true);
    api
      .fetch(`Invoice/GetUnInvoicedInvoiceLineItemsForOrg/${selectedOrgId}`)
      .then((response) => {
        if (response && response.data) {
          const data = response.data.map((x) => {
            return {
              ...x,
              invoiceLines: x.invoiceLines.map((l) => {
                return {
                  ...l,
                  tempAmount: (l.amount / 100).toFixed(2).toString(),
                };
              }),
            };
          });
          setGroupedData(data);
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => setLoading(false));
  }

  function getOrgList() {
    api
      .post("Org/CompanySelectList", { SortField: "NAME" })
      .then((response) => {
        if (response && response.data) {
          setOrgList(response.data);
        }
      })
      .catch(helpers.catchHandler);
  }

  function createInvoice(sendEmail) {
    _.each(groupedData, (x) => {
      if (
        _.find(x.invoiceLines, function (y) {
          return y.description === "Total Job Compensation Amount" && y.includeInInvoice;
        }) && _.filter(x.invoiceLines, z => z.includeInInvoice).length > 1
      ) {
        Alert.error("Please submit either Total Job Compensation Amount or individual line items for each job in invoice");
        return false;
      }
      return true;
    })


    const allLineItems = _.flatten(
      groupedData.map((x) => {
        return x.invoiceLines;
      })
    );

    if (
      _.find(allLineItems, function (x) {
        return isNaN(x.tempAmount);
      })
    ) {
      Alert.error("Please ensure all amounts are valid");
      return false;
    }

    if (
      !_.find(allLineItems, function (x) {
        return x.includeInInvoice;
      })
    ) {
      Alert.error("Please enable at least one line item to invoice");
      return false;
    }

    const validatedLineItems = allLineItems.map((x) => {
      return {
        ...x,
        amount: parseFloat(x.tempAmount) * 100,
      };
    });

    const payload = {
      OrgId: selectedOrgId,
      InvoiceLines: validatedLineItems,
      Comment: comment,
      DeliverEmail: sendEmail,
    };

    api
      .post("Invoice/CreateInvoice", payload)
      .then(() => {
        Alert.success("The invoice was created");
        getUninvoicedLineItemsForOrg();
        setComment("");
      })
      .catch(helpers.catchHandler);
  }

  function changeValue(jobIndex, itemIndex, fieldName, value) {
    const newGroupedData = groupedData.map((x, index) => {
      if (index === jobIndex) {
        return {
          ...x,
          invoiceLines: x.invoiceLines.map((l, lineIndex) => {
            if (lineIndex === itemIndex) {
              return {
                ...l,
                [fieldName]: value,
              };
            } else {
              return l;
            }
          }),
        };
      } else {
        return x;
      }
    });
    setGroupedData(newGroupedData);
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        switch (currentBreakpoint) {
          case breakpoints.mobile:
            return (
              <div>
                <SHBSpinner
                  on={loading}
                  className="spinnerCenter"
                  phrases={constants.PHRASES}
                >
                  {props.children}
                </SHBSpinner>
              </div>
            );
          case breakpoints.desktop:
          default:
            return (
              <SHBSpinner
                on={loading}
                className="spinnerCenter"
                phrases={constants.PHRASES}
              >
                <DesktopContainer screenName={"Un-Invoiced Items"}>
                  <Row className="p-0 m-0">
                    <Col xs="6">
                      <select
                        className="form-control"
                        value={selectedOrgId}
                        onChange={(e) => setSelectedOrgId(e.target.value)}
                      >
                        <option value=""></option>
                        {orgList &&
                          orgList.length > 0 &&
                          orgList.map((x, index) => (
                            <option key={`org-${index}`} value={x.id}>
                              {x.name}
                            </option>
                          ))}
                      </select>
                    </Col>
                    <Col xs="6">
                      {selectedOrgId && (
                        <>
                          <Button
                            onClick={() => createInvoice(true)}
                            className="btn"
                            style={{ float: "right" }}
                          >
                            Create Invoice and Email
                          </Button>
                          <Button
                            onClick={() => createInvoice(false)}
                            className="btn"
                            style={{ float: "right", marginRight: "1rem" }}
                          >
                            Create Invoice as Pending
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="p-0 m-0 mt-4">
                    <Col xs="6">
                      <label>Invoice Comment:</label>
                      <Input
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-0 ml-0 mr-0">
                    <Col xs="12">
                      {groupedData && groupedData.length > 0 && (
                        <Table striped hover size="sm" responsive={false}>
                          <thead>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupedData.map((x, index) => (
                              <Fragment key={`job-${index}`}>
                                <tr>
                                  <span
                                    style={{
                                      padding: ".25rem",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {x.jobHeaderDescription}
                                  </span>
                                </tr>
                                <tr>
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th style={{ paddingLeft: "1rem" }}>
                                          Description
                                        </th>
                                        <th>Amount</th>
                                        <th>Include In Invoice</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {x.invoiceLines.map((l, lineIndex) => (
                                        <tr
                                          key={`job-${index}-line${lineIndex}`}
                                        >
                                          <td style={{ paddingLeft: "1rem" }}>
                                            <Input
                                              value={l.description}
                                              onChange={(e) =>
                                                changeValue(
                                                  index,
                                                  lineIndex,
                                                  "description",
                                                  e.target.value
                                                )
                                              }
                                              disabled={l.description === "Total Job Compensation Amount"}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              value={l.tempAmount}
                                              onChange={(e) =>
                                                changeValue(
                                                  index,
                                                  lineIndex,
                                                  "tempAmount",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Switch
                                              checked={l.includeInInvoice}
                                              onChange={() =>
                                                changeValue(
                                                  index,
                                                  lineIndex,
                                                  "includeInInvoice",
                                                  !l.includeInInvoice
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </tr>
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      )}
                      {groupedData && groupedData.length === 0 && (
                        <div>
                          No un-invoiced items found for this organization
                        </div>
                      )}
                    </Col>
                  </Row>
                </DesktopContainer>
              </SHBSpinner>
            );
        }
      }}
    </Media>
  );
}
