import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
import Sidebar from "react-sidebar";
import _ from "lodash";
import {
  FaBars,
  FaUserCircle,
  FaTrailer,
  FaChartLine,
  FaBriefcase
} from "react-icons/fa";
import { RiListSettingsLine } from "react-icons/ri";
import { RiUserSettingsLine, RiMoneyDollarBoxLine } from "react-icons/ri";
import { TiFlowSwitch } from "react-icons/ti";
import { BsBuilding } from "react-icons/bs";
import { GiTruck, GiReceiveMoney, GiVote } from "react-icons/gi";
import { VscSignOut } from "react-icons/vsc";
import { BsFillHouseDoorFill, BsChatDots } from "react-icons/bs";
import { IoCalendarOutline } from "react-icons/io5";

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink as ReactStrapNavLink,
} from "reactstrap";

import { api, UserContext, constants } from "../utils";
import { useInterval } from "../hooks/useInterval";

const homeIcon = (
  <BsFillHouseDoorFill className="leftMenuButtons align-self-center" />
);
const shedIcon = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    className="leftMenuButtons align-self-center"
    style={{ fill: "#180375" }}
  >
    <g>
      <g>
        <path
          d="M478.377,86.928L269.374,2.608c-8.625-3.478-18.125-3.477-26.749,0.001L33.623,86.928
			c-18.28,7.375-27.153,28.247-19.778,46.528c7.375,18.28,28.247,27.152,46.529,19.779l19.35-7.806v356.374
			c0,5.632,4.566,10.199,10.199,10.199h88.54h155.075h88.54c5.633,0,10.199-4.567,10.199-10.199V145.428l19.354,7.808
			c4.284,1.726,8.779,2.602,13.364,2.602c14.66,0,27.677-8.785,33.162-22.382C505.53,115.175,496.658,94.302,478.377,86.928z
			 M168.264,491.602h-68.142v-44.875h68.142V491.602z M168.264,426.329h-68.142v-41.851h68.142V426.329z M168.264,364.081h-68.142
			v-44.769h68.142V364.081z M168.264,298.914h-68.142v-41.85h68.142V298.914z M323.339,491.602H188.662V231.968h134.677V491.602z
			 M411.878,491.602h-68.142v-44.875h68.142V491.602z M411.878,426.329h-68.142v-41.851h68.142V426.329z M411.878,364.081h-68.142
			v-44.769h68.142V364.081z M411.878,298.914h-68.142v-41.85h68.142V298.914z M411.878,236.664h-68.142v-14.896
			c0-5.632-4.566-10.199-10.199-10.199H178.462c-5.633,0-10.199,4.567-10.199,10.199v14.896h-68.141v-45.931h184.3
			c5.633,0,10.199-4.567,10.199-10.199s-4.566-10.199-10.199-10.199h-184.3v-33.138l21.117-8.677h269.129l21.511,8.678V236.664z
			 M172.192,108.122L256,74.312l83.807,33.81H172.192z M479.239,125.824c-2.357,5.842-7.948,9.616-14.245,9.616
			c-1.958,0-3.889-0.377-5.736-1.122L259.816,53.855c-1.224-0.494-2.52-0.741-3.815-0.741c-1.295,0-2.592,0.247-3.815,0.741
			L52.743,134.317c-7.851,3.166-16.815-0.643-19.981-8.493c-3.167-7.85,0.643-16.813,8.493-19.981l209.001-84.318
			c3.704-1.494,7.785-1.494,11.489-0.001l209.002,84.319C478.596,109.01,482.406,117.973,479.239,125.824z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M360.914,170.336h-33.656c-5.633,0-10.199,4.567-10.199,10.199s4.566,10.199,10.199,10.199h33.656
			c5.633,0,10.199-4.567,10.199-10.199S366.547,170.336,360.914,170.336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M219.149,334.538c-7.311,0-13.259,5.948-13.259,13.259s5.948,13.259,13.259,13.259s13.259-5.948,13.259-13.259
			S226.46,334.538,219.149,334.538z"
        />
      </g>
    </g>
  </svg>
);

const userIcon = (
  <RiUserSettingsLine className="leftMenuButtons align-self-center" />
);

const adminIcon = (
  <RiListSettingsLine className="leftMenuButtons align-self-center" />
);

const companyIcon = (
  <BsBuilding className="leftMenuButtons align-self-center" />
);

const scheduleIcon = (
  <IoCalendarOutline className="leftMenuButtons align-self-center" />
)

const jobIcon = <FaTrailer className="leftMenuButtons align-self-center" />;
const privateJobIcon = <FaBriefcase className="leftMenuButtons align-self-center" />;
const haulerFirmIcon = (
  <GiTruck className="leftMenuButtons align-self-center" />
);
const reportIcon = (
  <FaChartLine className="leftMenuButtons align-self-center" />
);
const subscriptionIcon = (
  <GiReceiveMoney className="leftMenuButtons align-self-center" />
);
const ratingIcon = <GiVote className="leftMenuButtons align-self-center" />;

const chatIcon = <BsChatDots className="leftMenuButtons align-self-center" />;

const invoiceIcon = (
  <RiMoneyDollarBoxLine className="leftMenuButtons align-self-center" />
);

const HaulerMenuLinks = [
  { url: "/jobs/hauler", title: "Jobs", icon: jobIcon },
  { url: "/privatejobs", title: "Private Jobs", icon: privateJobIcon },
  { url: "/schedule", title: "Schedule", icon: scheduleIcon }
];

const HaulerFirmMenuLinks = [
  { url: "/jobs/haulerFirm", title: "Jobs", icon: jobIcon },
  { url: "/privatejobs", title: "Private Jobs", icon: privateJobIcon },
  { url: "/org/companies/haulerFirm", title: "Companies", icon: companyIcon },
  { url: "/users/haulerFirm", title: "Users", icon: userIcon },
  { url: "/invoices", title: "Invoices", icon: invoiceIcon },
  { url: "/admin/profile/haulerFirm", title: "Admin", icon: adminIcon },
  { url: "/schedule", title: "Schedule", icon: scheduleIcon }
];
const CompanyAdminMenuLinks = [
  { url: "/jobs/company", title: "Jobs", icon: jobIcon },
  { url: "/sheds/company", title: "Shed Sightings", icon: shedIcon },
  {
    url: "/org/haulerFirms/company",
    title: "Hauler Firms",
    icon: haulerFirmIcon,
  },
  { url: "/users/company", title: "Users", icon: userIcon },
  { url: "/admin/profile/company", title: "Admin", icon: adminIcon },
  { url: "/schedule", title: "Schedule", icon: scheduleIcon }
];
const CompanyMenuLinks = [
  { url: "/jobs/company", title: "Jobs", icon: jobIcon },
  { url: "/sheds/company", title: "Shed Sightings", icon: shedIcon },
  {
    url: "/org/haulerFirms/company",
    title: "Hauler Firms",
    icon: haulerFirmIcon,
  },
  { url: "/schedule", title: "Schedule", icon: scheduleIcon }
];
const AdminMenuLinks = [
  { url: "/jobs/admin", title: "Jobs", icon: jobIcon },
  {
    url: "/org/haulerFirms/admin",
    title: "Hauler Firms",
    icon: haulerFirmIcon,
  },
  { url: "/org/companies/admin", title: "Companies", icon: companyIcon },
  { url: "/users/admin", title: "Users", icon: userIcon },
  { url: "/admin", title: "Admin", icon: adminIcon },
  { url: "/reports", title: "Reports", icon: reportIcon },
  { url: "/subscriptions", title: "Subscriptions", icon: subscriptionIcon },
  { url: "/ratingscontainer/admin", title: "Ratings", icon: ratingIcon },
  { url: "/schedule", title: "Schedule", icon: scheduleIcon }
];

export default function SidebarMenu() {
  const userCtx = useContext(UserContext);
  const [homeLink, setHomeLink] = useState("/dashboard/hauler");
  const [links, setLinks] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  let location = useLocation();
  const [newChatCount, setNewChatCount] = useState(0);

  const currentUser = userCtx?.currentUser ? userCtx.currentUser : null;

  useInterval(() => {
    //get new chat status
    api
      .fetch("chat/NewChatCount")
      .then((response) => setNewChatCount(response.data));
  }, 60000);

  useEffect(() => {
    let userLinks = [];
    let homeLink = "";
    if (
      currentUser?.includeHaulerModule &&
      (currentUser?.currentRoleId === constants.ROLE_IDS.DRIVER ||
        currentUser?.currentRoleId === constants.ROLE_IDS.DISPATCHER)
    ) {
      homeLink = "/dashboard/hauler";
      userLinks = userLinks.concat(HaulerMenuLinks);
    }
    if (
      currentUser?.includeCompanyModule &&
      (currentUser?.currentRoleId === constants.ROLE_IDS.COMPANY_CLERK ||
        currentUser?.currentRoleId === constants.ROLE_IDS.COMPANY_USER)
    ) {
      homeLink = "/dashboard/company";
      userLinks = userLinks.concat(CompanyMenuLinks);
    }
    if (
      currentUser?.includeCompanyAdminModule &&
      currentUser?.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN
    ) {
      homeLink = "/dashboard/company";
      userLinks = userLinks.concat(CompanyAdminMenuLinks);
    }
    if (
      currentUser?.includeHaulerFirmModule &&
      currentUser?.currentRoleId === constants.ROLE_IDS.COMPANY_ADMIN
    ) {
      homeLink = "/dashboard/haulerFirm";
      userLinks = userLinks.concat(HaulerFirmMenuLinks);
    }
    if (currentUser?.includeAdminModule && currentUser?.isSysAdmin) {
      homeLink = "/dashboard/admin";
      userLinks = userLinks.concat(AdminMenuLinks);
    }
    //userLinks = userLinks.concat([{ url: "/schedule", title: "Schedule", icon: scheduleIcon }]);
    setHomeLink(homeLink);
    setLinks(userLinks);
  }, [currentUser]);

  function shouldShowSwitchButton() {
    return (
      currentUser?.roleTypeIdList?.length &&
      currentUser.roleTypeIdList.length > 1
    );
  }

  function toggleSidebar(openOrClose) {
    setSidebarOpen(openOrClose);
  }

  const toggleDropdown = () => setOpenDropdown(!openDropdown);
  const linkItem = (link, index) => {
    if (link.url) {
      return (
        <NavItem
          key={`${sidebarOpen ? "open" : "closed"}Link${index}`}
          title={link.title}
        >
          <ReactStrapNavLink
            tag={NavLink}
            to={link.url}
            active={location.pathname === link.url}
            className="p-0"
          >
            <Row className="mx-0 my-1 p-0 align-items-center justify-content-center menuButtonColor">
              <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
                {link.icon}
              </Col>
              {sidebarOpen && (
                <Col xs="9" className="text-right">
                  {link.title}
                </Col>
              )}
            </Row>
          </ReactStrapNavLink>
        </NavItem>
      );
    }
  };
  const menuStyling = classnames(
    {
      stickyLeftMenuOpen: sidebarOpen,
      stickyLeftMenuCollapsed: !sidebarOpen,
    },
    "stickyLeftMenu p-0 m-0 py-2"
  );

  let menu = (
    <div className={menuStyling}>
      <div className="topLeftMenuSection">
        <Row
          className="m-0 mb-2 p-0 align-items-center justify-content-center "
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
            <FaBars className="barsButton align-self-center menuButtonColor" />
          </Col>
          {sidebarOpen && (
            <Col xs="9" className="menuButtonColor">
              {userCtx.currentUser.label}
            </Col>
          )}
        </Row>
        <Nav vertical={"true"}>
          {linkItem({ url: homeLink, title: "Dashboard", icon: homeIcon }, -1)}

          {links && links.length > 0 ? (
            <Nav vertical={"true"}>
              {_.map(links, (l, index) => {
                return linkItem(l, index);
              })}
            </Nav>
          ) : null}
          {linkItem(
            {
              url: "/chats",
              title: newChatCount ? `Chats (${newChatCount} new)` : "Chats",
              icon: chatIcon,
            },
            links && links.length > 0 ? links.length : 0
          )}
        </Nav>
      </div>
      <div className="bottomLeftMenuSection ">
        <Nav vertical>
          {shouldShowSwitchButton() ? (
            <NavItem
              key={`${sidebarOpen ? "open" : "closed"}SwitchContext`}
              title="Switch Context"
              onClick={toggleDropdown}
            >
              <Row className="m-0 p-0 align-items-center justify-content-center">
                <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0 dropbtn">
                  <TiFlowSwitch className="leftMenuButtons align-self-center menuButtonColor" />
                </Col>
                {sidebarOpen && (
                  <Col xs="9" className="text-right menuButtonColor">
                    Switch Role
                  </Col>
                )}
              </Row>
              {openDropdown && (
                <div>
                  {currentUser.roles.map(
                    (
                      r,
                      index //roleTypeNameList
                    ) => (
                      <div
                        key={index}
                        className="dropdown-content"
                        onClick={() => userCtx.setCurrentUserContext(r)}
                      >
                        {r.roleName} - {r.orgName}
                      </div>
                    )
                  )}
                </div>
              )}
            </NavItem>
          ) : null}
          <NavItem
            key={`${sidebarOpen ? "open" : "closed"}LinkProfile`}
            title="Profile"
          >
            <ReactStrapNavLink
              tag={NavLink}
              to={"/profile"}
              className="p-0"
              active={location.pathname === "/profile"}
            >
              <Row className="mx-0 my-1 p-0 align-items-center justify-content-center menuButtonColor">
                <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
                  <FaUserCircle className="leftMenuButtons align-self-center menuButtonColor profileButton" />
                </Col>
                {sidebarOpen && (
                  <Col xs="9" className="text-right">
                    Profile
                  </Col>
                )}
              </Row>
            </ReactStrapNavLink>
          </NavItem>
          <NavItem
            key={`${sidebarOpen ? "open" : "closed"}LinkSignOut`}
            title="Profile"
          >
            <ReactStrapNavLink
              onClick={() => userCtx.signOut()}
              className="p-0"
            >
              <Row className="mx-0 my-1 p-0 align-items-center justify-content-center menuButtonColor">
                <Col xs={sidebarOpen ? "3" : "12"} className="p-0 m-0">
                  <VscSignOut className="leftMenuButtons align-self-center menuButtonColor signOutButton flipHorizontal" />
                </Col>
                {sidebarOpen && (
                  <Col xs="9" className="text-right">
                    Sign Out
                  </Col>
                )}
              </Row>
            </ReactStrapNavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );

  return (
    <Sidebar
      sidebar={sidebarOpen && menu} // opened menu
      open={sidebarOpen}
      styles={{
        sidebar: {
          background: "white",
          zIndex: 2000,
          minWidth: 200,
          // marginTop: 70,
        },
      }}
      pullRight={false}
      onSetOpen={() => toggleSidebar(!sidebarOpen)}
    >
      {!sidebarOpen && menu} {/* collapsed menu */}
    </Sidebar>
  );
}
